define("boondmanager/initializers/settings-init", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    application.inject('route', 'settings', 'service:settings');
    application.inject('component', 'settings', 'service:settings');
    application.inject('route', 'messages', 'service:messages');
    application.inject('component', 'messages', 'service:messages');
    application.inject('controller', 'messages', 'service:messages');
  }
  var _default = _exports.default = {
    name: 'settings-init',
    after: 'config-meta',
    initialize: initialize
  };
});