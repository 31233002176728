define("boondmanager/pods/components/octane/bm-modal/merge/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "CjB/eO68",
    "block": "{\"symbols\":[\"@showMergeModal\",\"&attrs\"],\"statements\":[[8,\"octane/bm-modal\",[[17,2]],[[\"@modalNativeDisplayed\",\"@onClosed\",\"@onOpen\"],[[32,1],[32,0,[\"onClose\"]],[32,0,[\"onOpen\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "boondmanager/pods/components/octane/bm-modal/merge/template.hbs"
  });
});