define("boondmanager/adapters/device", ["exports", "boondmanager/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    //queryRecordUrlTemplate: '{+host}/api/my-account/devices{/id}',
    urlTemplate: '{+host}/api/my-account/devices{/id}{?query*}'
  });
});