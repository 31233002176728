define("boondmanager/pods/components/octane/bm-progress-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "r5tHAjkJ",
    "block": "{\"symbols\":[\"@centered\",\"@haveError\",\"&attrs\",\"@text\",\"@hidePercentage\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"bmc-progressbar\",[30,[36,1],[[32,0,[\"isFull\"]],\" bmc-progressbar_full\"],null],[30,[36,1],[[32,0,[\"isOver\"]],\" bmc-progressbar_over\"],null],[30,[36,1],[[32,2],\" bmc-progressbar_error\"],null],[30,[36,1],[[32,1],\" bmc-progressbar-center\"],null]]]],[17,3],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"bmc-progressbar_infos\"],[12],[2,\"\\n\\t\\t\"],[10,\"span\"],[14,0,\"current-value\"],[12],[2,[32,4]],[13],[2,\"\\n\"],[6,[37,1],[[30,[36,2],[[32,5]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"span\"],[14,0,\"percentageaccomplish\"],[12],[1,[30,[36,0],[[32,0,[\"percentage\"]]],[[\"decimals\"],[2]]]],[2,\" %\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\\n\\t\"],[10,\"div\"],[15,0,[31,[\"bmc-progressbar_bar \",[30,[36,1],[[30,[36,3],[[30,[36,0],[[32,0,[\"percentage\"]]],[[\"decimals\"],[2]]],100],null],\"bm-full\"],null]]]],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"bmc-progressbar_content\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"span\"],[14,0,\"bmc-progressbar_progress\"],[15,5,[30,[36,5],[[30,[36,4],[\"width : \",[32,0,[\"percentage\"]],\"%\"],null]],null]],[12],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"number\",\"if\",\"not\",\"eq\",\"concat\",\"html-safe\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-progress-bar/template.hbs"
  });
});