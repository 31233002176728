define("boondmanager/pods/components/octane/bm-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "neU+Lfm0",
    "block": "{\"symbols\":[\"@help\",\"&default\",\"@noSpan\",\"@namedBlocksInfo\",\"@iconOnly\",\"@disabled\",\"@ariaLabel\",\"&attrs\",\"@closeButton\"],\"statements\":[[11,\"button\"],[16,0,[31,[\"bmc-btn\",[30,[36,4],[[32,6],\" bm-tooltips\"],null],[30,[36,2],[[32,6],\" disabled\"],null]]]],[16,\"aria-label\",[32,7]],[16,\"disabled\",[32,6]],[17,8],[4,[38,0],[\"click\",[32,0,[\"onClick\"]]],null],[12],[2,\"\\n\"],[6,[37,2],[[32,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[1,[30,[36,1],[[32,5]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,3],[[32,4],\"default\",[27,[32,2]]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\\t\"],[10,\"span\"],[14,0,\"bmb-content\"],[12],[18,2,null],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[6,[37,2],[[32,9]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[11,\"span\"],[24,0,\"bmb-rectangle_close\"],[4,[38,0],[\"click\",[32,0,[\"close\"]]],null],[12],[1,[30,[36,1],[\"bmi-close-circle\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[8,\"octane/bm-help\",[[16,\"data-original-title\",[32,1]]],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"icon\",\"if\",\"-has-block\",\"unless\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-btn/template.hbs"
  });
});