define("boondmanager/pods/components/octane/bm-gadgets/widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2C5gatuh",
    "block": "{\"symbols\":[\"@gadget\",\"@onConfigureGadget\",\"@entityRights\"],\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"widgetType\"]],\"resourceTimesReports\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-gadgets/widget/resource-times-reports\",[],[[\"@gadget\",\"@onConfigureGadget\"],[[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"widgetType\"]],\"resourceExpensesReports\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-gadgets/widget/resource-expenses-reports\",[],[[\"@gadget\",\"@onConfigureGadget\"],[[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"widgetType\"]],\"resourceAbsencesReports\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-gadgets/widget/resource-absences-reports\",[],[[\"@gadget\",\"@entityRights\",\"@onConfigureGadget\"],[[32,1],[32,3],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"widgetType\"]],\"digitalWorkplace\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-gadgets/widget/digital-workplace\",[],[[\"@gadget\",\"@onConfigureGadget\"],[[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"widgetType\"]],\"myPeriodicalsTargets\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-gadgets/widget/my-periodicals-targets\",[],[[\"@gadget\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-gadgets/widget/charts\",[],[[\"@gadget\",\"@onConfigureGadget\"],[[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-gadgets/widget/template.hbs"
  });
});