define("boondmanager/pods/components/octane/bm-btn/validate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "liCt0Pcl",
    "block": "{\"symbols\":[\"@buttonIcon\",\"@disabled\",\"@onClick\",\"@model\",\"@buttonLabel\",\"&attrs\"],\"statements\":[[8,\"octane/bm-btn\",[[24,0,\"bmc-btn-validate bmb-circle bmb-xlarge bmb-validate bmb-validate-full\"],[24,\"data-name\",\"button-validate\"],[16,\"data-original-title\",[30,[36,1],[[30,[36,2],[[32,2]],null],[32,0,[\"label\"]]],null]],[16,\"aria-label\",[30,[36,1],[[32,5],[30,[36,4],[[32,5]],null],[30,[36,4],[\"common:actions.validate\"],null]],null]],[17,6]],[[\"@disabled\",\"@onClick\"],[[32,2],[30,[36,1],[[30,[36,3],[[30,[36,2],[[32,2]],null],[30,[36,2],[[32,4,[\"isValidating\"]]],null],[30,[36,2],[[32,4,[\"isSaving\"]]],null]],null],[32,3]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[30,[36,5],[[32,4,[\"isValidating\"]],[32,4,[\"isSaving\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[1,[30,[36,0],[\"bmi-spinner fa-pulse\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[1,[30,[36,0],[[32,1]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\"],[1,[30,[36,0],[\"bmi-validate\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\t\"],[10,\"span\"],[14,0,\"bmb-validate-content\"],[12],[2,\"\\n\\t\\t \"],[1,[30,[36,1],[[32,5],[30,[36,4],[[32,5]],null],[30,[36,4],[\"common:actions.validate\"],null]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"if\",\"not\",\"and\",\"t\",\"or\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-btn/validate/template.hbs"
  });
});