define("boondmanager/helpers/lookup-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.lookup = lookup;
  function lookup(params) {
    var array = params[0];
    var entry = params[1];
    var findKey = typeof params[2] !== 'undefined' ? params[2] : 'id';
    var readValue = typeof params[3] !== 'undefined' ? params[3] : 'value';
    for (var i = 0; i < array.length; i++) {
      if (array[i][findKey] === entry) return array[i][readValue];
    }
    return null;
  }
  var _default = _exports.default = Ember.Helper.helper(lookup);
});