define("boondmanager/adapters/deliverygroupment", ["exports", "boondmanager/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    pathForType: function pathForType(modelName) {
      return modelName;
    },
    query: function query(store, type, _query) {
      var url = this.buildURL('deliveries-groupments', null, null, 'query', _query);
      return this.ajax(url, 'GET');
    }
  });
});