define("boondmanager/helpers/has-value-in-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    router: Ember.inject.service(),
    /**
     * @private
     * Find and outputs translated text.
     *
     * @param {Array} params - positional parameters passed to the helper.
     *   The 1st element may be the currentWhen
     * @return {Boolean}
     */
    compute: function compute(params) {
      var arr = params[0];
      var key = params.length > 1 ? params[1] : null;
      var currentRouteName = this.get('router.currentRouteName');
      var depthRoute = 2;
      if (currentRouteName.includes('private.administrator')) {
        currentRouteName = currentRouteName.replace('.index', '');
        depthRoute = 3;
      }
      currentRouteName = currentRouteName.split('.').slice(0, depthRoute).join('.');
      if (typeof arr === 'string') arr = arr.split(' ');
      if (Ember.isArray(arr)) {
        for (var i = 0, len = arr.length; i < len; i++) {
          var compareValue = key !== null ? arr[i][key] : arr[i];
          if (compareValue === currentRouteName || compareValue === this.get('router.currentRouteName')) {
            return true;
          }
        }
      }
      return false;
    },
    refresh: Ember.observer('router.currentRouteName', function () {
      this.recompute();
    })
  });
});