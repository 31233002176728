define("boondmanager/pods/components/octane/bm-field/url/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FOx0XYsy",
    "block": "{\"symbols\":[\"@name\",\"@maxlength\",\"@required\",\"@disabled\",\"@value\",\"&attrs\",\"@title\",\"@iconClass\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"bmc-field-email bm-input-group \",[32,0,[\"classNames\"]]]]],[17,6],[4,[38,4],[[32,0,[\"onInsert\"]]],null],[12],[2,\"\\n\\t\"],[10,\"span\"],[14,0,\"bm-input-group-icon-left bm-tooltips\"],[14,\"data-container\",\"body\"],[15,\"data-original-title\",[32,7]],[12],[2,\"\\n\\t\\t\"],[1,[30,[36,6],[[30,[36,0],[[32,8],[30,[36,5],[[32,5],\" highlight\"],null]],null]],null]],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[6,[37,5],[[32,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[11,\"a\"],[24,6,\"#\"],[24,0,\"bm-link-secondary\"],[4,[38,3],[\"click\",[32,0,[\"openURL\"]]],null],[12],[1,[32,5]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\"],[8,\"octane/bm-field/input\",[[16,3,[32,1]],[16,\"data-name\",[32,1]],[16,1,[32,0,[\"inputId\"]]],[16,\"maxlength\",[32,2]]],[[\"@required\",\"@disabled\",\"@value\",\"@enter\",\"@keyUp\",\"@type\"],[[32,3],[32,4],[32,5],[32,0,[\"onEnter\"]],[32,0,[\"onChange\"]],\"url\"]],null],[2,\"\\n\\t\\t\"],[10,\"span\"],[14,0,\"bm-input-group-btn-right\"],[12],[2,\"\\n\\t\\t\\t\"],[8,\"octane/bm-btn\",[[16,\"data-name\",[30,[36,0],[\"button-\",[32,1]],null]],[24,0,\"bmb-icon\"],[24,\"tabindex\",\"-1\"],[16,\"aria-label\",[30,[36,2],[\"common:actions.create\"],null]]],[[\"@disabled\",\"@iconOnly\",\"@onClick\"],[[30,[36,1],[[32,5]],null],\"bmi-external-link\",[32,0,[\"openURL\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\"],[1,[30,[36,2],[\"common:actions.create\"],null]],[2,\"\\n\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"concat\",\"not\",\"t\",\"on\",\"did-insert\",\"if\",\"icon\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/url/template.hbs"
  });
});