define("boondmanager/pods/components/octane/bm-alerts/actions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/q5jyO9A",
    "block": "{\"symbols\":[\"warning\"],\"statements\":[[6,[37,2],[[32,0,[\"warnings\",\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-alerts\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"warnings\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"li\"],[14,0,\"bmc-alert_list-item\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[1,[30,[36,0],[[32,1,[\"caption\"]]],[[\"app\",\"message\"],[[32,1,[\"app\",\"name\"]],[32,1,[\"message\"]]]]]],[2,\"\\n\"],[6,[37,2],[[35,1,[\"oauthRedirection\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[8,\"link-to\",[[24,0,\"related-entity\"]],[[\"@route\",\"@model\"],[\"private.apps.app\",[32,1,[\"app\",\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[1,[30,[36,0],[[32,1,[\"app\",\"name\"]]],null]],[2,\"\\n\\t\\t\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"item\",\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-alerts/actions/template.hbs"
  });
});