define("boondmanager/pods/components/octane/bm-render-icon/comments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "hJW51G/a",
    "block": "{\"symbols\":[\"@comments\",\"&attrs\"],\"statements\":[[11,\"a\"],[24,6,\"#\"],[24,0,\"bmc-render-comments-icon bmb-icon bm-tooltips\"],[24,\"target\",\"_blank\"],[24,\"rel\",\"noopener noreferrer\"],[24,\"data-html\",\"true\"],[16,\"data-original-title\",[30,[36,0],[[32,1],[32,1],\"\"],null]],[17,2],[4,[38,1],[\"click\",[32,0,[\"onChange\"]]],null],[12],[2,\"\\n\\t\"],[1,[30,[36,2],[[30,[36,0],[[32,1],\"bmi-note\",\"bmi-add\"],null]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"on\",\"icon\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-render-icon/comments/template.hbs"
  });
});