define("boondmanager/pods/components/octane/bm-btn/sort-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "syoNkzI/",
    "block": "{\"symbols\":[\"dd\",\"menuoption\",\"&attrs\",\"@title\",\"@dropdownMenuRight\",\"@name\",\"@options\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-btn-sort-menu\"],[17,3],[4,[38,1],[[32,0,[\"didInsert\"]]],null],[12],[2,\"\\n\\t\"],[8,\"basic-dropdown\",[[24,0,\"bmb-dropdown_label bmb-simple\"],[24,\"data-name\",\"button-sort-menu\"],[16,\"aria-label\",[30,[36,2],[[32,4],\" \",[32,0,[\"selectedOption\"]]],null]]],[[\"@horizontalPosition\"],[[30,[36,3],[[32,5],\"auto-right\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[8,[32,1,[\"Trigger\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[10,\"span\"],[14,0,\"bmb-simple-text\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[32,4]],[2,\"\\n\\t\\t\\t\"],[10,\"span\"],[15,\"data-name\",[31,[\"label-sort-menu-\",[32,6]]]],[12],[2,\"\\n\\t\\t\\t\\t\"],[1,[32,0,[\"selectedOption\"]]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,4],[\"bmi-caret-down\"],null]],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\"],[8,[32,1,[\"Content\"]],[[24,0,\"bmb-dropdown_content\"],[16,\"data-name\",[31,[\"sort-menu-options-\",[32,6]]]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[32,7]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[8,\"octane/bm-btn\",[[24,0,\"dropdown-item\"],[24,\"data-name\",\"button-change-option\"],[16,\"aria-label\",[32,2,[\"value\"]]]],[[\"@onClick\"],[[30,[36,0],[[32,0,[\"changeOption\"]],[32,2,[\"id\"]],[32,1]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\\t\"],[1,[32,2,[\"value\"]]],[2,\"\\n\\t\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"did-insert\",\"concat\",\"if\",\"icon\",\"-track-array\",\"each\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-btn/sort-menu/template.hbs"
  });
});