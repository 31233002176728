define("boondmanager/utils/file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getFileSizeWithUnit = _exports.getFileNameWithoutExtension = _exports.UNIT_CONVERTER = void 0;
  var UNIT_CONVERTER = _exports.UNIT_CONVERTER = 1024;
  var getFileNameWithoutExtension = _exports.getFileNameWithoutExtension = function getFileNameWithoutExtension(fileName) {
    if (!fileName) {
      return '';
    }
    var split = fileName.split('.');
    split.pop();
    return split.join('.');
  };
  var getFileSizeWithUnit = _exports.getFileSizeWithUnit = function getFileSizeWithUnit(fileSize, i18n) {
    var size = fileSize / UNIT_CONVERTER;
    var unit = 'common:units.kilobytes.abbr';
    if (size > UNIT_CONVERTER) {
      size = size / UNIT_CONVERTER;
      unit = 'common:units.megabytes.abbr';
    }
    unit = i18n.t(unit);
    size = size.toFixed(1);
    return "".concat(size, " ").concat(unit);
  };
});