define("boondmanager/utils/activity", ["exports", "ember-copy", "boondmanager/models/project", "boondmanager/models/delivery", "boondmanager/models/batch"], function (_exports, _emberCopy, _project, _delivery, _batch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TYPE_PRODUCTION = _exports.TYPE_OPPORTUNITY = _exports.TYPE_INTERNAL = _exports.TYPE_ABSENCE = void 0;
  _exports.getDefaultActivity = getDefaultActivity;
  _exports.getDuration = getDuration;
  _exports.getIDsFromActivityID = getIDsFromActivityID;
  var TYPE_PRODUCTION = _exports.TYPE_PRODUCTION = 'production';
  var TYPE_INTERNAL = _exports.TYPE_INTERNAL = 'internal';
  var TYPE_ABSENCE = _exports.TYPE_ABSENCE = 'absence';
  var TYPE_OPPORTUNITY = _exports.TYPE_OPPORTUNITY = 'opportunity';

  /**
   * @module Utils
   * @submodule Activiy
   */

  /**
   * Extract Project, Delivery (and Batch - optional) IDs from Activity ID
   *
   * @param  {String}    activityID string of the form "${Project.prefix}${project.id} ${Delivery.prefix}${delivery.id} ${Batch.prefix}${batch.id}"
   * @return {String[]}  The 2 (project, delivery) or 3 (project, delivery and batch) IDs.
   */
  function getIDsFromActivityID(activityID) {
    var prefixes = [_project.default.prefix, _delivery.default.prefix, _batch.default.prefix];
    return activityID.split(' ').map(function (part, idx) {
      return part.replace(prefixes[idx], '');
    });
  }

  /**
   * To get a default activity from a list
   *
   * This method search first 'production' or first 'internal' or first 'absence'
   * activity in `activities` array and return it.
   *
   * @param  {Activity[]}  activities
   * @param  {Boolean}     returnCopy  if `true`, return a copy a the default activity found. Default is `false`.
   * @return {Activity}
   */
  function getDefaultActivity(activities) {
    var returnCopy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var defaultActivity;
    if (activities.length) {
      [TYPE_PRODUCTION, TYPE_INTERNAL, TYPE_ABSENCE].some(function (type) {
        return defaultActivity = activities.find(function (activity) {
          return activity.type === type;
        });
      });
      if (typeof defaultActivity === 'undefined') {
        defaultActivity = activities[0];
      }
    }
    return returnCopy ? (0, _emberCopy.copy)(defaultActivity, true) : defaultActivity;
  }

  /**
   * Get real duration depending on diff in days and current duration
   * @param startDate
   * @param endDate
   * @param currentDate
   * @param duration
   * @returns {number}
   * @private
   */
  function getDuration(startDate, endDate, currentDate, duration) {
    // get decimal part to check how to convert total duration into partial duration
    var intPart = parseInt((duration + '').split('.')[0]);
    var decimalPart = parseInt((duration + '').split('.')[1]);
    var diffInDays = endDate.diff(startDate, 'days') + 1;
    var realDuration;

    // in case of decimal part and current is the same of end date of absence period
    // we return the decimal part only
    if (decimalPart > 0) {
      if (currentDate.isSame(endDate)) {
        var totalDuration = 0;
        var intDuration = diffInDays > 1 ? intPart / (diffInDays - 1) : intPart;
        var newStartDate = startDate.clone();
        do {
          totalDuration += intDuration;
          newStartDate.add(1, 'days');
        } while (newStartDate.isBefore(endDate));
        realDuration = duration - totalDuration;
      } else {
        realDuration = diffInDays > 1 ? intPart / (diffInDays - 1) : intPart;
      }
    } else {
      realDuration = duration / diffInDays;
    }
    return realDuration;
  }
});