define("boondmanager/pods/components/octane/bm-field/select/selected/colors/state/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "yhkGn3C9",
    "block": "{\"symbols\":[\"@selected\"],\"statements\":[[10,\"div\"],[14,0,\"bmc-field-simple-colorpicker-selected-item\"],[12],[2,\"\\n\\t\"],[8,\"octane/bm-sticker\",[],[[\"@sticker\"],[[30,[36,0],[\"bm-state-color-\",[32,1,[\"id\"]]],null]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"concat\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/select/selected/colors/state/template.hbs"
  });
});