define("boondmanager/pods/components/octane/bm-search-filters/fields/simple-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "U1O3HIAE",
    "block": "{\"symbols\":[\"@filter\"],\"statements\":[[6,[37,0],[[32,1,[\"displayFilter\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[15,0,[30,[36,1],[\"col-12\",[30,[36,0],[[32,1,[\"minimal\"]],\" col-xl-6\"],null]],null]],[12],[2,\"\\n\\t\\t\\t\"],[8,\"octane/bm-field\",[],[[\"@name\"],[[32,1,[\"label\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\"],[8,\"octane/bm-field/select\",[[16,\"data-name\",[31,[\"input-\",[32,1,[\"label\"]]]]]],[[\"@addMissingSelected\",\"@options\",\"@selected\",\"@forceSearch\",\"@multiple\",\"@required\",\"@onChange\"],[false,[32,1,[\"options\"]],[32,1,[\"modalValue\"]],false,false,[32,1,[\"required\"]],[32,0,[\"onChange\"]]]],null],[2,\"\\n\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-search-filters/fields/simple-select/template.hbs"
  });
});