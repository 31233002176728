define("boondmanager/helpers/value-under", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Value Under Helper
   * Colors any value under a given test value.
   * @param {object} params Not actually used
   * @param {object} namedParams List of params, containing :
   *      - {number} value The value that needs to be tested (mandatory)
   *      - {string} displayValue The value
   *      - {string} unit The i18n key of the unit associated with the value
   *      - {string} className The CSS class that needs to be applied to the tag
   *      - {string} tag The tag that will be used for this pseudo-component
   *      - {number} testValue The value to test against
   * @returns {string} HTML tag with the applied value
   */
  var _default = _exports.default = Ember.Helper.extend({
    compute: function compute(params, namedParams) {
      var value = namedParams.value,
        displayValue = namedParams.displayValue,
        _namedParams$unit = namedParams.unit,
        unit = _namedParams$unit === void 0 ? '' : _namedParams$unit,
        _namedParams$classNam = namedParams.className,
        className = _namedParams$classNam === void 0 ? '' : _namedParams$classNam,
        _namedParams$tag = namedParams.tag,
        tag = _namedParams$tag === void 0 ? 'span' : _namedParams$tag,
        _namedParams$testValu = namedParams.testValue,
        testValue = _namedParams$testValu === void 0 ? 0 : _namedParams$testValu;
      className += value < testValue ? ' bm-negative' : '';
      return Ember.String.htmlSafe('<' + tag + ' class="' + className + '">' + displayValue + ' ' + unit + '</' + tag + '>');
    }
  });
});