define("boondmanager/initializers/history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * TODO: A EFFACER QUAND ON AURA MIS A JOUR EMBER >= 3.12
   * Le bug a été corrigé : github.com/emberjs/emberjs/pull/18226
   */
  var _default = _exports.default = {
    name: 'Fix double slash bug',
    after: 'config-meta',
    initialize: function initialize() {
      Ember.HistoryLocation.reopen({
        /**
        	Returns the current `location.pathname` without `rootURL` or `baseURL`
        	@private
        	@method getURL
        	@return url {String}
        */
        getURL: function getURL() {
          var location = this.location,
            rootURL = this.rootURL,
            baseURL = this.baseURL;
          var path = location.pathname;
          // remove trailing slashes if they exists
          rootURL = rootURL.replace(/\/$/, '');
          baseURL = baseURL.replace(/\/$/, '');
          // remove baseURL and rootURL from start of path
          var url = path.replace(new RegExp("^".concat(baseURL, "(?=/|$)")), '').replace(new RegExp("^".concat(rootURL, "(?=/|$)")), '').replace(/\/\//g, '/'); // remove extra slashes

          var search = location.search || '';
          url += search + this.getHash();
          return url;
        }
      });
    }
  };
});