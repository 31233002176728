define("boondmanager/pods/403/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "K1yUeiTT",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"bml-error-page\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"bml-error_img bml-error_img-403\"],[12],[2,\"\\n\\t\\t\"],[1,[30,[36,1],[\"img/error-403.png\",[30,[36,0],[\"messages:error.403.title\"],null]],null]],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\\t\"],[10,\"div\"],[14,0,\"bml-error_content\"],[12],[2,\"\\n\\t\\t\"],[10,\"h1\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,0],[\"messages:error.403.title\"],null]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\n\\t\\t\"],[10,\"p\"],[14,0,\"bml-error-description\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,0],[\"messages:error.403.message\"],null]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\n\\t\\t\"],[10,\"p\"],[14,0,\"bml-error-next-step\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,0],[\"messages:error.nextStep\"],null]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\n\\t\\t\"],[10,\"ul\"],[14,0,\"bml-error-actions\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"li\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[8,\"octane/bm-btn/back\",[[24,0,\"bmb-large\"]],[[],[]],null],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"img-relative\"]}",
    "moduleName": "boondmanager/pods/403/template.hbs"
  });
});