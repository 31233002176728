define("boondmanager/pods/components/octane/bm-render-icon/email/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "zNI/6aPt",
    "block": "{\"symbols\":[\"@email\",\"&attrs\"],\"statements\":[[11,\"a\"],[24,6,\"#\"],[16,0,[31,[\"bmc-render-email-icon bmb-icon \",[30,[36,0],[[32,1],\"disabled\"],null],\" bm-tooltips\"]]],[24,\"target\",\"_blank\"],[24,\"rel\",\"noopener noreferrer\"],[24,\"data-html\",\"true\"],[16,\"data-original-title\",[30,[36,3],[[32,1],[30,[36,2],[[30,[36,1],[\"bmi-email\"],null],\"&nbsp;\",[32,1]],null]],null]],[17,2],[4,[38,4],[\"click\",[32,0,[\"mailTo\"]]],null],[12],[2,\"\\n\\t\"],[1,[30,[36,1],[\"bmi-email\"],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"unless\",\"icon\",\"concat\",\"if\",\"on\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-render-icon/email/template.hbs"
  });
});