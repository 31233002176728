define("boondmanager/pods/components/octane/bm-field/checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "jTRTcqbI",
    "block": "{\"symbols\":[\"@errors\",\"@help\",\"&default\",\"@namedBlocksInfo\",\"@labelText\",\"@disabled\",\"&attrs\",\"@name\",\"@checked\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"bmc-field-checkbox \",[32,0,[\"classNames\"]]]]],[16,\"data-name\",[32,0,[\"dataName\"]]],[16,\"disabled\",[32,6]],[17,7],[4,[38,3],[[32,0,[\"onInsert\"]]],null],[12],[2,\"\\n\\t\"],[10,\"label\"],[12],[2,\"\\n\\t\\t\"],[8,\"octane/bm-field/input\",[[24,0,\"bmf-checkbox\"],[16,3,[32,8]],[16,\"data-name\",[32,8]],[4,[38,4],[\"change\",[32,0,[\"onChange\"]]],null]],[[\"@disabled\",\"@type\",\"@checked\"],[[32,6],\"checkbox\",[32,9]]],null],[2,\"\\n\\t\\t\"],[10,\"span\"],[14,0,\"bmf-checkbox-style\"],[12],[13],[2,\"\\n\"],[6,[37,2],[[30,[36,5],[[32,5],[32,2],[30,[36,1],[[32,4],\"content\",false],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"span\"],[14,0,\"bmc-field-checkbox-label\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"span\"],[14,0,\"bmc-field-checkbox-label-text\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,4],\"content\",false],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\\t\"],[18,3,[[30,[36,0],[\"content\"],null]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\\t\\t\"],[1,[32,5]],[2,\"\\n\"],[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\\t\"],[8,\"octane/bm-help\",[[24,\"data-html\",\"true\"],[23,\"data-original-title\",[32,2]]],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[8,\"octane/bm-field/error\",[],[[\"@errors\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"-named-block-invocation\",\"-has-block\",\"if\",\"did-insert\",\"on\",\"or\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/checkbox/template.hbs"
  });
});