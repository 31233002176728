define("boondmanager/pods/private/apps/advmodcustomers/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "eKzGPs3g",
    "block": "{\"symbols\":[\"__arg0\"],\"statements\":[[8,\"octane/bm-layout-profile\",[],[[\"@withFooter\",\"@namedBlocksInfo\"],[false,[30,[36,1],null,[[\"content\"],[0]]]]],[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1],\"content\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[8,\"octane/bm-alerts\",[],[[\"@type\"],[\"info\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[30,[36,0],[\"advmodcustomers:warning\"],null]],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"hash\",\"-is-named-block-invocation\",\"if\"]}",
    "moduleName": "boondmanager/pods/private/apps/advmodcustomers/index/template.hbs"
  });
});