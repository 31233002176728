define("boondmanager/pods/components/octane/bm-alerts/quotation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "e6iVd8NL",
    "block": "{\"symbols\":[\"message\",\"@company\",\"@dataName\",\"@warnings\"],\"statements\":[[6,[37,3],[[32,4,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-alerts\",[[16,\"data-name\",[32,3]]],[[\"@type\"],[\"warning\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"li\"],[14,0,\"bmc-alert_list-item\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,4],[[32,1,[\"code\"]],\"noBillingDetail\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[1,[30,[36,0],null,[[\"code\"],[\"noBillingDetail.quotation\"]]]],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,0,[\"billingDetails\",\"length\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\\t\"],[8,\"link-to\",[[24,0,\"bmc-btn bmb-rectangle\"]],[[\"@route\",\"@model\"],[\"private.companies.company\",[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[1,[30,[36,1],[\"bmi-add\"],null]],[2,\" \"],[1,[30,[36,0],null,[[\"code\"],[\"noBillingDetail.addBillingDetail\"]]]],[2,\"\\n\\t\\t\\t\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\\t\"],[1,[30,[36,0],null,[[\"code\"],[[32,1,[\"code\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"warning\",\"icon\",\"not\",\"if\",\"eq\",\"-track-array\",\"each\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-alerts/quotation/template.hbs"
  });
});