define("boondmanager/settings/reporting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reporting = void 0;
  var reporting = _exports.reporting = function reporting(i18n, settings) {
    return {
      addSeparator: 'Ajouter un séparateur',
      category: {
        activityExpensesSynthesis: {
          scorecards: {
            costsOfAllUsedTimeExcludingTaxPerActivity: {
              i18nKey: settings.fromKey('setting.typeOf.activity'),
              title: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.costsOfAllUsedTimeExcludingTaxPerActivity.title'),
              tooltip: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.costsOfAllUsedTimeExcludingTaxPerActivity.tooltip')
            },
            costsOfProductionAndExceptionalUsedTimeAndCalendarExcludingTax: {
              title: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.costsOfProductionAndExceptionalUsedTimeAndCalendarExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.costsOfAllUsedTimeExcludingTaxPerActivity.tooltip')
            },
            costsOfSignedExpensesExcludingTax: {
              title: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.costsOfSignedExpensesExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.costsOfSignedExpensesExcludingTax.tooltip')
            },
            costsOfUsedExpensesExcludingTax: {
              title: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.costsOfUsedExpensesExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.costsOfUsedExpensesExcludingTax.tooltip')
            },
            durationOfAllPlannedTime: {
              title: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.durationOfAllPlannedTime.title'),
              tooltip: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.durationOfAllPlannedTime.tooltip')
            },
            durationOfAllSignedTime: {
              title: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.durationOfAllSignedTime.title'),
              tooltip: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.durationOfAllSignedTime.tooltip')
            },
            durationOfAllUsedTime: {
              title: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.durationOfAllUsedTime.title'),
              tooltip: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.durationOfAllUsedTime.tooltip')
            },
            durationOfAllUsedTimePerActivity: {
              i18nKey: settings.fromKey('setting.typeOf.activity'),
              title: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.durationOfAllUsedTimePerActivity.title'),
              tooltip: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.durationOfAllUsedTimePerActivity.tooltip')
            },
            durationOfProductionAndExceptionalUsedTimeAndCalendar: {
              addItem: {
                cal: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.durationOfProductionAndExceptionalUsedTimeAndCalendar.addItem.cal'),
                hor: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.durationOfProductionAndExceptionalUsedTimeAndCalendar.addItem.hor')
              },
              title: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.durationOfProductionAndExceptionalUsedTimeAndCalendar.title'),
              tooltip: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.durationOfProductionAndExceptionalUsedTimeAndCalendar.tooltip')
            },
            numberOfRejectedAbsencesReports: {
              title: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.numberOfRejectedAbsencesReports.title')
            },
            numberOfRejectedExpensesReports: {
              title: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.numberOfRejectedExpensesReports.title')
            },
            numberOfRejectedTimesReports: {
              title: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.numberOfRejectedTimesReports.title')
            },
            numberOfValidatedAbsencesReports: {
              title: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.numberOfValidatedAbsencesReports.title')
            },
            numberOfValidatedExpensesReports: {
              title: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.numberOfValidatedExpensesReports.title')
            },
            numberOfValidatedTimesReports: {
              title: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.numberOfValidatedTimesReports.title')
            },
            numberOfWaitingForValidationAbsencesReports: {
              title: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.numberOfWaitingForValidationAbsencesReports.title')
            },
            numberOfWaitingForValidationExpensesReports: {
              title: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.numberOfWaitingForValidationExpensesReports.title')
            },
            numberOfWaitingForValidationTimesReports: {
              title: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.numberOfWaitingForValidationTimesReports.title')
            }
          },
          title: i18n.t('activityexpenses:title')
        },
        billingSynthesis: {
          scorecards: {
            deltaInvoicedExcludingTaxFollowingInvoicesDate: {
              title: i18n.t('reporting:categories.billingSynthesis.scorecards.deltaInvoicedExcludingTaxFollowingInvoicesDate.title'),
              tooltip: i18n.t('reporting:categories.billingSynthesis.scorecards.deltaInvoicedExcludingTaxFollowingInvoicesDate.tooltip')
            },
            turnoverInvoicedExcludingTaxFollowingCommandsPeriod: {
              title: i18n.t('reporting:categories.billingSynthesis.scorecards.turnoverInvoicedExcludingTaxFollowingCommandsPeriod.title'),
              tooltip: i18n.t('reporting:categories.billingSynthesis.scorecards.turnoverInvoicedExcludingTaxFollowingCommandsPeriod.tooltip')
            },
            turnoverInvoicedExcludingTaxFollowingInvoicesDate: {
              title: i18n.t('reporting:categories.billingSynthesis.scorecards.turnoverInvoicedExcludingTaxFollowingInvoicesDate.title'),
              tooltip: i18n.t('reporting:categories.billingSynthesis.scorecards.turnoverInvoicedExcludingTaxFollowingInvoicesDate.tooltip')
            },
            turnoverInvoicedExcludingTaxFollowingInvoicesDatePerStates: {
              i18nKey: settings.fromKey('setting.state.invoice'),
              title: i18n.t('reporting:categories.billingSynthesis.scorecards.turnoverInvoicedExcludingTaxFollowingInvoicesDatePerStates.title'),
              tooltip: i18n.t('reporting:categories.billingSynthesis.scorecards.turnoverInvoicedExcludingTaxFollowingInvoicesDatePerStates.tooltip')
            },
            turnoverInvoicedExcludingTaxFollowingInvoicesPeriod: {
              title: i18n.t('reporting:categories.billingSynthesis.scorecards.turnoverInvoicedExcludingTaxFollowingInvoicesPeriod.title'),
              tooltip: i18n.t('reporting:categories.billingSynthesis.scorecards.turnoverInvoicedExcludingTaxFollowingInvoicesPeriod.tooltip')
            },
            turnoverInvoicedExcludingTaxFollowingInvoicesPeriodPerStates: {
              i18nKey: settings.fromKey('setting.state.invoice'),
              title: i18n.t('reporting:categories.billingSynthesis.scorecards.turnoverInvoicedExcludingTaxFollowingInvoicesPeriodPerStates.title'),
              tooltip: i18n.t('reporting:categories.billingSynthesis.scorecards.turnoverInvoicedExcludingTaxFollowingInvoicesPeriodPerStates.tooltip')
            },
            turnoverInvoicedProFormaExcludingTaxFollowingInvoicesDate: {
              title: i18n.t('reporting:categories.billingSynthesis.scorecards.turnoverInvoicedProFormaExcludingTaxFollowingInvoicesDate.title'),
              tooltip: i18n.t('reporting:categories.billingSynthesis.scorecards.turnoverInvoicedExcludingTaxFollowingInvoicesDatePerStates.tooltip')
            },
            turnoverInvoicedProFormaExcludingTaxFollowingInvoicesPeriod: {
              title: i18n.t('reporting:categories.billingSynthesis.scorecards.turnoverInvoicedProFormaExcludingTaxFollowingInvoicesPeriod.title'),
              tooltip: i18n.t('reporting:categories.billingSynthesis.scorecards.turnoverInvoicedExcludingTaxFollowingInvoicesPeriodPerStates.tooltip')
            },
            turnoverOrderedExcludingTax: {
              title: i18n.t('reporting:categories.billingSynthesis.scorecards.turnoverOrderedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.billingSynthesis.scorecards.turnoverOrderedExcludingTax.tooltip')
            }
          },
          title: i18n.t('reporting:categories.billingSynthesis.title')
        },
        commercialSynthesis: {
          scorecards: {
            averageBillableSalary: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageBillableSalary.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageBillableSalary.tooltip')
            },
            averageDailyCostSigned: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageDailyCostSigned.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageDailyCostSigned.tooltip')
            },
            averageDailyCostSignedForEmployees: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageDailyCostSignedForEmployees.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageDailyCostSignedForEmployees.tooltip')
            },
            averageDailyCostSignedForExternals: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageDailyCostSignedForExternals.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageDailyCostSignedForExternals.tooltip')
            },
            averageSalary: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageSalary.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageSalary.tooltip')
            },
            averageSignedRate: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageSignedRate.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageSignedRate.tooltip')
            },
            averageSignedRateForEmployees: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageSignedRateForEmployees.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageSignedRateForEmployees.tooltip')
            },
            averageSignedRateForExternals: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageSignedRateForExternals.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageSignedRateForExternals.tooltip')
            },
            averageStructuralSalary: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageStructuralSalary.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageStructuralSalary.tooltip')
            },
            costsForecastExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.costsForecastExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.costsForecastExcludingTax.tooltip')
            },
            costsSignedExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.costsSignedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.costsSignedExcludingTax.tooltip')
            },
            costsSimulatedExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.costsSimulatedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.costsSimulatedExcludingTax.tooltip')
            },
            dailyProfitabilitySigned: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.dailyProfitabilitySigned.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.dailyProfitabilitySigned.tooltip')
            },
            dailyProfitabilitySignedForEmployees: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.dailyProfitabilitySignedForEmployees.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.dailyProfitabilitySignedForEmployees.tooltip')
            },
            dailyProfitabilitySignedForExternals: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.dailyProfitabilitySignedForExternals.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.dailyProfitabilitySignedForExternals.tooltip')
            },
            marginForecastExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.marginForecastExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.marginForecastExcludingTax.tooltip')
            },
            marginSignedExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.marginSignedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.marginSignedExcludingTax.tooltip')
            },
            marginSimulatedExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.marginSimulatedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.marginSimulatedExcludingTax.tooltip')
            },
            numberOfActionsCreatedOnContactsPerTypes: {
              i18nKey: settings.fromKey('setting.action.contact'),
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfActionsCreatedOnContactsPerTypes.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfActionsCreatedOnContactsPerTypes.tooltip')
            },
            numberOfActionsOnContactsPerTypes: {
              i18nKey: settings.fromKey('setting.action.contact'),
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfActionsOnContactsPerTypes.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfActionsOnContactsPerTypes.tooltip')
            },
            numberOfActionsOnOpportunitiesPerTypes: {
              i18nKey: settings.fromKey('setting.action.opportunity'),
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfActionsOnOpportunitiesPerTypes.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfActionsOnOpportunitiesPerTypes.tooltip')
            },
            numberOfActionsOnProjectsPerTypes: {
              i18nKey: settings.fromKey('setting.action.project'),
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfActionsOnProjectsPerTypes.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfActionsOnProjectsPerTypes.tooltip')
            },
            numberOfCompaniesCreated: {
              abbr: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfCompaniesCreated.abbr'),
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfCompaniesCreated.title')
            },
            numberOfContactsCreated: {
              abbr: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfContactsCreated.abbr'),
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfContactsCreated.title')
            },
            numberOfDeliveriesInProgressPerType: {
              i18nKey: settings.fromKey('setting.typeOf.delivery'),
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesInProgressPerType.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesInProgressPerType.tooltip')
            },
            numberOfDeliveriesInProgressNone: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesInProgressNone.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesInProgressPerType.tooltip')
            },
            numberOfDeliveriesInProgress: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesInProgress.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesInProgressPerType.tooltip')
            },
            numberOfDeliveriesStartedPerType: {
              i18nKey: settings.fromKey('setting.typeOf.delivery'),
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesStartedPerType.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesInProgressPerType.tooltip')
            },
            numberOfDeliveriesStartedNone: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesStartedNone.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesInProgressPerType.tooltip')
            },
            numberOfDeliveriesStarted: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesStarted.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesInProgressPerType.tooltip')
            },
            numberOfDeliveriesStoppedPerType: {
              i18nKey: settings.fromKey('setting.typeOf.delivery'),
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesStoppedPerType.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesInProgressPerType.tooltip')
            },
            numberOfDeliveriesStoppedNone: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesStoppedNone.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesInProgressPerType.tooltip')
            },
            numberOfDeliveriesStopped: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesStopped.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesInProgressPerType.tooltip')
            },
            numberOfOpportunitiesCreated: {
              abbr: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfOpportunitiesCreated.abbr'),
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfOpportunitiesCreated.title')
            },
            numberOfOpportunitiesPerStates: {
              i18nKey: settings.fromKey('setting.state.opportunity'),
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfOpportunitiesCreated.abbr'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfOpportunitiesPerStates.tooltip')
            },
            numberOfPackageProjectsInProgress: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfPackageProjectsInProgress.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesInProgressPerType.tooltip')
            },
            numberOfPositioningsCreated: {
              abbr: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfPositioningsCreated.abbr'),
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfPositioningsCreated.title')
            },
            numberOfProductProjectsInProgress: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfProductProjectsInProgress.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesInProgressPerType.tooltip')
            },
            numberOfProjectsCreated: {
              abbr: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfProjectsCreated.abbr'),
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfProjectsCreated.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesInProgressPerType.tooltip')
            },
            numberOfProjectsInProgress: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfProjectsInProgress.title')
            },
            numberOfRecruitmentProjectsInProgress: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfRecruitmentProjectsInProgress.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesInProgressPerType.tooltip')
            },
            numberOfTechnicalAssistanceProjectsInProgress: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfTechnicalAssistanceProjectsInProgress.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesInProgressPerType.tooltip')
            },
            profitabilityForecast: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.profitabilityForecast.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.profitabilityForecast.tooltip')
            },
            profitabilitySigned: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.profitabilitySigned.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.profitabilitySigned.tooltip')
            },
            profitabilitySimulated: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.profitabilitySimulated.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.profitabilitySimulated.tooltip')
            },
            rateOfPackageProjectsInProgress: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.rateOfPackageProjectsInProgress.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.rateOfPackageProjectsInProgress.tooltip')
            },
            rateOfProductProjectsInProgress: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.rateOfProductProjectsInProgress.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.rateOfProductProjectsInProgress.tooltip')
            },
            rateOfRecruimentProjectsInProgress: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.rateOfRecruimentProjectsInProgress.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.rateOfRecruimentProjectsInProgress.tooltip')
            },
            rateOfTechnicalAssistanceProjectsInProgress: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.rateOfTechnicalAssistanceProjectsInProgress.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.rateOfTechnicalAssistanceProjectsInProgress.tooltip')
            },
            staffSignedActivityRateLeavesExcludedForAllBillable: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesExcludedForAllBillable.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesExcludedForAllBillable.tooltip')
            },
            staffSignedActivityRateLeavesExcludedForBillableEmployees: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesExcludedForBillableEmployees.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesExcludedForBillableEmployees.tooltip')
            },
            staffSignedActivityRateLeavesExcludedForBillableExternals: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesExcludedForBillableExternals.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesExcludedForBillableExternals.tooltip')
            },
            staffSignedActivityRateLeavesIncludedForBillable: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesIncludedForBillable.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesIncludedForBillable.tooltip')
            },
            staffSignedActivityRateLeavesIncludedForBillableEmployees: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesIncludedForBillableEmployees.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesIncludedForBillableEmployees.tooltip')
            },
            staffSignedActivityRateLeavesIncludedForBillableExternals: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesIncludedForBillableExternals.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesIncludedForBillableExternals.tooltip')
            },
            turnoverForecastExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.turnoverForecastExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.turnoverForecastExcludingTax.tooltip')
            },
            turnoverSignedExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.turnoverSignedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.turnoverSignedExcludingTax.tooltip')
            },
            turnoverSimulatedExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.turnoverSimulatedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.turnoverSimulatedExcludingTax.tooltip')
            },
            turnoverWeightedExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.turnoverWeightedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.turnoverWeightedExcludingTax.tooltip')
            },
            turnoverWeightedExcludingTaxPerStates: {
              i18nKey: settings.fromKey('setting.state.opportunity'),
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.turnoverWeightedExcludingTaxPerStates.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.turnoverWeightedExcludingTaxPerStates.tooltip')
            }
          },
          title: i18n.t('reporting:categories.commercialSynthesis.title'),
          title_feminine: i18n.t('reporting:categories.commercialSynthesis.title_feminine')
        },
        companies: {
          scorecards: {
            costsForecastExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.costsForecastExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.companies.scorecards.costsForecastExcludingTax.tooltip')
            },
            costsOfProductionExpensesExcludingTax: {
              title: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.costsOfUsedExpensesExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.costsOfUsedExpensesExcludingTax.tooltip')
            },
            costsOfSignedExpensesExcludingTax: {
              title: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.costsOfSignedExpensesExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.costsOfSignedExpensesExcludingTax.tooltip')
            },
            costsSignedExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.costsSignedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.costsSignedExcludingTax.tooltip')
            },
            costsSimulatedExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.costsSimulatedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.companies.scorecards.costsSimulatedExcludingTax.tooltip')
            },
            durationOfAllTime: {
              title: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.durationOfAllUsedTime.title'),
              tooltip: i18n.t('reporting:categories.companies.scorecards.durationOfAllTime.tooltip')
            },
            durationOfSignedTime: {
              title: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.durationOfAllSignedTime.title'),
              tooltip: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.durationOfAllSignedTime.tooltip')
            },
            marginForecastExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.marginForecastExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.marginForecastExcludingTax.tooltip')
            },
            marginSignedExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.marginSignedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.marginSignedExcludingTax.tooltip')
            },
            marginSimulatedExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.marginSimulatedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.marginSimulatedExcludingTax.tooltip')
            },
            numberOfDeliveriesInProgress: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesInProgress.title')
            },
            numberOfExternalResourcesOnDeliveries: {
              title: i18n.t('reporting:categories.companies.scorecards.numberOfExternalResourcesOnDeliveries.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesInProgressPerType.tooltip')
            },
            numberOfInternalResourcesOnDeliveries: {
              title: i18n.t('reporting:categories.companies.scorecards.numberOfInternalResourcesOnDeliveries.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesInProgressPerType.tooltip')
            },
            numberOfOpportunitiesCreated: {
              abbr: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfOpportunitiesCreated.abbr'),
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfOpportunitiesCreated.title')
            },
            numberOfPackageProjectsInProgress: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfPackageProjectsInProgress.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesInProgressPerType.tooltip')
            },
            numberOfPositioningsCreated: {
              abbr: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfPositioningsCreated.abbr'),
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfPositioningsCreated.title')
            },
            numberOfProductProjectsInProgress: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfProductProjectsInProgress.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesInProgressPerType.tooltip')
            },
            numberOfProjectsInProgress: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfProjectsInProgress.title')
            },
            numberOfRecruitmentProjectsInProgress: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfRecruitmentProjectsInProgress.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesInProgressPerType.tooltip')
            },
            numberOfResourcesOnDeliveries: {
              title: i18n.t('reporting:categories.companies.scorecards.numberOfResourcesOnDeliveries.title')
            },
            numberOfTechnicalAssistanceProjectsInProgress: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfTechnicalAssistanceProjectsInProgress.title'),
              tooltip: i18n.t('reporting:categories.companies.scorecards.numberOfTechnicalAssistanceProjectsInProgress.tooltip')
            },
            turnoverForecastExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.turnoverForecastExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.companies.scorecards.turnoverForecastExcludingTax.tooltip')
            },
            turnoverInvoicedExcludingTaxFollowingInvoicesDate: {
              title: i18n.t('reporting:categories.billingSynthesis.scorecards.turnoverInvoicedExcludingTaxFollowingInvoicesDate.title'),
              tooltip: i18n.t('reporting:categories.billingSynthesis.scorecards.turnoverInvoicedExcludingTaxFollowingInvoicesDate.tooltip')
            },
            turnoverSignedExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.turnoverSignedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.companies.scorecards.turnoverSignedExcludingTax.tooltip')
            },
            turnoverSimulatedExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.turnoverSimulatedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.companies.scorecards.turnoverSimulatedExcludingTax.tooltip')
            },
            turnoverWeightedExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.turnoverWeightedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.turnoverWeightedExcludingTax.tooltip')
            }
          },
          title: i18n.t('corporama:search.companies.title')
        },
        globalSynthesis: {
          scorecards: {
            averageBillableSalary: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageBillableSalary.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageBillableSalary.tooltip')
            },
            averageDailyCostSigned: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageDailyCostSigned.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageDailyCostSigned.tooltip')
            },
            averageDailyCostSignedForEmployees: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageDailyCostSignedForEmployees.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageDailyCostSignedForEmployees.tooltip')
            },
            averageDailyCostSignedForExternals: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageDailyCostSignedForExternals.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageDailyCostSignedForExternals.tooltip')
            },
            averageDailyCostTaken: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.averageDailyCostTaken.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.averageDailyCostTaken.tooltip')
            },
            averageDailyCostTakenForEmployees: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.averageDailyCostTakenForEmployees.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.averageDailyCostTakenForEmployees.tooltip')
            },
            averageDailyCostTakenForExternals: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.averageDailyCostTakenForExternals.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.averageDailyCostTakenForExternals.tooltip')
            },
            averageNumberOfBillableEmployees: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.averageNumberOfBillableEmployees.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.averageNumberOfBillableEmployees.tooltip')
            },
            averageNumberOfBillableExternals: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.averageNumberOfBillableExternals.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.averageNumberOfBillableExternals.tooltip')
            },
            averageNumberOfBillableStaff: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.averageNumberOfBillableStaff.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.averageNumberOfBillableStaff.tooltip')
            },
            averageNumberOfStaff: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.averageNumberOfStaff.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.averageNumberOfStaff.tooltip')
            },
            averageNumberOfStructuralEmployees: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.averageNumberOfStructuralEmployees.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.averageNumberOfStructuralEmployees.tooltip')
            },
            averageNumberOfStructuralExternals: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.averageNumberOfStructuralExternals.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.averageNumberOfStructuralExternals.tooltip')
            },
            averageNumberOfStructuralStaff: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.averageNumberOfStructuralStaff.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.averageNumberOfStructuralStaff.tooltip')
            },
            averageSalary: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageSalary.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageSalary.tooltip')
            },
            averageSignedRate: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageSignedRate.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageSignedRate.tooltip')
            },
            averageSignedRateForEmployees: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageSignedRateForEmployees.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageSignedRateForEmployees.tooltip')
            },
            averageSignedRateForExternals: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageSignedRateForExternals.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageSignedRateForExternals.tooltip')
            },
            averageStructuralSalary: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageStructuralSalary.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageStructuralSalary.tooltip')
            },
            averageTakenRate: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.averageTakenRate.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.averageTakenRate.tooltip')
            },
            averageTakenRateForEmployees: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.averageTakenRateForEmployees.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.averageTakenRateForEmployees.tooltip')
            },
            averageTakenRateForExternals: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.averageTakenRateForExternals.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.averageTakenRateForExternals.tooltip')
            },
            costOfUnbilledActivity: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.costOfUnbilledActivity.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.costOfUnbilledActivity.tooltip')
            },
            costOfUnbilledForExternals: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.costOfUnbilledForExternals.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.costOfUnbilledForExternals.tooltip')
            },
            costOfUnbilledForInternals: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.costOfUnbilledForInternals.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.costOfUnbilledForInternals.tooltip')
            },
            costsOfPurchasesExcludingTax: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.costsOfPurchasesExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.costsOfPurchasesExcludingTax.tooltip')
            },
            costsOfPurchasesExcludingTaxFollowingChargedPeriod: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.costsOfPurchasesExcludingTaxFollowingChargedPeriod.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.costsOfPurchasesExcludingTax.tooltip')
            },
            costsOfPurchasesExcludingTaxPerStates: {
              i18nKey: settings.fromKey('setting.typeOf.purchase'),
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.costsOfPurchasesExcludingTaxPerStates.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.costsOfPurchasesExcludingTaxPerStates.tooltip')
            },
            costsOfPurchasesExcludingTaxPerStatesFollowingChargedPeriod: {
              i18nKey: settings.fromKey('setting.typeOf.purchase'),
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.costsOfPurchasesExcludingTaxPerStatesFollowingChargedPeriod.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.costsOfPurchasesExcludingTaxPerStatesFollowingChargedPeriod.tooltip')
            },
            costsProductionExcludingTax: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.costsProductionExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.costsProductionExcludingTax.tooltip')
            },
            costsRealExcludingTax: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.costsRealExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.costsRealExcludingTax.tooltip')
            },
            dailyProfitabilitySigned: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.dailyProfitabilitySigned.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.dailyProfitabilitySigned.tooltip')
            },
            dailyProfitabilitySignedForEmployees: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.dailyProfitabilitySignedForEmployees.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.dailyProfitabilitySignedForEmployees.tooltip')
            },
            dailyProfitabilitySignedForExternals: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.dailyProfitabilitySignedForExternals.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.dailyProfitabilitySignedForExternals.tooltip')
            },
            dailyProfitabilityTaken: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.dailyProfitabilityTaken.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.dailyProfitabilityTaken.tooltip')
            },
            dailyProfitabilityTakenForEmployees: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.dailyProfitabilityTakenForEmployees.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.dailyProfitabilityTakenForEmployees.tooltip')
            },
            dailyProfitabilityTakenForExternals: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.dailyProfitabilityTakenForExternals.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.dailyProfitabilityTakenForExternals.tooltip')
            },
            globalStructureCost: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.globalStructureCost.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.globalStructureCost.tooltip')
            },
            marginGrossContributiveInvoicedExcludingTaxFollowingInvoicesDate: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.marginGrossContributiveInvoicedExcludingTaxFollowingInvoicesDate.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.marginGrossContributiveInvoicedExcludingTaxFollowingInvoicesDate.tooltip')
            },
            marginGrossContributiveProductionExcludingTax: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.marginGrossContributiveProductionExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.marginGrossContributiveProductionExcludingTax.tooltip')
            },
            marginRealContributiveInvoicedExcludingTaxFollowingInvoicesDate: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.marginRealContributiveInvoicedExcludingTaxFollowingInvoicesDate.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.marginRealContributiveInvoicedExcludingTaxFollowingInvoicesDate.tooltip')
            },
            marginRealContributiveInvoicedExcludingTaxFollowingInvoicesPeriod: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.marginRealContributiveInvoicedExcludingTaxFollowingInvoicesPeriod.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.marginRealContributiveInvoicedExcludingTaxFollowingInvoicesPeriod.tooltip')
            },
            marginRealContributiveInvoicedPaidExcludingTaxFollowingInvoicesDate: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.marginRealContributiveInvoicedPaidExcludingTaxFollowingInvoicesDate.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.marginRealContributiveInvoicedPaidExcludingTaxFollowingInvoicesDate.tooltip')
            },
            marginRealContributiveProductionExcludingTax: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.marginRealContributiveProductionExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.marginRealContributiveProductionExcludingTax.tooltip')
            },
            marginRealExploitationInvoicedExcludingTaxFollowingInvoicesDate: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.marginRealExploitationInvoicedExcludingTaxFollowingInvoicesDate.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.marginRealExploitationInvoicedExcludingTaxFollowingInvoicesDate.tooltip')
            },
            marginRealExploitationInvoicedExcludingTaxFollowingInvoicesPeriod: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.marginRealExploitationInvoicedExcludingTaxFollowingInvoicesPeriod.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.marginRealExploitationInvoicedExcludingTaxFollowingInvoicesPeriod.tooltip')
            },
            marginRealExploitationInvoicedPaidExcludingTaxFollowingInvoicesDate: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.marginRealExploitationInvoicedPaidExcludingTaxFollowingInvoicesDate.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.marginRealExploitationInvoicedPaidExcludingTaxFollowingInvoicesDate.tooltip')
            },
            marginRealExploitationProductionExcludingTax: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.marginRealExploitationProductionExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.marginRealExploitationProductionExcludingTax.tooltip')
            },
            marginRealSignedExcludingTax: {
              title: i18n.t('deliveries:search.margin.name'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.marginRealSignedExcludingTax.tooltip')
            },
            numberOfBillableEmployees: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.numberOfBillableEmployees.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.numberOfBillableEmployees.tooltip')
            },
            numberOfBillableExternals: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.numberOfBillableExternals.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.numberOfBillableExternals.tooltip')
            },
            numberOfBillableStaff: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.numberOfBillableStaff.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.numberOfBillableStaff.tooltip')
            },
            numberOfStaff: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.numberOfStaff.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.numberOfStaff.tooltip')
            },
            numberOfStructuralStaff: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.numberOfStructuralStaff.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.numberOfStructuralStaff.tooltip')
            },
            profitabilityGrossContributiveInvoicedFollowingInvoicesDate: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.profitabilityGrossContributiveInvoicedFollowingInvoicesDate.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.profitabilityGrossContributiveInvoicedFollowingInvoicesDate.tooltip')
            },
            profitabilityGrossContributiveProduction: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.profitabilityGrossContributiveProduction.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.profitabilityGrossContributiveProduction.tooltip')
            },
            profitabilityRealContributiveInvoicedFollowingInvoicesDate: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.profitabilityRealContributiveInvoicedFollowingInvoicesDate.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.profitabilityRealContributiveInvoicedFollowingInvoicesDate.tooltip')
            },
            profitabilityRealContributiveInvoicedFollowingInvoicesPeriod: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.profitabilityRealContributiveInvoicedFollowingInvoicesPeriod.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.profitabilityRealContributiveInvoicedFollowingInvoicesPeriod.tooltip')
            },
            profitabilityRealContributiveInvoicedPaidFollowingInvoicesDate: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.profitabilityRealContributiveInvoicedPaidFollowingInvoicesDate.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.profitabilityRealContributiveInvoicedPaidFollowingInvoicesDate.tooltip')
            },
            profitabilityRealContributiveProduction: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.profitabilityRealContributiveProduction.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.profitabilityRealContributiveProduction.tooltip')
            },
            profitabilityRealExploitationInvoicedFollowingInvoicesDate: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.profitabilityRealExploitationInvoicedFollowingInvoicesDate.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.profitabilityRealExploitationInvoicedFollowingInvoicesDate.tooltip')
            },
            profitabilityRealExploitationInvoicedFollowingInvoicesPeriod: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.profitabilityRealExploitationInvoicedFollowingInvoicesPeriod.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.profitabilityRealExploitationInvoicedFollowingInvoicesPeriod.tooltip')
            },
            profitabilityRealExploitationInvoicedPaidFollowingInvoicesDate: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.profitabilityRealExploitationInvoicedPaidFollowingInvoicesDate.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.profitabilityRealExploitationInvoicedPaidFollowingInvoicesDate.tooltip')
            },
            profitabilityRealExploitationProduction: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.profitabilityRealExploitationProduction.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.profitabilityRealExploitationProduction.tooltip')
            },
            profitabilityRealSigned: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.profitabilityRealSigned.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.profitabilityRealSigned.tooltip')
            },
            realCostOfLeaves: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.realCostOfLeaves.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.realCostOfLeaves.tooltip')
            },
            staffPlannedActivityRateLeavesExcludedForAllBillable: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.staffPlannedActivityRateLeavesExcludedForAllBillable.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.staffPlannedActivityRateLeavesExcludedForAllBillable.tooltip')
            },
            staffPlannedActivityRateLeavesExcludedForBillableEmployees: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.staffPlannedActivityRateLeavesExcludedForBillableEmployees.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.staffPlannedActivityRateLeavesExcludedForBillableEmployees.tooltip')
            },
            staffPlannedActivityRateLeavesExcludedForBillableExternals: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.staffPlannedActivityRateLeavesExcludedForBillableExternals.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.staffPlannedActivityRateLeavesExcludedForBillableExternals.tooltip')
            },
            staffSignedActivityRateLeavesExcludedForAllBillable: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesExcludedForAllBillable.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesExcludedForAllBillable.tooltip')
            },
            staffSignedActivityRateLeavesExcludedForBillableEmployees: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesExcludedForBillableEmployees.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesExcludedForBillableEmployees.tooltip')
            },
            staffSignedActivityRateLeavesExcludedForBillableExternals: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesExcludedForBillableExternals.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesExcludedForBillableExternals.tooltip')
            },
            staffSignedActivityRateLeavesIncludedForBillable: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesIncludedForBillable.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesIncludedForBillable.tooltip')
            },
            staffSignedActivityRateLeavesIncludedForBillableEmployees: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesIncludedForBillableEmployees.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesIncludedForBillableEmployees.tooltip')
            },
            staffSignedActivityRateLeavesIncludedForBillableExternals: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesIncludedForBillableExternals.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesIncludedForBillableExternals.tooltip')
            },
            staffTakenActivityRateLeavesExcludedForAllBillable: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.staffTakenActivityRateLeavesExcludedForAllBillable.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.staffTakenActivityRateLeavesExcludedForAllBillable.tooltip')
            },
            staffTakenActivityRateLeavesExcludedForBillableEmployees: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.staffTakenActivityRateLeavesExcludedForBillableEmployees.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.staffTakenActivityRateLeavesExcludedForBillableEmployees.tooltip')
            },
            staffTakenActivityRateLeavesExcludedForBillableExternals: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.staffTakenActivityRateLeavesExcludedForBillableExternals.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.staffTakenActivityRateLeavesExcludedForBillableExternals.tooltip')
            },
            staffTakenActivityRateLeavesIncludedForBillable: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.staffTakenActivityRateLeavesIncludedForBillable.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.staffTakenActivityRateLeavesIncludedForBillable.tooltip')
            },
            staffTakenActivityRateLeavesIncludedForBillableEmployees: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.staffTakenActivityRateLeavesIncludedForBillableEmployees.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.staffTakenActivityRateLeavesIncludedForBillableEmployees.tooltip')
            },
            staffTakenActivityRateLeavesIncludedForBillableExternals: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.staffTakenActivityRateLeavesIncludedForBillableExternals.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.staffTakenActivityRateLeavesIncludedForBillableExternals.tooltip')
            },
            structuralPurchases: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.structuralPurchases.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.structuralPurchases.tooltip')
            },
            turnoverInvoicedExcludingTaxFollowingInvoicesDate: {
              title: i18n.t('advmodprojects:indicators.turnoverInvoicedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.turnoverInvoicedExcludingTaxFollowingInvoicesDate.tooltip')
            },
            turnoverInvoicedExcludingTaxFollowingInvoicesPeriod: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.turnoverInvoicedExcludingTaxFollowingInvoicesPeriod.title'),
              tooltip: i18n.t('reporting:categories.billingSynthesis.scorecards.turnoverInvoicedExcludingTaxFollowingInvoicesPeriod.tooltip')
            },
            turnoverProductionExcludingTax: {
              title: i18n.t('wexceptionnalactivity:priceExcludingTax'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.turnoverProductionExcludingTax.tooltip')
            },
            turnoverRealInvoicedPaidExcludingTaxFollowingInvoicesDate: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.turnoverRealInvoicedPaidExcludingTaxFollowingInvoicesDate.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.turnoverRealInvoicedPaidExcludingTaxFollowingInvoicesDate.tooltip')
            },
            turnoverSignedExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.turnoverSignedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.turnoverSignedExcludingTax.tooltip')
            },
            unbilledTakenStaffActivityRateForBillable: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.unbilledTakenStaffActivityRateForBillable.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.unbilledTakenStaffActivityRateForBillable.tooltip')
            },
            unbilledTakenStaffActivityRateForBillableEmployees: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.unbilledTakenStaffActivityRateForBillableEmployees.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.unbilledTakenStaffActivityRateForBillableEmployees.tooltip')
            },
            unbilledTakenStaffActivityRateForBillableExternals: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.unbilledTakenStaffActivityRateForBillableExternals.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.unbilledTakenStaffActivityRateForBillableExternals.tooltip')
            }
          },
          title: i18n.t('reporting:categories.globalSynthesis.title')
        },
        humanResourcesSynthesis: {
          scorecards: {
            costsOfAbsencesSignedTimeExcludingTax: {
              title: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.costsOfAbsencesSignedTimeExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.costsOfAbsencesSignedTimeExcludingTax.tooltip')
            },
            costsOfAllActivityExcludingTax: {
              title: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.costsOfAllActivityExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.costsOfAllActivityExcludingTax.tooltip')
            },
            costsOfInternalSignedTimeExcludingTax: {
              title: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.costsOfInternalSignedTimeExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.costsOfInternalSignedTimeExcludingTax.tooltip')
            },
            durationOfAbsencesSignedTime: {
              title: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.durationOfAbsencesSignedTime.title'),
              tooltip: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.durationOfAbsencesSignedTime.tooltip')
            },
            durationOfInternalSignedTime: {
              title: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.durationOfInternalSignedTime.title'),
              tooltip: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.durationOfInternalSignedTime.tooltip')
            },
            internalActivityRate: {
              title: i18n.t('inactivities:view.occupationRate'),
              tooltip: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.internalActivityRate.tooltip')
            },
            numberOfActionsOnResourcesPerTypes: {
              i18nKey: settings.fromKey('setting.action.resource'),
              title: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.numberOfActionsOnResourcesPerTypes.title'),
              tooltip: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.numberOfActionsOnResourcesPerTypes.tooltip')
            },
            numberOfBillableEmployees: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.numberOfBillableEmployees.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.numberOfBillableEmployees.tooltip')
            },
            numberOfBillableExternals: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.numberOfBillableExternals.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.numberOfBillableExternals.tooltip')
            },
            numberOfBillableStaff: {
              title: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.numberOfBillableStaff.title'),
              tooltip: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.numberOfBillableStaff.tooltip')
            },
            numberOfDepartures: {
              title: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.numberOfDepartures.title')
            },
            numberOfExternalResourcesOnDeliveries: {
              title: i18n.t('reporting:categories.companies.scorecards.numberOfExternalResourcesOnDeliveries.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesInProgressPerType.tooltip')
            },
            numberOfExternalStaff: {
              abbr: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.numberOfExternalStaff.abbr'),
              title: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.numberOfExternalStaff.title')
            },
            numberOfHired: {
              title: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.numberOfHired.title')
            },
            numberOfInternalResourcesOnDeliveries: {
              title: i18n.t('reporting:categories.companies.scorecards.numberOfInternalResourcesOnDeliveries.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfDeliveriesInProgressPerType.tooltip')
            },
            numberOfInternalStaff: {
              abbr: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.numberOfInternalStaff.abbr'),
              title: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.numberOfInternalStaff.title')
            },
            numberOfPositioningsCreated: {
              abbr: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfPositioningsCreated.abbr'),
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfPositioningsCreated.title')
            },
            numberOfResourcesOnAbsencesActivity: {
              title: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.numberOfResourcesOnAbsencesActivity.title')
            },
            numberOfResourcesOnDeliveries: {
              title: i18n.t('reporting:categories.companies.scorecards.numberOfResourcesOnDeliveries.title')
            },
            numberOfResourcesOnInternalActivity: {
              title: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.numberOfResourcesOnInternalActivity.title')
            },
            numberOfStaff: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.numberOfStaff.title'),
              tooltip: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.numberOfStaff.tooltip')
            },
            numberOfStructuralStaff: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.numberOfStructuralStaff.title'),
              tooltip: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.numberOfStructuralStaff.tooltip')
            },
            occupationRate: {
              title: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.occupationRate.title'),
              tooltip: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.occupationRate.tooltip')
            },
            staffPlannedActivityRateLeavesExcludedForAllBillable: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.staffPlannedActivityRateLeavesExcludedForAllBillable.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.staffPlannedActivityRateLeavesExcludedForAllBillable.tooltip')
            },
            staffPlannedActivityRateLeavesExcludedForBillableEmployees: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.staffPlannedActivityRateLeavesExcludedForBillableEmployees.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.staffPlannedActivityRateLeavesExcludedForBillableEmployees.tooltip')
            },
            staffPlannedActivityRateLeavesExcludedForBillableExternals: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.staffPlannedActivityRateLeavesExcludedForBillableExternals.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.staffPlannedActivityRateLeavesExcludedForBillableExternals.tooltip')
            },
            staffSignedActivityRateLeavesExcludedForAllBillable: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesExcludedForAllBillable.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesExcludedForAllBillable.tooltip')
            },
            staffSignedActivityRateLeavesExcludedForBillableEmployees: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesExcludedForBillableEmployees.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesExcludedForBillableEmployees.tooltip')
            },
            staffSignedActivityRateLeavesExcludedForBillableExternals: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesExcludedForBillableExternals.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesExcludedForBillableExternals.tooltip')
            },
            staffSignedActivityRateLeavesIncludedForBillable: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesIncludedForBillable.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesIncludedForBillable.tooltip')
            },
            staffSignedActivityRateLeavesIncludedForBillableEmployees: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesIncludedForBillableEmployees.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesIncludedForBillableEmployees.tooltip')
            },
            staffSignedActivityRateLeavesIncludedForBillableExternals: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesIncludedForBillableExternals.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesIncludedForBillableExternals.tooltip')
            },
            staffTakenActivityRateLeavesExcludedForAllBillable: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.staffTakenActivityRateLeavesExcludedForAllBillable.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.staffTakenActivityRateLeavesExcludedForAllBillable.tooltip')
            },
            staffTakenActivityRateLeavesExcludedForBillableEmployees: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.staffTakenActivityRateLeavesExcludedForBillableEmployees.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.staffTakenActivityRateLeavesExcludedForBillableEmployees.tooltip')
            },
            staffTakenActivityRateLeavesExcludedForBillableExternals: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.staffTakenActivityRateLeavesExcludedForBillableExternals.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.staffTakenActivityRateLeavesExcludedForBillableExternals.tooltip')
            },
            staffTakenActivityRateLeavesIncludedForBillable: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.staffTakenActivityRateLeavesIncludedForBillable.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.staffTakenActivityRateLeavesIncludedForBillable.tooltip')
            },
            staffTakenActivityRateLeavesIncludedForBillableEmployees: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.staffTakenActivityRateLeavesIncludedForBillableEmployees.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.staffTakenActivityRateLeavesIncludedForBillableEmployees.tooltip')
            },
            staffTakenActivityRateLeavesIncludedForBillableExternals: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.staffTakenActivityRateLeavesIncludedForBillableExternals.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.staffTakenActivityRateLeavesIncludedForBillableExternals.tooltip')
            },
            unbilledTakenStaffActivityRateForBillable: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.unbilledTakenStaffActivityRateForBillable.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.unbilledTakenStaffActivityRateForBillable.tooltip')
            },
            unbilledTakenStaffActivityRateForBillableEmployees: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.unbilledTakenStaffActivityRateForBillableEmployees.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.unbilledTakenStaffActivityRateForBillableEmployees.tooltip')
            },
            unbilledTakenStaffActivityRateForBillableExternals: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.unbilledTakenStaffActivityRateForBillableExternals.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.unbilledTakenStaffActivityRateForBillableExternals.tooltip')
            }
          },
          title: i18n.t('reporting:categories.humanResourcesSynthesis.title')
        },
        projects: {
          scorecards: {
            activityArea: {
              title: i18n.t('advmodcandidates:search.columns.values.activityAreas')
            },
            advancementProductionPlannedTime: {
              title: i18n.t('reporting:categories.projects.scorecards.advancementProductionPlannedTime.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.advancementProductionPlannedTime.tooltip')
            },
            advancementProductionSignedTimeSmoothedOnPeriod: {
              title: i18n.t('reporting:categories.projects.scorecards.advancementProductionSignedTimeSmoothedOnPeriod.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.advancementProductionSignedTimeSmoothedOnPeriod.tooltip')
            },
            advancementProductionUsedTime: {
              title: i18n.t('reporting:categories.projects.scorecards.advancementProductionUsedTime.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.advancementProductionUsedTime.tooltip')
            },
            company: {
              title: i18n.t('models:customer.name')
            },
            costsOfPurchasesExcludingTax: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.costsOfPurchasesExcludingTaxPerStates.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.costsOfPurchasesExcludingTax.tooltip')
            },
            costsProductionExcludingTax: {
              title: i18n.t('reporting:categories.projects.scorecards.costsProductionExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.costsProductionExcludingTax.tooltip')
            },
            costsSignedExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.costsSignedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.costsSignedExcludingTax.tooltip')
            },
            costsSimulatedExcludingTax: {
              title: i18n.t('reporting:categories.projects.scorecards.costsSimulatedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.costsSimulatedExcludingTax.tooltip')
            },
            deltaInvoicedExcludingTaxFollowingInvoicesDate: {
              title: i18n.t('reporting:categories.projects.scorecards.deltaInvoicedExcludingTaxFollowingInvoicesDate.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.deltaInvoicedExcludingTaxFollowingInvoicesDate.tooltip')
            },
            deltaInvoicedExcludingTaxFollowingInvoicesPeriod: {
              title: i18n.t('reporting:categories.projects.scorecards.deltaInvoicedExcludingTaxFollowingInvoicesPeriod.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.deltaInvoicedExcludingTaxFollowingInvoicesPeriod.tooltip')
            },
            durationOfExceptionalUsedCalendar: {
              title: i18n.t('reporting:categories.projects.scorecards.durationOfExceptionalUsedCalendar.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.durationOfExceptionalUsedCalendar.tooltip')
            },
            durationOfExceptionalUsedCalendarPerResources: {
              title: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.exceptionalCalendars'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.durationOfExceptionalUsedCalendarPerResources.tooltip')
            },
            durationOfExceptionalUsedTime: {
              title: i18n.t('reporting:categories.projects.scorecards.durationOfExceptionalUsedTime.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.durationOfExceptionalUsedTime.tooltip')
            },
            durationOfExceptionalUsedTimePerResources: {
              title: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.exceptionalTimes'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.durationOfExceptionalUsedTimePerResources.tooltip')
            },
            durationOfProductionPlannedTime: {
              title: i18n.t('reporting:categories.projects.scorecards.durationOfProductionPlannedTime.title'),
              tooltip: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.durationOfAllPlannedTime.tooltip')
            },
            durationOfProductionPlannedTimePerResources: {
              title: i18n.t('reporting:categories.projects.scorecards.durationOfProductionPlannedTimePerResources.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.durationOfProductionPlannedTimePerResources.tooltip')
            },
            durationOfProductionSignedAndPlannedTime: {
              title: i18n.t('reporting:categories.projects.scorecards.durationOfProductionSignedAndPlannedTime.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.durationOfProductionSignedAndPlannedTime.tooltip')
            },
            durationOfProductionSignedAndUsedTime: {
              title: i18n.t('reporting:categories.projects.scorecards.durationOfProductionSignedAndUsedTime.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.durationOfProductionSignedAndUsedTime.tooltip')
            },
            durationOfProductionSignedTime: {
              title: i18n.t('reporting:categories.projects.scorecards.durationOfProductionSignedTime.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.durationOfProductionSignedTime.tooltip')
            },
            durationOfProductionSignedTimePerResources: {
              title: i18n.t('reporting:categories.projects.scorecards.durationOfProductionSignedTimePerResources.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.durationOfProductionSignedTimePerResources.tooltip')
            },
            durationOfProductionSignedTimeSmoothedOnPeriod: {
              title: i18n.t('reporting:categories.projects.scorecards.durationOfProductionSignedTimeSmoothedOnPeriod.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.durationOfProductionSignedTimeSmoothedOnPeriod.tooltip')
            },
            durationOfProductionSignedTimeSmoothedOnPeriodPerResources: {
              title: i18n.t('resourceplanner:signedTime'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.durationOfProductionSignedTimeSmoothedOnPeriodPerResources.tooltip')
            },
            durationOfProductionUsedTime: {
              title: i18n.t('reporting:categories.projects.scorecards.durationOfProductionUsedTime.title'),
              tooltip: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.durationOfAllUsedTime.tooltip')
            },
            durationOfProductionUsedTimeInDays: {
              title: i18n.t('reporting:categories.projects.scorecards.durationOfProductionUsedTimeInDays.title'),
              tooltip: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.durationOfAllUsedTime.tooltip')
            },
            durationOfProductionUsedTimeInWorkUnits: {
              title: i18n.t('reporting:categories.projects.scorecards.durationOfProductionUsedTimeInWorkUnits.title'),
              tooltip: i18n.t('reporting:categories.activityExpensesSynthesis.scorecards.durationOfAllUsedTime.tooltip')
            },
            durationOfProductionUsedTimePerResources: {
              title: i18n.t('reporting:categories.projects.scorecards.durationOfProductionUsedTimePerResources.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.durationOfProductionUsedTimePerResources.tooltip')
            },
            endDate: {
              title: i18n.t('houraccounts:table.end')
            },
            expertiseArea: {
              title: i18n.t('wemailing:tinyMCE.expertiseArea')
            },
            mainManager: {
              title: i18n.t('advmodcandidates:search.responsible.name')
            },
            activityType: {
              title: i18n.t('resourceplanner:resource.activityType')
            },
            marginGrossInvoicedExcludingTax: {
              title: i18n.t('reporting:categories.projects.scorecards.marginGrossInvoicedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.marginGrossContributiveInvoicedExcludingTaxFollowingInvoicesDate.tooltip')
            },
            marginGrossInvoicedExcludingTaxFollowingInvoicesPeriod: {
              title: i18n.t('reporting:categories.projects.scorecards.marginGrossInvoicedExcludingTaxFollowingInvoicesPeriod.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.marginGrossInvoicedExcludingTaxFollowingInvoicesPeriod.tooltip')
            },
            marginGrossInvoicedPaidExcludingTaxFollowingInvoicesDate: {
              title: i18n.t('reporting:categories.projects.scorecards.marginGrossInvoicedPaidExcludingTaxFollowingInvoicesDate.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.marginGrossInvoicedPaidExcludingTaxFollowingInvoicesDate.tooltip')
            },
            marginGrossOrderedExcludingTax: {
              title: i18n.t('reporting:categories.projects.scorecards.marginGrossOrderedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.marginGrossOrderedExcludingTax.tooltip')
            },
            marginGrossProductionExcludingTax: {
              title: i18n.t('reporting:categories.projects.scorecards.marginGrossProductionExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.marginGrossContributiveProductionExcludingTax.tooltip')
            },
            marginGrossSignedExcludingTax: {
              title: i18n.t('reporting:categories.projects.scorecards.marginGrossSignedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.marginGrossSignedExcludingTax.tooltip')
            },
            marginSignedExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.marginSignedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.marginSignedExcludingTax.tooltip')
            },
            marginSimulatedExcludingTax: {
              title: i18n.t('reporting:categories.projects.scorecards.marginSimulatedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.marginSimulatedExcludingTax.tooltip')
            },
            productionCostByResource: {
              title: i18n.t('reporting:categories.projects.scorecards.productionCostByResource.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.productionCostByResource.tooltip')
            },
            profitabilityGrossInvoiced: {
              title: i18n.t('reporting:categories.projects.scorecards.profitabilityGrossInvoiced.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.profitabilityGrossInvoiced.tooltip')
            },
            profitabilityGrossInvoicedFollowingInvoicesPeriod: {
              title: i18n.t('reporting:categories.projects.scorecards.profitabilityGrossInvoicedFollowingInvoicesPeriod.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.profitabilityGrossInvoicedFollowingInvoicesPeriod.tooltip')
            },
            profitabilityGrossInvoicedPaidFollowingInvoicesDate: {
              title: i18n.t('reporting:categories.projects.scorecards.profitabilityGrossInvoicedPaidFollowingInvoicesDate.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.profitabilityGrossInvoicedPaidFollowingInvoicesDate.tooltip')
            },
            profitabilityGrossOrdered: {
              title: i18n.t('reporting:categories.projects.scorecards.profitabilityGrossOrdered.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.profitabilityGrossOrdered.tooltip')
            },
            profitabilityGrossProduction: {
              title: i18n.t('reporting:categories.projects.scorecards.profitabilityGrossProduction.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.profitabilityGrossProduction.tooltip')
            },
            profitabilityGrossSigned: {
              title: i18n.t('reporting:categories.projects.scorecards.profitabilityGrossSigned.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.profitabilityGrossSigned.tooltip')
            },
            profitabilitySigned: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.profitabilitySigned.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.profitabilitySigned.tooltip')
            },
            profitabilitySimulated: {
              title: i18n.t('reporting:categories.projects.scorecards.profitabilitySimulated.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.profitabilitySimulated.tooltip')
            },
            purchaseProductionCost: {
              title: i18n.t('reporting:categories.projects.scorecards.purchaseProductionCost.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.purchaseProductionCost.tooltip')
            },
            reference: {
              title: i18n.t('wdevis:reference')
            },
            remainsToBeDone: {
              title: i18n.t('advmodprojects:indicators.remainsToBeDone.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.remainsToBeDone.tooltip')
            },
            remainsToBeDoneSignedRate: {
              title: i18n.t('reporting:categories.projects.scorecards.remainsToBeDoneSignedRate.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.remainsToBeDoneSignedRate.tooltip')
            },
            remainsToBeDoneSignedRateSmoothedOnPeriod: {
              title: i18n.t('reporting:categories.projects.scorecards.remainsToBeDoneSignedRateSmoothedOnPeriod.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.remainsToBeDoneSignedRateSmoothedOnPeriod.tooltip')
            },
            restBudgetOrdered: {
              title: i18n.t('reporting:categories.projects.scorecards.restBudgetOrdered.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.restBudgetOrdered.tooltip')
            },
            restBudgetProductionPlannedTime: {
              title: i18n.t('reporting:categories.projects.scorecards.restBudgetProductionPlannedTime.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.restBudgetProductionPlannedTime.tooltip')
            },
            restBudgetProductionPlannedTimeSmoothedOnPeriod: {
              title: i18n.t('reporting:categories.projects.scorecards.restBudgetProductionPlannedTimeSmoothedOnPeriod.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.restBudgetProductionPlannedTimeSmoothedOnPeriod.tooltip')
            },
            restBudgetProductionSignedTimeSmoothedOnPeriod: {
              title: i18n.t('reporting:categories.projects.scorecards.restBudgetProductionSignedTimeSmoothedOnPeriod.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.restBudgetProductionSignedTimeSmoothedOnPeriod.tooltip')
            },
            restBudgetProductionUsedTime: {
              title: i18n.t('reporting:categories.projects.scorecards.restBudgetProductionUsedTime.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.restBudgetProductionUsedTime.tooltip')
            },
            startDate: {
              title: i18n.t('houraccounts:table.start')
            },
            state: {
              title: i18n.t('survey:view.state.abbr')
            },
            turnoverInvoicedExcludingTaxFollowingInvoicesDate: {
              title: i18n.t('advmodprojects:indicators.turnoverInvoicedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.billingSynthesis.scorecards.turnoverInvoicedExcludingTaxFollowingInvoicesDate.tooltip')
            },
            turnoverInvoicedExcludingTaxFollowingInvoicesPeriod: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.turnoverInvoicedExcludingTaxFollowingInvoicesPeriod.title'),
              tooltip: i18n.t('reporting:categories.billingSynthesis.scorecards.turnoverInvoicedExcludingTaxFollowingInvoicesPeriod.tooltip')
            },
            turnoverInvoicedPaidExcludingTaxFollowingInvoicesDate: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.turnoverRealInvoicedPaidExcludingTaxFollowingInvoicesDate.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.turnoverRealInvoicedPaidExcludingTaxFollowingInvoicesDate.tooltip')
            },
            turnoverOrderedExcludingTax: {
              title: i18n.t('orders:view.tabs.invoices.orderedTurnover'),
              tooltip: i18n.t('reporting:categories.billingSynthesis.scorecards.turnoverOrderedExcludingTax.tooltip')
            },
            turnoverProductionAdditional: {
              title: i18n.t('reporting:categories.projects.scorecards.turnoverProductionAdditional.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.turnoverProductionAdditional.tooltip')
            },
            turnoverProductionByResource: {
              title: i18n.t('reporting:categories.projects.scorecards.turnoverProductionByResource.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.turnoverProductionByResource.tooltip')
            },
            turnoverProductionExcludingTax: {
              title: i18n.t('reporting:categories.projects.scorecards.turnoverProductionExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.turnoverProductionExcludingTax.tooltip')
            },
            turnoverSignedExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.turnoverSignedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.turnoverSignedExcludingTax.tooltip')
            },
            turnoverSimulatedExcludingTax: {
              title: i18n.t('reporting:categories.projects.scorecards.turnoverSimulatedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.turnoverSimulatedExcludingTax.tooltip')
            },
            typeOf: {
              title: i18n.t('wemailing:templates.typesOf.title')
            }
          },
          title: i18n.t('advmodprojects:view.title')
        },
        recruitmentSynthesis: {
          scorecards: {
            numberOfActionsOnCandidatesPerTypes: {
              i18nKey: settings.fromKey('setting.action.candidate'),
              title: i18n.t('reporting:categories.recruitmentSynthesis.scorecards.numberOfActionsOnCandidatesPerTypes.title'),
              tooltip: i18n.t('reporting:categories.recruitmentSynthesis.scorecards.numberOfActionsOnCandidatesPerTypes.tooltip')
            },
            numberOfCandidatesCreated: {
              abbr: i18n.t('reporting:categories.recruitmentSynthesis.scorecards.numberOfCandidatesCreated.abbr'),
              title: i18n.t('reporting:categories.recruitmentSynthesis.scorecards.numberOfCandidatesCreated.title')
            },
            numberOfCandidatesCreatedPerSources: {
              i18nKey: settings.fromKey('setting.source'),
              title: i18n.t('reporting:categories.recruitmentSynthesis.scorecards.numberOfActionsOnCandidatesPerTypes.title'),
              tooltip: i18n.t('reporting:categories.recruitmentSynthesis.scorecards.numberOfCandidatesCreatedPerSources.tooltip')
            },
            numberOfCandidatesCreatedPerStates: {
              i18nKey: settings.fromKey('setting.state.candidate'),
              title: i18n.t('reporting:categories.recruitmentSynthesis.scorecards.numberOfActionsOnCandidatesPerTypes.title'),
              tooltip: i18n.t('reporting:categories.recruitmentSynthesis.scorecards.numberOfCandidatesCreatedPerStates.tooltip')
            },
            numberOfPositioningsCreated: {
              abbr: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfPositioningsCreated.abbr'),
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.numberOfPositioningsCreated.title')
            }
          },
          title: i18n.t('config:common.projectMode.3')
        },
        resources: {
          scorecards: {
            averageDailyProductionSignedPriceExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.averageSignedRate.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.averageDailyProductionSignedPriceExcludingTax.tooltip')
            },
            averageDailyProductionUsedPriceExcludingTax: {
              title: i18n.t('reporting:categories.resources.scorecards.averageDailyProductionUsedPriceExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.averageDailyProductionUsedPriceExcludingTax.tooltip')
            },
            costsOfAbsencesPlannedTime: {
              title: i18n.t('reporting:categories.resources.scorecards.costsOfAbsencesPlannedTime.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.costsOfAbsencesPlannedTime.tooltip')
            },
            costsOfAbsencesSignedTime: {
              title: i18n.t('reporting:categories.resources.scorecards.costsOfAbsencesSignedTime.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.costsOfAbsencesSignedTime.tooltip')
            },
            costsOfAbsencesUsedTime: {
              title: i18n.t('reporting:categories.resources.scorecards.costsOfAbsencesUsedTime.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.costsOfAbsencesUsedTime.tooltip')
            },
            costsOfInternalPlannedTime: {
              title: i18n.t('reporting:categories.resources.scorecards.costsOfInternalPlannedTime.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.costsOfInternalPlannedTime.tooltip')
            },
            costsOfInternalSignedTime: {
              title: i18n.t('reporting:categories.resources.scorecards.costsOfInternalSignedTime.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.costsOfInternalSignedTime.tooltip')
            },
            costsOfInternalUsedTime: {
              title: i18n.t('reporting:categories.resources.scorecards.costsOfInternalUsedTime.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.costsOfInternalUsedTime.tooltip')
            },
            costsPlannedExcludingTax: {
              title: i18n.t('reporting:categories.resources.scorecards.costsPlannedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.costsPlannedExcludingTax.tooltip')
            },
            costsRealExcludingTax: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.costsRealExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.costsRealExcludingTax.tooltip')
            },
            costsSignedExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.costsSignedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.costsSignedExcludingTax.tooltip')
            },
            disponibilityPlanned: {
              title: i18n.t('reporting:categories.resources.scorecards.disponibilityPlanned.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.disponibilityPlanned.tooltip')
            },
            disponibilityPlannedFromJ1: {
              title: i18n.t('reporting:categories.resources.scorecards.disponibilityPlannedFromJ1.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.disponibilityPlannedFromJ1.tooltip')
            },
            disponibilitySigned: {
              title: i18n.t('reporting:categories.resources.scorecards.disponibilitySigned.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.disponibilitySigned.tooltip')
            },
            disponibilitySignedSmoothedOnPeriod: {
              title: i18n.t('reporting:categories.resources.scorecards.disponibilitySignedSmoothedOnPeriod.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.disponibilitySignedSmoothedOnPeriod.tooltip')
            },
            disponibilityUsed: {
              title: i18n.t('reporting:categories.resources.scorecards.disponibilityUsed.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.disponibilityUsed.tooltip')
            },
            disponibilityUsedUntilJ: {
              title: i18n.t('reporting:categories.resources.scorecards.disponibilityUsedUntilJ.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.disponibilityUsedUntilJ.tooltip')
            },
            durationOfAbsencesPlannedTime: {
              title: i18n.t('reporting:categories.resources.scorecards.durationOfAbsencesPlannedTime.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.durationOfAbsencesPlannedTime.tooltip')
            },
            durationOfAbsencesPlannedTimeFromJ1: {
              title: i18n.t('reporting:categories.resources.scorecards.durationOfAbsencesPlannedTimeFromJ1.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.durationOfAbsencesPlannedTimeFromJ1.tooltip')
            },
            durationOfAbsencesSignedTime: {
              title: i18n.t('reporting:categories.resources.scorecards.durationOfAbsencesSignedTime.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.durationOfAbsencesSignedTime.tooltip')
            },
            durationOfAbsencesSignedTimeSmoothedOnPeriod: {
              title: i18n.t('reporting:categories.resources.scorecards.durationOfAbsencesSignedTimeSmoothedOnPeriod.title'),
              tooltip: i18n.t('reporting:categories.humanResourcesSynthesis.scorecards.durationOfAbsencesSignedTime.tooltip')
            },
            durationOfAbsencesUsedTime: {
              title: i18n.t('reporting:categories.resources.scorecards.durationOfAbsencesUsedTime.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.durationOfAbsencesUsedTime.tooltip')
            },
            durationOfAbsencesUsedTimeUntilJ: {
              title: i18n.t('reporting:categories.resources.scorecards.durationOfAbsencesUsedTimeUntilJ.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.durationOfAbsencesUsedTimeUntilJ.tooltip')
            },
            durationOfExceptionalUsedCalendar: {
              title: i18n.t('reporting:categories.projects.scorecards.durationOfExceptionalUsedCalendar.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.durationOfExceptionalUsedCalendar.tooltip')
            },
            durationOfExceptionalUsedCalendarPerProjects: {
              title: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.exceptionalCalendars'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.durationOfExceptionalUsedCalendarPerProjects.tooltip')
            },
            durationOfExceptionalUsedTime: {
              title: i18n.t('reporting:categories.projects.scorecards.durationOfExceptionalUsedTime.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.durationOfExceptionalUsedTime.tooltip')
            },
            durationOfExceptionalUsedTimePerProjects: {
              title: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.exceptionalTimes'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.durationOfExceptionalUsedTimePerProjects.tooltip')
            },
            durationOfInternalPlannedTime: {
              title: i18n.t('reporting:categories.resources.scorecards.durationOfInternalPlannedTime.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.durationOfInternalPlannedTime.tooltip')
            },
            durationOfInternalPlannedTimeFromJ1: {
              title: i18n.t('reporting:categories.resources.scorecards.durationOfInternalPlannedTimeFromJ1.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.durationOfInternalPlannedTimeFromJ1.tooltip')
            },
            durationOfInternalSignedTime: {
              title: i18n.t('reporting:categories.resources.scorecards.durationOfInternalSignedTime.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.durationOfInternalSignedTime.tooltip')
            },
            durationOfInternalSignedTimeSmoothedOnPeriod: {
              title: i18n.t('reporting:categories.resources.scorecards.durationOfInternalSignedTimeSmoothedOnPeriod.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.durationOfInternalSignedTimeSmoothedOnPeriod.tooltip')
            },
            durationOfInternalUsedTime: {
              title: i18n.t('reporting:categories.resources.scorecards.durationOfInternalUsedTime.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.durationOfInternalUsedTime.tooltip')
            },
            durationOfInternalUsedTimeUntilJ: {
              title: i18n.t('reporting:categories.resources.scorecards.durationOfInternalUsedTimeUntilJ.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.durationOfInternalUsedTimeUntilJ.tooltip')
            },
            durationOfProductionPlannedTime: {
              title: i18n.t('reporting:categories.resources.scorecards.durationOfProductionPlannedTime.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.durationOfProductionPlannedTime.tooltip')
            },
            durationOfProductionPlannedTimeFromJ1: {
              title: i18n.t('reporting:categories.resources.scorecards.durationOfProductionPlannedTimeFromJ1.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.durationOfProductionPlannedTimeFromJ1.tooltip')
            },
            durationOfProductionPlannedTimeFromJ1PerProjects: {
              title: i18n.t('reporting:categories.resources.scorecards.durationOfProductionPlannedTimeFromJ1PerProjects.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.durationOfProductionPlannedTimeFromJ1PerProjects.tooltip')
            },
            durationOfProductionPlannedTimePerProjects: {
              title: i18n.t('reporting:categories.resources.scorecards.durationOfProductionPlannedTimePerProjects.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.durationOfProductionPlannedTimePerProjects.tooltip')
            },
            durationOfProductionSignedAndPlannedTimePerProjects: {
              title: i18n.t('reporting:categories.resources.scorecards.durationOfProductionSignedAndPlannedTimePerProjects.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.durationOfProductionSignedAndPlannedTimePerProjects.tooltip')
            },
            durationOfProductionSignedAndUsedTimePerProjects: {
              title: i18n.t('reporting:categories.resources.scorecards.durationOfProductionSignedAndUsedTimePerProjects.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.durationOfProductionSignedAndUsedTimePerProjects.tooltip')
            },
            durationOfProductionSignedTime: {
              title: i18n.t('reporting:categories.resources.scorecards.durationOfProductionSignedTime.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.durationOfProductionSignedTime.tooltip')
            },
            durationOfProductionSignedTimePerProjects: {
              title: i18n.t('reporting:categories.resources.scorecards.durationOfProductionSignedTimePerProjects.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.durationOfProductionSignedTimePerProjects.tooltip')
            },
            durationOfProductionSignedTimeSmoothedOnPeriod: {
              title: i18n.t('reporting:categories.resources.scorecards.durationOfProductionSignedTimeSmoothedOnPeriod.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.durationOfProductionSignedTimeSmoothedOnPeriod.tooltip')
            },
            durationOfProductionSignedTimeSmoothedOnPeriodPerProjects: {
              title: i18n.t('reporting:categories.resources.scorecards.durationOfProductionSignedTimeSmoothedOnPeriodPerProjects.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.durationOfProductionSignedTimeSmoothedOnPeriodPerProjects.tooltip')
            },
            durationOfProductionUsedTime: {
              title: i18n.t('reporting:categories.resources.scorecards.durationOfProductionUsedTime.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.durationOfProductionUsedTime.tooltip')
            },
            durationOfProductionUsedTimePerProjects: {
              title: i18n.t('reporting:categories.resources.scorecards.durationOfProductionUsedTimePerProjects.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.durationOfProductionUsedTimePerProjects.tooltip')
            },
            durationOfProductionUsedTimeUntilJ: {
              title: i18n.t('reporting:categories.resources.scorecards.durationOfProductionUsedTimeUntilJ.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.durationOfProductionUsedTimeUntilJ.tooltip')
            },
            durationOfProductionUsedTimeUntilJPerProjects: {
              title: i18n.t('reporting:categories.resources.scorecards.durationOfProductionUsedTimeUntilJPerProjects.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.durationOfProductionUsedTimeUntilJPerProjects.tooltip')
            },
            loadingRatePlanned: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.staffPlannedActivityRateLeavesExcludedForAllBillable.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.loadingRatePlanned.tooltip')
            },
            loadingRatePlannedFromJ1: {
              title: i18n.t('reporting:categories.resources.scorecards.loadingRatePlannedFromJ1.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.loadingRatePlannedFromJ1.tooltip')
            },
            loadingRateSigned: {
              title: i18n.t('reporting:categories.resources.scorecards.loadingRateSigned.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.loadingRateSigned.tooltip')
            },
            loadingRateSignedSmoothedOnPeriod: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesExcludedForAllBillable.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.loadingRateSignedSmoothedOnPeriod.tooltip')
            },
            loadingRateUsed: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.staffTakenActivityRateLeavesExcludedForAllBillable.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.loadingRateUsed.tooltip')
            },
            loadingRateUsedUntilJ: {
              title: i18n.t('reporting:categories.resources.scorecards.loadingRateUsedUntilJ.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.loadingRateUsedUntilJ.tooltip')
            },
            marginPlannedExcludingTax: {
              title: i18n.t('reporting:categories.resources.scorecards.marginPlannedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.marginPlannedExcludingTax.tooltip')
            },
            marginRealProductionExcludingTax: {
              title: i18n.t('reporting:categories.resources.scorecards.marginRealProductionExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.globalSynthesis.scorecards.marginRealContributiveProductionExcludingTax.tooltip')
            },
            marginSignedExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.marginSignedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.marginSignedExcludingTax.tooltip')
            },
            numberOfWorkableDaysFromJ1: {
              title: i18n.t('reporting:categories.resources.scorecards.numberOfWorkableDaysFromJ1.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.numberOfWorkableDaysFromJ1.tooltip')
            },
            numberOfWorkableDaysUntilJ: {
              title: i18n.t('reporting:categories.resources.scorecards.numberOfWorkableDaysUntilJ.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.numberOfWorkableDaysUntilJ.tooltip')
            },
            numberOfWorkingDays: {
              title: i18n.t('reporting:categories.resources.scorecards.numberOfWorkingDays.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.numberOfWorkingDays.tooltip')
            },
            occupationRatePlanned: {
              title: i18n.t('reporting:categories.resources.scorecards.occupationRatePlanned.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.occupationRatePlanned.tooltip')
            },
            occupationRatePlannedFromJ1: {
              title: i18n.t('reporting:categories.resources.scorecards.occupationRatePlannedFromJ1.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.occupationRatePlannedFromJ1.tooltip')
            },
            occupationRateSigned: {
              title: i18n.t('reporting:categories.resources.scorecards.occupationRateSigned.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.occupationRateSigned.tooltip')
            },
            occupationRateSignedSmoothedOnPeriod: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.staffSignedActivityRateLeavesIncludedForBillable.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.occupationRateSignedSmoothedOnPeriod.tooltip')
            },
            occupationRateUsed: {
              title: i18n.t('reporting:categories.globalSynthesis.scorecards.staffTakenActivityRateLeavesIncludedForBillable.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.occupationRateUsed.tooltip')
            },
            occupationRateUsedUntilJ: {
              title: i18n.t('reporting:categories.resources.scorecards.occupationRateUsedUntilJ.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.occupationRateUsedUntilJ.tooltip')
            },
            averageDailyProductionCost: {
              title: i18n.t('reporting:categories.resources.scorecards.averageDailyProductionCost.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.averageDailyProductionCost.tooltip')
            },
            productionCostByProject: {
              title: i18n.t('reporting:categories.resources.scorecards.productionCostByProject.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.productionCostByResource.tooltip')
            },
            profitabilityPlanned: {
              title: i18n.t('reporting:categories.resources.scorecards.profitabilityPlanned.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.profitabilityPlanned.tooltip')
            },
            profitabilityRealProduction: {
              title: i18n.t('reporting:categories.resources.scorecards.profitabilityRealProduction.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.profitabilityRealProduction.tooltip')
            },
            profitabilitySigned: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.profitabilitySigned.title'),
              tooltip: i18n.t('reporting:categories.commercialSynthesis.scorecards.profitabilitySigned.tooltip')
            },
            restBudgetProductionPlannedTime: {
              title: i18n.t('reporting:categories.projects.scorecards.restBudgetProductionPlannedTime.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.restBudgetProductionPlannedTime.tooltip')
            },
            restBudgetProductionPlannedTimeSmoothedOnPeriod: {
              title: i18n.t('reporting:categories.projects.scorecards.restBudgetProductionPlannedTimeSmoothedOnPeriod.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.restBudgetProductionPlannedTimeSmoothedOnPeriod.tooltip')
            },
            restBudgetProductionSigned: {
              title: i18n.t('reporting:categories.projects.scorecards.restBudgetOrdered.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.restBudgetProductionSigned.tooltip')
            },
            restBudgetProductionSignedTimeSmoothedOnPeriod: {
              title: i18n.t('reporting:categories.projects.scorecards.restBudgetProductionSignedTimeSmoothedOnPeriod.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.restBudgetProductionSignedTimeSmoothedOnPeriod.tooltip')
            },
            restBudgetProductionUsedTime: {
              title: i18n.t('reporting:categories.resources.scorecards.restBudgetProductionUsedTime.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.restBudgetProductionUsedTime.tooltip')
            },
            totalExpenses: {
              title: i18n.t('reporting:categories.resources.scorecards.totalExpenses.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.totalExpenses.tooltip')
            },
            totalExpensesReinvoiced: {
              title: i18n.t('reporting:categories.resources.scorecards.totalExpensesReinvoiced.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.totalExpensesReinvoiced.tooltip')
            },
            costsProductionExcludingTax: {
              title: i18n.t('reporting:categories.resources.scorecards.costsProductionExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.productionCostByResource.tooltip')
            },
            turnoverProductionExcludingTax: {
              title: i18n.t('reporting:categories.projects.scorecards.turnoverProductionExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.turnoverProductionByResource.tooltip')
            },
            turnoverPlannedExcludingTax: {
              title: i18n.t('reporting:categories.resources.scorecards.turnoverPlannedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.turnoverPlannedExcludingTax.tooltip')
            },
            turnoverProductionByProject: {
              title: i18n.t('reporting:categories.resources.scorecards.turnoverProductionByProject.title'),
              tooltip: i18n.t('reporting:categories.projects.scorecards.turnoverProductionByResource.tooltip')
            },
            turnoverSignedExcludingTax: {
              title: i18n.t('reporting:categories.commercialSynthesis.scorecards.turnoverSignedExcludingTax.title'),
              tooltip: i18n.t('reporting:categories.resources.scorecards.turnoverSignedExcludingTax.tooltip')
            },
            activityTypePerProjects: {
              title: i18n.t('resourceplanner:resource.activityType')
            }
          },
          title: i18n.t('advmodprojects:settings.remainsToBeDoneMode.options.resources')
        }
      },
      configuration: {
        titleDistributionCustomer: i18n.t('reporting.configuration.titleDistributionCustomer'),
        titleProjects: i18n.t('reporting.configuration.titleProjects'),
        titleReporting: i18n.t('reporting.configuration.titleReporting'),
        titleResources: i18n.t('reporting.configuration.titleResources')
      },
      defaultScorecards: "Pour recharger la configuration par défaut, il vous suffit de vider entièrement la liste et d'enregistrer.",
      filters: {
        availabilitySorting: [{
          id: '',
          value: i18n.t('wplanproduction:filters.availabilitySorting.none')
        }, {
          id: 'asc',
          value: i18n.t('wplanproduction:filters.availabilitySorting.asc')
        }, {
          id: 'desc',
          value: i18n.t('wplanproduction:filters.availabilitySorting.desc')
        }],
        perimeterType: [{
          id: 'resources',
          value: i18n.t('advmodprojects:settings.remainsToBeDoneMode.options.resources')
        }, {
          id: 'projects',
          value: i18n.t('advmodprojects:view.title')
        }],
        periods: [{
          id: 'onePeriod',
          value: i18n.t('common:dateAndTime.customPeriod')
        }, {
          id: 'periodDynamic',
          value: i18n.t('common:dateAndTime.periodDynamic')
        }, {
          id: 'weekly',
          value: i18n.t('components:filterPeriod.range.thisWeek')
        }, {
          id: 'monthly',
          value: i18n.t('sepa:import.fields.month')
        }, {
          id: 'quarterly',
          value: i18n.t('components:filterPeriod.range.thisTrimester')
        }, {
          id: 'semiAnnual',
          value: i18n.t('components:filterPeriod.range.thisSemester')
        }, {
          id: 'annual',
          value: i18n.t('sepa:import.fields.year')
        }],
        reportingCategory: {
          label: i18n.t('components:bmSearchFilters.reportingCategory.label'),
          showByPeriods: i18n.t('components:bmSearchFilters.period_plural'),
          showByResources: i18n.t('advmodprojects:settings.remainsToBeDoneMode.options.resources')
        }
      },
      graph: 'Graphiques',
      groupAllDeliveries: 'Regrouper toutes les prestations',
      indicators: {
        activityExpensesSynthesis: i18n.t('settings:view.tabs.reporting.indicators.activityExpensesSynthesis'),
        availables: i18n.t('settings:view.tabs.reporting.indicators.availables'),
        billingSynthesis: i18n.t('settings:view.tabs.reporting.indicators.billingSynthesis'),
        commercialSynthesis: i18n.t('settings:view.tabs.reporting.indicators.commercialSynthesis'),
        displayed: i18n.t('settings:view.tabs.reporting.indicators.displayed'),
        globalSynthesis: i18n.t('settings:view.tabs.reporting.indicators.globalSynthesis'),
        humanResourcesSynthesis: i18n.t('settings:view.tabs.reporting.indicators.humanResourcesSynthesis'),
        label: i18n.t('components:bmSearchFilters.scorecards'),
        recruitmentSynthesis: i18n.t('settings:view.tabs.reporting.recruitmentSynthesis')
      },
      loading: {
        message: i18n.t('common:loading.message'),
        title: i18n.t('wpostproduction:loading.title')
      },
      loadingTooLong: {
        message: i18n.t('reporting:view.loadingTooLong.message')
      },
      noresults: 'Aucune donnée trouvée',
      noresults_msg: "Il n'existe pas de données disponibles pour les filtres de recherche sélectionnés. Veuillez élargir votre recherche.",
      occupationGradient: "Afficher un dégradé de couleur en fonction du taux d'occupation",
      others: 'Autres',
      positioningPeriod: {
        created: i18n.t('reporting:view.positioningPeriod.created'),
        running: i18n.t('reporting:view.positioningPeriod.running')
      },
      searchEnd: {
        message: i18n.t('reporting:searchEnd.message'),
        title: i18n.t('reporting:searchEnd.title')
      },
      separatorScorecard: '--- Séparateur ---',
      showContracts: 'Afficher les contrats RH et de sous-traitance',
      showPercentage: {
        offText: i18n.t('reporting.showPercentage.offText'),
        onText: i18n.t('reporting.showPercentage.onText')
      },
      sideMenu: {
        companies: i18n.t('corporama:search.companies.title'),
        productionPlans: i18n.t('wplanproduction:dataTypes.production'),
        projects: i18n.t('advmodprojects:view.title'),
        resources: i18n.t('advmodprojects:settings.remainsToBeDoneMode.options.resources'),
        synthesis: i18n.t('candidates:view.tabs.overview.title'),
        title: i18n.t('saaseditor:reporting.label')
      },
      table: 'Tableaux',
      title: 'Reporting',
      view: 'Vue',
      errors: {
        reportingTimedOut: {
          title: i18n.t('messages:error.reportingTimedOut.title'),
          message: i18n.t('messages:error.reportingTimedOut.message')
        }
      }
    };
  };
});