define("boondmanager/pods/components/octane/bm-progress-bar/dashboard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Ax+ctbxy",
    "block": "{\"symbols\":[\"@model\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"bmc-progressbar bmc-progressbar-dashboard bmc-progressbar-center\",[30,[36,0],[[32,0,[\"isFull\"]],\" bmc-progressbar_full\"],null],[30,[36,0],[[32,0,[\"isOver\"]],\" bmc-progressbar_over\"],null]]]],[17,2],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"bmc-progressbar_infos\"],[12],[2,\"\\n\\t\\t\"],[10,\"span\"],[14,0,\"current-value\"],[12],[1,[32,1,[\"label\"]]],[6,[37,0],[[32,1,[\"foundSimilar\"]]],null,[[\"default\"],[{\"statements\":[[2,\" - \"],[1,[32,1,[\"internalReference\"]]]],\"parameters\":[]}]]],[13],[2,\"\\n\\t\\t\"],[10,\"span\"],[14,0,\"percentageaccomplish\"],[12],[1,[30,[36,2],[\"components:bmProgressBar.percentageSet\"],[[\"percentage\",\"count\"],[[30,[36,1],[[32,0,[\"percentage\"]]],[[\"decimals\"],[[32,0,[\"PERCENTAGE_PRECISION\"]]]]],[32,0,[\"percentage\"]]]]]],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[15,0,[31,[\"bmc-progressbar_bar \",[30,[36,0],[[30,[36,3],[[32,0,[\"percentage\"]],100],null],\"bm-full\"],null]]]],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"bmc-progressbar_content\"],[12],[2,\"\\n\\t\\t\"],[10,\"span\"],[14,0,\"bmc-progressbar_progress\"],[15,5,[30,[36,5],[[30,[36,4],[\"width : \",[32,0,[\"percentage\"]],\"%\"],null]],null]],[12],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"number\",\"t\",\"eq\",\"concat\",\"html-safe\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-progress-bar/dashboard/template.hbs"
  });
});