define("boondmanager/initializers/handle-error-on-request", ["exports", "boondmanager/adapters/errors/version-error"], function (_exports, _versionError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize( /* application */
  ) {
    Ember.Controller.reopen({
      router: Ember.inject.service(),
      notify: Ember.inject.service(),
      i18n: Ember.inject.service(),
      handleErrorOnRequest: function handleErrorOnRequest(error, msg, title, options) {
        if (!navigator.onLine) {
          var i18n = this.get('i18n');
          this.get('notify').error(i18n.t('common:validateErrorConnection'));
        } else {
          if (error instanceof _versionError.default) {
            return this.get('router').transitionTo('badversion');
          } else {
            this.get('notify').error(msg, title, options);
          }
        }
      },
      handle403: function handle403(error, type) {
        this.get('router').transitionTo('private.403', {
          queryParams: {
            type: type
          }
        });
      }
    });
    Ember.Component.reopen({
      router: Ember.inject.service(),
      notify: Ember.inject.service(),
      i18n: Ember.inject.service(),
      handleErrorOnRequest: function handleErrorOnRequest(error, msg, title, options) {
        if (!navigator.onLine) {
          var i18n = this.get('i18n');
          this.get('notify').error(i18n.t('common:validateErrorConnection'));
        } else {
          if (error instanceof _versionError.default) {
            return this.get('router').transitionTo('badversion');
          } else {
            this.get('notify').error(msg, title, options);
          }
        }
      },
      handle403: function handle403(error, type) {
        this.get('router').transitionTo('private.403', {
          queryParams: {
            type: type
          }
        });
      }
    });
    Ember.Service.reopen({
      handle403: function handle403(error, name, id, module) {
        var router = Ember.getOwner(this).lookup('route:application');
        if (id === '0' && name === 'rights') {
          switch (module) {
            case 'opportunity':
              router.transitionTo('private.403', {
                queryParams: {
                  type: 'createOpportunity'
                }
              });
              break;
            default:
              router.transitionTo('private.403');
              break;
          }
        } else {
          router.transitionTo('private.403');
        }
      }
    });
  }
  var _default = _exports.default = {
    initialize: initialize
  };
});