define("boondmanager/pods/components/octane/bm-field/select/results/colors/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "H3jFK3ok",
    "block": "{\"symbols\":[\"&default\",\"@color\",\"@namedBlocksInfo\"],\"statements\":[[10,\"div\"],[14,0,\"bmc-field-simple-colorpicker-color\"],[15,5,[30,[36,1],[[30,[36,0],[\"background: \",[32,2,[\"value\"]],\";\"],null]],null]],[12],[13],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,3],\"default\",[27,[32,1]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"html-safe\",\"-has-block\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/select/results/colors/template.hbs"
  });
});