define("boondmanager/helpers/moment-start-of", ["exports", "ember-moment/utils/helper-compute", "ember-moment/helpers/-base"], function (_exports, _helperCompute, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    moment: Ember.inject.service(),
    globalAllowEmpty: false,
    compute: (0, _helperCompute.default)(function (params, _ref) {
      var locale = _ref.locale,
        timezone = _ref.timezone;
      this._super.apply(this, arguments);
      if (!params || params && params.length !== 2) {
        throw new TypeError('ember-moment: Invalid Number of arguments, must be 2');
      }
      var moment = this.get('moment');
      return this.morphMoment(moment.moment(params[0]), {
        locale: locale,
        timezone: timezone
      }).startOf(params[1]);
    })
  });
});