define("boondmanager/helpers/icon", ["exports", "boondmanager/utils/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.icon = icon;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  function icon(params) {
    if (params && Ember.typeOf(params[0]) === 'string') {
      var types = ['fa', 'icon', 'glyphicon'];
      var _params$0$split = params[0].split('-', 1),
        _params$0$split2 = _slicedToArray(_params$0$split, 1),
        iconType = _params$0$split2[0];
      var iconClasses = [params[0]];
      if (types.indexOf(iconType) !== -1) {
        iconClasses.unshift(iconType);
      }
      return new Ember.String.htmlSafe("<i class=\"".concat(iconClasses.join(' '), "\" aria-hidden=\"true\" focusable=\"false\"></i>"));
    } else {
      _logger.default.log.apply(_logger.default, ['[icon helper] - Bad params'].concat(Array.prototype.slice.call(arguments)));
      return '';
    }
  }
  var _default = _exports.default = Ember.Helper.helper(icon);
});