define("boondmanager/models/todelete/sourcefrag", ["exports", "ember-data", "boondmanager/models/base"], function (_exports, _emberData, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    // ATTRIBUTES
    detail: _emberData.default.attr('string'),
    typeOf: _emberData.default.attr('number')
  });
});