define("boondmanager/utils/random", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getRandomFloat = void 0;
  var getRandomFloat = _exports.getRandomFloat = function getRandomFloat(max) {
    var fixed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
    return (Math.random() * max).toFixed(fixed);
  };
});