define("boondmanager/pods/components/octane/bm-field/nationalities/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ICGHKiEQ",
    "block": "{\"symbols\":[\"__arg0\",\"__arg1\",\"item\",\"selected\",\"&attrs\",\"@name\",\"@selection\",\"@onChange\"],\"statements\":[[11,\"div\"],[24,0,\"bm-field-nationalities\"],[17,5],[4,[38,4],[[32,0,[\"onInsert\"]]],null],[12],[2,\"\\n\\t\"],[8,\"octane/bm-field/select\",[[16,\"data-name\",[30,[36,5],[\"input-\",[32,6]],null]]],[[\"@options\",\"@selected\",\"@disabled\",\"@onChange\",\"@required\",\"@namedBlocksInfo\"],[[32,0,[\"options\"]],[32,7],[32,0,[\"disabled\"]],[32,8],[32,0,[\"required\"]],[30,[36,6],null,[[\"selected-item\",\"option\"],[1,1]]]]],[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1],\"selected-item\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,1],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[30,[36,0],[[32,4,[\"icon\"]]],null]],[2,\" \"],[1,[32,4,[\"value\"]]],[2,\"\\n\\t\\t\"]],\"parameters\":[4]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1],\"option\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[30,[36,0],[[32,3,[\"icon\"]]],null]],[2,\" \"],[1,[32,3,[\"value\"]]],[2,\"\\n\\t\\t\"]],\"parameters\":[3]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"let\",\"-is-named-block-invocation\",\"if\",\"did-insert\",\"concat\",\"hash\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/nationalities/template.hbs"
  });
});