define("boondmanager/pods/components/octane/bm-simple-period/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "BpLra8Kz",
    "block": "{\"symbols\":[\"@startDate\",\"@endDate\"],\"statements\":[[6,[37,3],[[30,[36,2],[[32,1],[32,2]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[10,\"p\"],[12],[1,[30,[36,0],[[32,1]],null]],[2,\" \"],[1,[30,[36,1],[\"common:dateTo\"],null]],[2,\" \"],[1,[30,[36,0],[[32,2]],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[10,\"p\"],[12],[2,\"-\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"time\",\"t\",\"and\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-simple-period/template.hbs"
  });
});