define("boondmanager/adapters/bankingwebview", ["exports", "boondmanager/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    /** PROPERTIES **/

    /**
     * UrlTemplate for queryRecord
     * @property queryRecordUrlTemplate
     * @type string
     */
    queryRecordUrlTemplate: '{+host}/api/banking-connections{/endpoint*}{?query*}',
    /** METHODS **/
    /**
     * Path Customization
     * @returns {string}
     */
    pathForType: function pathForType() {
      return 'banking-connections';
    }
  });
});