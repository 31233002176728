define("boondmanager/pods/components/octane/bm-progress-bar/times-report/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "I7bA0zIV",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"bmc-progressbar bmc-progressbar-timesreport\",[30,[36,3],[[32,0,[\"isFull\"]],\" bmc-progressbar_full\"],null],[30,[36,3],[[32,0,[\"isOver\"]],\" bmc-progressbar_over\"],null]]]],[17,1],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"percentageaccomplish\"],[12],[1,[30,[36,1],[[32,0,[\"percentage\"]]],[[\"decimals\"],[[32,0,[\"PERCENTAGE_PRECISION\"]]]]]],[2,\" %\"],[13],[2,\"\\n\\t\"],[10,\"div\"],[15,0,[31,[\"bmc-progressbar_bar \",[30,[36,3],[[30,[36,4],[[32,0,[\"percentage\"]],100],null],\"bm-full\"],null]]]],[12],[2,\"\\n\\t\"],[10,\"span\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,5],[[32,0,[\"workUnits\"]],[32,0,[\"days\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[1,[30,[36,1],[[30,[36,0],[[32,0,[\"workUnits\"]]],[[\"decimals\"],[[32,0,[\"VIEW_PRECISION\"]]]]]],[[\"decimals\"],[[32,0,[\"VIEW_PRECISION\"]]]]]],[2,\" \"],[1,[30,[36,2],[\"components:bmProgressbarTimesreport.workUnit\"],null]],[2,\" -\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\"],[1,[30,[36,1],[[30,[36,0],[[32,0,[\"days\"]]],[[\"decimals\"],[[32,0,[\"VIEW_PRECISION\"]]]]]],[[\"decimals\"],[[32,0,[\"VIEW_PRECISION\"]]]]]],[2,\" / \"],[1,[32,0,[\"total\"]]],[2,\" \"],[1,[30,[36,2],[\"components:bmProgressbarTimesreport.workingDays\"],null]],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"bmc-progressbar_content\"],[12],[2,\"\\n\\t\\t\"],[10,\"span\"],[14,0,\"bmc-progressbar_progress\"],[15,5,[30,[36,7],[[30,[36,6],[\"width : \",[32,0,[\"percentage\"]],\"%\"],null]],null]],[12],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"round\",\"number\",\"t\",\"if\",\"eq\",\"not-eq\",\"concat\",\"html-safe\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-progress-bar/times-report/template.hbs"
  });
});