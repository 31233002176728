define("boondmanager/pods/components/octane/bm-table-list/check/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "rs+QzWAL",
    "block": "{\"symbols\":[\"@referTop\",\"&attrs\",\"@disabled\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"bmc-table-list-check \",[30,[36,0],[[32,1],\" bm-refer-top\",\"\"],null]]]],[17,2],[4,[38,1],[\"click\",[32,0,[\"onClick\"]]],null],[4,[38,2],[[32,0,[\"initElement\"]]],null],[12],[2,\"\\n\\t\"],[10,\"input\"],[14,3,\"cbitem[]\"],[14,0,\"bmf-checkbox bmc-table-list-check-checkbox\"],[15,\"checked\",[32,0,[\"checked\"]]],[15,2,[32,0,[\"itemId\"]]],[15,\"data-id\",[32,0,[\"itemId\"]]],[15,\"data-checklistid\",[32,0,[\"checklistId\"]]],[14,\"data-type\",\"check\"],[15,\"disabled\",[32,3]],[14,4,\"checkbox\"],[12],[13],[2,\"\\n\\t\"],[10,\"span\"],[14,0,\"bmf-checkbox-style\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"on\",\"did-insert\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-table-list/check/template.hbs"
  });
});