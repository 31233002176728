define("boondmanager/helpers/img-absolute", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.imgAbsolute = imgAbsolute;
  function imgAbsolute(params) {
    var src = (params[0] || '').replace(/^\/+/, '');
    var alt = params[1] ? " alt=\"".concat(params[1], "\"") : '';
    return new Ember.String.htmlSafe("<img src=\"".concat(src, "\"") + alt + '/>');
  }
  var _default = _exports.default = Ember.Helper.helper(imgAbsolute);
});