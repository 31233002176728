define("boondmanager/pods/components/octane/bm-search-filters/fields/contact/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0tv1IitC",
    "block": "{\"symbols\":[\"@filter\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n\\t\\t\"],[8,\"octane/bm-field\",[],[[\"@name\"],[[30,[36,0],[\"contacts:title\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[8,\"octane/bm-field/contact\",[],[[\"@name\",\"@selected\",\"@onChange\",\"@multiple\"],[\"contact\",[32,1,[\"bmFieldValue\"]],[32,0,[\"update\"]],true]],null],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-search-filters/fields/contact/template.hbs"
  });
});