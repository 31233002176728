define("boondmanager/adapters/currentuser", ["exports", "boondmanager/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import Logger from 'boondmanager/utils/logger';
  var _default = _exports.default = _application.default.extend({
    queryRecordUrlTemplate: '{+host}/api/application/current-user'
  });
});