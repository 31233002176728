define("boondmanager/pods/components/octane/bm-tabs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "8Jpjk7Q7",
    "block": "{\"symbols\":[\"&default\",\"&attrs\",\"@namedBlocksInfo\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-tabs\"],[17,2],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,3],\"default\",[27,[32,1]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-has-block\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-tabs/template.hbs"
  });
});