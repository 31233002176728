define("boondmanager/utils/groupby", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = groupBy;
  function groupBy(array, key) {
    return array.reduce(function (newArray, item) {
      var value = key instanceof Function ? key(item) : Ember.get(item, key);
      var element = newArray.find(function (r) {
        return r && r.key === value;
      });
      if (element) {
        element.values.push(item);
      } else {
        newArray.push({
          key: value,
          values: [item]
        });
      }
      return newArray;
    }, []);
  }
});