define("boondmanager/pods/leaving/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "TRnNjS/b",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"bmc-splash-screen\"],[14,0,\"bmc-splash-screen\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"bmc-splash-screen-wrapper\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"bmc-splash-screen-spin\"],[12],[13],[2,\"\\n\\t\\t\"],[10,\"p\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"img\"],[15,\"src\",[31,[[34,0],\"img/super-james.png\"]]],[14,\"alt\",\"Super James\"],[12],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"rootURL\"]}",
    "moduleName": "boondmanager/pods/leaving/template.hbs"
  });
});