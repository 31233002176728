define("boondmanager/pods/components/octane/bm-btn/back/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "XwVtIc0d",
    "block": "{\"symbols\":[\"&default\",\"&attrs\",\"@namedBlocksInfo\"],\"statements\":[[11,\"button\"],[17,2],[24,0,\"bmc-btn_back bmb-rectangle\"],[4,[38,1],[\"click\",[32,0,[\"backToPreviousPage\"]]],null],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,3],\"default\",[27,[32,1]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\"],[1,[30,[36,0],[\"components:bmBtnBack.previousPage\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"on\",\"-has-block\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-btn/back/template.hbs"
  });
});