define("boondmanager/initializers/dropzone", ["exports", "dropzone"], function (_exports, _dropzone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  /**
   * Initialize Dropzone plugin
   *
   *   * set `autoDiscover` to `false`
   *
   */
  function initialize( /* application */
  ) {
    if (typeof _dropzone.default !== 'undefined') {
      _dropzone.default.autoDiscover = false;
    }
  }
  var _default = _exports.default = {
    name: 'dropzone',
    initialize: initialize
  };
});