define("boondmanager/pods/boondmanager/myaccount/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "qIrOi0tv",
    "block": "{\"symbols\":[\"__arg0\"],\"statements\":[[8,\"octane/bm-layout-profile\",[],[[\"@withFooter\",\"@withTabs\",\"@namedBlocksInfo\"],[false,false,[30,[36,3],null,[[\"content\"],[0]]]]],[[\"default\"],[{\"statements\":[[6,[37,0],[[30,[36,4],[[32,1],\"content\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,0,[\"hasError\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[8,\"octane/bm-alerts\",[],[[\"@type\"],[\"warning\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\"],[2,[32,0,[\"errorMsg\"]]],[2,\"\\n\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\\t\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"-outlet\",\"component\",\"hash\",\"-is-named-block-invocation\"]}",
    "moduleName": "boondmanager/pods/boondmanager/myaccount/template.hbs"
  });
});