define("boondmanager/pods/components/octane/bm-tiny-mce-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "sBBT30w6",
    "block": "{\"symbols\":[\"@value\",\"@small\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"bmc-tinyMce-text \",[30,[36,0],[[32,2],\"bmc-tinyMce-text_small\"],null]]]],[17,3],[4,[38,1],[[32,0,[\"addEventListeners\"]]],null],[12],[2,\"\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[2,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"did-insert\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-tiny-mce-text/template.hbs"
  });
});