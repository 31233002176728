define("boondmanager/instance-initializers/body-idclass", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // Default config
  var defaultConfig = {
    addId: true,
    addClass: false,
    addRouteNameClass: true,
    excludeRouteNames: ['application', 'index']
  };
  function initialize(instance) {
    var sep = '-';
    var env = instance.resolveRegistration('config:environment');

    // Read environment 'body-idclass' config if exist
    var config = Ember.assign({}, defaultConfig, env['body-idclass']);
    var _prevClass = null;
    var _prevRouteName = null;
    Ember.Route.reopen({
      _getRouteNames: function _getRouteNames() {
        return this.get('routeName').split('.').filter(function (routeName) {
          return config.excludeRouteNames.indexOf(routeName) === -1;
        });
      },
      _getRouteName: function _getRouteName(routeNames) {
        if (!Ember.isArray(routeNames) || routeNames.length === 0) {
          routeNames = this._getRouteNames();
        }
        return routeNames[routeNames.length - 1];
      },
      _getId: function _getId(parts) {
        if (Ember.isArray(parts) && parts.length) {
          return parts.join(sep);
        }
        return this._getRouteNames().join(sep);
      },
      addIdClasses: Ember.on('activate', function () {
        var $body = (0, _jquery.default)('body');
        var routeNames = this._getRouteNames();
        var routeName = this._getRouteName(routeNames);
        var id = this._getId(routeNames);
        if (config.addId) {
          $body.attr('id', id);
        }
        if (config.addClass) {
          if (_prevClass !== null && (!config.addRouteNameClass || _prevRouteName !== _prevClass)) {
            $body.removeClass(_prevClass);
          }
          $body.addClass(id);
        }
        if (config.addRouteNameClass && (!config.addClass || routeName !== id)) {
          $body.addClass(routeName);
        }
        _prevClass = id;
        _prevRouteName = routeName;
      }),
      removeIdClasses: Ember.on('deactivate', function () {
        var $body = (0, _jquery.default)('body');
        var routeNames = this._getRouteNames();
        var routeName = this._getRouteName(routeNames);
        var id = this._getId(routeNames);
        if (config.addId) {
          $body.removeAttr('id');
        }
        if (config.addClass && (!config.addRouteNameClass || routeName !== id)) {
          $body.removeClass(id);
        }
        if (config.addRouteNameClass && (!config.addClass || routeName !== id)) {
          $body.removeClass(routeName);
        }
      })
    });
  }
  var _default = _exports.default = {
    name: 'body-idclass',
    initialize: initialize
  };
});