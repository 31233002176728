define("boondmanager/pods/components/octane/bm-alerts/notdeleted-dependson/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Yh1W8V2S",
    "block": "{\"symbols\":[\"item\",\"@onClose\"],\"statements\":[[8,\"octane/bm-alerts\",[[4,[38,1],[[32,0,[\"checkModelName\"]]],null]],[[\"@type\",\"@onClose\"],[\"danger\",[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"bmc-alerts-batch\"],[12],[2,\"\\n\\t\\t\"],[10,\"p\"],[12],[1,[32,0,[\"notDeleteCountMessage\"]]],[13],[2,\"\\n\\t\\t\\t\"],[10,\"ul\"],[14,0,\"bmc-alert_list\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"getAllRoutes\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[10,\"li\"],[14,0,\"bmc-alert_list-item\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[8,\"link-to\",[[24,0,\"alert-link\"]],[[\"@route\",\"@model\"],[[32,1,[\"routeName\"]],[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[1,[32,1,[\"label\"]]],[2,\"\\n\\t\\t\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\\t\\t\"],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,0,[\"notDeleteMessage\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"p\"],[12],[1,[32,0,[\"notDeleteMessage\"]]],[13],[2,\"\\n\"],[6,[37,0],[[32,0,[\"notDeleteList\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[1,[32,0,[\"notDeleteList\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"did-insert\",\"-track-array\",\"each\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-alerts/notdeleted-dependson/template.hbs"
  });
});