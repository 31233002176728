define("boondmanager/initializers/reset-scroll-position", ["exports", "boondmanager/utils/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    initialize: function initialize() {
      Ember.Route.reopen({
        init: function init() {
          this._super.apply(this, arguments);
          this.on('activate', function () {
            _logger.default.log('Initializer:reset-scroll-position - scrollTop on route activate event');
            window.scrollTo(0, 0);
          });
        }
      });
    }
  };
});