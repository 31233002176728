define("boondmanager/pods/components/octane/bm-gadget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "IngU/kNG",
    "block": "{\"symbols\":[\"&default\",\"@icon\",\"@metric\",\"@collection\",\"&attrs\",\"@namedBlocksInfo\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"bmc-gadget \",[30,[36,2],[[32,4],\"bmc-gadget_collection\"],null],\" \",[30,[36,2],[[32,3],\"bmc-gadget_metric\"],null],\" \"]]],[17,5],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"bmc-gadget_header\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,3],[[32,6],\"header\",false],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"div\"],[14,0,\"bmc-gadget_header-title\"],[12],[2,\"\\n\"],[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[1,[30,[36,1],[[32,2]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\\t\"],[10,\"h2\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[18,1,[[30,[36,0],[\"header\"],null]]],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\"],[6,[37,2],[[32,0,[\"help\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[8,\"octane/bm-help\",[[24,\"data-html\",\"true\"],[23,\"data-original-title\",[32,0,[\"help\"]]]],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[30,[36,3],[[32,6],\"actions\",false],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"div\"],[14,0,\"bmc-gadget_actions\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[18,1,[[30,[36,0],[\"actions\"],null]]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\\n\"],[6,[37,2],[[30,[36,3],[[32,6],\"content\",false],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[14,0,\"bmc-gadget_content\"],[12],[2,\"\\n\\t\\t\\t\"],[18,1,[[30,[36,0],[\"content\"],null]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"-named-block-invocation\",\"icon\",\"if\",\"-has-block\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-gadget/template.hbs"
  });
});