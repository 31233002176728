define("boondmanager/pods/components/octane/bm-search-filters/fields/switch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "S0SVDcuZ",
    "block": "{\"symbols\":[\"@filter\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n\\t\\t\"],[8,\"octane/bm-field\",[],[[\"@inline\",\"@name\",\"@reverse\",\"@help\"],[true,[32,1,[\"label\"]],true,[32,1,[\"disabledTooltip\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[8,\"octane/bm-field/switch\",[],[[\"@name\",\"@value\",\"@onChange\",\"@disabled\"],[[32,1,[\"queryParamKey\"]],[30,[36,0],[[32,1,[\"modalValue\"]],[32,1,[\"valueMapping\",\"trueValue\"]]],null],[32,0,[\"switch\"]],[32,1,[\"disabled\"]]]],null],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"is-selected\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-search-filters/fields/switch/template.hbs"
  });
});