define("boondmanager/utils/financial", ["exports", "bignumberjs"], function (_exports, _bignumberjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.add = add;
  _exports.applyExchangeRates = applyExchangeRates;
  _exports.calculateAmountIncludingTax = calculateAmountIncludingTax;
  _exports.calculateReinvoiceAmountExcludingTax = calculateReinvoiceAmountExcludingTax;
  _exports.calculateReinvoiceRate = calculateReinvoiceRate;
  _exports.calculateTax = calculateTax;
  _exports.calculateTaxRate = calculateTaxRate;
  _exports.calculateTaxRateFromAmounts = calculateTaxRateFromAmounts;
  _exports.calculateTotalAmountExcludingTax = calculateTotalAmountExcludingTax;
  _exports.calculateTotalAmountIncludingTax = calculateTotalAmountIncludingTax;
  _exports.default = void 0;
  _exports.div = div;
  _exports.extractTaxedAmount = extractTaxedAmount;
  _exports.isInteger = isInteger;
  _exports.mult = mult;
  _exports.recalculateAmountOnExchangeRatesChange = recalculateAmountOnExchangeRatesChange;
  _exports.roundAmountWithExchangeRates = roundAmountWithExchangeRates;
  _exports.roundAtPrecision = roundAtPrecision;
  _exports.roundBigDecimals = roundBigDecimals;
  _exports.sub = sub;
  var DEFAULT_PRECISION = 2;
  function applyExchangeRates(amount, rates) {
    amount = rates && rates.length ? Number(rates.reduce(function (amount, rate) {
      // see ATTRIBUTES TYPE CASTING at the beginning of this file
      return mult(amount, Number(rate));
    }, Number(amount))) : Number(amount); // see ATTRIBUTES TYPE CASTING at the beginning of this file
    return roundBigDecimals(amount);
  }
  function extractTaxedAmount(amount, tax) {
    if (tax < 0) {
      return 0;
    } else {
      return Number(amount - amount / (1 + tax / 100)); //Montant TVA
    }
  }

  function calculateTax(amount, taxedAmount) {
    if (amount === 0 || taxedAmount === 0) {
      return 0;
    } else if (Math.abs(amount / 2) < Math.abs(taxedAmount)) {
      return 100;
    } else {
      return Number(100 * taxedAmount / (amount - taxedAmount));
    }
  }
  function roundBigDecimals(amount) {
    return roundAtPrecision(amount, 6);
  }
  function mult(a, b) {
    a = new _bignumberjs.default(Number(a).toString());
    b = new _bignumberjs.default(Number(b).toString());
    return a.times(b).toNumber();
  }
  function add(a, b) {
    a = new _bignumberjs.default(a.toString());
    b = new _bignumberjs.default(b.toString());
    return a.plus(b).toNumber();
  }
  function div(a, b) {
    a = new _bignumberjs.default(a.toString());
    b = new _bignumberjs.default(b.toString());
    return a.dividedBy(b).toNumber();
  }
  function sub(a, b) {
    a = new _bignumberjs.default(a.toString());
    b = new _bignumberjs.default(b.toString());
    return a.minus(b).toNumber();
  }

  /**
   * Round an amount so the display of this amount in a another currency will be rounded with the given precision)
   * @param {number} amount
   * @param {number} precision
   * @param {number} exchangeRate
   * @param {number} exchangeRateAgency
   * @return {number}
   */
  function roundAmountWithExchangeRates(amount) {
    var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_PRECISION;
    var exchangeRate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
    var exchangeRateAgency = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1;
    var exchangeRates = mult(exchangeRate, exchangeRateAgency);
    // apply exchange rate to get the final value
    var amountWithExchangeRates = div(amount, exchangeRates);
    // round value
    var roundFinalAmount = roundAtPrecision(amountWithExchangeRates, precision);
    // revert amount to dbAmount
    return mult(roundFinalAmount, exchangeRates);
  }

  /**
   * Round value with the given precision
   * @param {number} value
   * @param {number} precision
   * @return {number}
   */
  function roundAtPrecision(value, precision) {
    return decimalAdjust('round', value, -precision);
  }

  /**
   * Test if value is an integer for a given precision
   * @param {number} value
   * @param {number} precision
   * @return {boolean}
   */
  function isInteger(value, precision) {
    var realValue = roundAtPrecision(value, precision);
    var intValue = roundAtPrecision(value, 0);
    return realValue - intValue === 0;
  }
  function decimalAdjust(type, value, exp) {
    // If the exp is undefined or zero...
    if (typeof exp === 'undefined' || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
      return NaN;
    }
    // If the value is negative...
    if (value < 0) {
      return -decimalAdjust(type, -value, exp);
    }
    // Shift
    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp)));
    // Shift back
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp));
  }
  function calculateTaxRate(taxRates) {
    var taxRate = 0;
    taxRates.forEach(function (rate) {
      taxRate = add(taxRate, rate);
    });
    return taxRate;
  }
  function calculateAmountIncludingTax(amountExcludingTax, taxRates, exchangeRate, exchangeRateAgency) {
    amountExcludingTax = roundAmountWithExchangeRates(amountExcludingTax, DEFAULT_PRECISION, exchangeRate, exchangeRateAgency);
    var amountIncludingTax = amountExcludingTax;
    taxRates.forEach(function (taxRate) {
      amountIncludingTax = add(amountIncludingTax, roundAmountWithExchangeRates(mult(amountExcludingTax, div(taxRate, 100)), DEFAULT_PRECISION, exchangeRate, exchangeRateAgency));
    });
    return roundAmountWithExchangeRates(amountIncludingTax, DEFAULT_PRECISION, exchangeRate, exchangeRateAgency);
  }
  function calculateTotalAmountExcludingTax(amountExcludingTax, quantity, exchangeRate, exchangeRateAgency) {
    amountExcludingTax = roundAmountWithExchangeRates(amountExcludingTax, DEFAULT_PRECISION, exchangeRate, exchangeRateAgency);
    return roundAmountWithExchangeRates(mult(amountExcludingTax, quantity), DEFAULT_PRECISION, exchangeRate, exchangeRateAgency);
  }
  function calculateTotalAmountIncludingTax(amountExcludingTax, quantity, taxRates, exchangeRate, exchangeRateAgency) {
    amountExcludingTax = calculateTotalAmountExcludingTax(amountExcludingTax, quantity, exchangeRate, exchangeRateAgency);
    var amountIncludingTax = amountExcludingTax;
    taxRates.forEach(function (taxRate) {
      amountIncludingTax = add(amountIncludingTax, roundAmountWithExchangeRates(mult(amountExcludingTax, div(taxRate, 100)), DEFAULT_PRECISION, exchangeRate, exchangeRateAgency));
    });
    return roundAmountWithExchangeRates(amountIncludingTax, DEFAULT_PRECISION, exchangeRate, exchangeRateAgency);
  }
  function calculateTaxRateFromAmounts(amountExcludingTax, amountIncludingTax) {
    if (amountExcludingTax < 0 && amountIncludingTax < 0) {
      amountExcludingTax = Math.abs(amountExcludingTax);
      amountIncludingTax = Math.abs(amountIncludingTax);
    }
    return roundBigDecimals(amountExcludingTax && amountExcludingTax <= amountIncludingTax ? mult(100, sub(div(amountIncludingTax, amountExcludingTax), 1)) : 0);
  }
  function calculateReinvoiceRate(amountReinvoicedExcludingTax, amountIncludingTax) {
    return roundBigDecimals(amountReinvoicedExcludingTax ? div(mult(100, sub(amountReinvoicedExcludingTax, amountIncludingTax)), amountReinvoicedExcludingTax) : '');
  }
  function calculateReinvoiceAmountExcludingTax(reinvoicedRate, amountIncludingTax, exchangeRate, exchangeRateAgency) {
    return roundAmountWithExchangeRates(reinvoicedRate < 100 ? div(amountIncludingTax, sub(1, div(reinvoicedRate, 100))) : 0, DEFAULT_PRECISION, exchangeRate, exchangeRateAgency);
  }
  function recalculateAmountOnExchangeRatesChange(amount, oldExchangeRate, newExchangeRate) {
    return mult(div(amount, oldExchangeRate), newExchangeRate);
  }
  var _default = _exports.default = {
    DEFAULT_PRECISION: DEFAULT_PRECISION,
    applyExchangeRates: applyExchangeRates,
    extractTaxedAmount: extractTaxedAmount,
    calculateTax: calculateTax,
    roundBigDecimals: roundBigDecimals,
    roundAmountWithExchangeRates: roundAmountWithExchangeRates,
    calculateTaxRate: calculateTaxRate,
    calculateTaxRateFromAmounts: calculateTaxRateFromAmounts,
    calculateAmountIncludingTax: calculateAmountIncludingTax,
    calculateTotalAmountExcludingTax: calculateTotalAmountExcludingTax,
    calculateTotalAmountIncludingTax: calculateTotalAmountIncludingTax,
    calculateReinvoiceAmountExcludingTax: calculateReinvoiceAmountExcludingTax,
    calculateReinvoiceRate: calculateReinvoiceRate,
    recalculateAmountOnExchangeRatesChange: recalculateAmountOnExchangeRatesChange,
    add: add,
    mult: mult,
    div: div,
    sub: sub
  };
});