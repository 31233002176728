define("boondmanager/utils/exports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.containsProof = _exports.TYPE_INTERNAL_AND_CERTIFIED_DOCUMENTS = _exports.TYPE_FORMATTED_FILES_AND_DOCUMENTS = _exports.TYPE_CERTIFIED_DOCUMENTS = void 0;
  var TYPE_CERTIFIED_DOCUMENTS = _exports.TYPE_CERTIFIED_DOCUMENTS = 'certifiedDocuments';
  var TYPE_INTERNAL_AND_CERTIFIED_DOCUMENTS = _exports.TYPE_INTERNAL_AND_CERTIFIED_DOCUMENTS = 'internalAndCertifiedDocuments';
  var TYPE_FORMATTED_FILES_AND_DOCUMENTS = _exports.TYPE_FORMATTED_FILES_AND_DOCUMENTS = 'formattedFilesAndDocuments';
  var containsProof = _exports.containsProof = function containsProof(type) {
    return [TYPE_CERTIFIED_DOCUMENTS, TYPE_INTERNAL_AND_CERTIFIED_DOCUMENTS].includes(type);
  };
});