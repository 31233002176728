define("boondmanager/pods/components/octane/bm-field/simple-color-picker/state/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0DegVjJV",
    "block": "{\"symbols\":[\"__arg0\",\"__arg1\",\"color\",\"selected\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,\"data-name\",[31,[[32,0,[\"dataName\"]]]]],[16,0,[31,[\"bmc-field-simple-colorpicker bmc-field-simple-colorpicker-state \",[32,0,[\"classNames\"]]]]],[17,5],[4,[38,3],[[32,0,[\"onInsert\"]]],null],[12],[2,\"\\n\\t\"],[8,\"octane/bm-field/select\",[[24,\"data-name\",\"simple-colorpicker\"]],[[\"@useObjects\",\"@dropdownClass\",\"@triggerClass\",\"@forceHideSearch\",\"@options\",\"@selected\",\"@onChange\",\"@disabled\",\"@required\",\"@namedBlocksInfo\"],[true,\"bmc-field-simple-colorpicker-options bmc-field-simple-colorpicker-state-options\",\"bmc-field-simple-colorpicker-trigger\",true,[32,0,[\"colors\"]],[32,0,[\"selected\"]],[32,0,[\"onChange\"]],[32,0,[\"disabled\"]],true,[30,[36,4],null,[[\"selected-item\",\"option\"],[1,1]]]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],\"selected-item\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[8,\"octane/bm-field/select/selected/colors/state\",[],[[\"@selected\"],[[32,4]]],null],[2,\"\\n\\t\\t\"]],\"parameters\":[4]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],\"option\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[8,\"octane/bm-field/select/results/colors/state\",[],[[\"@color\"],[[32,3]]],null],[2,\"\\n\\t\\t\"]],\"parameters\":[3]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"let\",\"-is-named-block-invocation\",\"if\",\"did-insert\",\"hash\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/simple-color-picker/state/template.hbs"
  });
});