define("boondmanager/pods/components/octane/bm-alerts/resource/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "sDLDyUwE",
    "block": "{\"symbols\":[\"message\",\"@model\",\"@dataName\",\"@warnings\"],\"statements\":[[6,[37,3],[[32,4,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[8,\"octane/bm-alerts\",[[16,\"data-name\",[32,3]]],[[\"@type\"],[\"warning\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,7],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[10,\"li\"],[14,0,\"bmc-alert_list-item\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,1,[\"code\"]],\"noContract\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\\t\"],[1,[30,[36,0],null,[[\"code\"],[[30,[36,3],[[30,[36,2],[[35,1,[\"typeOf\"]],1],null],\"noContract.external\",\"noContract.internal\"],null]]]]],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[8,\"link-to\",[[24,0,\"bmc-btn bmb-rectangle\"],[24,\"data-container\",\"body\"],[24,\"data-name\",\"button-add-contract\"]],[[\"@route\",\"@model\",\"@query\",\"@bubbles\"],[\"private.contracts.contract\",0,[30,[36,4],null,[[\"resource\",\"parentContract\"],[[32,2,[\"id\"]],0]]],false]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\\t\\t\\t\\t\\t\"],[1,[30,[36,5],[\"bmi-add\"],null]],[2,\" \"],[1,[30,[36,6],[\"components:bmAlertsResource.contract.add\"],null]],[2,\"\\n\\t\\t\\t\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\\t\\t\\t\\t\\t\"],[1,[30,[36,0],null,[[\"code\"],[[32,1,[\"code\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"warning\",\"model\",\"eq\",\"if\",\"explicit-query-params\",\"icon\",\"t\",\"-track-array\",\"each\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-alerts/resource/template.hbs"
  });
});