define("boondmanager/helpers/has-item-in-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    compute: function compute(params) {
      var item = params[0];
      var list = params[1];
      if (typeof list === 'string') list = list.split(',');
      if (!Ember.isArray(list)) return false;else return list.includes(item);
    }
  });
});