define("boondmanager/pods/components/octane/bm-text-clickable/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+kfbSTu4",
    "block": "{\"symbols\":[\"@disabled\",\"&attrs\",\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"isInline\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[11,\"span\"],[16,0,[31,[\"bmc-text-clickable\",[30,[36,0],[[32,1],\" disabled\"],null]]]],[17,2],[4,[38,1],[\"click\",[32,0,[\"onClick\"]]],null],[12],[2,\"\\n\\t\\t\"],[18,3,null],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[11,\"div\"],[16,0,[31,[\"bmc-text-clickable\",[30,[36,0],[[32,1],\" disabled\"],null]]]],[17,2],[4,[38,1],[\"click\",[32,0,[\"onClick\"]]],null],[12],[2,\"\\n\\t\\t\"],[18,3,null],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-text-clickable/template.hbs"
  });
});