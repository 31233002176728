define("boondmanager/pods/components/octane/bm-field/calendar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "4szKyFLw",
    "block": "{\"symbols\":[\"&attrs\",\"@disabled\"],\"statements\":[[11,\"div\"],[16,0,[31,[[32,0,[\"classNames\"]],\" bmc-field-calendar\"]]],[17,1],[4,[38,1],[[35,0]],null],[12],[2,\"\\n\\t\"],[8,\"octane/bm-field/select\",[[24,\"data-name\",\"calendar\"]],[[\"@customOptionsSearch\",\"@selected\",\"@onChange\",\"@options\",\"@placeholder\",\"@searchPlaceholder\",\"@nbMaxBeforeSearch\",\"@disabled\",\"@useObjects\"],[[32,0,[\"searchCalendars\"]],[32,0,[\"selected\"]],[32,0,[\"onchange\"]],[32,0,[\"options\"]],[30,[36,2],[\"common:none\"],[[\"context\"],[\"calendar\"]]],[30,[36,2],[\"components:bmFieldSearchItems.searchMessage\"],[[\"context\"],[\"calendar\"]]],0,[32,2],true]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"onInsert\",\"did-insert\",\"t\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/calendar/template.hbs"
  });
});