define("boondmanager/initializers/input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'add data-attributes to input component',
    initialize: function initialize() {
      Ember.TextField.reopen({
        // bind data-custom-class to add specific class on tooltip div
        attributeBindings: ['data-placement', 'data-original-title', 'data-html', 'data-custom-class', 'disabledPlaceholder:placeholder'],
        disabledPlaceholder: Ember.computed('disabled', function () {
          return this.get('disabled') ? '' : this.get('placeholder');
        }),
        didRender: function didRender() {
          this._super.apply(this, arguments);
          if (this.element.name) {
            this.element.dataset.name = "input-".concat(this.element.name);
          }
        }
      });
    }
  };
});