define("boondmanager/pods/components/octane/bm-field/availability/candidate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "SCvRoS+n",
    "block": "{\"symbols\":[\"@name\",\"@availability\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"bm-field-availability-candidate \",[32,0,[\"classNames\"]]]]],[17,3],[4,[38,1],[[32,0,[\"onInsert\"]]],null],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[15,0,[31,[\"col-12 \",[30,[36,3],[[30,[36,2],[[32,0,[\"selectedType\"]],\"date\"],null],\"col-xl-6\"],null]]]],[12],[2,\"\\n\\t\\t\\t\"],[8,\"octane/bm-field/select\",[[16,\"data-name\",[30,[36,0],[\"input-\",[32,1],\"-type\"],null]]],[[\"@options\",\"@selected\",\"@defaultKey\",\"@disabled\",\"@onChange\"],[[30,[36,4],[[32,0,[\"availabilityTypes\"]]],[[\"selected\"],[[32,0,[\"selectedType\"]]]]],[32,0,[\"selectedType\"]],-1,[32,0,[\"disabled\"]],[32,0,[\"updateSelectedType\"]]]],null],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,0,[\"selectedType\"]],\"date\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"div\"],[14,0,\"col-12 col-xl-6\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[8,\"octane/bm-field/date\",[],[[\"@id\",\"@name\",\"@value\",\"@format\",\"@disabled\",\"@onChange\"],[[32,1],[30,[36,0],[[32,1],\"-date\"],null],[32,2,[\"date\"]],[32,0,[\"displayedDateFormat\"]],[32,0,[\"disabled\"]],[32,0,[\"updateSelectedDate\"]]]],null],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"concat\",\"did-insert\",\"eq\",\"if\",\"options-for\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/availability/candidate/template.hbs"
  });
});