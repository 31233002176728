define("boondmanager/helpers/dropdown", ["exports", "boondmanager/helpers/icon"], function (_exports, _icon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.dropdown = dropdown;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  function renderItem(item, groupItem) {
    var attrs = [];
    var classes = [];
    var value;
    if (typeof groupItem !== 'undefined') {
      var group = groupItem.id || groupItem.value;
      attrs.push("data-group=\"".concat(group, "\""));
    }
    if (item.separator) {
      attrs.push('role="separator"');
      classes.push('divider');
      value = '---';
    } else {
      (false && !(typeof item.id !== 'undefined') && Ember.assert("Helper 'dropdown' - renderItem - item must have an id", typeof item.id !== 'undefined'));
      (false && !(item.value !== '') && Ember.assert("Helper 'dropdown' - renderItem - item must have a non-empty string value", item.value !== ''));
      attrs.push("data-value=\"".concat(item.id, "\""));
      if (item.disabled) {
        classes.push('disabled');
      }
      var content = item.value;
      if (item.icon) {
        content = "".concat(_icon.default.compute(item.icon), " ").concat(item.value);
      }
      value = "<a href=\"#\" onclick=\"return false;\">".concat(content, "</a>");
    }
    attrs.push("class=\"".concat(classes.join(' '), "\""));
    return Ember.String.htmlSafe("<li ".concat(attrs.join(' '), ">").concat(value, "</li>"));
  }

  /**
   * Bootstrap Dropdown
   *
   * CAUTION: this helper display only the <ul><li> part of Bootstrap dropdown! You
   * MUST add the <button> part yourself! (see example)
   *
   * This helper require one parameter : name. It must be an non-empty
   * String.
   *
   * A second optional parameter can be defined : <li> array. If not defined,
   * <li> array is an empty array.
   *
   * Example:
   * ```javascript
   * options = [
   *     {
   *         "id"       : "<li>'s 'data-value' attribute",
   *         "value"    : "<li>'s text",
   *         "icon"     : "optional icon to display in <li>"
   *         "disabled" : "optional true|false (default to false)"
   *     },
   *     // if an object own a 'option' property, il's a <li class="dropdown-header">
   *     {
   *         "id"     : "", // not used here
   *         "value"  : "<li>'s text",
   *         "option" : [
   *             {
   *                 "id"    : "<li>'s 'data-value' attribute",
   *                 "value" : "<li>'s text"
   *             },
   *             ...
   *         ]
   *     },
   *     // to display a separator line
   *     {
   *         "separator" : true
   *     },
   *     ...
   * ];
   * ```
   *
   * ```handlebars
   * <button name="my-dropdown" type="button"
   *         class="bm-btn btn-sm dropdown-toggle"
   *         aria-haspopup="true" aria-expanded="false"
   *         data-toggle="dropdown">
   *   My dropdown
   * </button>
   * {{dropdown "my-dropdown" options}}
   * ```
   *
   * @public
   * @method dropdown
   * @for Ember.Templates.helpers
   */
  function dropdown(params, hash) {
    var _params = _slicedToArray(params, 2),
      name = _params[0],
      _params$ = _params[1],
      options = _params$ === void 0 ? [] : _params$;
    (false && !(name !== '') && Ember.assert("Helper 'dropdown' - 1st parameter 'name' must be defined to a non-empty string", name !== ''));
    (false && !(Ember.typeOf(options) === 'array') && Ember.assert("Helper 'dropdown' (".concat(name, ") - 2nd parameter 'options' must be an array"), Ember.typeOf(options) === 'array'));
    var _ref = hash || {},
      _ref$classes = _ref.classes,
      classes = _ref$classes === void 0 ? [] : _ref$classes,
      _ref$disabled = _ref.disabled,
      disabled = _ref$disabled === void 0 ? false : _ref$disabled,
      action = _ref.action;

    // clean parameters
    name = name.trim();

    // transform 'classes' in an array and remove empty strings
    if (Ember.typeOf(classes) === 'string') {
      classes = classes.split(/,|\s+/).map(function (str) {
        return str.trim();
      }).filter(function (item) {
        return item !== '';
      });
    }

    // add main class 'dropdown-menu' if not already exists
    if (classes.indexOf('dropdown-menu') === -1) {
      classes.unshift('dropdown-menu');
    }
    if (disabled && classes.indexOf('disabled') === -1) {
      classes.push('disabled');
    }
    var attrs = ["class=\"".concat(classes.join(' '), "\""), 'role="menu"', "aria-labelledby=\"".concat(name, "\""), 'data-rel="bm-field-dropdown"'];
    if (action) {
      attrs.push("data-action=\"".concat(action, "\""));
    }
    var html = ["<ul ".concat(attrs.join(' '), ">")];
    options.forEach(function (item) {
      if (item.option) {
        html.push("<li class=\"dropdown-header\">".concat(item.value, "</li>"));
        item.option.forEach(function (subitem) {
          html.push(renderItem(subitem, item));
        });
      } else {
        html.push(renderItem(item));
      }
    });
    html.push('</ul>');
    return new Ember.String.htmlSafe(html.join(''));
  }
  var _default = _exports.default = Ember.Helper.helper(dropdown);
});