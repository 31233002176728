define("boondmanager/helpers/filter-items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.filterItems = filterItems;
  function filterItems(options, selected, idKeyname, valueKeyname) {
    idKeyname = idKeyname || 'id';
    valueKeyname = valueKeyname || 'value';
    var finalList = [];
    var excludeProperties = ['options', idKeyname, valueKeyname];
    var buildObject = function buildObject(option) {
      var properties = Object.keys(option);
      var hasValue = typeof option[valueKeyname] !== 'string' || option[valueKeyname].trim() !== '';
      var tmpObject = {};
      tmpObject[idKeyname] = option[idKeyname];
      tmpObject[valueKeyname] = hasValue ? option[valueKeyname] : option[idKeyname];
      properties.forEach(function (property) {
        if (!excludeProperties.includes(property)) {
          tmpObject[property] = option[property];
        }
      });
      return tmpObject;
    };
    options.forEach(function (option) {
      // recursive build options
      if (option.hasOwnProperty('option') && option.option.length > 0) {
        var tmpObject = buildObject(option);
        tmpObject.option = filterItems(option.option, selected, idKeyname, valueKeyname);
        if (tmpObject.option.length > 0) {
          finalList.push(tmpObject);
        }
      } else {
        var hasValue = typeof option[valueKeyname] !== 'string' || option[valueKeyname].trim() !== '';
        var isEnabled = typeof option.isEnabled === 'undefined' || option.isEnabled;
        var isSelected = Ember.isArray(selected) && selected.includes(option[idKeyname]) || !Ember.isArray(selected) && selected === option[idKeyname];
        if (hasValue && isEnabled || isSelected) {
          var _tmpObject = buildObject(option);
          finalList.push(_tmpObject);
        }
      }
    });
    return finalList;
  }
  var _default = _exports.default = Ember.Helper.helper(filterItems);
});