define("boondmanager/utils/octane/is-octane-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isOctaneModel = isOctaneModel;
  function isOctaneModel(model) {
    return model instanceof Object && (model.hasOwnProperty('@tracked') || model.hasOwnProperty('@action'));
  }
});