define("boondmanager/helpers/escape-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.escapeHTML = escapeHTML;
  var escapeExpression = Ember.Handlebars.Utils.escapeExpression;
  function escapeHTML(value) {
    var unsafeString = '';
    if (typeof value[0] !== 'undefined') {
      if (typeof value[0] === 'string') {
        unsafeString = value[0];
      } else if (value[0].toString) {
        unsafeString = value[0].toString();
      }
    }
    return escapeExpression(unsafeString);
  }
  var _default = _exports.default = Ember.Helper.helper(escapeHTML);
});