define("boondmanager/initializers/i18n-init", ["exports", "boondmanager/config/environment", "numeral", "i18next", "boondmanager/helpers/number"], function (_exports, _environment, _numeral, _i18next, _number) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    application.inject('route', 'i18n', 'service:i18n');
    application.inject('controller', 'i18n', 'service:i18n');
    application.inject('component', 'i18n', 'service:i18n');
    application.inject('model', 'i18n', 'service:i18n');
    _environment.default.i18nextOptions.interpolation = {
      format: function format(value, _format) {
        var output = '';
        switch (_format) {
          case 'total':
            if (typeof value !== 'undefined' && !isNaN(value)) {
              var n = !(Math.abs(value) < 1e-6) ? (0, _numeral.default)(value) : (0, _numeral.default)(0);
              _numeral.default.localeData().delimiters = {
                decimal: _i18next.default.t('common:numeral.delimiters.decimal'),
                //GRI common:number
                thousands: _i18next.default.t('common:numeral.delimiters.thousands') //GRI common:number
              };

              output = n.format(_i18next.default.t('common:numeral.number.rounded'));
            }
            return output;
          case 'totalfloat':
            if (typeof value !== 'undefined' && !isNaN(value)) {
              var _n = (0, _numeral.default)(value);
              _numeral.default.localeData().delimiters = {
                decimal: _i18next.default.t('common:numeral.delimiters.decimal'),
                //GRI common:number
                thousands: _i18next.default.t('common:numeral.delimiters.thousands') //GRI common:number
              };

              output = _n.format(_i18next.default.t('common:numeral.number.precision', {
                precision: '00'
              }));
            }
            return output;
          case 'numberFormatWithOneDecimal':
            return (0, _number.numberFormat)([value], {
              decimals: 1
            }, _i18next.default);
          case 'numberFormatWithTwoDecimal':
            return (0, _number.numberFormat)([value], {
              decimals: 2
            }, _i18next.default);
          default:
            return value;
        }
      }
    };
    _environment.default.i18nextOptions.saveMissing = true;
    _environment.default.i18nextOptions.appendNamespaceToMissingKey = true;
    /* eslint-disable  no-unused-vars */
    _environment.default.i18nextOptions.missingKeyHandler = function (lngs, ns, key, fallbackValue, updateMissing, options) {
      var message = "unable to find translation for '".concat(ns, ":").concat(key, "'");
      if (application && application.__container__ && application.__container__.lookup) {
        var errorsCollector = application.__container__.lookup('service:errors-collector');
        errorsCollector.add(message, {
          key: "".concat(ns, ":").concat(key)
        }, 'translation');
      }
      console.error(message);
    };
  }
  var _default = _exports.default = {
    name: 'i18n-init',
    after: 'config-meta',
    initialize: initialize
  };
});