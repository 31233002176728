define("boondmanager/pods/components/octane/bm-splash-screen/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "4OnSYOTt",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[24,1,\"bmc-splash-screen\"],[24,0,\"bmc-splash-screen\"],[17,1],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"bmc-splash-screen-wrapper\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"bmc-splash-screen-spin\"],[12],[13],[2,\"\\n\\t\\t\"],[10,\"p\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,0],[\"img/super-james.png\",\"James\"],null]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"img-relative\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-splash-screen/template.hbs"
  });
});