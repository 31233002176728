define("boondmanager/pods/components/octane/bm-field/tinymce/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "N4EYo7nG",
    "block": "{\"symbols\":[\"&attrs\",\"@disabled\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"bmc-field-tinymce \",[30,[36,0],[[32,0,[\"disabled\"]],\"disabled\"],null],\" \",[32,0,[\"classNames\"]]]]],[17,1],[4,[38,1],[[32,0,[\"onInsert\"]]],null],[4,[38,2],[[32,0,[\"onUpdate\"]],[32,0,[\"contentValue\"]]],null],[4,[38,2],[[32,0,[\"onUpdateDisabled\"]],[32,2]],null],[4,[38,2],[[32,0,[\"reloadTinyMCE\"]],[32,0,[\"currentUser\",\"theme\"]]],null],[12],[2,\"\\n\"],[6,[37,0],[[30,[36,3],[[32,0,[\"disabled\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[8,\"textarea\",[[16,0,[32,0,[\"inputClass\"]]],[16,1,[32,0,[\"getElementId\"]]],[16,\"onchange\",[32,0,[\"onValueChanged\"]]]],[[\"@name\",\"@value\",\"@placeholder\"],[[32,0,[\"name\"]],[32,0,[\"contentValue\"]],[32,0,[\"placeholder\"]]]],null],[2,\"\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[14,0,\"bmc-field-template-tinymce-disabled\"],[12],[2,\"\\n\\t\\t\\t\"],[8,\"octane/bm-tiny-mce-text\",[],[[\"@value\"],[[32,0,[\"contentValue\"]]]],null],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"did-insert\",\"did-update\",\"not\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/tinymce/template.hbs"
  });
});