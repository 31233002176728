define("boondmanager/helpers/subscription-periods", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    compute: function compute(params, hash) {
      var subscription = hash.subscription;
      var startDate = hash.startDate;
      var endDate = hash.endDate;
      var monthes = endDate.month() - startDate.month() + 1 + 12 * (endDate.year() - startDate.year());
      switch (subscription) {
        case 0:
          {
            // Unitaire
            return 0;
          }
        case 1:
          {
            // Mensuel
            return monthes;
          }
        case 2:
          {
            // Trimestriel
            return Math.ceil(monthes / 3);
          }
        case 3:
          {
            // Semestriel
            return Math.ceil(monthes / 6);
          }
        case 4:
          {
            // Annuel
            return Math.ceil(monthes / 12);
          }
      }
    }
  });
});