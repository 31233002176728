define("boondmanager/models/todelete/appsurveysurveytypefragment", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    originalID: _emberData.default.attr('string'),
    reference: _emberData.default.attr('number'),
    barometer: _emberData.default.attr('string'),
    question: _emberData.default.attr('string'),
    allowComments: _emberData.default.attr('boolean'),
    state: _emberData.default.attr('boolean'),
    dependsOn: _emberData.default.attr('string'),
    resourcesAllowed: _emberData.default.attr('array'),
    required: Ember.computed('barometer', function () {
      return this.get('barometer') !== 'none';
    })
  });
});