define("boondmanager/pods/components/octane/bm-search-filters/fields/composite-actionoriginsandsources/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Yrv8TPip",
    "block": "{\"symbols\":[\"subFilter\",\"FilterComponent\",\"@filter\"],\"statements\":[[10,\"div\"],[14,0,\"bmc-modal-native_search-input\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"bmc-modal-native_search_buttonselectall\"],[12],[2,\"\\n\\t\\t\"],[8,\"octane/bm-btn\",[[24,\"data-name\",\"button-check-all\"],[24,0,\"bmb-link\"],[16,\"aria-label\",[30,[36,2],[\"common:actions.selectAll\"],null]]],[[\"@onClick\"],[[32,0,[\"checkAll\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[30,[36,2],[\"common:actions.selectAll\"],null]],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\"],[8,\"octane/bm-btn\",[[24,\"data-name\",\"button-uncheck-all\"],[24,0,\"bmb-link\"],[16,\"aria-label\",[30,[36,2],[\"common:actions.unselectAll\"],null]]],[[\"@onClick\"],[[32,0,[\"uncheckAll\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[30,[36,2],[\"common:actions.unselectAll\"],null]],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,3,[\"subFilters\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"component\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[8,[32,2],[],[[\"@filter\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"let\",\"t\",\"-track-array\",\"each\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-search-filters/fields/composite-actionoriginsandsources/template.hbs"
  });
});