define("boondmanager/pods/components/octane/bm-field/search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "P8S+H1t/",
    "block": "{\"symbols\":[\"&attrs\",\"@value\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-field-search\"],[17,1],[4,[38,0],[[32,0,[\"onInsert\"]]],null],[12],[2,\"\\n\\t\"],[10,\"div\"],[15,0,[31,[\"bm-input-group \",[30,[36,1],[[32,0,[\"focused\"]],\"bm-focus\"],null],\" \",[30,[36,1],[[32,2],\"bm-has-value\"],null]]]],[12],[2,\"\\n\\t\\t\"],[10,\"span\"],[14,0,\"bm-input-group-icon-left\"],[12],[1,[30,[36,2],[\"bmi-search\"],null]],[13],[2,\"\\n\\t\\t\"],[8,\"octane/bm-field/input\",[],[[\"@value\",\"@focusIn\",\"@focusOut\",\"@keyUp\"],[[32,2],[32,0,[\"focusIn\"]],[32,0,[\"focusOut\"]],[32,0,[\"keyUp\"]]]],null],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"if\",\"icon\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/search/template.hbs"
  });
});