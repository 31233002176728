define("boondmanager/pods/components/octane/bm-field/departments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "KBVntU2K",
    "block": "{\"symbols\":[\"&attrs\",\"@name\",\"@multiple\",\"@selected\",\"@forceSearch\",\"@allowClear\",\"@options\",\"@onChange\",\"@disabled\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"bmc-field-departments \",[32,0,[\"classNames\"]]]]],[17,1],[4,[38,1],[[35,0]],null],[12],[2,\"\\n\\t\"],[8,\"octane/bm-field/select\",[[16,\"data-name\",[32,2]]],[[\"@multiple\",\"@selected\",\"@createOnEnter\",\"@createOnEnterMaxLength\",\"@forceSearch\",\"@allowClear\",\"@options\",\"@onChange\",\"@disabled\"],[[32,3],[32,4],true,100,[32,5],[32,6],[32,7],[32,8],[32,9]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"onInsert\",\"did-insert\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/departments/template.hbs"
  });
});