define("boondmanager/helpers/kanban-chevron", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.kanbanChevron = kanbanChevron;
  // params[0] : date (ex: action date)
  // params[1] : optional prefix (ex: 'bmi-icon-up-')
  function kanbanChevron(params) {
    var chevronDate = params[0];
    var chevronPrefix = params[1];
    var diffInDays = Math.abs((0, _moment.default)().diff(chevronDate, 'days'));
    var iconLevel = null;
    if (diffInDays < 7) {
      iconLevel = 3;
    } else if (diffInDays < 90) {
      iconLevel = 2;
    } else if (!isNaN(diffInDays)) {
      iconLevel = 1;
    }
    if (chevronPrefix && iconLevel) {
      return chevronPrefix + iconLevel;
    } else return iconLevel;
  }
  var _default = _exports.default = Ember.Helper.helper(kanbanChevron);
});