define("boondmanager/pods/components/octane/bm-field/power-search/results/profile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "YwV5izT6",
    "block": "{\"symbols\":[\"&default\",\"@name\",\"@headerIcon\",\"@headerThumbnail\",\"@namedBlocksInfo\"],\"statements\":[[10,\"div\"],[14,0,\"bmc-field-power-search_result_profile\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,3],[[32,3],[32,4]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[15,0,[31,[\"bmc-field-power-search_result_profile_icon \",[30,[36,2],[[32,4],\"bmc-field-power-search_result_profile_img-profile\"],null]]]],[12],[2,\"\\n\"],[6,[37,2],[[32,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[1,[30,[36,1],[[32,4],\"\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\\t\"],[1,[30,[36,0],[[32,3]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"bmc-field-power-search_result_profile_text\"],[12],[2,\"\\n\"],[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"span\"],[14,0,\"bmc-field-power-search_result_profile_text-name\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[32,2]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[30,[36,4],[[32,5],\"default\",[27,[32,1]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"icon\",\"img-absolute\",\"if\",\"or\",\"-has-block\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/power-search/results/profile/template.hbs"
  });
});