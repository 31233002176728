define("boondmanager/helpers/currentPeriod", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.currentPeriod = currentPeriod;
  _exports.default = void 0;
  function currentPeriod(params) {
    if (params.length) {
      return (0, _moment.default)().format(params[0]);
    } else {
      return (0, _moment.default)().format('YYYYMMDD');
    }
  }
  var _default = _exports.default = Ember.Helper.helper(currentPeriod);
});