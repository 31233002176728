define("boondmanager/pods/components/octane/bm-btn/scroll-to-top/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1L2mGrFm",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[8,\"octane/bm-btn\",[[24,0,\"bmc-btn-scroll-to-top bmb-circle\"],[17,1],[4,[38,0],[\"click\",[32,0,[\"scrollTop\"]]],null],[4,[38,1],[[32,0,[\"checkScroll\"]]],null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[1,[30,[36,2],[\"bmi-arrow-up\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"did-insert\",\"icon\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-btn/scroll-to-top/template.hbs"
  });
});