define("boondmanager/pods/components/octane/bm-list-noresults/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "u/XkxlXU",
    "block": "{\"symbols\":[\"&default\",\"@message\",\"@title\",\"@icon\",\"@namedBlocksInfo\",\"&attrs\",\"@loading\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-list-noresults\"],[17,6],[12],[2,\"\\n\"],[6,[37,2],[[32,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[8,\"octane/bm-loader\",[],[[\"@spinner\",\"@text\"],[\"true\",[30,[36,5],[\"common:loading.message\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[32,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[1,[30,[36,1],[[32,4]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\"],[1,[30,[36,1],[\"bmi-noresults\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"h2\"],[12],[1,[32,3]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"p\"],[12],[1,[32,2]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[30,[36,4],[[30,[36,3],[[32,5],\"actions\",false],null],[32,0,[\"displayButtons\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"div\"],[14,0,\"bmc-list-noresults_buttons\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[18,1,[[30,[36,0],[\"actions\"],null]]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[30,[36,4],[[30,[36,3],[[32,5],\"footer\",false],null],[32,0,[\"displayFooter\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"div\"],[14,0,\"bmc-list-noresults_footer\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[18,1,[[30,[36,0],[\"footer\"],null]]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"-named-block-invocation\",\"icon\",\"if\",\"-has-block\",\"and\",\"t\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-list-noresults/template.hbs"
  });
});