define("boondmanager/initializers/route-interactivity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'add Route monitoring to all routes',
    initialize: function initialize() {
      Ember.Route.reopen({
        apmTracking: Ember.inject.service(),
        monitorInteractivity: true,
        latestTransition: null,
        beforeModel: function beforeModel(transition) {
          this.set('latestTransition', transition);
          this.sendTransitionEvent('Initializing', transition.targetName);
          return this._super.apply(this, arguments);
        },
        afterModel: function afterModel(model, transition) {
          this.sendTransitionEvent('Activating', transition.targetName);
          return this._super.apply(this, arguments);
        },
        /**
         * Send data to tracking service to monitor route transition
         * @param {string} phase The phase of the transition
         * @param {string} targetName The destination route for the current transition
         */
        sendTransitionEvent: function sendTransitionEvent(phase, targetName) {
          if (this.get('fullRouteName') === 'application' || !this.get('monitorInteractivity')) {
            return;
          }
          var data = {
            event: "route".concat(phase),
            destination: targetName,
            routeName: this.get('fullRouteName'),
            clientTime: new Date().getTime() / 1000
          };
          this.apmTracking.trackRoute(data);
        },
        /**
         * Determine if this is the destination route for the transition (otherwise, it's a parent)
         *
         * @method _isLeafRoute
         * @private
         *
         * @param {object} transition - http://emberjs.com/api/classes/Transition.html
         * @return {boolean} True if this route is the target of the current transition
         */
        _isLeafRoute: function _isLeafRoute() {
          var transition = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('latestTransition');
          return transition && transition.targetName === this.get('fullRouteName');
        },
        // réecriture du willTransition de RouteInteractivityMixin pour éviter d'appeler _super quand c'est un rechargement des qp
        actions: {
          willTransition: function willTransition(transaction) {
            if (this._isLeafRoute()) {
              if (this.get('monitorInteractivity')) {
                this.set('monitorInteractivity', false);
              }
            }

            //petite surcharge ici
            if (!transaction.queryParamsOnly) {
              return this._super.apply(this, arguments) !== false; // Check explicitly for falsey value
            }
          },
          /**
           * Add possibility to add an anchor query params on route to scroll to specific DOM element in view
           * query params must be named "anchor" and contains DOM element ID
           */
          didTransition: function didTransition() {
            var anchor = this.controller.get('anchor');
            var that = this;
            if (anchor) {
              Ember.run.schedule('afterRender', function () {
                var elt = document.getElementById(anchor);
                if (elt) {
                  elt.scrollIntoView();
                }
                that.sendTransitionEvent('Interactive', that.fullRouteName);
              });
            }
          }
        }
      });
    }
  };
});