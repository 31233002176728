define("boondmanager/helpers/in-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    /**
     * @private
     * Find and outputs translated text.
     *
     * @param {Array} params - positional parameters passed to the helper.
     *   The 1st element may be the currentWhen
     * @return {Boolean}
     */
    compute: function compute(params) {
      var arr = params[0];
      var key = params.length > 1 ? params[1] : null;
      var isStrict = params.length > 2 ? params[2] : true;
      if (Ember.isArray(arr)) {
        // Dans le cas ou le back retourne un id sous forme de int alors que les options disponibles
        // sont sous forme de string (ou inversement), il peut être nécessaire d'avoir à comparer
        // les keys ne façon non stricte
        return isStrict ? arr.includes(key) : arr.some(function (elt) {
          return elt == key;
        });
      }
      return false;
    }
  });
});