define("boondmanager/pods/components/octane/bm-global-help/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Zv+1juTF",
    "block": "{\"symbols\":[],\"statements\":[[11,\"a\"],[24,6,\"#\"],[24,0,\"bmc-btn bmb-icon bmb-icon-secondary bmb-large bm-tooltips\"],[24,\"rel\",\"noopener noreferrer\"],[24,\"data-name\",\"button-help\"],[24,\"data-container\",\"body\"],[24,\"data-fulltext\",\"true\"],[16,\"data-original-title\",[31,[[30,[36,0],[\"common:actions.help\"],null]]]],[4,[38,1],[\"click\",[32,0,[\"help\"]]],null],[12],[2,\"\\n\\t\"],[1,[30,[36,2],[\"bmi-question-circle\"],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"on\",\"icon\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-global-help/template.hbs"
  });
});