define("boondmanager/adapters/document", ["exports", "boondmanager/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    queryUrlTemplate: '{+host}/api/documents{/viewer}{?query*}',
    urlSegments: Object.freeze({
      viewer: function viewer(type, id, snapshot, query) {
        if (query && query.viewer) {
          return 'viewer';
        }
      }
    })
  });
});