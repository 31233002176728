define("boondmanager/pods/components/octane/bm-alerts/absencesreport/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "X5sZsm7p",
    "block": "{\"symbols\":[\"warning\"],\"statements\":[[6,[37,3],[[32,0,[\"warnings\",\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-alerts\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"warnings\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"li\"],[14,0,\"bmc-alert_list-item\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[1,[30,[36,0],[[32,1,[\"caption\"]]],[[\"type\",\"project\"],[[32,1,[\"type\"]],[32,1,[\"project\"]]]]]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-alerts/absencesreport/template.hbs"
  });
});