define("boondmanager/pods/components/octane/bm-btn/save/cta/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "k9h+jxJI",
    "block": "{\"symbols\":[\"@disabled\",\"@onClick\",\"&default\"],\"statements\":[[8,\"octane/bm-btn\",[[24,0,\"bmb-rectangle bmb-dropdown bmb-validate\"],[24,\"data-name\",\"button-save\"],[16,\"aria-label\",[30,[36,0],[\"common:actions.save\"],null]]],[[\"@disabled\",\"@onClick\"],[[32,1],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[1,[30,[36,1],[\"bmi-save\"],null]],[2,\" \"],[1,[30,[36,0],[\"common:actions.save\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-rectangle bmb-validate\"],[24,\"data-name\",\"button-dropdown-save-cta\"],[24,\"data-toggle\",\"dropdown\"]],[[\"@iconOnly\",\"@disabled\"],[\"bmi-caret-down\",[32,1]]],null],[2,\"\\n\"],[10,\"div\"],[14,0,\"dropdown-menu dropdown-menu-right\"],[12],[2,\"\\n\\t\"],[18,3,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"icon\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-btn/save/cta/template.hbs"
  });
});