define("boondmanager/pods/components/octane/bm-search-filters/fields/reporting-compare-indicators-period/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "qW9FJXNJ",
    "block": "{\"symbols\":[\"@filter\"],\"statements\":[[6,[37,2],[[32,1,[\"displayFilter\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n\\t\\t\\t\"],[8,\"octane/bm-field\",[],[[\"@name\"],[[32,1,[\"label\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\"],[8,\"octane/bm-field/select\",[[24,\"data-name\",\"input-compare-indicators-period\"]],[[\"@addMissingSelected\",\"@options\",\"@selected\",\"@forceSearch\",\"@multiple\",\"@required\",\"@onChange\"],[false,[32,1,[\"options\"]],[32,1,[\"modalValue\"]],false,false,[32,1,[\"required\"]],[30,[36,1],[[30,[36,0],[[32,1,[\"modalValue\"]]],null]],null]]],null],[2,\"\\n\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-search-filters/fields/reporting-compare-indicators-period/template.hbs"
  });
});