define("boondmanager/pods/components/octane/bm-search-filters/fields/product/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "7GDCGNfl",
    "block": "{\"symbols\":[\"@filter\"],\"statements\":[[6,[37,1],[[32,0,[\"currentUser\",\"hasModuleProductsEnabled\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n\\t\\t\\t\"],[8,\"octane/bm-field\",[],[[\"@name\"],[[30,[36,0],[\"products:title\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\"],[8,\"octane/bm-field/product\",[],[[\"@name\",\"@selected\",\"@onChange\",\"@multiple\"],[\"product\",[32,1,[\"bmFieldValue\"]],[32,0,[\"update\"]],true]],null],[2,\"\\n\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-search-filters/fields/product/template.hbs"
  });
});