define("boondmanager/initializers/useragentDetection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    initialize: function initialize() {
      var docElement = document.documentElement;
      docElement.setAttribute('data-useragent', navigator.userAgent);
      docElement.setAttribute('data-platform', navigator.platform);
      docElement.className += !!('ontouchstart' in window) || !!('onmsgesturechange' in window) ? ' touch' : '';
    }
  };
});