define("boondmanager/adapters/digitalworkplacecategory", ["exports", "boondmanager/adapters/integratedapps"], function (_exports, _integratedapps) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _integratedapps.default.extend({
    deleteRecordUrlTemplate: '{+host}/api/apps/digital-workplace/categories{/id}'
  });
});