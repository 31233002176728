define("boondmanager/pods/components/octane/bm-layout-profile/section/block/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "lTpE52NW",
    "block": "{\"symbols\":[\"&default\",\"&attrs\",\"@namedBlocksInfo\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-layout-profile-section_block\"],[17,2],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,3],\"actions\",false],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[14,0,\"bmc-layout-profile-section_block_actions\"],[12],[2,\"\\n\\t\\t\\t\"],[18,1,[[30,[36,0],[\"actions\"],null]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[30,[36,1],[[32,3],\"header\",false],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[14,0,\"bmc-layout-profile-section_block_header\"],[12],[2,\"\\n\\t\\t\\t\"],[18,1,[[30,[36,0],[\"header\"],null]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[30,[36,1],[[32,3],\"content\",false],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[14,0,\"bmc-layout-profile-section_block_content\"],[12],[2,\"\\n\\t\\t\\t\"],[18,1,[[30,[36,0],[\"content\"],null]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"-named-block-invocation\",\"-has-block\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-layout-profile/section/block/template.hbs"
  });
});