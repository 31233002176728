define("boondmanager/services/interactivity-tracking", ["exports", "ember-interactivity/services/interactivity-tracking"], function (_exports, _interactivityTracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO A supprimer une fois la migration terminée
  var _default = _exports.default = _interactivityTracking.default.extend({
    apmMetrics: Ember.inject.service(),
    trackComponent: function trackComponent(data) {
      var spanName = 'component `' + data.component + '`';
      var spanType = 'ember.component.render';
      var span = null;
      //console.log('APM track interactivity component', data.event);

      switch (data.event) {
        case 'componentInitializing':
          span = this.get('apmMetrics').startSpan(spanName, spanType);
          if (span) {
            span.addLabels(data);
          }
          break;
        case 'componentInteractive':
          span = this.get('apmMetrics').getSpan(spanName, spanType);
          if (span) {
            span.end();
          }
          break;
      }
    },
    trackRoute: function trackRoute(data) {
      var spanName = 'route `' + data.routeName + '`';
      var loadingSpanType = 'ember.route.loading';
      var RenderingSpanType = 'ember.route.render';
      var span = null;
      //console.log('APM track interactivity route', data.event);

      switch (data.event) {
        case 'routeInitializing':
          span = this.get('apmMetrics').startSpan('initializing ' + spanName, loadingSpanType + '');
          if (span) {
            span.addLabels(data);
          }
          break;
        case 'routeActivating':
          span = this.get('apmMetrics').getSpan('initializing ' + spanName, loadingSpanType);
          if (span) {
            span.end();
          }
          span = this.get('apmMetrics').startSpan('rendering ' + spanName, RenderingSpanType);
          if (span) {
            span.addLabels(data);
          }
          break;
        case 'routeInitialized':
          span = this.get('apmMetrics').getSpan('rendering ' + spanName, RenderingSpanType);
          if (span) {
            this.get('apmMetrics').getCurrentTransaction().mark('domInteractive');
            span.end();
          }
          break;
      }
    }
  });
});