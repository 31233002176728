define("boondmanager/helpers/label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.label = label;
  function label(params) {
    var color = params[0];
    var value = params[1];
    var moreClass = params[2];
    return new Ember.String.htmlSafe('<span class="label label-' + color + (moreClass !== undefined ? ' ' + moreClass : '') + '">' + Ember.Handlebars.Utils.escapeExpression(value) + '</span>');
  }
  var _default = _exports.default = Ember.Helper.helper(label);
});