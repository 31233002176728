define("boondmanager/adapters/integratedapps", ["exports", "boondmanager/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    urlTemplate: '{+host}/api/apps/{app}{/type}{/id}{/endpoint*}{.format}{?query*}',
    queryUrlTemplate: '{+host}/api/apps/{app}{/type}{/viewer}{/id}{/endpoint*}{.format}{?query*}',
    updateRecordUrlTemplate: '{+host}/api/apps/{app}{/type}{/id}{/endpoint*}{?query*}',
    createRecordUrlTemplate: '{+host}/api/apps/{app}{/type}{/endpoint}{?query*}',
    deleteRecordUrlTemplate: '{+host}/api/apps/{app}{/type}{/id}',
    pathForType: function pathForType(type) {
      if (type === 'main' || type === 'share' || type === 'configuration' || type === 'reporting') {
        return type;
      } else {
        return this._super.apply(this, arguments);
      }
    },
    urlSegments: Object.freeze({
      type: function type(_type, id, snapshot, query) {
        if (query && Ember.get(query, 'type')) {
          var t = query.type;
          delete query.type;
          return this.pathForType(t);
        } else if (snapshot && Ember.get(snapshot, 'adapterOptions.type')) {
          return this.pathForType(snapshot.adapterOptions.type);
        } else {
          return this.pathForType(_type);
        }
      },
      app: function app(type, id, snapshot, query) {
        if (query && Ember.get(query, 'app')) {
          return query.app;
        } else if (snapshot && Ember.get(snapshot, 'adapterOptions.app')) {
          return snapshot.adapterOptions.app;
        }
      },
      viewer: function viewer(type, id, snapshot, query) {
        if (query && Ember.get(query, 'viewer')) {
          return 'viewer';
        }
      },
      id: function id(type, _id, snapshot, query) {
        if (type === 'configuration') {
          return undefined;
        } else {
          var normalizedID = _id;
          if (query && query.id) {
            normalizedID = query.id;
          } else if (snapshot && Ember.get(snapshot, 'adapterOptions.id')) {
            normalizedID = snapshot.adapterOptions.id;
          }
          return normalizedID === 'default' ? '0' : normalizedID;
        }
      }
    }),
    generate: function generate(model, params) {
      var _this = this;
      var url = this.buildURL(model, null, null, 'query', params);
      return this.ajax(url, 'GET', {
        data: params
      }).then(function () {
        //Le back ne renvoie pas les fichiers générés.
        var routeName = _this.get('router.currentRouteName');
        Ember.getOwner(_this).lookup("route:".concat(routeName)).refresh(true);
      });
    }
  });
});