define("boondmanager/adapters/oauththirdparty", ["exports", "boondmanager/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    queryRecordUrlTemplate: '{+host}/api{/thirdParty}{/id}',
    urlTemplate: '{+host}/api/my-account/third-parties{/id}{?query*}',
    urlSegments: Object.freeze({
      id: function id(type, _id, snapshot, query) {
        if (query && Ember.get(query, 'id')) {
          return query.id;
        } else if (snapshot && Ember.get(snapshot, 'adapterOptions.id')) {
          return snapshot.adapterOptions.id;
        }
      },
      thirdParty: function thirdParty(type, id, snapshot, query) {
        var thirdParty = null;
        if (query && query.thirdParty) {
          thirdParty = query.thirdParty;
        } else if (snapshot && Ember.get(snapshot, 'adapterOptions.thirdParty')) {
          thirdParty = snapshot.adapterOptions.thirdParty;
        }
        return thirdParty;
      }
    })
  });
});