define("boondmanager/pods/components/octane/bm-profile-navigation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "l9ZhHbbW",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[6,[37,0],[[32,0,[\"display\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[11,\"div\"],[24,0,\"bmc-profile-navigation\"],[17,1],[12],[2,\"\\n\\t\\t\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-rectangle bmb-xsmall\"],[24,\"data-name\",\"button-profile-navigation-prev\"]],[[\"@onClick\",\"@disabled\",\"@iconOnly\"],[[32,0,[\"previous\"]],[32,0,[\"isFirstPage\"]],\"bmi-caret-left-light\"]],null],[2,\"\\n\\t\\t\"],[10,\"p\"],[14,\"data-name\",\"text-profile-navigation\"],[12],[1,[32,0,[\"page\"]]],[2,\" / \"],[1,[32,0,[\"pagingSearch\",\"total\"]]],[13],[2,\"\\n\\t\\t\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-rectangle bmb-xsmall\"],[24,\"data-name\",\"button-profile-navigation-next\"]],[[\"@onClick\",\"@disabled\",\"@iconOnly\"],[[32,0,[\"next\"]],[32,0,[\"isLastPage\"]],\"bmi-caret-right-light\"]],null],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-profile-navigation/template.hbs"
  });
});