define("boondmanager/pods/components/octane/bm-field/account/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "vpyfDApb",
    "block": "{\"symbols\":[\"__arg0\",\"__arg1\",\"record\",\"selected\",\"@disabled\",\"&attrs\",\"@name\",\"@multiple\",\"@renderInPlace\",\"@required\",\"@nbDisplayedItems\",\"@noValuePlaceholder\"],\"statements\":[[11,\"div\"],[16,0,[31,[[32,0,[\"classNames\"]]]]],[17,6],[4,[38,3],[[32,0,[\"onInsert\"]]],null],[4,[38,4],[[32,0,[\"onUpdate\"]],[32,0,[\"selected\"]]],null],[12],[2,\"\\n\\t\"],[8,\"octane/bm-field/power-search\",[[16,3,[32,7]]],[[\"@customSearch\",\"@selected\",\"@multiple\",\"@renderInPlace\",\"@placeholder\",\"@loadingMessage\",\"@selectAll\",\"@onChange\",\"@disabled\",\"@required\",\"@nbDisplayedItems\",\"@displayNbSelected\",\"@noValuePlaceholder\",\"@namedBlocksInfo\"],[[32,0,[\"searchItems\"]],[32,0,[\"selected\"]],[32,8],[32,9],[30,[36,5],[\"components:bmFieldSearchItems.searchMessage\"],[[\"context\"],[\"account\"]]],[32,0,[\"loadingMessage\"]],[32,0,[\"selectAll\"]],[32,0,[\"onChange\"]],[32,5],[32,10],[32,11],[32,0,[\"displayNbSelected\"]],[32,12],[30,[36,6],null,[[\"selected-item\",\"option\"],[1,1]]]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],\"selected-item\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[30,[36,2],[[32,5],[32,4,[\"completeName\"]],[32,4,[\"entityName\"]]],null]],[2,\"\\n\\t\\t\"]],\"parameters\":[4]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],\"option\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[32,3,[\"entityName\"]]],[2,\"\\n\\t\\t\"]],\"parameters\":[3]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"let\",\"-is-named-block-invocation\",\"if\",\"did-insert\",\"did-update\",\"t\",\"hash\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/account/template.hbs"
  });
});