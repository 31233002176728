define("boondmanager/pods/components/octane/bm-field/radio/barometer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "4e5WSlrd",
    "block": "{\"symbols\":[\"@errors\",\"@help\",\"&default\",\"@labelText\",\"@checked\",\"@icon\",\"&attrs\",\"@name\",\"@disabled\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-field-radio bmc-field-radio_barometer\"],[17,7],[4,[38,5],[[32,0,[\"onInsert\"]]],null],[12],[2,\"\\n\\t\"],[10,\"label\"],[12],[2,\"\\n\\t\\t\"],[10,\"input\"],[15,0,[31,[\"bmf-checkbox \",[32,5]]]],[15,3,[32,8]],[15,\"checked\",[32,5]],[15,\"disabled\",[32,9]],[15,\"onChange\",[32,0,[\"onChange\"]]],[14,4,\"radio\"],[12],[13],[2,\"\\n\\t\\t\"],[10,\"span\"],[14,0,\"bmf-checkbox-style\"],[12],[13],[2,\"\\n\"],[6,[37,1],[[32,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[1,[30,[36,4],[[30,[36,3],[[32,6],[30,[36,1],[[32,5],\" bm-active\"],null]],null]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,2],[[32,4],[32,2],[35,0]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[10,\"span\"],[14,0,\"bmc-field-checkbox-label\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[10,\"span\"],[14,0,\"bmc-field-checkbox-label-text\"],[12],[2,\"\\n\"],[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\\t\\t\"],[18,3,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\\t\\t\\t\"],[1,[32,4]],[2,\"\\n\"],[6,[37,1],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\\t\\t\"],[8,\"octane/bm-help\",[[24,\"data-html\",\"true\"],[16,\"data-original-title\",[32,2]]],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[8,\"octane/bm-field/error\",[],[[\"@errors\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"has-block\",\"if\",\"or\",\"concat\",\"icon\",\"did-insert\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/radio/barometer/template.hbs"
  });
});