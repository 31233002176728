define("boondmanager/pods/components/octane/bm-field/validate-hour-accounts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "O4ezIjZg",
    "block": "{\"symbols\":[\"&attrs\",\"@labelText\",\"@checked\",\"@disabled\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-field-validate-hour-account\"],[17,1],[4,[38,0],[[32,0,[\"onInsert\"]]],null],[12],[2,\"\\n\\t\"],[8,\"octane/bm-field\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[8,\"octane/bm-field/checkbox\",[],[[\"@name\",\"@labelText\",\"@checked\",\"@disabled\"],[\"validated\",[32,2],[32,3],[32,4]]],null],[2,\"\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/validate-hour-accounts/template.hbs"
  });
});