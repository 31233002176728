define("boondmanager/adapters/esignature", ["exports", "boondmanager/adapters/integratedapps"], function (_exports, _integratedapps) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _integratedapps.default.extend({
    // override methode to use PUT instead of PATCH
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var url,
        fields = Ember.getWithDefault(snapshot, 'adapterOptions.fields', []),
        queryParams = Ember.getWithDefault(snapshot, 'adapterOptions.queryParams', {}),
        endpoint = Ember.getWithDefault(snapshot, 'adapterOptions.endpoint', ''),
        modelType = Ember.getWithDefault(snapshot, 'adapterOptions.type', type.modelName);
      if (fields.length) {
        var keys = Object.keys(data.data.attributes);
        for (var i in keys) {
          if (!fields.contains(keys[i])) delete data.data.attributes[keys[i]];
        }
        keys = Object.keys(data.data.relationships);
        for (var _i in keys) {
          if (!fields.contains(keys[_i])) delete data.data.relationships[keys[_i]];
        }
        url = this.buildURL(modelType, snapshot.id, snapshot, 'updateRecord', Ember.assign({
          fields: fields.join(',')
        }, queryParams));
      } else {
        url = this.buildURL(modelType, snapshot.id, snapshot, 'updateRecord', queryParams);
      }
      var verb = ['cancel'].includes(endpoint) ? 'POST' : 'PUT';
      return this.ajax(url, verb, {
        data: data
      });
    }
  });
});