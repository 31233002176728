define("boondmanager/helpers/delivery-scenario", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Outputs deiveries scenarios available to a typeOf delivery.
   *
   * "typeOfDeliveries" in params[0] is required.
   * "index" in params[1] is required.
   *
   * Example:
   *
   * ```handlebars
   * options=(delivery-scenario this.typeOfDeliveries index)
   * ```
   *
   * @public
   * @method delivery-scenario
   * @for Ember.Templates.helpers
   */
  var _default = _exports.default = Ember.Helper.extend({
    i18n: Ember.inject.service(),
    settings: Ember.inject.service(),
    typeOfDeliveries: null,
    /**
     * @private
     * Outputs list of scenarios according to other deliveries type of.
     *
     * @param {Array} params - positional parameters passed to the helper.
     *   1st element : typeOfDeliveries list
     *   2nd element : index of current type of delivery
     *
     * @return {Array} list of scenarios available for delivery type of with this index.
     */
    compute: function compute(params) {
      var typeOfDeliveries = params[0];
      var index = params[1];
      this.set('typeOfDeliveries', typeOfDeliveries); // Permet de relancer le helper si ce paramètre change

      var scenariosList = this.get('settings').bm.common.deliveryScenario;

      // Si archivé, je garde toute la liste pour pouvoir afficher le label du scénario
      if (!typeOfDeliveries.options[index].isEnabled) {
        return scenariosList;
      } else {
        var typeOfDeliveriesOptionsSelected = [];
        var typeOfDeliveryToCompare = typeOfDeliveries.options.objectAt(index);
        // On créé une liste des scénarios présents dans les autres options non archivées
        typeOfDeliveries.options.forEach(function (typeOfDelivery) {
          typeOfDelivery.scenario.forEach(function (scenarioId) {
            if (!typeOfDeliveryToCompare.scenario.includes(scenarioId)) {
              typeOfDeliveriesOptionsSelected.push(scenarioId);
            }
          });
        });

        // On filtre sur les scenarios non présents ailleurs
        return scenariosList.filter(function (scenario) {
          return !typeOfDeliveriesOptionsSelected.includes(scenario.id);
        });
      }
    },
    // Permet de relancer le helper si le paramètre "typeOfDeliveries" ou la langue changent
    refreshScenarioList: Ember.observer('i18n._locale', 'typeOfDeliveries', function () {
      this.recompute();
    })
  });
});