define("boondmanager/helpers/dynamic-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.dynamicParams = dynamicParams;
  function dynamicParams(params /*, hash*/) {
    var hashParams = params[0];
    var values = Ember.assign({}, hashParams);
    return Ember.Object.create({
      isQueryParams: true,
      values: values
    });
  }
  var _default = _exports.default = Ember.Helper.helper(dynamicParams);
});