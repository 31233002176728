define("boondmanager/pods/components/octane/bm-cache-indicator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "DqZYx+LE",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-cache-indicator\"],[17,1],[4,[38,3],[[32,0,[\"registerListener\"]]],null],[4,[38,4],[[32,0,[\"unregisterListener\"]]],null],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,5],[[32,0,[\"reporting\",\"useCache\"]],\"withoutCache\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"span\"],[14,0,\"bm-text-secondary\"],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"refreshing\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[1,[30,[36,0],[\"components:bmCacheIndicator.refreshing\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,0,[\"disabled\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[1,[30,[36,0],[\"components:bmCacheIndicator.lastRefresh\"],[[\"time\"],[[32,0,[\"lastRefresh\"]]]]]],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\\t\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-circle\"],[24,\"data-placement\",\"top\"],[16,\"data-original-title\",[30,[36,0],[\"components:bmCacheIndicator.refresh\"],null]],[24,\"data-name\",\"button-refresh\"],[16,\"aria-label\",[30,[36,0],[\"components:bmCacheIndicator.refresh\"],null]]],[[\"@disabled\",\"@iconOnly\",\"@onClick\"],[[32,0,[\"disabled\"]],[30,[36,2],[[32,0,[\"disabled\"]],\"bmi-spinner fa-pulse\",\"bmi-refresh\"],null],[32,0,[\"refresh\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[1,[30,[36,0],[\"components:bmCacheIndicator.refresh\"],null]],[2,\"\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"not\",\"if\",\"did-insert\",\"will-destroy\",\"not-eq\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-cache-indicator/template.hbs"
  });
});