define("boondmanager/pods/private/apps/specialreporting/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "3W0k30R6",
    "block": "{\"symbols\":[\"__arg0\"],\"statements\":[[8,\"octane/bm-layout-search\",[],[[\"@title\",\"@icon\",\"@namedBlocksInfo\"],[\"specialreporting:title\",\"bmi-app-specialreporting\",[30,[36,3],null,[[\"actions\",\"searchFields\"],[0,0]]]]],[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1],\"actions\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-circle\"],[24,\"data-name\",\"button-confirm-extract\"],[24,\"data-placement\",\"top\"],[16,\"data-original-title\",[30,[36,2],[\"common:actions.extract\"],null]],[16,\"aria-label\",[30,[36,2],[\"common:actions.extract\"],null]]],[[\"@iconOnly\",\"@onClick\"],[\"bmi-action-file text/csv\",[32,0,[\"confirmExtract\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[30,[36,2],[\"common:actions.extract\"],null]],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1],\"searchFields\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[8,\"octane/bm-search-filters/views/apps/specialreporting\",[[24,0,\"bmc-search-fields\"]],[[\"@params\",\"@onUpdate\"],[[32,0,[\"activeQueryParams\"]],[32,0,[\"updateSearchFilters\"]]]],null],[2,\"\\n\\t\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[8,\"octane/bm-modal/extract/specialreporting\",[],[[\"@show\",\"@onConfirm\",\"@onClose\"],[[32,0,[\"showConfirmExtract\"]],[32,0,[\"extract\"]],[32,0,[\"closeConfirmExtractModal\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-is-named-block-invocation\",\"if\",\"t\",\"hash\"]}",
    "moduleName": "boondmanager/pods/private/apps/specialreporting/template.hbs"
  });
});