define("boondmanager/helpers/unescape", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.unescape = unescape;
  function unescape(value) {
    var val = JSON.stringify(value[0]);
    return val ? JSON.parse(val.replaceAll('&#x2F;', '/')) : {};
  }
  var _default = _exports.default = Ember.Helper.helper(unescape);
});