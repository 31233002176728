define("boondmanager/pods/components/octane/bm-alerts/expensesreport/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "WI8Qr5Fx",
    "block": "{\"symbols\":[\"warning\",\"entity\",\"index\",\"@model\"],\"statements\":[[6,[37,0],[[32,0,[\"warnings\",\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-alerts\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"warnings\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"li\"],[14,0,\"bmc-alert_list-item\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[1,[30,[36,1],[[32,1,[\"caption\"]]],[[\"type\",\"project\",\"delivery\",\"month\",\"count\"],[[32,1,[\"type\"]],[32,1,[\"project\"]],[32,1,[\"delivery\"]],[30,[36,5],[[30,[36,4],[[32,4,[\"lastTermDay\"]]],[[\"format\"],[\"MMMM YYYY\"]]]],null],[32,1,[\"relatedEntities\",\"length\"]]]]]],[2,\"\\n\"],[6,[37,0],[[32,1,[\"relatedEntities\",\"length\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,1,[\"relatedEntities\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\\t\"],[1,[30,[36,0],[[32,3],\",\"],null]],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[8,\"link-to\",[[24,0,\"related-entity\"]],[[\"@route\",\"@model\"],[[32,2,[\"route\"]],[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[1,[30,[36,1],[[32,2,[\"label\"]]],null]],[2,\"\\n\\t\\t\\t\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2,3]}]]]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"t\",\"-track-array\",\"each\",\"time\",\"capitalize\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-alerts/expensesreport/template.hbs"
  });
});