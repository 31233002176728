define("boondmanager/utils/cachecomputed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = cacheComputed;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function cacheComputed() {
    var _Ember;
    var func = arguments[arguments.length - 1];
    var dependentKeys, getter, setter;
    if (typeof func !== 'string') {
      dependentKeys = Array.prototype.slice.call(arguments, 0, arguments.length - 1);
      if (typeof func === 'function') {
        getter = func;
      } else if (func.get && typeof func.get === 'function') {
        getter = func.get;
      }
      if (func.set && typeof func.set === 'function') {
        setter = func.set;
      }
    } else {
      dependentKeys = Array.prototype.slice.call(arguments);
    }
    return (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(dependentKeys).concat([{
      get: function get(key) {
        if (typeof this["_".concat(key)] !== 'undefined') {
          return this["_".concat(key)];
        } else if (getter) {
          return getter.call(this, key);
        }
      },
      set: function set(key, value, oldValue) {
        var ret;
        if (setter) {
          ret = setter.call(this, key, value, oldValue);
        } else {
          ret = value;
        }
        this["_".concat(key)] = ret;
        return ret;
      }
    }]));
  }
});