define("boondmanager/pods/components/octane/bm-help/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "l/qTKvhp",
    "block": "{\"symbols\":[\"@dataContainer\",\"&attrs\"],\"statements\":[[11,\"span\"],[24,0,\"bmc-help bm-tooltips\"],[16,\"data-container\",[30,[36,0],[[32,1],[32,1],\"body\"],null]],[17,2],[12],[2,\"\\n\\t\"],[1,[30,[36,1],[\"bmi-question-circle\"],null]],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"icon\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-help/template.hbs"
  });
});