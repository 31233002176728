define("boondmanager/pods/components/octane/bm-table-list/checkall/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "hcn7bTZE",
    "block": "{\"symbols\":[\"&attrs\",\"@cbAllChecked\",\"@cbAllIndeterminate\",\"@disabled\",\"@cbAllClick\"],\"statements\":[[11,\"th\"],[24,0,\"bmc-table-list-checkall\"],[17,1],[4,[38,0],[\"click\",[32,0,[\"click\"]]],null],[12],[2,\"\\n\\t\"],[11,\"input\"],[24,3,\"cball\"],[24,0,\"bmf-checkbox\"],[16,\"checked\",[32,2]],[16,\"indeterminate\",[32,3]],[16,\"disabled\",[32,4]],[16,\"data-checklistid\",[32,0,[\"checklistId\"]]],[16,\"data-type\",[32,0,[\"dataType\"]]],[24,4,\"checkbox\"],[4,[38,0],[\"click\",[32,5]],null],[12],[13],[2,\"\\n\\t\"],[10,\"span\"],[14,0,\"bmf-checkbox-style\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-table-list/checkall/template.hbs"
  });
});