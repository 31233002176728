define("boondmanager/adapters/formtemplate", ["exports", "boondmanager/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    /** PROPERTIES **/

    /**
     * UrlTemplate for queryRecord
     * @property queryRecordUrlTemplate
     * @type string
     */
    queryRecordUrlTemplate: '{+host}/api/forms/templates{/id}{?query*}',
    /**
     * UrlTemplate for query
     * @property queryUrlTemplate
     * @type string
     */
    queryUrlTemplate: '{+host}/api/forms/templates{/id}{?query*}',
    /**
     * UrlTemplate for updateRecord
     * @property updateRecordUrlTemplate
     * @type string
     */
    updateRecordUrlTemplate: '{+host}/api/forms/templates{/id}{?query*}',
    /**
     * UrlTemplate for createRecord
     * @property createRecordUrlTemplate
     * @type string
     */
    createRecordUrlTemplate: '{+host}/api/forms/templates{/id}{?query*}',
    /**
     * UrlTemplate for deleteRecord
     * @property deleteRecordUrlTemplate
     * @type string
     */
    deleteRecordUrlTemplate: '{+host}/api/forms/templates{/id}{?query*}',
    /** METHODS **/
    /**
     * Path Customization
     * @returns {string}
     */
    pathForType: function pathForType() {
      return 'forms';
    }
  });
});