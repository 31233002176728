define("boondmanager/utils/forms", ["exports", "moment", "ember-copy"], function (_exports, _moment, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getAttributesFragmentErrors = getAttributesFragmentErrors;
  _exports.getConfirmReferencesErrors = getConfirmReferencesErrors;
  _exports.getDuplicateErrors = getDuplicateErrors;
  _exports.getDuplicateTranslationErrors = getDuplicateTranslationErrors;
  _exports.getSaveReferencesErrors = getSaveReferencesErrors;
  _exports.getYearsPeriod = getYearsPeriod;
  _exports.validateEmail = validateEmail;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function validateEmail(mail) {
    // regex from RFC 5322 standard found here https://emailregex.com/
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(mail);
  }

  /**
   * Construction des erreurs de duplication pour un modèle, une liste d'erreurs et un attribut du modèle passé
   * @param {Array} errors
   * @param {DS.Model} model
   * @param {String} attr
   * @param {String} key
   * @param {String} regex
   * @return {Array}
   */
  function getDuplicateErrors(errors, model, attr, key, regex) {
    var duplicateErrors = [];
    var attrRegex = regex ? regex : new RegExp("/data/attributes/".concat(attr));
    if (errors.find(function (error) {
      return error.code === '1013' && error.source.pointer.match(attrRegex);
    })) {
      Ember.get(model, attr).forEach(function (item) {
        var value = item[key];
        if (value !== '') duplicateErrors[value] = typeof duplicateErrors[value] !== 'undefined';
      });
    }
    return duplicateErrors;
  }
  function getDuplicateTranslationErrors(errors, model, attr, key, regex) {
    var duplicateErrors = [];
    var attrRegex = regex ? regex : new RegExp("/data/attributes/".concat(attr));
    if (errors.find(function (error) {
      return error.code === '1013' && error.source.pointer.match(attrRegex);
    })) {
      Ember.get(model, attr).forEach(function (item) {
        var attrAttribute = Ember.get(item, 'attribute');
        var attrLanguage = Ember.get(item, 'language');
        if (attrAttribute !== '' && attrLanguage !== '' && attrAttribute === key) {
          duplicateErrors[attrLanguage] = typeof duplicateErrors[attrLanguage] !== 'undefined';
        }
      });
    }
    return duplicateErrors;
  }
  function getSaveReferencesErrors(errors, modelReferences) {
    var _referencesErrors = {};
    var regex = new RegExp("/data/attributes/references");
    errors.forEach(function (err) {
      if (err.source.pointer.match(regex)) {
        var attribut = err.source.pointer.split('/').at(-1);
        var idx = modelReferences.toArray()[err.source.pointer.split('/').at(-2)].get('row');
        if (!_referencesErrors[idx]) {
          _referencesErrors[idx] = {};
        }
        if (!_referencesErrors[idx][attribut]) {
          _referencesErrors[idx][attribut] = [];
        }
        _referencesErrors[idx][attribut].push(err);
      }
    });
    return _referencesErrors;
  }
  function getAttributesFragmentErrors(errors, modelAttribute, fragmentName) {
    var _attributeErrors = {};
    var regex = new RegExp("/data/attributes/" + fragmentName);
    errors.forEach(function (err) {
      if (err.source.pointer.match(regex)) {
        var attribute = err.source.pointer.split('/').at(-1);
        var idx = err.source.pointer.split('/').at(-2);
        if (!_attributeErrors[idx]) {
          _attributeErrors[idx] = {};
        }
        if (!_attributeErrors[idx][attribute]) {
          _attributeErrors[idx][attribute] = [];
        }
        _attributeErrors[idx][attribute].push(err);
      }
    });
    return _attributeErrors;
  }
  function getConfirmReferencesErrors(ref, modelReferences, referencesErrors) {
    var _referencesErrors$err;
    var errIndex = ref.get('row');
    var refIndex = modelReferences.indexOf(ref);
    var _referencesErrors = referencesErrors !== null && referencesErrors !== void 0 ? referencesErrors : [];
    _referencesErrors[errIndex] = (_referencesErrors$err = _referencesErrors[errIndex]) !== null && _referencesErrors$err !== void 0 ? _referencesErrors$err : {};
    delete _referencesErrors[errIndex].startMonth;
    delete _referencesErrors[errIndex].startYear;
    delete _referencesErrors[errIndex].endMonth;
    delete _referencesErrors[errIndex].endYear;
    var _isError = false;
    if (!ref.get('startYear') && !ref.get('startMonth')) {
      ref.set('endMonth', null);
      ref.set('endYear', null);
    }

    // Vérifier la cohérence des dates
    if (ref.get('startMonth') && !ref.get('startYear')) {
      var _referencesErrors$err2;
      _referencesErrors[errIndex]['startYear'] = (_referencesErrors$err2 = _referencesErrors[errIndex]['startYear']) !== null && _referencesErrors$err2 !== void 0 ? _referencesErrors$err2 : [];
      _referencesErrors[errIndex]['startYear'].push({
        code: '1002',
        detail: '1002 - Wrong or missing attribute',
        source: {
          pointer: '/data/attributes/references/' + refIndex + '/startYear'
        },
        status: '422'
      });
      _isError = true;
    }
    if (ref.get('endMonth') && !ref.get('endYear')) {
      var _referencesErrors$err3;
      _referencesErrors[errIndex]['endYear'] = (_referencesErrors$err3 = _referencesErrors[errIndex]['endYear']) !== null && _referencesErrors$err3 !== void 0 ? _referencesErrors$err3 : [];
      _referencesErrors[errIndex]['endYear'].push({
        code: '1002',
        detail: '1002 - Wrong or missing attribute',
        source: {
          pointer: '/data/attributes/references/' + refIndex + '/endYear'
        },
        status: '422'
      });
      _isError = true;
    }
    if (ref.get('endYear') && (ref.get('endYear') < ref.get('startYear') || ref.get('endMonth') && ref.get('endYear') === ref.get('startYear') && ref.get('endMonth') < ref.get('startMonth'))) {
      var _referencesErrors$err4, _referencesErrors$err5;
      _referencesErrors[errIndex]['endMonth'] = (_referencesErrors$err4 = _referencesErrors[errIndex]['endMonth']) !== null && _referencesErrors$err4 !== void 0 ? _referencesErrors$err4 : [];
      _referencesErrors[errIndex]['endMonth'].push({
        code: '2850',
        detail: '2850 - endDate have to be superior or equal to startDate',
        source: {
          pointer: '/data/attributes/references/' + refIndex + '/endMonth'
        },
        status: '422'
      });
      _referencesErrors[errIndex]['endYear'] = (_referencesErrors$err5 = _referencesErrors[errIndex]['endYear']) !== null && _referencesErrors$err5 !== void 0 ? _referencesErrors$err5 : [];
      _referencesErrors[errIndex]['endYear'].push({
        code: '2850',
        detail: '2850 - endDate have to be superior or equal to startDate',
        source: {
          pointer: '/data/attributes/references/' + refIndex + '/endYear'
        },
        status: '422'
      });
      _isError = true;
    }
    if (ref.get('startYear') && ref.get('startMonth') && ref.get('startYear') === (0, _moment.default)().year() && ref.get('startMonth') > (0, _moment.default)().month() + 1) {
      var _referencesErrors$err6, _referencesErrors$err7;
      _referencesErrors[errIndex]['startYear'] = (_referencesErrors$err6 = _referencesErrors[errIndex]['startYear']) !== null && _referencesErrors$err6 !== void 0 ? _referencesErrors$err6 : [];
      _referencesErrors[errIndex]['startYear'].push({
        code: '2851',
        detail: '2851 - startDate cannot be superior to actual date',
        source: {
          pointer: '/data/attributes/references/' + refIndex + '/startYear'
        },
        status: '422'
      });
      _referencesErrors[errIndex]['startMonth'] = (_referencesErrors$err7 = _referencesErrors[errIndex]['startMonth']) !== null && _referencesErrors$err7 !== void 0 ? _referencesErrors$err7 : [];
      _referencesErrors[errIndex]['startMonth'].push({
        code: '2851',
        detail: '2851 - startDate cannot be superior to actual date',
        source: {
          pointer: '/data/attributes/references/' + refIndex + '/startMonth'
        },
        status: '422'
      });
      _isError = true;
    }
    if (ref.get('endYear') && ref.get('endMonth') && ref.get('endYear') === (0, _moment.default)().year() && ref.get('endMonth') > (0, _moment.default)().month() + 1) {
      var _referencesErrors$err8, _referencesErrors$err9;
      _referencesErrors[errIndex]['endYear'] = (_referencesErrors$err8 = _referencesErrors[errIndex]['endYear']) !== null && _referencesErrors$err8 !== void 0 ? _referencesErrors$err8 : [];
      _referencesErrors[errIndex]['endYear'].push({
        code: '2852',
        detail: '2852 - endDate cannot be superior to actual date',
        source: {
          pointer: '/data/attributes/references/' + refIndex + '/endYear'
        },
        status: '422'
      });
      _referencesErrors[errIndex]['endMonth'] = (_referencesErrors$err9 = _referencesErrors[errIndex]['endMonth']) !== null && _referencesErrors$err9 !== void 0 ? _referencesErrors$err9 : [];
      _referencesErrors[errIndex]['endMonth'].push({
        code: '2852',
        detail: '2852 - endDate cannot be superior to actual date',
        source: {
          pointer: '/data/attributes/references/' + refIndex + '/endMonth'
        },
        status: '422'
      });
      _isError = true;
    }
    return {
      _isError: _isError,
      _referencesErrors: (0, _emberCopy.copy)(_referencesErrors)
    };
  }
  function getYearsPeriod(yearRange) {
    var order = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'desc';
    var minYear = (0, _moment.default)().year() - yearRange;
    var _years = order === 'desc' ? _toConsumableArray(Array(yearRange + 1).keys()).reverse() : _toConsumableArray(Array(yearRange + 1).keys());
    return _years.map(function (index) {
      var year = minYear + index;
      return {
        id: year,
        value: year
      };
    });
  }
  var _default = _exports.default = {
    validateEmail: validateEmail
  };
});