define("boondmanager/initializers/ember-i18next", ["exports", "boondmanager/config/environment", "ember-i18next/configuration"], function (_exports, _environment, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize( /* application */
  ) {
    if (!_environment.default.i18nextOptions) {
      // eslint-disable-next-line no-console
      console.warn('No configuration found for ember-i18next. Did you set up i18nextOptions in your environment.js?');
    }
    _configuration.default.load(_environment.default.i18nextOptions);
  }
  var _default = _exports.default = {
    name: 'ember-i18next',
    initialize: initialize
  };
});