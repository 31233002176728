define("boondmanager/pods/private/administrator/appsmanagement/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Wpgq+KOh",
    "block": "{\"symbols\":[\"__arg0\"],\"statements\":[[8,\"octane/bm-layout-profile\",[],[[\"@model\",\"@withTabs\",\"@withFooter\",\"@icon\",\"@title\",\"@namedBlocksInfo\"],[[32,0,[\"model\"]],true,false,\"bmi-app\",[30,[36,5],[\"adminappsmanagement:view.appsmanagement.title\"],null],[30,[36,7],null,[[\"tabs\",\"content\"],[0,0]]]]],[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1],\"tabs\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[8,\"link-to\",[[24,0,\"nav-item\"],[24,\"data-name\",\"tab-apps\"]],[[\"@route\",\"@active\"],[\"private.administrator.appsmanagement.apps\",[30,[36,4],[[32,0,[\"router\",\"currentRouteName\"]],\"private.administrator.appsmanagement.apps\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[30,[36,5],[\"adminappsmanagement:view.appsinstalled.title\"],null]],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\n\\t\\t\"],[8,\"link-to\",[[24,0,\"nav-item\"],[24,\"data-name\",\"tab-marketplace\"]],[[\"@route\",\"@active\",\"@query\"],[\"private.administrator.appsmanagement.marketplace\",[30,[36,4],[[32,0,[\"router\",\"currentRouteName\"]],\"private.administrator.appsmanagement.marketplace\"],null],[30,[36,7],null,[[\"validations\",\"visibilities\"],[[30,[36,6],[\"validated\"],null],[30,[36,6],[\"public\"],null]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[30,[36,5],[\"adminappsmanagement:view.marketplace.title\"],null]],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1],\"content\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\\t\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"-is-named-block-invocation\",\"if\",\"eq\",\"t\",\"array\",\"hash\"]}",
    "moduleName": "boondmanager/pods/private/administrator/appsmanagement/template.hbs"
  });
});