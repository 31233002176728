define("boondmanager/pods/components/octane/bm-field/email/multiple/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "IrPn+ROQ",
    "block": "{\"symbols\":[\"&attrs\",\"@selected\",\"@name\",\"@disabled\",\"@required\",\"@maxItems\",\"@replaceOnSelect\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"bmc-field-email-multiple \",[32,0,[\"classNames\"]]]]],[17,1],[4,[38,0],[[32,0,[\"onInsert\"]]],null],[4,[38,0],[[32,0,[\"onUpdateSelected\"]],[32,2]],null],[4,[38,1],[[32,0,[\"onUpdateSelected\"]],[32,2]],null],[4,[38,1],[[32,0,[\"onUpdate\"]],[32,0,[\"selected\"]]],null],[12],[2,\"\\n\\t\"],[8,\"octane/bm-field/power-search\",[[16,3,[32,3]]],[[\"@customSearch\",\"@selected\",\"@multiple\",\"@placeholder\",\"@loadingMessage\",\"@selectAll\",\"@onChange\",\"@disabled\",\"@required\",\"@displayNbSelected\",\"@maxItems\",\"@clearOnSelect\",\"@replaceOnSelect\",\"@valueKeyName\",\"@idKeyName\"],[[32,0,[\"searchItems\"]],[32,0,[\"selected\"]],true,[30,[36,2],[\"components:bmFieldSearchItems.searchMessage\"],[[\"context\"],[\"emails\"]]],[30,[36,2],[\"components:bmFieldSearchItems.loadingMessage\"],null],false,[32,0,[\"onChange\"]],[32,4],[32,5],false,[32,6],[32,0,[\"clearOnSelect\"]],[32,7],\"email\",\"email\"]],null],[2,\"\\n\\t\"],[8,\"octane/bm-field/error\",[],[[\"@errors\"],[[34,3]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update\",\"t\",\"errors\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/email/multiple/template.hbs"
  });
});