define("boondmanager/initializers/deprecation-filter", ["exports", "boondmanager/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize( /* application */
  ) {
    var deprecationFilterConfig = _environment.default.deprecationFilter || {};
    var once = [];
    Ember.Debug.registerDeprecationHandler(function (message, options, next) {
      switch (deprecationFilterConfig[options.id]) {
        case 'silence':
          break;
        case 'once':
          if (once.indexOf(options.id) === -1) {
            once.push(options.id);
            next(message, options);
          }
          break;
        default:
          next(message, options);
          break;
      }
    });
  }
  var _default = _exports.default = {
    name: 'deprecation-filter',
    after: 'config-meta',
    initialize: initialize
  };
});