define("boondmanager/pods/private/apps/corporama/settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "T4gi/0Be",
    "block": "{\"symbols\":[\"__arg0\",\"__arg0\",\"__arg0\"],\"statements\":[[8,\"octane/bm-layout-profile\",[],[[\"@withFooter\",\"@title\",\"@icon\",\"@namedBlocksInfo\"],[false,[30,[36,0],[\"corporama:title\"],null],\"bmi-app-corporama\",[30,[36,1],null,[[\"content\"],[0]]]]],[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1],\"content\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[8,\"octane/bm-layout-profile/section\",[],[[\"@namedBlocksInfo\"],[[30,[36,1],null,[[\"header\",\"content\"],[0,0]]]]],[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,2],\"header\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\"],[1,[30,[36,0],[\"corporama:view.api\"],null]],[2,\"\\n\\t\\t\\t\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[32,2],\"content\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[8,\"octane/bm-field\",[],[[\"@name\",\"@namedBlocksInfo\"],[[30,[36,0],[\"corporama:view.appToken\"],null],[30,[36,1],null,[[\"content\"],[0]]]]],[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,3],\"content\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\\t\\t\\t\\t\"],[1,[32,0,[\"model\",\"token\"]]],[2,\"\\n\\t\\t\\t\\t\\t\\t\\t\"]],\"parameters\":[]}]]]],\"parameters\":[3]}]]],[2,\"\\n\\t\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[2]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"hash\",\"-is-named-block-invocation\",\"if\"]}",
    "moduleName": "boondmanager/pods/private/apps/corporama/settings/template.hbs"
  });
});