define("boondmanager/helpers/valid-invoice-state", ["exports", "boondmanager/models/invoice"], function (_exports, _invoice) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ObjProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  var _default = _exports.default = Ember.Helper.extend({
    i18n: Ember.inject.service(),
    settings: Ember.inject.service(),
    invoice: null,
    compute: function compute(params) {
      var _this = this;
      var invoice = params[0];
      var canUnlockInvoices = params[1];
      this.set('invoice', invoice);
      var promise = invoice.get('order.project.agency').then(function (agency) {
        var invoicesLockingStates = agency.invoicesLockingStates;
        var isInvoiceStateLocked = invoicesLockingStates.includes(invoice.get('state'));
        var states = _this.get('settings').customer.state.invoice.filterBy('isEnabled');
        var attrs = _this.get('invoice').changedAttributes();
        if (!_this.get('invoice.isNew') && !invoice.get('isProForma') && !(attrs.state && attrs.state[0] === _invoice.STATE_PROFORMA)) {
          states = states.filter(function (state) {
            return state.id !== _invoice.STATE_PROFORMA;
          });
        }
        if (isInvoiceStateLocked && !canUnlockInvoices) {
          states = states.filter(function (state) {
            return invoicesLockingStates.includes(state.id);
          });
        }
        return states;
      });
      return ObjProxy.create({
        promise: promise
      });
    },
    refreshText: Ember.observer('i18n._locale', 'invoice.isSaving', function () {
      if (!this.get('invoice.isSaving')) {
        this.recompute();
      }
    })
  });
});