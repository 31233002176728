define("boondmanager/models/todelete/solrserverfragment", ["exports", "ember-data", "ember-data-model-fragments"], function (_exports, _emberData, _emberDataModelFragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    // ATTRIBUTES
    // originalID (front) <=> id (back)
    // cf serializers/fragment.js
    originalID: _emberData.default.attr('string'),
    url: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    numberOfCustomers: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    maximumNumberOfCustomers: _emberData.default.attr('number', {
      defaultValue: 65
    }),
    isDefault: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    canDelete: _emberData.default.attr('boolean', {
      defaultValue: true
    })
  });
});