define("boondmanager/services/store", ["exports", "boondmanager/utils/logger", "ember-data"], function (_exports, _logger, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var adaptersMapping = {
    appsurveysatisfaction: 'integratedapps',
    appsurveysatisfactionindicator: 'integratedapps',
    appsurveyenquiry: 'integratedapps',
    appsurveycustomer: 'integratedapps',
    appdigitalworkplacedocument: 'integratedapps',
    appdigitalworkplacecategory: 'digitalworkplacecategory',
    appdigitalworkplacecategories: 'integratedapps',
    appdigitalworkplacenews: 'integratedapps',
    appemailingconnection: 'integratedapps',
    appemailingresource: 'integratedapps',
    appemailingcustomer: 'integratedapps',
    appemailingtemplate: 'integratedapps',
    appemailingcontact: 'integratedapps',
    appemailinginvoice: 'integratedapps',
    appemailingquotation: 'integratedapps',
    appemailingpositioning: 'integratedapps',
    appemailingcandidate: 'integratedapps',
    appemailingshare: 'integratedapps',
    appemailingtimesreport: 'integratedapps',
    appemailingexpensesreport: 'integratedapps',
    appemailingabsencesreport: 'integratedapps',
    appnotificationsevent: 'integratedapps',
    appnotificationsresource: 'integratedapps',
    appnotificationscustomer: 'integratedapps',
    appsgcalendarresource: 'integratedapps',
    appsgmailresource: 'integratedapps',
    appsmicrosoftresource: 'integratedapps',
    appgviewerresource: 'integratedapps',
    appextractpayrollcontract: 'integratedapps',
    appextractpayrollresource: 'integratedapps',
    appplanproductionresource: 'integratedapps',
    apppostproductionproject: 'integratedapps',
    apppostproductionresource: 'integratedapps',
    quotation: 'integratedapps',
    esignature: 'esignature',
    appcontractscontract: 'integratedapps',
    appadvantagesadvantage: 'integratedapps',
    appmarkersmarker: 'integratedapps',
    appaccountingpayrollinvoice: 'integratedapps',
    appaccountingpayrollexpensesreport: 'integratedapps',
    appaccountingpayrollpayment: 'integratedapps',
    appaccountingpayrollcontract: 'integratedapps',
    appaccountingpayrollcompany: 'integratedapps',
    appaccountingpayrollresource: 'integratedapps',
    appaccountingpayrollcustomer: 'integratedapps',
    apphrflowcustomer: 'integratedapps',
    apphrflowresume: 'integratedapps',
    appadvancedcandidatescandidate: 'integratedapps',
    appadvancedcandidatesresource: 'integratedapps',
    time: 'integratedapps',
    appexceptionalactivitycompany: 'integratedapps',
    appcorporamacustomer: 'integratedapps',
    appdataclosingcustomer: 'integratedapps',
    appdataclosinginvoice: 'integratedapps',
    appdataclosingexpensesreport: 'integratedapps',
    appdataclosingtimesreport: 'integratedapps',
    appbackupdatabasecustomer: 'integratedapps',
    appcreateactivitydocumentsresource: 'integratedapps',
    appintranetaccountsresource: 'integratedapps',
    'apporganizationcharts-orgchart': 'integratedapps',
    'apporganizationcharts-node': 'integratedapps',
    'apporganizationcharts-setting': 'integratedapps',
    appabsencesaccountsabsencesaccount: 'integratedapps',
    absencesaccount: 'integratedapps',
    appsepacustomer: 'integratedapps',
    appsepaorder: 'integratedapps',
    appsepacontract: 'integratedapps',
    appsepacompany: 'integratedapps',
    appsepapurchase: 'integratedapps',
    appsepainvoice: 'integratedapps',
    appsepapayment: 'integratedapps',
    appdoctemplatescustomer: 'integratedapps',
    appdoctemplatestemplate: 'integratedapps',
    appresourceplannerresource: 'integratedapps',
    appresourceplannerproject: 'integratedapps',
    appsaaseditorcustomer: 'integratedapps',
    appsaaseditordelivery: 'integratedapps',
    appsaaseditorindicator: 'integratedapps',
    appsaaseditorreporting: 'integratedapps',
    appansweringvalidatorsansweringmachine: 'integratedapps',
    appspecialreportingreporting: 'integratedapps',
    appadvancedprojectscustomer: 'integratedapps',
    appadvancedprojectsproject: 'integratedapps',
    appextractbirequest: 'integratedapps',
    appextractbitemplate: 'integratedapps',
    appextractbicustomer: 'integratedapps',
    apphouraccountshouraccount: 'integratedapps',
    apphouraccountscustomer: 'integratedapps',
    apphouraccountsresource: 'integratedapps',
    appadvancedcustomerscompany: 'integratedapps',
    appadvancedcustomerscustomer: 'integratedapps',
    appadvancedcustomersresource: 'integratedapps',
    appcelebrationscustomer: 'integratedapps',
    appcelebrationsemployee: 'integratedapps',
    appcelebrationsresource: 'integratedapps'
  };
  var _default = _exports.default = _emberData.default.Store.extend({
    adapterFor: function adapterFor(modelName) {
      if (adaptersMapping[modelName]) {
        _logger.default.debug("overriding adapterFor \"".concat(modelName, "\" with \"").concat(adaptersMapping[modelName], "\""));
        return this._super(adaptersMapping[modelName]);
      }
      return this._super(modelName);
    }
  });
});