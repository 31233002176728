define("boondmanager/pods/components/octane/bm-rights-filters/fields/multiple-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Mz79wSWP",
    "block": "{\"symbols\":[\"@filter\",\"@displayNbSelected\",\"@disabled\"],\"statements\":[[8,\"octane/bm-field/select\",[[16,\"data-name\",[32,1,[\"label\"]]]],[[\"@addMissingSelected\",\"@options\",\"@selected\",\"@forceSearch\",\"@multiple\",\"@renderInPlace\",\"@onChange\",\"@placeholder\",\"@displayNbSelected\",\"@nbDisplayedItems\",\"@disabled\"],[false,[32,1,[\"options\"]],[32,1,[\"modalValue\"]],false,true,true,[32,0,[\"onChange\"]],[30,[36,0],[\"common:none\"],[[\"context\"],[[32,1,[\"placeholderContext\"]]]]],[30,[36,2],[[30,[36,1],[[32,2],false],null]],null],1,[32,3]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"eq\",\"not\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-rights-filters/fields/multiple-select/template.hbs"
  });
});