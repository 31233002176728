define("boondmanager/helpers/label-for", ["exports", "boondmanager/config/environment", "boondmanager/utils/logger"], function (_exports, _environment, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Find and outputs translated text.
   *
   * This helper require one parameter : the translation key to locate the array
   * of objects (or array of arrays) in i18n dict.
   *
   * Other parameters are optional :
   *   - 2nd param is the searched value in each object (or array) in this array - default = ''
   *   - 3nd param is the object's key (or array's index) where we search the 'searched value' (ie. 2nd param) - default = 'id'
   *   - 4th param is the object's key (or array's index) where we keep value which will be return by this helper - default = 'value'
   *
   * Example:
   * ```json
   * // i18n EN dict
   * {
   *   "common" : {
   *     "keywordsType" : [
   *       { "id" : 0, "value" : "CV, DT, Lastname & Firstname" },
   *       { "id" : 1, "value" : "CV"                 },
   *       { "id" : 2, "value" : "DT"                 },
   *       { "id" : 3, "value" : "Lastname"           },
   *       { "id" : 4, "value" : "Firstname"          },
   *       { "id" : 5, "value" : "Lastname#Firstname" },
   *       { "id" : 6, "value" : "Title"              }
   *     ]
   *   },
   *   "test" : {
   *     "values" : [
   *       { "key" : 0, "label" : "ID __id__" },
   *       { "key" : 1, "label" : "Another ID (__id__)"}
   *     ]
   *   }
   *   "test2" : {
   *     "values" : [
   *       [ 0, "ID __id__" ],
   *       [ 1, "Another ID (__id__)" ]
   *     ]
   *   }
   * }
   * ```
   *
   * ```handlebars
   * {{label-for 'common.keywordsType' 0                    }}  {{!-- return "CV, DT, Lastname & Firstname" --}}
   * {{label-for 'test'                1 "key" "label" id=5 }}  {{!-- return "Another ID (5)"               --}}
   * {{label-for 'test'                0  0      1     id=2 }}  {{!-- return "ID 2"                         --}}
   * ```
   *
   * @public
   * @method label-for
   * @for Ember.Templates.helpers
   */
  var _default = _exports.default = Ember.Helper.extend({
    i18n: Ember.inject.service(),
    settings: Ember.inject.service(),
    /**
     * @private
     * Find and outputs translated text.
     *
     * @param {Array} params - positional parameters passed to the helper.
     *   The 1st element must be the translation key to locate the array in i18n dict
     *   The 2nd element is the searched value - default = ''
     *   The 3nd element is the key (or index) where we search the value defined in 2nd element - default = 'id'
     *   The 4th element is the key (or index) where we keep returned value - default = 'value'
     *
     * @param {Object} hash - an object containing the hash parameters passed to the
     *   helper. Used for translation substitutions.
     *
     * @return {String} text localized for the current locale.
     */
    compute: function compute(params, hash) {
      var options = _environment.default.i18nextOptions || {};
      var path = params[0];
      var searchVal = typeof params[1] !== 'undefined' ? params[1] : ''; // CAUTION: could be numeric value like 0!
      var searchKey = typeof params[2] !== 'undefined' ? params[2] : 'id'; // CAUTION: could be numeric value like 0!
      var returnKey = typeof params[3] !== 'undefined' ? params[3] : 'value'; // CAUTION: could be numeric value like 0!

      var returnVal = typeof hash.defaultValue !== 'undefined' ? hash.defaultValue : this._getDefaultValue(searchVal, options);

      // force return to be an object (here it's an Array...)
      var items;
      if (typeof path === 'string') {
        switch (path) {
          case 'country':
            items = this.get('i18n').countryList;
            break;
          case 'subdivision':
            items = this.get('i18n').subDivisionList;
            break;
          case 'nationality':
            items = this.get('i18n').nationality;
            break;
          default:
            items = this.get('settings').fromKey(path);
            break;
        }
      } else {
        items = path;
      }
      if (Ember.isArray(items)) {
        var item = items.find(function (item) {
          // CAUTION, the searchVal can have a string format instead of a numeric one
          return typeof item[searchKey] !== 'undefined' && String(item[searchKey]) === String(searchVal);
        });
        if (typeof hash.defaultValue === 'undefined') {
          if (typeof item !== 'undefined' && typeof item[returnKey] !== 'undefined') {
            returnVal = item[returnKey];
          } else {
            // warning car pas de valeur par defaut de fournie
            _logger.default.warn("".concat(this, " - label-for - Bad or no existing i18n key \"").concat(path, "\". So force 'value' to ").concat(returnVal));
          }
        } else {
          if (typeof item !== 'undefined' && typeof item[returnKey] !== 'undefined' && item[returnKey] !== '') {
            returnVal = item[returnKey];
          }
        }
      }
      return returnVal;
    },
    refreshText: Ember.observer('i18n._locale', function () {
      this.recompute();
    }),
    _getDefaultValue: function _getDefaultValue(key, options) {
      return options.defaultValue !== undefined ? options.defaultValue : key;
    }
  });
});