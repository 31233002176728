define("boondmanager/pods/components/octane/bm-field/parent-company/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "idTYrHvf",
    "block": "{\"symbols\":[\"@company\",\"@currentCompanyId\",\"@name\",\"@onChange\",\"@disabled\",\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-field-parent-company\"],[17,6],[4,[38,4],[[32,0,[\"onInsert\"]]],null],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"noParentCompany\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[8,\"octane/bm-field\",[],[[\"@noTitle\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[8,\"octane/bm-btn\",[[24,\"data-name\",\"button-addParentCompany\"],[24,0,\"bmb-link\"],[16,\"aria-label\",[30,[36,1],[\"components:bmFieldParentCompany.addParentCompany\"],null]]],[[\"@onClick\"],[[32,0,[\"showParentCompany\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\"],[1,[30,[36,3],[\"bmi-add\"],null]],[2,\" \"],[1,[30,[36,1],[\"components:bmFieldParentCompany.addParentCompany\"],null]],[2,\"\\n\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\"],[8,\"octane/bm-field\",[],[[\"@inlineContent\",\"@name\"],[true,[30,[36,1],[\"models:company.attributes.parentCompany.name\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,1,[\"stateClass\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[8,\"octane/bm-sticker\",[[16,\"data-name\",[30,[36,0],[\"sticker-\",[32,1,[\"stateClass\"]]],null]]],[[\"@smallSticker\",\"@sticker\"],[true,[32,1,[\"stateClass\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\"],[8,\"octane/bm-field/company\",[[24,0,\"parent-company\"]],[[\"@selected\",\"@currentCompanyId\",\"@name\",\"@onChange\",\"@disabled\",\"@showLock\"],[[32,1],[32,2],[32,3],[32,4],[32,5],true]],null],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"concat\",\"t\",\"if\",\"icon\",\"did-insert\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/parent-company/template.hbs"
  });
});