define("boondmanager/pods/private/administrator/bankingconnections/bankingconnection/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "SzWYQ5u3",
    "block": "{\"symbols\":[\"__arg0\"],\"statements\":[[8,\"octane/bm-layout-profile\",[],[[\"@model\",\"@withTabs\",\"@title\",\"@icon\",\"@seeLogs\",\"@namedBlocksInfo\"],[[32,0,[\"model\"]],false,[32,0,[\"model\",\"bankName\"]],\"bmi-bank\",[32,0,[\"entityRights\",\"actions\",\"seeLogs\"]],[30,[36,2],null,[[\"content\"],[0]]]]],[[\"default\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[32,1],\"content\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\\t\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"hash\",\"-is-named-block-invocation\",\"if\"]}",
    "moduleName": "boondmanager/pods/private/administrator/bankingconnections/bankingconnection/template.hbs"
  });
});