define("boondmanager/pods/components/octane/bm-alerts/houraccounts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Y+N8TXfO",
    "block": "{\"symbols\":[\"warning\",\"day\",\"index\"],\"statements\":[[6,[37,0],[[32,0,[\"warnings\",\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-alerts\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"warnings\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[10,\"li\"],[14,0,\"bmc-alert_list-item\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[1,[30,[36,4],[[32,1,[\"caption\"]]],[[\"count\",\"date\",\"fromDate\",\"toDate\",\"expected\",\"hours\"],[[32,1,[\"days\",\"length\"]],[30,[36,1],[[32,1,[\"date\"]]],null],[30,[36,1],[[32,1,[\"fromDate\"]]],null],[30,[36,1],[[32,1,[\"toDate\"]]],null],[32,1,[\"expected\"]],[32,1,[\"hours\"]]]]]],[2,\"\\n\"],[6,[37,0],[[32,1,[\"days\",\"length\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,1,[\"days\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\\t\\t\"],[1,[30,[36,0],[[32,3],\",\"],null]],[2,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[1,[30,[36,1],[[32,2]],null]],[2,\"\\n\"]],\"parameters\":[2,3]}]]]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"time\",\"-track-array\",\"each\",\"t\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-alerts/houraccounts/template.hbs"
  });
});