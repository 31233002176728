define("boondmanager/pods/components/octane/bm-gadgets/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ACfVZhdE",
    "block": "{\"symbols\":[\"@gadget\",\"@onConfigureGadget\",\"@entityRights\"],\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"gadgetType\"]],\"widget\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-gadgets/widget\",[],[[\"@gadget\",\"@entityRights\",\"@onConfigureGadget\"],[[32,1],[32,3],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"gadgetType\"]],\"metric\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-gadgets/metric\",[],[[\"@gadget\",\"@onConfigureGadget\"],[[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"gadgetType\"]],\"collection\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-gadgets/collection\",[],[[\"@gadget\",\"@onConfigureGadget\"],[[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-gadgets/template.hbs"
  });
});