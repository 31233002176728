define("boondmanager/helpers/moment-from-now", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.fromNow = fromNow;
  function fromNow(params) {
    return (0, _moment.default)(params[0], 'YYYY-MM-DD hh:mm:ss').fromNow();
  }
  var _default = _exports.default = Ember.Helper.helper(fromNow);
});