define("boondmanager/pods/components/octane/bm-field/select/results/colors/state/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "MTKtIh+v",
    "block": "{\"symbols\":[\"&default\",\"@color\",\"@namedBlocksInfo\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"bmc-field-simple-colorpicker-color \",[30,[36,0],[\"bm-state-color-\",[32,2,[\"id\"]]],null]]]],[12],[13],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,3],\"default\",[27,[32,1]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"-has-block\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/select/results/colors/state/template.hbs"
  });
});