define("boondmanager/pods/error-dict/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "9LSDp93l",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"bml-error-page\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"bml-error_img\"],[12],[2,\"\\n\\t\\t\"],[1,[30,[36,1],[\"img/error-dict.png\",[30,[36,0],[\"messages:error.errorDict.title\"],null]],null]],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\\t\"],[10,\"div\"],[14,0,\"bml-error_content\"],[12],[2,\"\\n\\t\\t\"],[10,\"h1\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,0],[\"messages:error.errorDict.title\"],null]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\n\\t\\t\"],[10,\"p\"],[14,0,\"bml-error-description\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,0],[\"messages:error.errorDict.message\"],null]],[2,\"\\n\\t\\t\\t\"],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,0],[\"messages:error.errorDict.nextStep\"],null]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"p\"],[14,0,\"bml-error_content-page-name bm-text-secondary\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"i\"],[12],[1,[30,[36,0],[\"messages:error.errorDict.pageName\"],null]],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"img-relative\"]}",
    "moduleName": "boondmanager/pods/error-dict/template.hbs"
  });
});