define("boondmanager/helpers/abbr", ["exports", "boondmanager/utils/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Outputs HTML <abbr>.
   *
   * "value" (in params[0] or hash) is required.
   * "title" is optional.
   *
   * Example:
   *
   * ```handlebars
   * {{!-- <abbr title="mise à jour">maj<abbr> --}}
   * {{abbr i18nKey="common:update"}}
   * {{abbr (t "common:update.abbr") (t "common:update.title")}}
   * {{abbr value=(t "common:update.abbr") title=(t "common:update.title")}}
   * ```
   *
   * @public
   * @method abbr
   * @for Ember.Templates.helpers
   */
  var _default = _exports.default = Ember.Helper.extend({
    i18n: Ember.inject.service(),
    /**
     * @private
     * Outputs well formatted HTML <abbr>.
     *
     * hash.i18nKey OR hash.value OR params[0] is required!
     *
     * @param {Array} params - positional parameters passed to the helper.
     *   1st element : <abbr> content
     *   2nd element : "title" attribute value
     *
     * @param {Object} hash - an object containing the hash parameters passed to the helper.
     *                 Hash values takes precedence over params. Its "i18nKey" property takes precedence
     *                 over other properties (ie. "value" and "title"):
     *
     *   "i18nKey"        : dictionay key to give translated <abbr> content and its "title" attribute. <abbr> content is
     *                      searched in `${i18nKey}.abbr` and `${i18nKey}`. "title" is searched in `${i18nKey}.label`,
     *                      `${i18nKey}.name`, `${i18nKey}.title` and `${i18nKey}`. If not find, "title" = <abbr> content
     *   "value"          : <abbr> content
     *   "title"          : "title" attribute
     *
     * @return {String} well formatted HTML <abbr> in the current locale.
     */
    compute: function compute(params, hash) {
      // hash values takes precedence over params
      var i18nKey = hash.i18nKey,
        _hash$value = hash.value,
        value = _hash$value === void 0 ? params[0] : _hash$value,
        _hash$title = hash.title,
        title = _hash$title === void 0 ? params[1] : _hash$title;
      var isAbbr = false;
      if (typeof i18nKey === 'string' && i18nKey !== '') {
        var i18n = this.get('i18n');
        if (i18n.exists(i18nKey)) {
          if (!this.hasObserverFor('i18n._locale')) {
            // create onserver on i18n._locale to recompute this helper when locale change
            this.addObserver('i18n._locale', this, function () {
              this.recompute();
            });
          }
          value = i18n.t(["".concat(i18nKey, ".abbr"), "".concat(i18nKey, ".name"), i18nKey]);
          title = i18n.t(['label', 'name', 'title'].map(function (subkey) {
            return "".concat(i18nKey, ".").concat(subkey);
          }));

          // exclude abbr if value is same as title
          isAbbr = i18n.exists("".concat(i18nKey, ".abbr")) && value !== title;
        }
      }
      if (typeof value === 'undefined') {
        if (typeof title !== 'undefined') {
          value = title;
        } else {
          _logger.default.error('Helper {{abbr}} without value!');
          return '';
        }
      }
      if (typeof title === 'undefined') {
        title = value;
      }
      if (value.includes('<abbr ')) {
        isAbbr = false;
      }
      return Ember.String.htmlSafe(isAbbr ? "<abbr title=\"".concat(title, "\">").concat(value, "</abbr>") : value);
    }
  });
});