define("boondmanager/pods/components/octane/bm-rights-filters/fields/switch-inside-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "w7m+94C7",
    "block": "{\"symbols\":[\"@filter\"],\"statements\":[[10,\"td\"],[14,0,\"bmt-action\"],[12],[2,\"\\n\\t\"],[8,\"octane/bm-field/switch\",[],[[\"@name\",\"@value\",\"@onChange\",\"@disabled\"],[[32,1,[\"queryParamKey\"]],[30,[36,0],[[32,1,[\"modalValue\"]],[32,1,[\"valueMapping\",\"trueValue\"]]],null],[32,0,[\"switch\"]],[32,1,[\"disabled\"]]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"is-selected\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-rights-filters/fields/switch-inside-table/template.hbs"
  });
});