define("boondmanager/pods/components/octane/bm-footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UNYmvvWA",
    "block": "{\"symbols\":[\"&attrs\",\"@model\",\"@seeLogs\"],\"statements\":[[6,[37,2],[[32,2,[\"id\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[11,\"footer\"],[17,1],[24,0,\"bmc-footer\"],[12],[2,\"\\n\\t\\t\"],[10,\"p\"],[14,0,\"bmc-footer-reference\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"span\"],[14,0,\"bmc-footer-reference-title\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[1,[30,[36,1],[\"components:bmFooter.reference\"],null]],[2,\"\\n\\t\\t\\t\"],[13],[2,\" : \"],[1,[32,2,[\"internalReference\"]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\n\\t\\t\"],[10,\"span\"],[14,1,\"bmc-footer-creationdate\"],[14,0,\"bmc-footer-creationdate\"],[12],[13],[2,\"\\n\\n\\t\\t\"],[10,\"span\"],[14,1,\"bmc-footer-updatedate\"],[14,0,\"bmc-footer-updatedate\"],[12],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"span\"],[14,1,\"bmc-footer-seelogs\"],[14,0,\"bmc-footer-seelogs\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[8,\"link-to\",[],[[\"@route\",\"@query\"],[\"private.logs\",[30,[36,0],[[32,0,[\"logParams\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\"],[1,[30,[36,1],[\"models:log.name\"],null]],[2,\"\\n\\t\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"explicit-query-params\",\"t\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-footer/template.hbs"
  });
});