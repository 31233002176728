define("boondmanager/pods/components/octane/bm-orgchart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "uFwdqap1",
    "block": "{\"symbols\":[\"__arg0\",\"__arg1\",\"child\",\"&default\",\"&attrs\",\"@data\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-orgchart\"],[17,5],[12],[2,\"\\n\\t\"],[8,\"octane/bm-orgchart/children\",[],[[\"@rootNode\",\"@namedBlocksInfo\"],[[32,6],[30,[36,4],null,[[\"node-root\",\"node-add\",\"node\"],[0,0,1]]]]],[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1],\"node-root\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[18,4,[[30,[36,0],[\"node-root\"],null]]],[2,\"\\n\\t\\t\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1],\"node-add\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[18,4,[[30,[36,0],[\"node-add\"],null]]],[2,\"\\n\\t\\t\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1],\"node\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[18,4,[[30,[36,0],[\"node\"],null],[32,3]]],[2,\"\\n\\t\\t\"]],\"parameters\":[3]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"-named-block-invocation\",\"let\",\"-is-named-block-invocation\",\"if\",\"hash\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-orgchart/template.hbs"
  });
});