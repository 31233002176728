define("boondmanager/pods/components/octane/bm-field/tinymce/thread/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "XuwAXkHX",
    "block": "{\"symbols\":[\"&attrs\",\"@name\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-field-tinymce bmc-field-tinymce-one-line bmc-field-tinymce-thread\"],[17,1],[4,[38,0],[[32,0,[\"onInsert\"]]],null],[4,[38,1],[[32,0,[\"onUpdate\"]],[32,0,[\"contentValue\"]]],null],[4,[38,1],[[32,0,[\"reloadTinyMCE\"]],[32,0,[\"currentUser\",\"theme\"]]],null],[12],[2,\"\\n\\t\"],[8,\"textarea\",[[16,0,[32,0,[\"inputClass\"]]]],[[\"@name\",\"@value\",\"@id\",\"@onchange\",\"@placeholder\"],[[32,2],[32,0,[\"value\"]],[32,0,[\"getElementId\"]],[32,0,[\"onValueChanged\"]],[30,[36,2],[\"components:bmFieldTinyMCE.threadPlaceholder\"],null]]],null],[2,\"\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update\",\"t\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/tinymce/thread/template.hbs"
  });
});