define("boondmanager/initializers/modelMeta", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'add meta to model',
    initialize: function initialize() {
      _emberData.default.Model.reopen({
        _metadata: _emberData.default.attr(),
        meta: Ember.computed('_metadata', {
          get: function get() {
            return this.get('_metadata');
          },
          set: function set(_, value) {
            return value;
          }
        })
      });
    }
  };
});