define("boondmanager/initializers/availability", ["exports", "jquery", "moment"], function (_exports, _jquery, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PROFILE_TYPES = _exports.NOT_DEFINED = _exports.Availability = void 0;
  _exports.initialize = initialize;
  /**
   * List of supported `profile` types
   *
   * @type {String[]}
   * @final
   */
  var PROFILE_TYPES = _exports.PROFILE_TYPES = ['resource', 'candidate'];
  var NOT_DEFINED = _exports.NOT_DEFINED = -1;

  // Number.isInteger polyfill - cf https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Objets_globaux/Number/isInteger
  var isInteger = Number.isInteger || function (value) {
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
  };

  /**
   * Resource or Candidate Availability
   *
   * Ressource Availability from backend :
   *     - 'immediate' (PARAM_TYPEDISPO = 1 || 3 in BDD)
   *     - ISO8601 date YYYY-MM-DD (date = PARAM_DATEDISPO and PARAM_TYPEDISPO = 2 || 4 in BDD)
   *
   * Candidate Availability from backend:
   *     - ISO8601 date YYYY-MM-DD (date = PARAM_DATEDISPO and PARAM_TYPEDISPO = 9 in BDD)
   *     -  -1   = undefined
   *     - [0-8] = Availability types (ie. setting.availability) from settings files.
   *
   */
  var Availability = _exports.Availability = Ember.Object.extend({
    i18n: Ember.inject.service(),
    settings: Ember.inject.service(),
    /**
     * Availability raw value from backend
     *
     * * Candidate availability can be:
     *     * a {Number}
     *     * a {String} representing an ISO8601 date
     *
     * * Resource availability can be:
     *     * "automatic" (a date is defined but is not editable)
     *     * "immediate"
     *     * a {String} representing an ISO8601 date
     *
     * this raw value can be:
     *     * a number (id) correponding to a value in settings setting.availability
     *     * a string :
     *         * representing an ISO8601 date
     *         * "immediate"
     *         * "undefined" (the string ! not `undefined` JS value)
     *
     * @type {Number|String}
     */
    raw: Ember.computed({
      get: function get() {
        return NOT_DEFINED;
      } /*key*/,
      set: function set(key, newValue /*, oldValue*/) {
        var raw = NOT_DEFINED;
        if (newValue === 'immediate') {
          raw = newValue;
        } else if (_moment.default.isMoment(newValue)) {
          if (newValue.isValid()) {
            raw = newValue.format('YYYY-MM-DD');
          }
        } else if (_moment.default.isDate(newValue)) {
          raw = (0, _moment.default)(newValue).format('YYYY-MM-DD');
        } else if (_jquery.default.isNumeric(newValue)) {
          var newNumberValue = Number(newValue);
          if (newNumberValue < 100 && newNumberValue !== NOT_DEFINED) {
            // convert to a number if it's a string representing an integer inf. to 100 (arbitrary value to not include year ;p) and not NOT_DEFINED
            raw = newNumberValue;
          }
        } else if (typeof newValue !== 'undefined' && newValue !== 'undefined') {
          var m = (0, _moment.default)(newValue);
          if (m.isValid()) {
            raw = m.format('YYYY-MM-DD');
          }
        }
        return raw;
      }
    }),
    isDefined: Ember.computed('raw', function () {
      return this.get('raw') !== 'undefined';
    }).readOnly(),
    isIDValue: Ember.computed('raw', function () {
      return isInteger(this.get('raw'));
    }).readOnly(),
    isUndefined: Ember.computed('raw', function () {
      return this.get('raw') === NOT_DEFINED;
    }).readOnly(),
    /**
     * Is availability immediate? Only use with a resource profile
     *
     * @type {Boolean}
     * @readOnly
     */
    isImmediate: Ember.computed.equal('raw', 'immediate').readOnly(),
    /**
     * Availability state
     *
     * @type {String}  'success' | 'warning' | 'danger'
     * @readOnly
     */
    state: Ember.computed('isImmediate', 'date', function () {
      var state = 'warning';
      if (this.get('isDefined')) {
        if (this.get('isImmediate')) {
          return 'danger';
        }
        var date = this.get('date');
        if (_moment.default.isMoment(date)) {
          if (date.isAfter((0, _moment.default)().add(15, 'd'))) {
            state = 'success';
          } else if (date.isBefore((0, _moment.default)())) {
            state = 'danger';
          }
        }
      }
      return state;
    }).readOnly(),
    /**
     * Availability date
     *
     * @type {Moment|undefined}
     * @readOnly
     */
    date: Ember.computed('raw', function () {
      var date;
      if (this.get('isDefined')) {
        if (this.get('isImmediate')) {
          date = (0, _moment.default)();
        } else if (!this.get('isIDValue')) {
          var raw = this.get('raw');
          date = (0, _moment.default)(raw, _moment.default.ISO_8601);
        }
      }
      return date;
    }).readOnly(),
    /**
     * Availability settings value
     *
     * @type {String|undefined}  cf api/application/settings - setting.availability
     * @readOnly
     */
    value: Ember.computed('raw', 'i18n._locale', function () {
      var value;
      if (this.get('isDefined')) {
        var raw = this.get('raw');
        if (this.get('isIDValue')) {
          if (raw === -1) {
            return raw;
          }
          if (this.get('settings').customer.hasOwnProperty('availability')) {
            var availabilities = this.get('settings').customer.availability.find(function (availability) {
              return availability.id === raw;
            });
            if (availabilities && typeof availabilities.value !== 'undefined') {
              value = availabilities.value;
            }
          }
        }
      }
      return value;
    }).readOnly()
  });
  function initialize(application) {
    application.register('availability:main', Availability, {
      singleton: false
    });
  }
  var _default = _exports.default = {
    name: 'availability',
    initialize: initialize
  };
});