define("boondmanager/pods/private/apps/app/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "15sNk9M/",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[32,0,[\"model\",\"isIFrameCompatible\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-app/component/iframe\",[],[[\"@app\",\"@fn\",\"@currentUrl\",\"@keywords\",\"@emailingType\",\"@fromRecords\",\"@toRecords\"],[[32,0,[\"model\"]],[32,0,[\"fn\"]],[32,0,[\"currentUrl\"]],[32,0,[\"keywords\"]],[32,0,[\"emailingType\"]],[32,0,[\"fromRecords\"]],[32,0,[\"toRecords\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"if\"]}",
    "moduleName": "boondmanager/pods/private/apps/app/template.hbs"
  });
});