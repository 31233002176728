define("boondmanager/utils/url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.hasNoBlankTarget = hasNoBlankTarget;
  _exports.isInternal = isInternal;
  /**
    @module boondmanager/utils/url
  */

  /**
   * isInternal check if url is an url in the application
   *
   * @method isInternal
   * @for boondmanager/utils/url
   * @static
   * @param  {String} url  url string toi check
   * @return {Boolean} true if it is an internal url
   * @public
   */
  function isInternal(url) {
    return url.indexOf(document.location.origin) === 0;
  }

  /**
   * hasNoBlankTarget check if DOM element has a no "target" attribut OR target != "_blank"
   *
   * @method hasNoBlankTarget
   * @for boondmanager/utils/url
   * @static
   * @param  {DOM} el  DOM element to check
   * @return {Boolean} true if DOM element has no target attribut or target != "_blank"
   * @public
   */
  function hasNoBlankTarget(el) {
    var attr = el.attributes.el;
    return !attr || attr.value !== '_blank';
  }
  var _default = _exports.default = {
    isInternal: isInternal,
    hasNoBlankTarget: hasNoBlankTarget
  };
});