define("boondmanager/models/todelete/alertfragment", ["exports", "ember-data", "ember-data-model-fragments", "boondmanager/utils/alerts", "boondmanager/utils/bm", "boondmanager/models/alert"], function (_exports, _emberData, _emberDataModelFragments, _alerts, _bm, _alert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.AlertFragment = void 0;
  var ObjProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
  var AlertFragment = _exports.AlertFragment = _emberDataModelFragments.default.Fragment.extend({
    settings: Ember.inject.service('settings'),
    perimeters: Ember.inject.service(),
    originalID: _emberData.default.attr('number'),
    module: _emberData.default.attr('string'),
    indicator: _emberData.default.attr('string'),
    isDaily: _emberData.default.attr('boolean'),
    isWeekly: _emberData.default.attr('boolean'),
    isAdministrator: _emberData.default.attr('boolean'),
    canReadAlert: _emberData.default.attr('boolean'),
    canWriteAlert: _emberData.default.attr('boolean'),
    state: _emberData.default.attr('number'),
    originalState: _emberData.default.attr('number'),
    params: _emberData.default.attr(),
    /**
     * Permet de définir si la card est en mode lecture ou édition : affichage différent de la card selon l'état
     */
    edition: false,
    /**
     * Copie de l'alerte en cours de modification, utilisé pour annuler les modifications de l'utilisateur
     */
    originalAlert: undefined,
    /**
     * Liste des rapports dont l'alerte doit faire l'objet
     */
    reports: Ember.computed('isDaily', 'isWeekly', {
      get: function get() {
        var reports = [];
        if (this.get('isDaily')) reports.push(_alert.REPORT_TYPE_DAILY);
        if (this.get('isWeekly')) reports.push(_alert.REPORT_TYPE_WEEKLY);
        return reports;
      },
      set: function set(key, value) {
        this.set('isDaily', value.includes(_alert.REPORT_TYPE_DAILY));
        this.set('isWeekly', value.includes(_alert.REPORT_TYPE_WEEKLY));
        return value;
      }
    }),
    /**
     * Récupération de la liste des alertes en fonction du module sélectionné
     * @returns {{id: string, value: *}}
     */
    indicatorsList: Ember.computed('i18n._locale', 'module', function () {
      var _this$get$bm$alerts$i;
      return (_this$get$bm$alerts$i = this.get('settings').bm.alerts.indicators[this.get('module')]) !== null && _this$get$bm$alerts$i !== void 0 ? _this$get$bm$alerts$i : [];
    }).readOnly(),
    alertState: Ember.computed('state', 'originalState', 'isAdministrator', function () {
      if (this.get('isAdministrator')) {
        return this.get('state') === _alert.ALERT_STATE_DEFAULT ? this.get('originalState') : this.get('state');
      } else {
        return this.get('state');
      }
    }).readOnly(),
    isAlertStateInactive: Ember.computed('alertState', function () {
      return this.get('alertState') === _alert.ALERT_STATE_INACTIVE;
    }).readOnly(),
    originalStateValue: Ember.computed('originalState', 'i18n._locale', function () {
      return this.get('i18n').t('models:alert.attributes.state.values', {
        returnObjects: true
      }).findBy('id', this.get('originalState')).value;
    }).readOnly(),
    /**
     * Retourne un objet contenant le type de chaque paramètre
     */
    indicatorFields: Ember.computed('indicator', function () {
      return (0, _alerts.getFieldListFromIndicator)(this.get('indicator'));
    }).readOnly(),
    fieldsListAdmin: Ember.computed('i18n._locale', 'perimeter', 'indicator', 'indicatorFields', 'params', function () {
      return (0, _alerts.buildFieldList)(this.get('i18n'), this.get('perimeter'), this.get('indicator'), this.get('indicatorFields'), this.get('params'), true);
    }).readOnly(),
    /**
     * Retourne un tableau contenant la liste des paramètres.
     * Un wormhole est utilisé pour ajouter les inputs/selects côté template afin de pouvoir saisir les valeurs de ces paramètres
     */
    fieldsList: Ember.computed('i18n._locale', 'perimeter', 'indicator', 'indicatorFields', 'params', function () {
      return (0, _alerts.buildFieldList)(this.get('i18n'), this.get('perimeter'), this.get('indicator'), this.get('indicatorFields'), this.get('params'), false);
    }).readOnly(),
    /**
     * Construit la description de l'alerte en prenant en compte les paramètres
     */
    description: Ember.computed('i18n._locale', 'perimeter', 'indicator', 'indicatorFields', 'params', function () {
      return (0, _alerts.buildDescription)(this.get('i18n'), this.get('perimeter'), this.get('indicator'), this.get('indicatorFields'), this.get('params'));
    }).readOnly(),
    /**
     * Construit la description de l'alerte en prenant en compte les paramètres
     */
    paramsDescription: Ember.computed('i18n._locale', 'perimeter', 'indicator', 'fieldsList', 'isDaily', 'isWeekly', function () {
      var _this = this;
      var i18n = this.get('i18n');
      var promise = (0, _alerts.computeFieldLabel)(i18n, this.get('perimeter'), this.get('fieldsList'), this.get('indicator'), false).then(function (paramsDescription) {
        if (_this.get('isDaily') || _this.get('isWeekly')) {
          var reportsDescription = [];
          if (_this.get('isDaily')) {
            reportsDescription.push(i18n.t('alerts:view.reports.daily'));
          }
          if (_this.get('isWeekly')) {
            reportsDescription.push(i18n.t('alerts:view.reports.weekly'));
          }
          paramsDescription.push(i18n.t('alerts:view.fields.reportFrequency') + ' : ' + reportsDescription.join(', '));
        }
        return Ember.String.capitalize(paramsDescription.join('<br>'));
      });
      return ObjProxy.create({
        promise: promise
      });
    }).readOnly(),
    /**
     * Construit la description de l'alerte en prenant en compte les paramètres
     */
    paramsDescriptionAdmin: Ember.computed('i18n._locale', 'perimeter', 'indicator', 'fieldsListAdmin', 'isDaily', 'isWeekly', function () {
      var _this2 = this;
      var i18n = this.get('i18n');
      var promise = (0, _alerts.computeFieldLabel)(i18n, this.get('perimeter'), this.get('fieldsListAdmin'), this.get('indicator'), true).then(function (paramsDescription) {
        if (_this2.get('isDaily') || _this2.get('isWeekly')) {
          var reportsDescription = [];
          if (_this2.get('isDaily')) {
            reportsDescription.push(i18n.t('alerts:view.reports.daily'));
          }
          if (_this2.get('isWeekly')) {
            reportsDescription.push(i18n.t('alerts:view.reports.weekly'));
          }
          paramsDescription.push(i18n.t('alerts:view.fields.reportFrequency') + ' : ' + reportsDescription.join(', '));
        }
        return Ember.String.capitalize(paramsDescription.join('<br>'));
      });
      return ObjProxy.create({
        promise: promise
      });
    }).readOnly(),
    rightsModule: Ember.computed('indicator', function () {
      switch (this.get('indicator')) {
        /**
         * Actions
         **/
        case _alert.INDICATOR_ACTIONSOFTHEDAY:
        case _alert.INDICATOR_ACTIONSLATE:
        case _alert.INDICATOR_ACTIONSUPCOMING:
          return _bm.MODULE_ACTIONS;
        /**
         * Activités et frais
         **/
        case _alert.INDICATOR_TIMESREPORTSWITHNOVALIDATION:
          return _bm.MODULE_TIMESREPORTS;
        case _alert.INDICATOR_EXPENSESREPORTSWITHNOVALIDATION:
          return _bm.MODULE_EXPENSESREPORTS;
        case _alert.INDICATOR_ABSENCESREPORTSWITHNOVALIDATION:
          return _bm.MODULE_ABSENCESREPORTS;
        /**
         * Candidats
         **/
        case _alert.INDICATOR_CANDIDATESWITHNOACTION:
        case _alert.INDICATOR_CANDIDATESNOTUPDATED:
          return _bm.MODULE_CANDIDATES;
        /**
         * Ressources
         **/
        case _alert.INDICATOR_RESOURCESWITHNOACTION:
        case _alert.INDICATOR_RESOURCESWITHNOCONTRACTS:
        case _alert.INDICATOR_RESOURCESWITHNOMORECONTRACTS:
        case _alert.INDICATOR_RESOURCESPROBATIONARYDATEUPCOMING:
        case _alert.INDICATOR_RESOURCESPROBATIONARYDATETODAY:
        case _alert.INDICATOR_RESOURCESPROBATIONARYDATETERMINATED:
        case _alert.INDICATOR_CONTRACTSENDEDUPCOMING:
        case _alert.INDICATOR_CONTRACTSENDEDTODAY:
        case _alert.INDICATOR_CONTRACTSENDED:
        case _alert.INDICATOR_RESOURCESWITHOVERLAPPEDCONTRACTS:
        case _alert.INDICATOR_RESOURCESWITHNOCONTRACTENDDATE:
        case _alert.INDICATOR_RESOURCESARCHIVEDWITHACTIVATEDINTRANET:
        case _alert.INDICATOR_RESOURCESWITHFOLLOWEDDOCUMENTS:
          return _bm.MODULE_RESOURCES;
        /**
         * Besoins
         **/
        case _alert.INDICATOR_OPPORTUNITIESWITHNOACTION:
          return _bm.MODULE_OPPORTUNITIES;
        /**
         * Positionnements
         **/
        case _alert.INDICATOR_POSITIONINGSNOTUPDATED:
          return _bm.MODULE_OPPORTUNITIES_POSITIONINGS;
        /**
         * Projets
         **/
        case _alert.INDICATOR_PROJECTSWITHNOACTION:
        case _alert.INDICATOR_PROJECTSENDEDUPCOMING:
        case _alert.INDICATOR_PROJECTSENDEDUPCOMINGORALREADYENDED:
        case _alert.INDICATOR_PROJECTSENDEDTODAY:
        case _alert.INDICATOR_PROJECTSENDED:
        case _alert.INDICATOR_DELIVERIESENDEDUPCOMING:
        case _alert.INDICATOR_DELIVERIESENDEDTODAY:
        case _alert.INDICATOR_DELIVERIESENDED:
        case _alert.INDICATOR_DELIVERIESNOTENTIRELYCOVEREDBYCONTRACTS:
          return _bm.MODULE_PROJECTS;
        /**
         * Facturation
         **/
        case _alert.INDICATOR_PROJECTSWITHNOORDERNOTARCHIVED:
        case _alert.INDICATOR_PROJECTSWITHSIGNEDTURNOVERGREATERTHANORDEREDTURNOVER:
        case _alert.INDICATOR_ORDERSWITHNOBILLINGDETAIL:
        case _alert.INDICATOR_ORDERSWITHNODELIVERYCORRELATED:
        case _alert.INDICATOR_ORDERSWITHINVOICEDTURNOVERGREATERTHANORDEREDTURNOVER:
        case _alert.INDICATOR_ORDERSWITHSCHEDULESAMOUNTDIFFERENTFROMORDEREDTURNOVER:
        case _alert.INDICATOR_INVOICESWITHNOACTION:
        case _alert.INDICATOR_INVOICESWITHDATESOFPAYMENTSUPCOMING:
        case _alert.INDICATOR_INVOICESWITHDATESOFPAYMENTSISTODAY:
        case _alert.INDICATOR_INVOICESWITHDATESOFPAYMENTSPAST:
        case _alert.INDICATOR_SCHEDULESPASTWITHNOINVOICE:
          return _bm.MODULE_BILLING_INVOICES;
      }
    }).readOnly(),
    /**
     * Retourne le périmètre associé au module sélectionné
     */
    perimeter: Ember.computed('perimeters', 'indicator', function () {
      return this.get('perimeters').getModule((0, _alerts.getPerimeterModule)(this.get('indicator')));
    }).readOnly(),
    canConfirm: Ember.computed('module', 'indicator', function () {
      return this.get('module') && this.get('indicator');
    }).readOnly()
  });
  var _default = _exports.default = AlertFragment;
});