define("boondmanager/pods/components/octane/bm-search-filters/fields/simple-date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "VtwiXaf0",
    "block": "{\"symbols\":[\"@filter\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n\\t\\t\"],[8,\"octane/bm-field\",[],[[\"@name\"],[[32,1,[\"label\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[8,\"octane/bm-field/date\",[[16,0,[32,1,[\"id\"]]]],[[\"@name\",\"@value\",\"@format\",\"@viewMode\",\"@onChange\"],[[32,1,[\"id\"]],[32,1,[\"modalValue\"]],[32,1,[\"displayFormat\"]],[32,1,[\"viewMode\"]],[32,0,[\"onChange\"]]]],null],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "boondmanager/pods/components/octane/bm-search-filters/fields/simple-date/template.hbs"
  });
});