define("boondmanager/utils/fragments/utils", ["exports", "@ember-data/store/-private", "@ember-data/store", "boondmanager/utils/fragments/fragment", "boondmanager/utils/fragments/fragment-array", "boondmanager/utils/fragments/array"], function (_exports, _private, _store, _fragment, _fragmentArray, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getData = getData;
  _exports.getDataForSet = getDataForSet;
  _exports.getDefaultValue = getDefaultValue;
  _exports.hasValue = hasValue;
  _exports.isFragmentTypeObject = isFragmentTypeObject;
  _exports.isInternalModelVersion = isInternalModelVersion;
  _exports.isRecordDataV1Version = isRecordDataV1Version;
  _exports.setData = setData;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  /**
   * Check if ember version use internal model
   * @returns {boolean}
   */
  function isInternalModelVersion() {
    var version = Ember.VERSION.split('.');
    return parseInt(version[0]) <= 3 || parseInt(version[0]) === 4 && parseInt(version[1]) < 7;
  }

  /**
   * Check if ember version use record data v1
   * @returns {boolean}
   */
  function isRecordDataV1Version() {
    var version = Ember.VERSION.split('.');
    return parseInt(version[0]) === 4 && parseInt(version[1]) >= 7 && parseInt(version[1]) <= 11;
  }

  /**
   * Copy of attr function. Used in base fragment model
   * @param record
   * @param options
   * @returns {*}
   */
  function getDefaultValue(record, options) {
    if (typeof options.defaultValue === 'function') {
      return options.defaultValue.apply(null, arguments);
    } else {
      var defaultValue = options.defaultValue;
      (false && !(_typeof(defaultValue) !== 'object' || defaultValue === null) && Ember.assert("Non primitive defaultValues are not supported because they are shared between all instances. If you would like to use a complex object as a default value please provide a function that returns the complex object.", _typeof(defaultValue) !== 'object' || defaultValue === null));
      return defaultValue;
    }
  }

  /**
   * Copy of attr function. Used in base fragment model.
   * @param internalModel
   * @param key
   * @returns {*}
   */
  function hasValue(internalModel, key) {
    return (0, _private.recordDataFor)(internalModel).hasAttr(key);
  }

  /**
   * Get data from record
   * @param model
   * @param key
   * @returns {*}
   */
  function getData(model, key) {
    if (isInternalModelVersion()) {
      var internalModel = model._internalModel;
      if (hasValue(internalModel, key)) {
        return internalModel.getAttributeValue(key);
      } else {
        return getDefaultValue(model, {}, key);
      }
    } else if (isRecordDataV1Version()) {
      if (model.isDestroyed || model.isDestroying) {
        return;
      }
      return (0, _private.recordDataFor)(model).getAttr((0, _store.recordIdentifierFor)(model), key);
    } else {
      if (model.isDestroyed || model.isDestroying) {
        return;
      }
      return (0, _private.peekCache)(model).getAttr((0, _store.recordIdentifierFor)(model), key);
    }
  }

  /**
   * Get data from record to set fragment
   * @param model
   * @param key
   * @param value
   * @returns {*}
   */
  function getDataForSet(model, key, value) {
    if (isInternalModelVersion()) {
      return value;
    } else if (isRecordDataV1Version()) {
      var identifier = (0, _store.recordIdentifierFor)(model);
      var recordData = (0, _store.storeFor)(model)._instanceCache.getRecordData(identifier);
      return recordData.getAttr(identifier, key);
    } else {
      (false && !(!model.currentState.isDeleted) && Ember.assert("Attempted to set '".concat(key, "' on the deleted record ").concat((0, _store.recordIdentifierFor)(model)), !model.currentState.isDeleted));
      var _identifier = (0, _store.recordIdentifierFor)(model);
      var cache = (0, _private.peekCache)(model);
      return cache.getAttr(_identifier, key);
    }
  }

  /**
   * Set data in record
   * @param model
   * @param key
   * @param value
   * @returns {*}
   */
  function setData(model, key, value) {
    if (isInternalModelVersion()) {
      return model._internalModel.setDirtyAttribute(key, value);
    } else if (isRecordDataV1Version()) {
      var identifier = (0, _store.recordIdentifierFor)(model);
      var recordData = (0, _store.storeFor)(model)._instanceCache.getRecordData(identifier);
      recordData.setAttr(identifier, key, value);
      if (!model.isValid) {
        var errors = model.errors;
        if (errors.get(key)) {
          errors.remove(key);
          model.currentState.cleanErrorRequests();
        }
      }
    } else {
      (false && !(!model.currentState.isDeleted) && Ember.assert("Attempted to set '".concat(key, "' on the deleted record ").concat((0, _store.recordIdentifierFor)(model)), !model.currentState.isDeleted));
      var _identifier2 = (0, _store.recordIdentifierFor)(model);
      var cache = (0, _private.peekCache)(model);
      cache.setAttr(_identifier2, key, value);
      if (!model.isValid) {
        var _errors = model.errors;
        if (_errors.get(key)) {
          _errors.remove(key);
          model.currentState.cleanErrorRequests();
        }
      }
    }
  }

  /**
   * Object is instance of fragment or not
   * @param obj
   * @returns {boolean}
   */
  function isFragmentTypeObject(obj) {
    return obj instanceof _fragment.default || obj instanceof _fragmentArray.default || obj instanceof _array.default;
  }
});