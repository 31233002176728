define("boondmanager/utils/preventBubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.preventBubleFromClasses = preventBubleFromClasses;
  _exports.preventBubleFromTags = preventBubleFromTags;
  function preventBubleFromClasses(event, whitelistedClasses) {
    var preventBubble = false;
    var path = event.path || event.composedPath && event.composedPath();
    if (!path) {
      //MBE Méthode pour IE pour récupérer le path
      path = [];
      var node = event.target;
      while (node !== document.body) {
        path.push(node);
        node = node.parentNode;
      }
    }
    path.forEach(function (element) {
      if (typeof element.classList !== 'undefined') {
        whitelistedClasses.forEach(function (klass) {
          if (element.classList.contains(klass)) {
            preventBubble = true;
          }
        });
      }
    });
    return preventBubble;
  }
  function preventBubleFromTags(event, whitelistedTags) {
    var preventBubble = false;
    var path = event.path || event.composedPath && event.composedPath();
    if (!path) {
      //MBE Méthode pour IE pour récupérer le path
      path = [];
      var node = event.target;
      while (node !== document.body) {
        path.push(node);
        node = node.parentNode;
      }
    }
    path.forEach(function (element) {
      if (typeof element.classList !== 'undefined') {
        whitelistedTags.forEach(function (tag) {
          if (element.tagName === tag) {
            preventBubble = true;
          }
        });
      }
    });
    return preventBubble;
  }
  var _default = _exports.default = {
    preventBubleFromClasses: preventBubleFromClasses,
    preventBubleFromTags: preventBubleFromTags
  };
});