define("boondmanager/pods/components/octane/bm-truncate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+vxeUcn0",
    "block": "{\"symbols\":[\"&default\",\"&attrs\",\"@namedBlocksInfo\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"bmc-truncate \",[32,0,[\"truncatedClass\"]]]]],[16,\"data-original-title\",[32,0,[\"truncatedAttribute\"]]],[17,2],[4,[38,1],[[32,0,[\"initEventListeners\"]]],null],[12],[2,\"\\n\"],[6,[37,0],[[30,[36,2],[[32,3],\"default\",[27,[32,1]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[14,0,\"bmc-truncate-text\"],[12],[2,\"\\n\\t\\t\\t\"],[18,1,null],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,0,[\"cssTruncating\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[14,0,\"bmc-truncate-text\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[32,0,[\"processedText\"]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\"],[1,[32,0,[\"processedText\"]]],[2,\"\\n\\t\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"did-insert\",\"-has-block\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-truncate/template.hbs"
  });
});