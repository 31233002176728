define("boondmanager/initializers/custom-inflector-rules", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize( /* application */
  ) {
    // ajout d'une règle spécifique pour dire au pluralize que synthesis et downloadCenter ne doivent pas être pluralisés
    // sinon ils deviennent syntheses et downloadCenters et ne correspondent plus aux modules backend
    _emberInflector.default.inflector.uncountable('synthesis');
    _emberInflector.default.inflector.uncountable('reportingsynthesis');
    _emberInflector.default.inflector.uncountable('downloadCenter');
    _emberInflector.default.inflector.uncountable('apps.wexceptionnalactivity');
    _emberInflector.default.inflector.uncountable('apps.wextractpayroll');
    _emberInflector.default.inflector.uncountable('apps.wplanproduction');
    _emberInflector.default.inflector.uncountable('apps.wpostproduction');
    _emberInflector.default.inflector.uncountable('apps.hrflow');
    _emberInflector.default.inflector.uncountable('apps.survey');
    _emberInflector.default.inflector.uncountable('apps.specialreporting');
    _emberInflector.default.inflector.uncountable('apps.saaseditor.search.reporting');
    _emberInflector.default.inflector.uncountable('apps.digitalworkplace');
  }
  var _default = _exports.default = {
    name: 'custom-inflector-rules',
    initialize: initialize
  };
});