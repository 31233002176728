define("boondmanager/helpers/i", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.i = i;
  function i(params) {
    var className = params[0];
    return new Ember.String.htmlSafe('<i class="' + className + '"></i>');
  }
  var _default = _exports.default = Ember.Helper.helper(i);
});