define("boondmanager/pods/components/octane/bm-admin-globaldata/section/action/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "vAHo0pOI",
    "block": "{\"symbols\":[\"__arg0\",\"@key\",\"@model\",\"@path\",\"&attrs\"],\"statements\":[[8,\"octane/bm-layout-profile/section\",[[24,0,\"bmc-admin-globaldata_section-action\"],[17,5],[4,[38,6],[[32,0,[\"refreshValue\"]],[32,0,[\"modelValue\"]]],null]],[[\"@opened\",\"@onOpened\",\"@namedBlocksInfo\"],[[32,0,[\"sectionOpened\"]],[32,0,[\"onSectionOpened\"]],[30,[36,5],null,[[\"header\",\"content\"],[0,0]]]]],[[\"default\"],[{\"statements\":[[6,[37,0],[[30,[36,3],[[32,1],\"header\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n\"],[2,\"\\t\\t\"],[1,[30,[36,1],[[30,[36,4],[[32,4],\".title\"],null]],null]],[2,\"\\n\\t\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[30,[36,3],[[32,1],\"content\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[8,\"octane/bm-field\",[],[[\"@name\",\"@icon\",\"@errors\"],[[30,[36,1],[\"components:bmAdminGlobaldataSectionAction.actions\"],null],[32,0,[\"userLanguageIcon\"]],[32,0,[\"errors\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[8,\"octane/bm-field/textarea\",[],[[\"@name\",\"@value\",\"@onChange\"],[\"text\",[32,0,[\"value\"]],[30,[36,2],[[32,0,[\"updateBasicTranslation\"]],[32,3],[32,2]],null]]],null],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,0],[[32,0,[\"notice\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"div\"],[14,0,\"bmc-legend\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"notice\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"bm-text-secondary\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[2,[32,0,[\"notice\"]]],[2,\"\\n\\t\\t\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"t\",\"fn\",\"-is-named-block-invocation\",\"concat\",\"hash\",\"did-update\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-admin-globaldata/section/action/template.hbs"
  });
});