define("boondmanager/pods/components/octane/bm-field/rate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "AH7PMqst",
    "block": "{\"symbols\":[\"&attrs\",\"@value\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"bmc-field-rate \",[30,[36,0],[[32,0,[\"disabled\"]],\"disabled\"],null]]]],[17,1],[4,[38,1],[[32,0,[\"onInsert\"]]],null],[4,[38,2],[[32,0,[\"onUpdateValue\"]],[32,2]],null],[12],[2,\"\\n\\t\"],[10,\"span\"],[14,0,\"bmc-field-rate-stars\"],[15,\"data-rank\",[32,0,[\"rateValue\"]]],[12],[2,\"\\n\\t\\t\"],[1,[30,[36,3],[\"rank\"],null]],[2,\"\\n\\t\\t\"],[1,[30,[36,3],[\"rank\"],null]],[2,\"\\n\\t\\t\"],[1,[30,[36,3],[\"rank\"],null]],[2,\"\\n\\t\\t\"],[1,[30,[36,3],[\"rank\"],null]],[2,\"\\n\\t\\t\"],[1,[30,[36,3],[\"rank\"],null]],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[8,\"octane/bm-field/input\",[[24,3,\"rate\"],[24,\"data-name\",\"rate\"]],[[\"@value\",\"@type\"],[[32,0,[\"rateValue\"]],\"hidden\"]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"did-insert\",\"did-update\",\"icon\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/rate/template.hbs"
  });
});