define("boondmanager/pods/components/octane/bm-render-icon/website/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "MC9NuWH1",
    "block": "{\"symbols\":[\"@website\",\"&attrs\"],\"statements\":[[11,\"span\"],[16,0,[31,[\"bmc-render-website-icon bmb-icon \",[30,[36,0],[[32,1],\"disabled\"],null],\" bm-tooltips\"]]],[24,\"data-html\",\"true\"],[16,\"data-original-title\",[30,[36,3],[[32,1],[30,[36,2],[[30,[36,1],[\"bmi-website\"],null],\"&nbsp;\",[32,1]],null]],null]],[17,2],[4,[38,4],[\"click\",[32,0,[\"external\"]]],null],[12],[2,\"\\n\\t\"],[1,[30,[36,1],[\"bmi-website\"],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"unless\",\"icon\",\"concat\",\"if\",\"on\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-render-icon/website/template.hbs"
  });
});