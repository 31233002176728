define("boondmanager/adapters/jobprogress", ["exports", "boondmanager/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    urlTemplate: '{+host}/api/architecture/jobs{?query*}'
  });
});