define("boondmanager/helpers/to-lower-case", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toLowerCase = toLowerCase;
  function toLowerCase(value) {
    if (typeof value[0] === 'string') {
      return value[0].toLowerCase();
    } else {
      return value[0];
    }
  }
  var _default = _exports.default = Ember.Helper.helper(toLowerCase);
});