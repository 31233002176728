define("boondmanager/pods/components/octane/bm-app/component/corporama/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "hx30wv4F",
    "block": "{\"symbols\":[\"__arg0\",\"&attrs\",\"@isIntegrated\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-app-corporama\"],[17,2],[12],[2,\"\\n\\t\"],[8,\"octane/bm-layout-profile/section\",[[24,\"data-name\",\"section-corporama\"],[17,2]],[[\"@withTitle\",\"@icon\",\"@namedBlocksInfo\"],[[32,3],\"bmi-app-corporama\",[30,[36,4],null,[[\"header\",\"content\"],[0,0]]]]],[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,2],[[32,1],\"header\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[30,[36,3],[\"corporama:title\"],null]],[2,\"\\n\\t\\t\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,2],[[32,1],\"content\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,0,[\"isFulfilled\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[10,\"iframe\"],[15,\"src\",[32,0,[\"corporamaUrl\"]]],[14,\"scrolling\",\"yes\"],[14,\"marginwidth\",\"0\"],[14,\"marginheight\",\"0\"],[14,\"frameborder\",\"0\"],[15,5,[32,0,[\"iFrameStyle\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\\t\"],[1,[30,[36,0],[\"bmi-spinner fa-2x fa-pulse\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"icon\",\"if\",\"-is-named-block-invocation\",\"t\",\"hash\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-app/component/corporama/template.hbs"
  });
});