define("boondmanager/pods/components/octane/bm-legend/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "vMACGTSW",
    "block": "{\"symbols\":[\"@legendIcon\",\"@title\",\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-legend\"],[17,3],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"displayTitle\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"h4\"],[14,0,\"bmc-legend-title\"],[12],[1,[30,[36,1],[[30,[36,0],[[32,1],[32,1],\"bmi-legend\"],null]],null]],[2,\" \"],[1,[30,[36,0],[[32,2],[32,2],[30,[36,2],[\"components:bmLegend.legend\"],null]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[10,\"div\"],[14,0,\"bm-text-secondary\"],[12],[2,\"\\n\\t\\t\"],[18,4,null],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"icon\",\"t\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-legend/template.hbs"
  });
});