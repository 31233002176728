define("boondmanager/pods/components/octane/bm-progress-bar/invoice/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "HmcjchCW",
    "block": "{\"symbols\":[\"@centered\",\"&attrs\",\"@model\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"bmc-progressbar \",[30,[36,0],[[32,0,[\"isFull\"]],\"bmc-progressbar_full\"],null],\" \",[30,[36,0],[[32,0,[\"isOver\"]],\"bmc-progressbar_over\"],null],\" \",[30,[36,0],[[32,1],\"bmc-progressbar-center\"],null]]]],[17,2],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"bmc-progressbar_infos\"],[12],[2,\"\\n\\t\\t\"],[10,\"span\"],[14,0,\"percentageaccomplish\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,1],[\"components:bmProgressBarInvoice.amountPayable\"],null]],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,2],[[32,0,[\"amountPayable\"]]],[[\"sign\",\"change\",\"agencyChange\"],[[32,3,[\"currencySymbolValue\"]],[32,3,[\"exchangeRate\"]],[32,3,[\"exchangeRateAgency\"]]]]]],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\\t\"],[10,\"div\"],[15,0,[31,[\"bmc-progressbar_bar \",[30,[36,0],[[30,[36,4],[[30,[36,3],[[32,0,[\"percentage\"]]],[[\"decimals\"],[2]]],100],null],\"bm-full\"],null]]]],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"bmc-progressbar_content\"],[12],[2,\"\\n\\t\\t\"],[10,\"span\"],[14,0,\"bmc-progressbar_progress\"],[15,5,[30,[36,6],[[30,[36,5],[\"width : \",[32,0,[\"percentage\"]],\"%\"],null]],null]],[12],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"t\",\"money\",\"number\",\"eq\",\"concat\",\"html-safe\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-progress-bar/invoice/template.hbs"
  });
});