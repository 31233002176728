define("boondmanager/adapters/errors/version-error", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.AdapterError.extend({
    name: 'VersionsMismatch',
    message: 'Front & Back versions mismatch. Please reload your page'
  });
});