define("boondmanager/pods/components/octane/bm-absence-account/synthesis/dashboard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ln5qaHgK",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-absence-account-synthesis bmc-absence-account-synthesis_dashboard\"],[17,1],[12],[2,\"\\n\\t\"],[10,\"p\"],[14,0,\"bm-paragraphe\"],[12],[2,\"\\n\\t\\t\"],[10,\"strong\"],[15,0,[30,[36,4],[\"bmc-absence-account_details-section-item-number \",[30,[36,2],[[30,[36,3],[[35,0,[\"delta\"]],0],null],\"bm-negative\",[30,[36,2],[[30,[36,1],[[35,0,[\"delta\"]],0],null],\"bm-positive\"],null]],null]],null]],[12],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,5],[[32,0,[\"summary\",\"delta\"]]],[[\"decimals\"],[2]]]],[2,\"\\n\\t\\t\"],[13],[10,\"br\"],[12],[13],[2,\"\\n\\t\\t\"],[1,[32,0,[\"summary\",\"name\"]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"summary\",\"gt\",\"if\",\"lt\",\"concat\",\"number\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-absence-account/synthesis/dashboard/template.hbs"
  });
});