define("boondmanager/pods/components/octane/bm-field/rate-per-kilometer-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FzUOCzjR",
    "block": "{\"symbols\":[\"__arg0\",\"__arg1\",\"item\",\"item\",\"@precision\",\"@sign\",\"&attrs\",\"@options\",\"@selected\",\"@onChange\",\"@disabled\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"bmc-field-rate-per-kilometer-type \",[32,0,[\"classNames\"]]]]],[24,\"data-name\",\"input-ratePerKilometerType\"],[17,7],[4,[38,5],[[32,0,[\"onInsert\"]]],null],[12],[2,\"\\n\\t\"],[8,\"octane/bm-field/select\",[],[[\"@options\",\"@selected\",\"@useObjects\",\"@onChange\",\"@disabled\",\"@required\",\"@namedBlocksInfo\"],[[32,8],[32,9],true,[32,10],[32,11],true,[30,[36,6],null,[[\"selected-item\",\"option\"],[1,1]]]]],[[\"default\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[32,1],\"selected-item\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[32,4,[\"name\"]]],[2,\" - \"],[1,[30,[36,0],[[32,4,[\"amount\"]]],[[\"sign\",\"precision\",\"change\"],[[32,6],[32,5],[32,4,[\"exchangeRate\"]]]]]],[2,\"/\"],[1,[30,[36,1],[\"components:bmFieldRatePerKilometerType.kilometers.abbr\"],null]],[2,\"\\n\\t\\t\"]],\"parameters\":[4]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[30,[36,3],[[32,1],\"option\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[32,3,[\"name\"]]],[2,\" - \"],[1,[30,[36,0],[[32,3,[\"amount\"]]],[[\"sign\",\"precision\",\"change\"],[[32,6],[32,5],[32,3,[\"exchangeRate\"]]]]]],[2,\"/\"],[1,[30,[36,1],[\"components:bmFieldRatePerKilometerType.kilometers.abbr\"],null]],[2,\"\\n\\t\\t\"]],\"parameters\":[3]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"money\",\"t\",\"let\",\"-is-named-block-invocation\",\"if\",\"did-insert\",\"hash\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/rate-per-kilometer-type/template.hbs"
  });
});