define("boondmanager/helpers/capitalize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.capitalize = capitalize;
  _exports.default = void 0;
  function capitalize(value) {
    var vString = '';
    if (typeof value[0] === 'string') vString = value[0];else if (value[0].toString) vString = value[0].toString();else return value[0];
    return vString.charAt(0).toUpperCase() + vString.slice(1);
  }
  var _default = _exports.default = Ember.Helper.helper(capitalize);
});