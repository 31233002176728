define("boondmanager/helpers/img-relative", ["exports", "boondmanager/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.imgRelative = imgRelative;
  function imgRelative(params) {
    var rootURL = _environment.default.rootURL.replace(/\/+$/, '');
    var src = (params[0] || '').replace(/^\/+/, '');
    var alt = params[1] ? " alt=\"".concat(params[1], "\"") : '';
    var classe = params[2] ? " class=\"".concat(params[2], "\"") : '';
    return new Ember.String.htmlSafe("<img src=\"".concat(rootURL, "/").concat(src, "\"") + alt + classe + '/>');
  }
  var _default = _exports.default = Ember.Helper.helper(imgRelative);
});