define("boondmanager/pods/components/octane/bm-tabs/section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "tkytdPV+",
    "block": "{\"symbols\":[\"&default\",\"@labelClass\",\"@elementId\",\"@name\",\"@inputAction\",\"&attrs\",\"@checked\",\"@namedBlocksInfo\"],\"statements\":[[11,\"section\"],[24,0,\"bmc-tabs_section\"],[16,1,[31,[\"section-\",[32,3]]]],[17,6],[12],[2,\"\\n\"],[6,[37,3],[[32,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"input\"],[14,3,\"sections\"],[15,1,[31,[[30,[36,1],[[32,4],[32,3]],null]]]],[14,\"checked\",\"\"],[14,4,\"radio\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\"],[11,\"input\"],[24,3,\"sections\"],[16,1,[31,[[30,[36,1],[[32,4],[32,3]],null]]]],[24,4,\"radio\"],[4,[38,2],[\"click\",[32,5]],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[30,[36,4],[[32,8],\"label\",false],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"label\"],[15,0,[31,[\"bmc-tabs_section-label \",[32,2]]]],[15,\"for\",[31,[[30,[36,1],[[32,4],[32,3]],null]]]],[15,\"data-name\",[30,[36,1],[\"label-\",[32,3]],null]],[12],[2,\"\\n\\t\\t\\t\"],[18,1,[[30,[36,0],[\"label\"],null]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[30,[36,4],[[32,8],\"article\",false],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"article\"],[14,0,\"bmc-tabs_section-article\"],[12],[2,\"\\n\\t\\t\\t\"],[18,1,[[30,[36,0],[\"article\"],null]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-named-block-invocation\",\"concat\",\"on\",\"if\",\"-has-block\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-tabs/section/template.hbs"
  });
});