define("boondmanager/initializers/fragment", ["exports", "boondmanager/utils/fragments/fragment", "@ember-data/serializer/json", "@ember-data/store", "ember-data/-private", "boondmanager/transforms/fragment"], function (_exports, _fragment, _json, _store, _private, _fragment2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    _store.default.reopen({
      createFragment: function createFragment(type, data) {
        return new _fragment.default(Ember.getOwner(this), type, data);
      },
      serializerFor: function serializerFor(modelName) {
        // this assertion is cargo-culted from ember-data TODO: update comment
        var owner = Ember.getOwner(this);
        var normalizedModelName = (0, _private.normalizeModelName)(modelName);
        var match = normalizedModelName.match(/^-bf-(fragmentarray|fragment|array)(?:\$([^$]+))?/);
        if (match) {
          // dans le cas des fragments / fragments array / array
          // on va rechercher dans un premier temps un serializer classique qui est directement lié à l'attribut
          var serializerName = match[2];
          var serializer = owner.lookup("serializer:".concat(serializerName));
          if (serializer !== undefined) return serializer;

          // s'il n'est pas trouvé, on va rechercher le serializer fragment dans le registry
          serializer = owner.lookup('serializer:-bf-fragment');
          if (serializer !== undefined) return serializer;

          // fallback sur le serializer standard
          return owner.lookup('serializer:-default');
        } else {
          return this._super.apply(this, arguments);
        }
      }
    });

    /**
     Override `Snapshot._attributes` to snapshot fragment attributes before they are
     passed to the `DS.Model#serialize`.
      @private
     */
    var oldSnapshotAttributes = Object.getOwnPropertyDescriptor(_private.Snapshot.prototype, '_attributes');
    Object.defineProperty(_private.Snapshot.prototype, '_attributes', {
      get: function get() {
        var attrs = oldSnapshotAttributes.get.call(this);
        if (attrs) {
          Object.keys(attrs).forEach(function (key) {
            var attr = attrs[key];
            // If the attribute has a `_createSnapshot` method, invoke it before the
            // snapshot gets passed to the serializer
            if (attr && typeof attr._createSnapshot === 'function') {
              attrs[key] = attr._createSnapshot();
            }
          });
        }
        return attrs;
      }
    });
    _json.default.reopen({
      /**
       * Enables fragment properties to have custom transforms based on the fragment
       * type, so that deserialization does not have to happen on the fly
       * @method transformFor
       * @private
       */
      transformFor: function transformFor(attributeType) {
        if (attributeType.indexOf('-bf-') !== 0) {
          return this._super.apply(this, arguments);
        }
        var owner = Ember.getOwner(this);
        var containerKey = "transform:".concat(attributeType);
        if (!owner.hasRegistration(containerKey)) {
          var match = attributeType.match(/^-bf-(fragmentarray|fragment|array)(?:\$([^$]+))?/);
          if (match) {
            var transformName = match[1];
            var transformClass = owner.factoryFor("transform:-bf-".concat(transformName));
            if (typeof transformClass !== 'undefined') {
              transformClass = transformClass.class;
            } else {
              transformClass = _fragment2.default;
            }
            owner.register(containerKey, transformClass);
          }
        }
        return owner.lookup(containerKey);
      },
      applyTransforms: function applyTransforms(typeClass, data) {
        var _this = this;
        var attributes = typeClass.attributes;
        typeClass.eachTransformedAttribute(function (key, typeClass) {
          if (data[key] === undefined) {
            return;
          }
          var transform = _this.transformFor(typeClass);
          var transformMeta = attributes.get(key);
          transform.type = transformMeta.type;
          transform.modelName = transformMeta.modelName;
          data[key] = transform.deserialize(data[key], transformMeta.options, data);
        });
        return data;
      }
    });
  }
  var _default = _exports.default = {
    initialize: initialize
  };
});