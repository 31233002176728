define("boondmanager/pods/components/octane/bm-sort/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "hVMzmw9s",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],[[30,[36,1],[[35,0],\" icon-on-right\"],null]],null]]],\"hasEval\":false,\"upvars\":[\"sortIcon\",\"concat\",\"icon\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-sort/template.hbs"
  });
});