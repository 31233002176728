define("boondmanager/helpers/imagify-username", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.imagifyUsername = imagifyUsername;
  function imagifyUsername(params) {
    var name = params[0].replace(/[^a-zA-Z ]/, '');
    var trigram = '';
    var parts = name.split(' ');
    if (parts.length >= 3) {
      trigram = parts[0].charAt(0).toUpperCase() + parts[1].charAt(0).toUpperCase() + parts[2].charAt(0).toUpperCase();
    } else if (parts.length >= 2) {
      trigram = parts[0].charAt(0).toUpperCase() + parts[1].substr(0, 2).toUpperCase();
    } else {
      trigram = parts[0].substr(0, 3).toUpperCase();
    }
    var charCodeSum = trigram.split().reduce(function (sum, v) {
      return sum = sum + v.charCodeAt(0);
    }, 0);
    var backgroundIds = ['#3598DC', '#32C5D2', '#36D7B7', '#BFBFBF', '#E08283', '#E87E04', '#F3C200', '#9B59B6'];
    var background = backgroundIds[charCodeSum % backgroundIds.length];
    return new Ember.String.htmlSafe("<span class=\"imagify-username\" style=\"background-color: ".concat(background, "\">").concat(trigram, "</span>"));
  }
  var _default = _exports.default = Ember.Helper.helper(imagifyUsername);
});