define("boondmanager/initializers/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  var ExtendedComponent = {
    loadAttrsHash: Ember.on('didReceiveAttrs', function /* attrs */
    () {
      var _this = this;
      if (this.get('attrsHash')) {
        var hash = this.get('attrsHash');
        Object.keys(hash).forEach(function (key) {
          _this.set(key, hash[key]);
        });
      }
    })
  };
  function initialize( /* application */
  ) {
    Ember.Component.reopen(ExtendedComponent);
  }
});