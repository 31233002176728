define("boondmanager/helpers/is-app-active", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    router: Ember.inject.service(),
    /**
     * @private
     * Find and outputs translated text.
     *
     * @param {Array} params - positional parameters passed to the helper.
     *   The 1st element may be the app
     * @return {Boolean}
     */
    compute: function compute(params) {
      var app = params[0];
      var currentRouteName = this.get('router.currentRouteName').split('.');
      return currentRouteName.length >= 3 && app && currentRouteName[0] === 'private' && currentRouteName[1] === 'apps' && (currentRouteName[2] === 'app' && this.get('router.currentRoute.params.id') === app.id || currentRouteName[2] === app.code);
    },
    refresh: Ember.observer('router.{currentRouteName,currentRoute.params.id}', function () {
      this.recompute();
    })
  });
});