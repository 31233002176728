define("boondmanager/pods/private/administrator/architecture/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "rzg4OwoG",
    "block": "{\"symbols\":[\"__arg0\"],\"statements\":[[8,\"octane/bm-layout-profile\",[],[[\"@withTabs\",\"@icon\",\"@title\",\"@namedBlocksInfo\"],[true,\"bmi-database\",[30,[36,4],[\"adminarchitecture:title\"],null],[30,[36,5],null,[[\"tabs\",\"content\"],[0,0]]]]],[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1],\"tabs\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[8,\"link-to\",[[24,0,\"nav-item\"],[24,\"data-name\",\"tab-architecture\"]],[[\"@route\"],[\"private.administrator.architecture.servers\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[30,[36,4],[\"adminarchitecture:view.tabs.architecture.title\"],null]],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\"],[8,\"link-to\",[[24,0,\"nav-item\"],[24,\"data-name\",\"tab-configuration\"]],[[\"@route\"],[\"private.administrator.architecture.configuration\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[30,[36,4],[\"adminarchitecture:view.tabs.configuration.title\"],null]],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\"],[8,\"link-to\",[[24,0,\"nav-item\"],[24,\"data-name\",\"tab-jobs\"]],[[\"@route\"],[\"private.administrator.architecture.jobs\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[30,[36,4],[\"adminarchitecture:view.tabs.jobs.title\"],null]],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1],\"content\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\\t\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"-is-named-block-invocation\",\"if\",\"t\",\"hash\"]}",
    "moduleName": "boondmanager/pods/private/administrator/architecture/template.hbs"
  });
});