define("boondmanager/helpers/time", ["exports", "boondmanager/utils/logger", "ember-moment/helpers/moment-format"], function (_exports, _logger, _momentFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  /**
   * Outputs HTML <time>.
   *
   * This helper require one parameter (params[0]) : the date.
   *
   * hash is optional:
   *   - "value"      : {string} content of <time></time>. Default is the date in format "hash.format"
   *   - "id"         : {string} "id" attribute
   *   - "format"     : {string} date format to display. Default 'L'
   *   - "formatAttr" : {string} "datetime" attribute format. Default 'YYYY-MM-DD'
   *   - "datetime"   : {boolean} Add (or not) "datetime" attribute. Default true
   *   - "pubdate"    : {boolean} Add (or not) "pubdate" attribute. Default false
   *   - "locale"     : {string}
   *   - "timezone"   : {string}
   *   - "allowEmpty" : {boolean} Allows an empty value. An empty string is returned. Default false
   *   - "capitalize" : {boolean} Capitalize value
   *
   * Example:
   *
   * ```handlebars
   * {{time item.startDate}}
   * {{time model.PRJ_DATEUPDATE value=(concate (moment-format model.PRJ_DATEUPDATE "DD/MM/YY") (t "common:at") (moment-format model.PRJ_DATEUPDATE "HH:mm"))}}
   * ```
   *
   * @public
   * @method time
   * @for Ember.Templates.helpers
   */
  var _default = _exports.default = _momentFormat.default.extend({
    i18n: Ember.inject.service(),
    compute: function compute(_ref, _ref2) {
      var _ref3 = _slicedToArray(_ref, 1),
        date = _ref3[0];
      var value = _ref2.value,
        id = _ref2.id,
        _ref2$format = _ref2.format,
        format = _ref2$format === void 0 ? 'DD/MM/YY' : _ref2$format,
        _ref2$formatAttr = _ref2.formatAttr,
        formatAttr = _ref2$formatAttr === void 0 ? 'YYYY-MM-DD' : _ref2$formatAttr,
        _ref2$datetime = _ref2.datetime,
        datetime = _ref2$datetime === void 0 ? true : _ref2$datetime,
        pubdate = _ref2.pubdate,
        locale = _ref2.locale,
        timezone = _ref2.timezone,
        _ref2$allowEmpty = _ref2.allowEmpty,
        allowEmpty = _ref2$allowEmpty === void 0 ? true : _ref2$allowEmpty,
        _ref2$html = _ref2.html,
        html = _ref2$html === void 0 ? false : _ref2$html,
        _ref2$capitalize = _ref2.capitalize,
        capitalize = _ref2$capitalize === void 0 ? false : _ref2$capitalize;
      var hackFormat = false;
      if (allowEmpty && !date) {
        return '';
      }
      if (typeof date === 'undefined') {
        _logger.default.error('Helper {{time}} without date param!');
        return '';
      }
      var attrs = [];
      if (id) {
        attrs.push("id=\"".concat(id, "\""));
      }
      if (datetime) {
        attrs.push("datetime=\"".concat(this._super([date, formatAttr], {
          locale: locale,
          timezone: timezone
        }), "\""));
      }
      if (pubdate) {
        attrs.push('pubdate');
      }

      // Format e is supposed to return day of week regarding of locale (eg: 0 for monday in fr-fr but 1 in en-en)
      // But it doesn't seem to work, maybe issue with timezone...
      // To fix this, we ask format E which is ISO week day, so regardless of locale, and then adapt it to locale
      if (format === 'e') {
        hackFormat = true;
        format = 'E';
      }
      var content = typeof value === 'undefined' ? this._super([date, format], {
        locale: locale,
        timezone: timezone
      }) : value;
      if (hackFormat) {
        // Format E is ISO day of week, which returns from 1 (monday) to 7 (sunday)
        var startDay = this.i18n.startOfWeekISO();
        // So if locale calendar doesn't start on monday (1) we end up with a mismatch between calendar header and body
        if (startDay !== 1) {
          // Offset between header start of week and ISO start of week
          var offset = 1 - startDay;
          content = parseInt(content);
          // In order to use % 7 we temporarly need to get back on scale from 0 to 6
          content -= 1;
          content = (content + offset) % 7;
          // Getting back on a 1 to 7 scale
          content += 1;
        }
      }
      if (capitalize) {
        content = content.charAt(0).toUpperCase() + content.slice(1);
      }
      return html ? Ember.String.htmlSafe("<time ".concat(attrs.join(' '), ">").concat(content, "</time>")) : content;
    }
  });
});