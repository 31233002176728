define("boondmanager/pods/components/octane/bm-calendar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "pRdQ0M2h",
    "block": "{\"symbols\":[\"day\",\"&default\",\"&attrs\",\"@namedBlocksInfo\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"klassNames\"]]],[17,3],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[32,4],\"default\",[27,[32,2]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"ul\"],[14,0,\"bmc-calendar_day-names\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"headerDays\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[10,\"li\"],[12],[1,[30,[36,0],[[32,1]],null]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-track-array\",\"each\",\"-has-block\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-calendar/template.hbs"
  });
});