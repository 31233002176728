define("boondmanager/pods/components/octane/bm-field/date/update/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "yAAWxMA7",
    "block": "{\"symbols\":[\"&attrs\",\"@labeltxt\"],\"statements\":[[11,\"div\"],[24,0,\"form-inline bmc-field-dateupdate\"],[17,1],[4,[38,0],[[32,0,[\"onInsert\"]]],null],[12],[2,\"\\n\\t\"],[10,\"label\"],[15,\"for\",[31,[[32,0,[\"name\"]],\"-\",[32,0,[\"elementId\"]]]]],[14,0,\"bmc-field-dateupdate_title\"],[12],[2,\"\\n\\t\\t\"],[1,[30,[36,2],[[32,2],[32,2],[30,[36,1],[\"components:filterDateUpdate.label\"],null]],null]],[2,\" \\n\\t\"],[13],[2,\"\\n\\n\\t\"],[10,\"time\"],[15,1,[31,[[32,0,[\"name\"]],\"-\",[32,0,[\"elementId\"]]]]],[15,\"data-name\",[30,[36,3],[\"elmt-\",[32,0,[\"name\"]]],null]],[15,\"datetime\",[32,0,[\"currentDateISO8601\"]]],[12],[1,[32,0,[\"currentDateLabel\"]]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"t\",\"if\",\"concat\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/date/update/template.hbs"
  });
});