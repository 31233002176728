define("boondmanager/utils/fallbackIfUndefined-computed-macro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = fallbackIfUndefinedComputedMacro;
  function fallbackIfUndefinedComputedMacro(fallback) {
    return Ember.computed({
      get: function get() {
        return fallback;
      } /*key*/,
      set: function set(key, value) {
        return typeof value === 'undefined' ? fallback : value;
      }
    });
  }
});