define("boondmanager/pods/components/octane/bm-high-charts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "97gTsK2Z",
    "block": "{\"symbols\":[\"@chartOptions\",\"@content\",\"&attrs\"],\"statements\":[[8,\"high-charts\",[[24,0,\"bmc-highcharts\"],[24,\"data-name\",\"component-high-chart\"],[17,3],[4,[38,0],[[32,0,[\"didInsert\"]]],null]],[[\"@chartOptions\",\"@content\"],[[32,1],[32,2]]],null]],\"hasEval\":false,\"upvars\":[\"did-insert\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-high-charts/template.hbs"
  });
});