define("boondmanager/pods/components/octane/bm-btn/save/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "8vsiAeSf",
    "block": "{\"symbols\":[\"@buttonLabel\",\"@model\",\"@buttonIcon\",\"@notPrimary\",\"@onClick\",\"@label\",\"&attrs\"],\"statements\":[[8,\"octane/bm-btn\",[[16,0,[30,[36,4],[[30,[36,1],[[32,0,[\"isStickyElement\"]],\"bmc-btn-save bmb-circle bmb-xlarge bmb-circle-border bmb-save \",\"bmc-btn-save bmb-rectangle bmb-rectangle-shadow \"],null],[30,[36,3],[[32,4],\"bmc-btn-activated\"],null]],null]],[24,\"data-name\",\"button-save\"],[16,\"data-original-title\",[30,[36,1],[[30,[36,5],[[32,0,[\"isDisabled\"]]],null],[32,6]],null]],[16,\"aria-label\",[30,[36,0],[\"common:actions.save\"],null]],[17,7]],[[\"@disabled\",\"@onClick\"],[[32,0,[\"isDisabled\"]],[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[1,[30,[36,2],[[32,3]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,2,[\"isSaving\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[1,[30,[36,2],[\"bmi-spinner fa-pulse\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\"],[1,[30,[36,2],[\"bmi-save\"],null]],[2,\"\\n\\t\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[6,[37,1],[[30,[36,5],[[32,0,[\"isStickyElement\"]]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t \"],[1,[32,1]],[2,\" \\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\"],[1,[30,[36,0],[\"common:actions.save\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"icon\",\"unless\",\"concat\",\"not\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-btn/save/template.hbs"
  });
});