define("boondmanager/pods/components/octane/bm-alerts/banking-account/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "lPf1kJCJ",
    "block": "{\"symbols\":[\"message\",\"@dataName\",\"@warnings\"],\"statements\":[[6,[37,3],[[32,3,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-alerts\",[[16,\"data-name\",[32,2]]],[[\"@type\"],[\"danger\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[10,\"p\"],[12],[1,[30,[36,0],[\"adminbankingconnections:view.no_agency\"],null]],[13],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"li\"],[14,0,\"bmc-alert_list-item\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"strong\"],[12],[1,[32,1,[\"bankName\"]]],[13],[2,\" - \"],[1,[32,1,[\"name\"]]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-alerts/banking-account/template.hbs"
  });
});