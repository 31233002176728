define("boondmanager/pods/components/octane/bm-app/buttons-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "O1znY7XZ",
    "block": "{\"symbols\":[\"app\",\"&attrs\",\"@apps\"],\"statements\":[[6,[37,6],[[32,3,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[11,\"div\"],[17,2],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"dropdown\"],[12],[2,\"\\n\\t\\t\\t\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-circle bmb-dropdown\"],[24,\"data-name\",\"button-apps\"],[24,\"data-toggle\",\"dropdown\"],[24,\"data-placement\",\"top\"],[16,\"data-original-title\",[30,[36,3],[\"apps:title\"],null]],[16,\"aria-label\",[30,[36,3],[\"apps:title\"],null]]],[[\"@iconOnly\"],[\"bmi-apps\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\"],[1,[30,[36,3],[\"apps:title\"],null]],[2,\"\\n\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"dropdown-menu dropdown-menu-right\"],[14,\"aria-labelledby\",\"dropdownMenuButton\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[8,\"link-to\",[[24,0,\"dropdown-item bmc-app-buttons-list-app\"]],[[\"@route\",\"@model\",\"@query\"],[\"private.apps.app\",[32,1,[\"id\"]],[30,[36,0],null,[[\"fn\",\"currentUrl\"],[[32,1,[\"fn\"]],[32,1,[\"currentUrl\"]]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[1,[30,[36,2],[[30,[36,1],[\"bmi-app-\",[32,1,[\"app\",\"code\"]]],null]],null]],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[1,[32,1,[\"name\"]]],[2,\"\\n\\t\\t\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"explicit-query-params\",\"concat\",\"icon\",\"t\",\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-app/buttons-list/template.hbs"
  });
});