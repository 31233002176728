define("boondmanager/helpers/lbl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.lbl = lbl;
  function lbl(params) {
    var libelle = params[0];
    return new Ember.String.htmlSafe('<span class="lbl" data-lbl="' + Ember.Handlebars.Utils.escapeExpression(libelle) + '">');
  }
  var _default = _exports.default = Ember.Helper.helper(lbl);
});