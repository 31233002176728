define("boondmanager/helpers/file-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.fileIcon = fileIcon;
  function fileIcon(params) {
    var name = params[0];
    var nameParts = name.split('.');
    var extension = nameParts[nameParts.length - 1].toLowerCase();
    switch (extension) {
      case 'png':
      case 'jpeg':
      case 'jpg':
      case 'bmp':
      case 'gif':
      case 'tiff':
      case 'svg':
      case 'webp':
        return 'image/' + extension;
      case 'txt':
      case 'json':
        return 'text/plain';
      case 'mpeg':
      case 'mp4':
      case 'avi':
        return 'video/' + extension;
      case 'ods':
        return 'application/vnd.oasis.opendocument.spreadsheet';
      case 'xls':
        return 'application/vnd.ms-excel';
      case 'xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case 'csv':
        return 'text/csv';
      case 'odt':
        return 'application/vnd.oasis.opendocument.text';
      case 'doc':
        return 'application/msword';
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'odp':
        return 'application/vnd.oasis.opendocument.presentation';
      case 'ppt':
        return 'application/vnd.ms-powerpoint';
      case 'pptx':
        return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      case 'wav':
        return 'audio/x-wav';
      case 'mp3':
        return 'audio/mpeg';
      case 'pdf':
        return 'application/pdf';
      case 'zip':
        return 'application/zip';
      case 'gz':
        return 'application/gzip';
    }
  }
  var _default = _exports.default = Ember.Helper.helper(fileIcon);
});