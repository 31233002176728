define("boondmanager/adapters/vendor", ["exports", "boondmanager/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    updateRecordUrlTemplate: '{+host}/api/vendor{?query*}',
    pathForType: function pathForType() {
      return 'vendor';
    },
    queryRecord: function queryRecord(store, type, query) {
      delete query.id;
      var url = this.buildURL('vendor', null, null, 'queryRecord', query);
      return this.ajax(url, 'GET').then(function (result) {
        return result;
      });
    }
  });
});