define("boondmanager/helpers/duration-format", ["exports", "moment", "ember-moment/utils/helper-compute", "ember-moment/helpers/-base"], function (_exports, _moment, _helperCompute, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    globalAllowEmpty: true,
    compute: (0, _helperCompute.default)(function (params) {
      this._super.apply(this, arguments);
      var duration = params[0];
      if (duration) {
        var type = params[1];
        if (type === 'hours') {
          var durationHours = Math.floor(duration.asHours());
          var durationMinutes = _moment.default.utc(duration.asMilliseconds()).format('mm');
          return "".concat(durationHours, "h").concat(durationMinutes);
        } else {
          return Math.round(duration.asDays());
        }
      }
    })
  });
});