define("boondmanager/pods/components/octane/bm-alerts/emailing/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Q//T/mTJ",
    "block": "{\"symbols\":[\"message\",\"@warnings\"],\"statements\":[[6,[37,4],[[32,2,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-alerts\",[],[[\"@type\"],[\"danger\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"li\"],[14,0,\"bmc-alert_list-item\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[1,[30,[36,2],null,[[\"code\"],[[32,1,[\"code\"]]]]]],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[32,1,[\"code\"]],\"appEmailingWrongAuthentication\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[8,\"link-to\",[[24,0,\"bmc-btn bmb-rectangle\"]],[[\"@route\",\"@model\"],[\"private.apps.wemailing.resource\",[32,0,[\"currentUser\",\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\\t\\t\\t\\t\\t\"],[1,[30,[36,0],[\"bmi-action-configuration\"],null]],[2,\" \"],[1,[30,[36,1],[\"settings:title\"],null]],[2,\"\\n\\t\\t\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"icon\",\"t\",\"warning\",\"eq\",\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-alerts/emailing/template.hbs"
  });
});