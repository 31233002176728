define("boondmanager/pods/components/octane/bm-view-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "leKDehRX",
    "block": "{\"symbols\":[\"view\",\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-view-selector\"],[17,2],[12],[2,\"\\n\"],[6,[37,10],[[30,[36,9],[[30,[36,9],[[32,0,[\"views\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[8,\"octane/bm-btn\",[[16,0,[30,[36,3],[\"bmb-rectangle bmb-rectangle-multiple\",[30,[36,2],[[30,[36,1],[[32,0,[\"currentView\"]],[32,1,[\"id\"]]],null],\" bmb-confirm\",\"\"],null]],null]],[16,\"data-name\",[30,[36,3],[\"view-selector-\",[32,1,[\"id\"]]],null]],[16,\"aria-label\",[30,[36,7],[[30,[36,2],[[30,[36,6],[[32,1,[\"app\"]],[30,[36,5],[[32,1,[\"app\",\"model\",\"isNative\"]]],null]],null],[32,1,[\"app\",\"model\",\"title\"]],[30,[36,0],[[32,1,[\"label\"]]],null]],null],[30,[36,2],[[32,1,[\"info\"]],[32,1,[\"info\"]]],null]],null]]],[[\"@onClick\"],[[30,[36,4],[[32,0,[\"onSwitch\"]],[32,1,[\"id\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[30,[36,8],[[32,1,[\"icon\"]]],null]],[2,\"\\n\"],[6,[37,2],[[30,[36,6],[[32,1,[\"app\"]],[30,[36,5],[[32,1,[\"app\",\"model\",\"isNative\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[10,\"span\"],[14,0,\"bmb-rectangle_label\"],[12],[1,[32,1,[\"app\",\"model\",\"title\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\\t\"],[10,\"span\"],[14,0,\"bmb-rectangle_label\"],[12],[1,[30,[36,0],[[32,1,[\"label\"]]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[32,1,[\"info\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[10,\"span\"],[14,0,\"bmb-rectangle_info\"],[12],[1,[32,1,[\"info\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"eq\",\"if\",\"concat\",\"fn\",\"not\",\"and\",\"or\",\"icon\",\"-track-array\",\"each\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-view-selector/template.hbs"
  });
});