define("boondmanager/utils/perimeter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildPerimeterValue = buildPerimeterValue;
  _exports.perimeterValueSeparator = _exports.defaultPerimeterTypes = void 0;
  /**
   * Default perimeter types (plural strings)
   *
   *  * 'agencies'
   *  * 'businessUnits'
   *  * 'managers'
   *  * 'poles'
   *
   * @type {String[]}
   */
  var defaultPerimeterTypes = _exports.defaultPerimeterTypes = ['agencies', 'businessUnits', 'poles', 'managers'];
  var perimeterValueSeparator = _exports.perimeterValueSeparator = '_';
  function buildPerimeterValue(id, type) {
    return "".concat(type).concat(perimeterValueSeparator).concat(id);
  }
});