define("boondmanager/helpers/number", ["exports", "numeral"], function (_exports, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.numberFormat = numberFormat;
  /**
   * Displays a number on which the rates provided have been applied
   *
   * This helper require two arguments :
   *   - 1st arg : the initial number value
   *
   * Other named args are optional :
   *   - 'precision'    : if precision === 0 or false, amount will be rounded (Math.round())
  
   * Example:
   *
   * ```handlebars
   * {{number 10}}
   * ```
   *
   * WARNING! we do not properly use "numeral"! We only use its formatting properties.
   * Numeral language/locale never change : "en" (english). But we redefine the
   * delimiters and currency symbol at each call of this helper :/. Numeral can not
   * display numbers with another currency symbol than the one defined in the current
   * language/locale definition (here we use only "en")...
   *
   * @public
   * @method number
   * @for Ember.Templates.helpers
   */
  var _default = _exports.default = Ember.Helper.extend({
    i18n: Ember.inject.service(),
    compute: function compute(params, hash) {
      return numberFormat(params, hash, this.get('i18n'));
    },
    refreshText: Ember.observer('i18n._locale', function () {
      this.recompute();
    })
  });
  /**
   * Number format (reusable in components or controllers)
   * @param params
   * @param hash
   * @param i18n
   * @returns {*|string}
   */
  function numberFormat(params, hash, i18n) {
    var value = params[0];
    var output = '';
    if (typeof value === 'undefined' || isNaN(value)) {
      output = '-';
    } else {
      var precision = hash.decimals || 0;
      var sign = hash.sign || '';
      var i18nKeyFormat = getNumberFormat(hash.numeral, 'default');
      var i18nKeyOptions;
      if (typeof precision === 'number' || typeof precision === 'boolean') {
        if (precision === 0 || precision === false) {
          i18nKeyFormat = getNumberFormat(hash.numeral, 'rounded');
        } else {
          i18nKeyFormat = getNumberFormat(hash.numeral, 'precision');
          i18nKeyOptions = {
            precision: '0'.repeat(precision)
          };
        }
      }
      var format = i18n.t(i18nKeyFormat, i18nKeyOptions);
      // avoid bad conversion of string value into number value with numeral
      value = Number(value);
      var n = !isEpsilon(value) ? (0, _numeral.default)(value) : (0, _numeral.default)(0);
      _numeral.default.localeData().delimiters = {
        decimal: i18n.t('common:numeral.delimiters.decimal'),
        //GRI common:number
        thousands: i18n.t('common:numeral.delimiters.thousands') //GRI common:number
      };

      // force currency symbol :/
      // cf https://github.com/adamwdraper/Numeral-js/issues/165
      if (sign !== '') {
        _numeral.default.localeData().currency = {
          symbol: sign.toString()
        };
      }
      output = n.format(format);
      if (hash.showEvolution) {
        output = (value > 0 ? '+' : '') + output;
      }
    }
    return output;
  }
  function isEpsilon(number) {
    return Math.abs(number) < 1e-6;
  }
  function getNumberFormat(type, precision) {
    var format, formats;
    switch (type) {
      case 'currency':
        formats = {
          default: 'common:numeral.currency.default',
          rounded: 'common:numeral.currency.rounded',
          precision: 'common:numeral.currency.precision'
        };
        format = formats[precision];
        break;
      case 'day':
        formats = {
          default: 'common:numeral.day.default',
          rounded: 'common:numeral.day.rounded',
          precision: 'common:numeral.day.precision'
        };
        format = formats[precision];
        break;
      case 'month':
        formats = {
          default: 'common:numeral.month.default',
          rounded: 'common:numeral.month.rounded',
          precision: 'common:numeral.month.precision'
        };
        format = formats[precision];
        break;
      case 'hour':
        formats = {
          default: 'common:numeral.hour.default',
          rounded: 'common:numeral.hour.rounded',
          precision: 'common:numeral.hour.precision'
        };
        format = formats[precision];
        break;
      case 'number':
        formats = {
          default: 'common:numeral.number.default',
          rounded: 'common:numeral.number.rounded',
          precision: 'common:numeral.number.precision'
        };
        format = formats[precision];
        break;
      case 'numberInput':
        formats = {
          default: 'common:numeral.numberInput.default',
          rounded: 'common:numeral.numberInput.rounded',
          precision: 'common:numeral.numberInput.precision'
        };
        format = formats[precision];
        break;
      case 'percentage':
        formats = {
          default: 'common:numeral.percentage.default',
          rounded: 'common:numeral.percentage.rounded',
          precision: 'common:numeral.percentage.precision'
        };
        format = formats[precision];
        break;
      default:
        formats = {
          default: 'common:numeral.number.default',
          rounded: 'common:numeral.number.rounded',
          precision: 'common:numeral.number.precision'
        };
        format = formats[precision];
        break;
    }
    return format;
  }
});