define("boondmanager/decorators/frozen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.frozen = frozen;
  function frozen(targetKey) {
    return function (target, key, descriptor) {
      var setup = function setup(instance) {
        Object.defineProperty(instance, key, {
          get: function get() {
            var changedAttributes = instance.changedAttributes();
            return instance.hasDirtyAttributes && changedAttributes[targetKey] ? changedAttributes[targetKey][0] : instance[targetKey];
          },
          configurable: true,
          enumerable: true
        });
      };
      if (!target.constructor._setupFrozen) {
        target.constructor._setupFrozen = true;
        var originalInit = target.constructor.prototype.init;
        target.constructor.prototype.init = function () {
          if (originalInit) {
            originalInit.apply(this, arguments);
          }
          setup(this);
        };
      }
      return descriptor;
    };
  }
});