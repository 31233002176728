define("boondmanager/pods/components/octane/bm-btn/notifications/noresult/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "A4Oauki7",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"bmc-layout-search-noresults\"],[12],[2,\"\\n\\t\"],[1,[30,[36,0],[\"bmi-no-notification\"],null]],[2,\"\\n\\t\"],[10,\"h2\"],[12],[1,[30,[36,1],[\"components:bmBtnNotificationsNoresult.noresult\"],null]],[13],[2,\"\\n\\t\"],[10,\"p\"],[12],[1,[30,[36,1],[\"components:bmBtnNotificationsNoresult.noresult_detail\"],null]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"icon\",\"t\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-btn/notifications/noresult/template.hbs"
  });
});