define("boondmanager/pods/private/administrator/appsdeveloped/app/sections/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "WrLfBL4N",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"disabled\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],null,[[\"to\"],[\"bml-content-action-button-save\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[8,\"octane/bm-btn/save\",[],[[\"@model\",\"@onClick\",\"@disabled\"],[[32,0,[\"model\"]],[32,0,[\"save\"]],[32,0,[\"disableSaveButton\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"octane/bm-sections-customized/edit/sections\",[],[[\"@model\",\"@errors\",\"@sections\"],[[32,0,[\"model\"]],[32,0,[\"sectionsErrors\"]],[32,0,[\"sections\"]]]],null]],\"hasEval\":false,\"upvars\":[\"ember-wormhole\",\"unless\"]}",
    "moduleName": "boondmanager/pods/private/administrator/appsdeveloped/app/sections/template.hbs"
  });
});