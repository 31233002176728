define("boondmanager/helpers/percentage", ["exports", "numeral"], function (_exports, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Displays a percentage
   *
   * This helper require one argument :
   *   - 1st arg : the number value (by default 1 => 100%)
   *
   * A second optional argument could be defined to set the max value.
   * The percentage is calculate like this : number value / max value.
   * The default max value is 1.
   *
   * Other named args are optional :
   *   - 'precision'    : if precision === 0 or false, value will be rounded (Math.round())
  
   * Example:
   *
   * ```handlebars
   * {{percentage 0.123}}
   * ```
   *
   * WARNING! we do not properly use "numeral"! We only use its formatting properties.
   * Numeral language/locale never change : "en" (english). But we redefine the
   * delimiters and currency symbol at each call of this helper :/. Numeral can not
   * display numbers with another currency symbol than the one defined in the current
   * language/locale definition (here we use only "en")...
   *
   * @public
   * @method mpercentage
   * @for Ember.Templates.helpers
   */
  var _default = _exports.default = Ember.Helper.extend({
    i18n: Ember.inject.service(),
    compute: function compute(params, hash) {
      var i18n = this.get('i18n');
      var value = params[0];
      var maxValue = params[1] || 1;
      var output = '';
      if (typeof value === 'undefined' || isNaN(value)) {
        output = '<span class="bm-percentage bm-text-danger">-</span>';
      } else {
        var precision = hash.precision;
        var i18nKeyRootFormat = 'common:numeral.percentage';
        var i18nKeyFormat = "".concat(i18nKeyRootFormat, ".default");
        var i18nKeyOptions;
        if (typeof precision === 'number' || typeof precision === 'boolean') {
          if (precision === 0 || precision === false) {
            i18nKeyFormat = "".concat(i18nKeyRootFormat, ".rounded");
          } else {
            i18nKeyFormat = "".concat(i18nKeyRootFormat, ".precision");
            i18nKeyOptions = {
              precision: '0'.repeat(precision)
            };
          }
        }
        var format = i18n.t(i18nKeyFormat, i18nKeyOptions);
        var n = (0, _numeral.default)(value / maxValue);

        // force currency symbol :/
        // cf https://github.com/adamwdraper/Numeral-js/issues/165
        _numeral.default.localeData().delimiters = {
          decimal: i18n.t('common:numeral.delimiters.decimal'),
          //GRI common:number
          thousands: i18n.t('common:numeral.delimiters.thousands') //GRI common:number
        };

        output = '<span class="bm-percentage">' + n.format(format) + '</span>';
      }
      return new Ember.String.htmlSafe(output);
    },
    refreshText: Ember.observer('i18n._locale', function () {
      this.recompute();
    })
  });
});