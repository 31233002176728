define("boondmanager/pods/components/octane/bm-field/tab-iframe/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FHzxvGuH",
    "block": "{\"symbols\":[\"&attrs\",\"@index\",\"@iframe\",\"@urlErrors\",\"@deleteIframe\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"row bmc-field_tab-iframe \",[32,0,[\"errorClass\"]],\" \",[32,0,[\"classNames\"]]]]],[17,1],[4,[38,0],[[32,0,[\"onInsert\"]]],null],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-12 col-xl-5\"],[12],[2,\"\\n\\t\\t\"],[8,\"octane/bm-field\",[],[[\"@name\",\"@noTitleOnHover\"],[[30,[36,1],[\"advmodcustomers:view.tabs.iFrames.title\"],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\\n\\t\\t\"],[8,\"octane/bm-field/input\",[[24,0,\"titleIframe\"],[24,\"maxlength\",\"30\"],[16,3,[30,[36,2],[\"titleIframe-\",[32,2]],null]],[16,\"data-name\",[30,[36,2],[\"titleIframe-\",[32,2]],null]]],[[\"@value\"],[[32,3,[\"title\"]]]],null],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\\t\"],[10,\"div\"],[14,0,\"col-12 col-xl-5\"],[12],[2,\"\\n\\t\\t\"],[8,\"octane/bm-field\",[],[[\"@errors\",\"@noTitle\"],[[32,4],true]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[8,\"octane/bm-field/input\",[[24,0,\"urlIframe\"],[16,3,[30,[36,2],[\"urlIframe-\",[32,2]],null]],[16,\"data-name\",[30,[36,2],[\"urlIframe-\",[32,2]],null]],[16,\"placeholder\",[30,[36,1],[\"advmodcustomers:view.tabs.iFrames.url\"],null]]],[[\"@value\"],[[32,3,[\"url\"]]]],null],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\\t\"],[10,\"div\"],[14,0,\"col-12 col-xl-2\"],[12],[2,\"\\n\\t\\t\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-icon\"],[24,\"data-name\",\"button-delete-tab-iframe\"],[16,\"data-original-title\",[30,[36,1],[\"common:actions.delete\"],null]],[16,\"aria-label\",[30,[36,1],[\"common:actions.delete\"],null]]],[[\"@iconOnly\",\"@onClick\"],[\"bmi-delete\",[30,[36,3],[[32,5],[32,2]],null]]],null],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"t\",\"concat\",\"fn\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/tab-iframe/template.hbs"
  });
});