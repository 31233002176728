define("boondmanager/helpers/to-string", ["exports", "boondmanager/utils/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toString = toString;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  /**
   * Output a string.
   *
   * This helper is usefull with string composable helpers like `capitalize` and
   * i18next `t` helper because `t` helper always return a SafeString object and
   * string composable helpers want a string!
   *
   * Some helpers do not need `to-string` because they implicitly convert our params:
   *   - concat
   *   - abbr
   *   ...
   *
   *
   * Example:
   *
   * ```handlebars
   * {{capitalize (to-string (t "i18nKey"))}}
   * ```
   *
   * @public
   * @method to-string
   * @for Ember.Templates.helpers
   */
  function toString(params /*, hash*/) {
    if (Ember.typeOf(params[0]) === 'string') {
      return params[0];
    } else if (_typeof(params[0]) === 'object' && params[1] === true) {
      return JSON.stringify(params[0], null, '\t');
    } else if (params[0].toString) {
      return params[0].toString();
    } else {
      _logger.default.warn("[Helper to-string] params[0] doesn't have toString() method! So return empty string...", params[0]);
      return '';
    }
  }
  var _default = _exports.default = Ember.Helper.helper(toString);
});