define("boondmanager/utils/recursiveSet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = recursiveSet;
  function buildDeepObject(key, value) {
    var path = key.split('.');
    var segment = path.shift();
    var r = {};
    Ember.set(r, segment, path.length ? buildDeepObject(path.join('.'), value) : value);
    return r;
  }
  function recursiveSet(object, key, value) {
    var path = key.split('.');
    var segment = path.shift();
    var subObject = Ember.get(object, segment);
    if (subObject) {
      recursiveSet(subObject, path.join('.'), value);
    } else {
      Ember.assign(object, buildDeepObject(key, value));
    }
  }
});