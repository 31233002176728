define("boondmanager/utils/string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.capitalize = void 0;
  _exports.keyString = keyString;
  _exports.removeDiacritics = removeDiacritics;
  // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
  function removeDiacritics(str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  /**
   * normalise une chaine => enleve les espace, les accents et la met en minuscule. Ex: Prénom -> prenom
   * @param {string} str
   * @return {string}
   */
  function keyString(str) {
    if (typeof str !== 'string') {
      return '';
    } else {
      return removeDiacritics(str).toLowerCase().replace(/[^a-z0-9_]/, '');
    }
  }

  /**
   * Si un '/' est présent dans la chaine, alors la fonction capitalize de ember va mettre en majuscule la lettre le suivant.
   * ex: prestation/achat devient Prestation/Achat
   * Hors, selon les cas, ce n'est pas ce qui est souhaité, et ce n'est pas conforme à la documentation de emberJs
   * https://wishgroupe.atlassian.net/browse/BS3-2707
   */
  var capitalize = _exports.capitalize = function capitalize(str) {
    var replacement = '#SLASH#';
    str = str.replaceAll('/', replacement);
    str = Ember.String.capitalize(str);
    str = str.replaceAll(replacement, '/');
    return str;
  };
  var _default = _exports.default = {
    capitalize: capitalize,
    removeDiacritics: removeDiacritics,
    keyString: keyString
  };
});