define("boondmanager/pods/components/octane/bm-alerts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "93x34mZH",
    "block": "{\"symbols\":[\"message\",\"&default\",\"@alertIcon\",\"&attrs\",\"@inline\",\"@onClose\",\"@namedBlocksInfo\"],\"statements\":[[11,\"div\"],[17,4],[16,0,[31,[\"bmc-alerts bmc-alerts-\",[32,0,[\"type\"]],\" alert fade show \",[30,[36,5],[[32,5],\"bmc-alerts_inline\"],null]]]],[24,\"role\",\"alert\"],[12],[2,\"\\n\"],[6,[37,5],[[32,6]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[8,\"octane/bm-btn\",[[24,\"data-name\",\"button-close-alert\"],[24,0,\"close\"]],[[\"@iconOnly\",\"@onClick\"],[\"bmi-close\",[32,0,[\"onClose\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,6],[[32,0,[\"noIcon\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[14,0,\"bmc-alert-icon-container\"],[12],[2,\"\\n\"],[6,[37,5],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[1,[30,[36,4],[[32,3]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\\t\"],[1,[30,[36,4],[[30,[36,3],[\"bmi-\",[35,2],\"-alert\"],null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,5],[[30,[36,7],[[32,7],\"default\",[27,[32,2]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"ul\"],[14,0,\"bmc-alert_list bmc-alert_list-default\"],[12],[2,\"\\n\\t\\t\\t\"],[18,2,null],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\"],[10,\"ul\"],[14,0,\"bmc-alert_list bmc-alert_list-default\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"alertMessages\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[10,\"li\"],[14,0,\"bmc-alert_list-item\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[2,[32,1]],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"type\",\"concat\",\"icon\",\"if\",\"unless\",\"-has-block\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-alerts/template.hbs"
  });
});