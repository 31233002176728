define("boondmanager/pods/components/octane/bm-search-filters/fields/period-actions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "WNiClEw2",
    "block": "{\"symbols\":[\"@filter\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,1,[\"hidden\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n\\t\\t\\t\"],[8,\"octane/bm-field\",[[24,0,\"bmc-field-action_periodActions\"]],[[\"@name\"],[[30,[36,0],[\"components:bmSearchFilters.periodActions\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\"],[8,\"octane/bm-field/select\",[[16,\"data-name\",[31,[\"input-\",[32,0,[\"name\"]]]]]],[[\"@addMissingSelected\",\"@required\",\"@multiple\",\"@options\",\"@selected\",\"@onChange\"],[true,[32,1,[\"required\"]],[32,0,[\"multiple\"]],[32,1,[\"optionsValues\"]],[32,1,[\"modalValue\"]],[32,0,[\"onChange\"]]]],null],[2,\"\\n\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"not\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-search-filters/fields/period-actions/template.hbs"
  });
});