define("boondmanager/pods/components/octane/bm-alerts/doctemplates/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6fV85RBC",
    "block": "{\"symbols\":[\"item\",\"message\",\"@warnings\"],\"statements\":[[6,[37,5],[[32,3,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-alerts\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"p\"],[12],[1,[30,[36,2],[\"doctemplates:templateWarning\"],[[\"name\",\"count\"],[[32,1,[\"name\"]],[32,1,[\"messages\",\"length\"]]]]]],[13],[2,\"\\n\\t\\t\\t\"],[10,\"ul\"],[14,0,\"bmc-alert_list\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,1,[\"messages\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\\t\\t\\t\"],[10,\"li\"],[14,0,\"bmc-alert_list-item\"],[12],[1,[30,[36,1],null,[[\"code\",\"variables\",\"count\"],[[32,2,[\"code\"]],[30,[36,0],[\", \",[32,2,[\"variables\"]]],null],[32,2,[\"variables\",\"length\"]]]]]],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"join\",\"warning\",\"t\",\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-alerts/doctemplates/template.hbs"
  });
});