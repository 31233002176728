define("boondmanager/pods/components/octane/bm-render-icon/phone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ODIyjPjK",
    "block": "{\"symbols\":[\"@phone2\",\"@phone1\",\"&attrs\"],\"statements\":[[11,\"a\"],[24,6,\"#\"],[16,0,[31,[\"bmc-render-phone-icon bmb-icon \",[30,[36,0],[[32,0,[\"hasNoPhone\"]],\"disabled\"],null],\" bm-tooltips\"]]],[24,\"rel\",\"noopener noreferrer nofollow\"],[24,\"data-html\",\"true\"],[16,\"data-original-title\",[30,[36,2],[[30,[36,0],[[32,2],[30,[36,2],[[30,[36,1],[\"bmi-phone\"],null],\"&nbsp;\",[32,2]],null]],null],[30,[36,0],[[30,[36,3],[[32,2],[32,1]],null],\"<br>\"],null],[30,[36,0],[[32,1],[30,[36,2],[[30,[36,1],[\"bmi-phone\"],null],\"&nbsp;\",[32,1]],null]],null]],null]],[17,3],[4,[38,4],[\"click\",[32,0,[\"callTo\"]]],null],[12],[2,\"\\n\\t\"],[1,[30,[36,1],[\"bmi-phone\"],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"icon\",\"concat\",\"and\",\"on\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-render-icon/phone/template.hbs"
  });
});