define("boondmanager/utils/errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getAttributeErrors = getAttributeErrors;
  function getAttributeErrors(errors, model, attributeName) {
    var _errors = {};
    var regex = new RegExp("/data/attributes/".concat(attributeName));
    errors.forEach(function (err) {
      if (err.source.pointer && err.source.pointer.match(regex)) {
        var attribute = err.source.pointer.split('/').at(-1);
        var idx = err.source.pointer.split('/').at(-2);
        if (!_errors[idx]) {
          _errors[idx] = {};
        }
        if (!_errors[idx][attribute]) {
          _errors[idx][attribute] = [];
        }
        _errors[idx][attribute].push(err);
      }
    });
    return _errors;
  }
});