define("boondmanager/utils/fragments/attributes", ["exports", "boondmanager/utils/fragments/fragment", "boondmanager/utils/fragments/fragment-array", "boondmanager/utils/fragments/array", "boondmanager/utils/fragments/utils"], function (_exports, _fragment, _fragmentArray, _array, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.array = array;
  _exports.fragment = fragment;
  _exports.fragmentArray = fragmentArray;
  /**
   * Fragment attribute for model
   * @param type
   * @param options
   * @returns {*}
   */
  function fragment(type) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return Ember.computed('isDestroyed', 'isDestroying', {
      get: function get(key) {
        if (false /* DEBUG */) {
          if (['_internalModel', 'recordData', 'currentState'].indexOf(key) !== -1) {
            throw new Error("'".concat(key, "' is a reserved property name on instances of classes extending Model. Please choose a different property name for your attr on ").concat(this.constructor.toString()));
          }
        }
        var data = (0, _utils.getData)(this, key);
        if (this._fragments[key] instanceof _fragment.default) {
          this._fragments[key].refreshData(data);
        } else {
          if (data === undefined && options.defaultValue !== undefined) {
            var defaultValue = options.defaultValue;
            if (typeof options.defaultValue === 'function') {
              defaultValue = defaultValue();
            } else {
              data = defaultValue;
            }
          }
          if (data !== null && !(data instanceof _fragment.default)) {
            this._fragments[key] = new _fragment.default(Ember.getOwner(this), type, data, options.keysToPOJO, this.isNew);
          }
        }
        if (this._fragments[key] instanceof _fragment.default) {
          this._fragments[key].setParent(this);
        }
        return this._fragments[key];
      },
      set: function set(key, value) {
        if (false /* DEBUG */) {
          if (['_internalModel', 'recordData', 'currentState'].indexOf(key) !== -1) {
            throw new Error("'".concat(key, "' is a reserved property name on instances of classes extending Model. Please choose a different property name for your attr on ").concat(this.constructor.toString()));
          }
        }
        var currentValue = (0, _utils.getDataForSet)(this, key, value);
        if (currentValue !== value || (0, _utils.isInternalModelVersion)()) {
          if (value !== null && !(value instanceof _fragment.default)) {
            value = new _fragment.default(Ember.getOwner(this), type, value, options.keysToPOJO, this.isNew);
          }
          if (value instanceof _fragment.default) {
            value.setParent(this);
          }
          (0, _utils.setData)(this, key, value);
        }
        return value;
      }
    }).meta({
      modelName: type,
      type: "-bf-fragment$".concat(type),
      isAttribute: true,
      isBFragment: true,
      fragmentType: 'fragment',
      options: options
    });
  }

  /**
   * Fragment array attribute for model
   * @param type
   * @param options
   * @returns {*}
   */
  function fragmentArray(type) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return Ember.computed('isDestroyed', 'isDestroying', {
      get: function get(key) {
        if (false /* DEBUG */) {
          if (['_internalModel', 'recordData', 'currentState'].indexOf(key) !== -1) {
            throw new Error("'".concat(key, "' is a reserved property name on instances of classes extending Model. Please choose a different property name for your attr on ").concat(this.constructor.toString()));
          }
        }
        var data = (0, _utils.getData)(this, key);
        if (this._fragments[key] instanceof _fragmentArray.default && data !== undefined) {
          if (this._fragments[key].length === 0) {
            this._fragments[key]._setContent(data);
          } else {
            this._fragments[key].refreshData(data);
          }
        } else {
          if (data === undefined && options.defaultValue !== undefined) {
            var defaultValue = options.defaultValue;
            if (typeof options.defaultValue === 'function') {
              defaultValue = defaultValue();
            }
            if (Ember.isArray(defaultValue)) {
              data = defaultValue;
            }
          }
          if (data !== null && !(this._fragments[key] instanceof _fragmentArray.default)) {
            this._fragments[key] = new _fragmentArray.default(Ember.getOwner(this), type, Ember.isArray(data) ? data : undefined, options.keysToPOJO, this.isNew);
          }
        }
        if (this._fragments[key] instanceof _fragmentArray.default) {
          this._fragments[key].setParent(this);
        }
        return this._fragments[key];
      },
      set: function set(key, value) {
        if (false /* DEBUG */) {
          if (['_internalModel', 'recordData', 'currentState'].indexOf(key) !== -1) {
            throw new Error("'".concat(key, "' is a reserved property name on instances of classes extending Model. Please choose a different property name for your attr on ").concat(this.constructor.toString()));
          }
        }
        var currentValue = (0, _utils.getDataForSet)(this, key, value);
        if (currentValue !== value || (0, _utils.isInternalModelVersion)()) {
          if (value !== null && !(value instanceof _fragmentArray.default)) {
            value = new _fragmentArray.default(Ember.getOwner(this), type, Ember.isArray(value) ? value : undefined, options.keysToPOJO, this.isNew);
          }
          if (value instanceof _fragmentArray.default) {
            value.setParent(this);
          }
          (0, _utils.setData)(this, key, value);
        }
        return value;
      }
    }).meta({
      modelName: type,
      type: "-bf-fragmentarray$".concat(type),
      isAttribute: true,
      isBFragment: true,
      fragmentType: 'fragmentarray',
      options: options
    });
  }

  /**
   * Array attribute for model
   * @param type
   * @param options
   * @returns {*}
   */
  function array(type) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return Ember.computed('isDestroyed', 'isDestroying', {
      get: function get(key) {
        if (false /* DEBUG */) {
          if (['_internalModel', 'recordData', 'currentState'].indexOf(key) !== -1) {
            throw new Error("'".concat(key, "' is a reserved property name on instances of classes extending Model. Please choose a different property name for your attr on ").concat(this.constructor.toString()));
          }
        }
        var data = (0, _utils.getData)(this, key);
        if (this._fragments[key] instanceof _array.default && this._fragments[key].length === 0 && data !== undefined) {
          this._fragments[key]._setContent(data);
        } else {
          if (data === undefined && options.defaultValue !== undefined) {
            var defaultValue = options.defaultValue;
            if (typeof options.defaultValue === 'function') {
              defaultValue = defaultValue();
            }
            if (Ember.isArray(defaultValue)) {
              data = defaultValue;
            }
          }
          if (data !== null && !(this._fragments[key] instanceof _array.default)) {
            this._fragments[key] = new _array.default(Ember.getOwner(this), type, Ember.isArray(data) ? data : undefined, options.keysToPOJO, this.isNew);
          }
        }
        if (this._fragments[key] instanceof _array.default) {
          this._fragments[key].setParent(this);
        }
        return this._fragments[key];
      },
      set: function set(key, value) {
        if (false /* DEBUG */) {
          if (['_internalModel', 'recordData', 'currentState'].indexOf(key) !== -1) {
            throw new Error("'".concat(key, "' is a reserved property name on instances of classes extending Model. Please choose a different property name for your attr on ").concat(this.constructor.toString()));
          }
        }
        var currentValue = (0, _utils.getDataForSet)(this, key, value);
        if (currentValue !== value || (0, _utils.isInternalModelVersion)()) {
          if (value !== null && !(value instanceof _array.default)) {
            value = new _array.default(Ember.getOwner(this), type, Ember.isArray(value) ? value : undefined, options.keysToPOJO, this.isNew);
          }
          if (value instanceof _array.default) {
            value.setParent(this);
          }
          (0, _utils.setData)(this, key, value);
        }
        return value;
      }
    }).meta({
      modelName: type,
      type: "-bf-array$".concat(type),
      isAttribute: true,
      isBFragment: true,
      fragmentType: 'array',
      options: options
    });
  }
});