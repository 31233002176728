define("boondmanager/pods/components/octane/bm-search-filters/fields/hr-separation-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "4kvacgYS",
    "block": "{\"symbols\":[],\"statements\":[[10,\"hr\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "boondmanager/pods/components/octane/bm-search-filters/fields/hr-separation-filter/template.hbs"
  });
});