define("boondmanager/pods/components/octane/bm-field/emailing/template/activity-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Ul2FL+Ru",
    "block": "{\"symbols\":[\"&attrs\",\"@value\",\"@disabled\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-field-activity-content\"],[17,1],[4,[38,0],[[32,0,[\"onInsert\"]]],null],[12],[2,\"\\n\\t\"],[8,\"octane/bm-field/tinymce\",[],[[\"@value\",\"@onChange\",\"@options\",\"@disabled\"],[[32,2],[32,0,[\"onValueChanged\"]],[32,0,[\"options\"]],[32,3]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/emailing/template/activity-content/template.hbs"
  });
});