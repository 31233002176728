define("boondmanager/pods/components/octane/bm-btn/tasks/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2j9BIvmU",
    "block": "{\"symbols\":[\"@onTodolistClick\"],\"statements\":[[6,[37,0],[[32,0,[\"tasksService\",\"hasTasks\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,0,[\"tasksService\",\"pendingTasksCount\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[8,\"octane/bm-btn\",[[24,0,\"bm-chip\"],[16,\"data-original-title\",[30,[36,2],[\"components:bmBtnTasks.todolist.title_pending\"],[[\"count\"],[[32,0,[\"tasksService\",\"pendingTasksCount\"]]]]]],[24,\"data-name\",\"button-pending-actions-count\"],[16,\"aria-label\",[30,[36,2],[\"components:bmBtnTasks.tasks\"],[[\"count\"],[[32,0,[\"tasksService\",\"pendingTasksCount\"]]]]]]],[[\"@onClick\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[30,[36,2],[\"components:bmBtnTasks.tasks\"],[[\"count\"],[[32,0,[\"tasksService\",\"pendingTasksCount\"]]]]]],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\"],[8,\"octane/bm-btn\",[[16,0,[30,[36,1],[\"bm-chip\",[30,[36,0],[[32,0,[\"tasksService\",\"isTodolistDisplayed\"]],\" bm-chip_validated\"],null]],null]],[16,\"data-original-title\",[30,[36,2],[\"components:bmBtnTasks.todolist.title_validated\"],[[\"count\"],[[32,0,[\"tasksService\",\"totalTasksCount\"]]]]]],[24,\"data-name\",\"button-pending-actions-count\"]],[[\"@iconOnly\",\"@onClick\"],[\"bmi-check\",[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\",\"t\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-btn/tasks/template.hbs"
  });
});