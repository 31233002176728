define("boondmanager/pods/components/octane/bm-field/input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+lZpEf3b",
    "block": "{\"symbols\":[\"@value\",\"@type\",\"@maxFitLength\",\"@placeholder\",\"&attrs\",\"@checked\"],\"statements\":[[6,[37,3],[[32,0,[\"isCheckbox\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[8,\"input\",[[16,0,[32,0,[\"inputClass\"]]],[16,\"placeholder\",[32,4]],[16,\"required\",[32,0,[\"required\"]]],[16,\"disabled\",[32,0,[\"disabled\"]]],[17,5],[4,[38,0],[\"focusin\",[32,0,[\"focusIn\"]]],null],[4,[38,0],[\"focusout\",[32,0,[\"focusOut\"]]],null],[4,[38,0],[\"keyup\",[32,0,[\"keyUp\"]]],null],[4,[38,0],[\"keydown\",[32,0,[\"keyDown\"]]],null],[4,[38,0],[\"change\",[32,0,[\"onChange\"]]],null],[4,[38,1],[[32,0,[\"onInsert\"]]],null],[4,[38,2],[[32,0,[\"onUpdate\"]],[32,6]],null]],[[\"@checked\",\"@type\"],[[32,6],\"checkbox\"]],null]],\"parameters\":[]},{\"statements\":[[8,\"input\",[[16,0,[32,0,[\"inputClass\"]]],[16,\"placeholder\",[32,4]],[16,\"required\",[32,0,[\"required\"]]],[16,\"disabled\",[32,0,[\"disabled\"]]],[17,5],[16,\"autofocus\",[32,0,[\"autofocus\"]]],[4,[38,0],[\"click\",[32,0,[\"click\"]]],null],[4,[38,0],[\"focusin\",[32,0,[\"focusIn\"]]],null],[4,[38,0],[\"focusout\",[32,0,[\"focusOut\"]]],null],[4,[38,0],[\"keyup\",[32,0,[\"keyUp\"]]],null],[4,[38,0],[\"keydown\",[32,0,[\"keyDown\"]]],null],[4,[38,0],[\"change\",[32,0,[\"onChange\"]]],null],[4,[38,1],[[32,0,[\"onInsert\"]]],null],[4,[38,2],[[32,0,[\"onUpdate\"]],[32,1]],null]],[[\"@value\",\"@type\",\"@maxFitLength\"],[[32,1],[32,2],[32,3]]],null]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"on\",\"did-insert\",\"did-update\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/input/template.hbs"
  });
});