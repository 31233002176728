define("boondmanager/utils/logger", ["exports", "boondmanager/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * To use it in Ember, import it:
   *
   * ```
   * // ex. in app/routes/index.js
   * import Controller from '@ember/controller';
   * import Logger from '../utils/logger';
   *
   * export default Controller.extend({
   *   init() {
   *     Logger.debug('Init index controller.');
   *   }
   * });
   * ```
   *
   */
  var Logger = {
    /**
     * Force all console's methods to shut up ;p
     *
     * This property takes precedence over all other.
     *
     * @property shutUp
     * @type {Boolean}
     * @default  false
     */
    shutUp: false,
    /**
     * Force (de)activation of `Logger.log`
     *
     * This property takes precedence over `Ember.ENV.console.log`.
     * If this property and `Ember.ENV.console.log` are `undefined`,
     * `Logger.log` is ON only in 'development' environment.
     *
     * @property forceLogOn
     * @type {Boolean|undefined}
     * @default  undefined
     */
    /**
     * Force (de)activation of `Logger.warn`
     *
     * This property takes precedence over `Ember.ENV.console.warn`.
     * If this property and `Ember.ENV.console.warn` are `undefined`,
     * `Logger.warn` is ON.
     *
     * @property forceWarnOn
     * @type {Boolean|undefined}
     * @default  undefined
     */
    /**
     * Force (de)activation of `Logger.error`
     *
     * This property takes precedence over `Ember.ENV.console.error`
     * If this property and `Ember.ENV.console.error` are `undefined`,
     * `Logger.error` is ON.
     *
     * @property forceErrorOn
     * @type {Boolean|undefined}
     * @default  undefined
     */
    /**
     * Force (de)activation of `Logger.info`
     *
     * This property takes precedence over `Ember.ENV.console.info`
     * If this property and `Ember.ENV.console.info` are `undefined`,
     * `Logger.info` is ON only in 'development' environment.
     *
     * @property forceInfoOn
     * @type {Boolean|undefined}
     * @default  undefined
     */
    /**
     * Force (de)activation of `Logger.debug`
     *
     * This property takes precedence over `Ember.ENV.console.debug`
     * If this property and `Ember.ENV.console.debug` are `undefined`,
     * `Logger.debug` is ON only in 'development' environment.
     *
     * @property forceDebugOn
     * @type {Boolean|undefined}
     * @default  undefined
     */
    /**
     * Force (de)activation of `Logger.assert`
     *
     * This property takes precedence over `Ember.ENV.console.assert`
     * If this property and `Ember.ENV.console.assert` are `undefined`,
     * `Logger.assert` is ON.
     *
     * @property forceAssertOn
     * @type {Boolean|undefined}
     * @default  undefined
     */
    /**
     * To know if Logger.force${Method}On is `true` or `false`. If `true`, Logger.${method}
     * is ON else if `false`, it is (forced to) OFF!
     *
     * @method  _isForced
     * @for Logger
     * @param  {string}  method method's name
     * @return {Boolean}
     * @private
     */
    _isForced: function _isForced(method) {
      return typeof Logger[Ember.String.camelize("force-".concat(method, "-on"))] !== 'undefined';
    },
    /**
     * To know if Ember.ENV.console.${method} is `true` or `false`. If `true`, Logger.${method}
     * is ON else if `false`, it is OFF. This state can be overrided by setting `this.force${Method}On`
     * property!
     *
     * @method  _isConfigured
     * @for Logger
     * @param  {String}  method method's name
     * @return {Boolean}
     * @private
     */
    _isConfigured: function _isConfigured(method) {
      return typeof _environment.default.console !== 'undefined' && typeof _environment.default.console[method] !== 'undefined';
    },
    /**
     * To know if ${method} method is active or not.
     *
     * @method  isActive
     * @param  {String}  method method's name
     * @return {Boolean}
     * @public
     */
    isActive: function isActive(method) {
      var isActive;
      var onlyInDevMode = _environment.default.environment === 'development';
      switch (method) {
        case 'log':
          isActive = Logger._isForced(method) ? Logger[method] : Logger._isConfigured(method) ? _environment.default.console[method] : onlyInDevMode;
          break;
        case 'warn':
          isActive = Logger._isForced(method) ? Logger[method] : Logger._isConfigured(method) ? _environment.default.console[method] : true;
          break;
        case 'error':
          isActive = Logger._isForced(method) ? Logger[method] : Logger._isConfigured(method) ? _environment.default.console[method] : true;
          break;
        case 'info':
          isActive = Logger._isForced(method) ? Logger[method] : Logger._isConfigured(method) ? _environment.default.console[method] : onlyInDevMode;
          break;
        case 'debug':
          isActive = Logger._isForced(method) ? Logger[method] : Logger._isConfigured(method) ? _environment.default.console[method] : onlyInDevMode;
          break;
        case 'assert':
          isActive = Logger._isForced(method) ? Logger[method] : Logger._isConfigured(method) ? _environment.default.console[method] : true;
          break;
        default:
          /* eslint-disable no-console */
          console.warn("Logger.".concat(method, " does not exists..."));
        /* eslint-enable no-console */
      }

      return !Logger.shutUp && isActive;
    },
    /**
     * Logs the arguments to the console **ONLY IN DEV MODE**
     * You can pass as many arguments as you want and they will be joined together with a space.
     *
     * ```javascript
     * let foo = 1;
     * Logger.log('log value of foo:', foo);
     * // "log value of foo: 1" will be printed to the console
     * ```
     *
     * @method log
     * @for Logger
     * @param {*} arguments
     * @public
     */
    log: function log() {
      if (Logger.isActive('log')) {
        var _console;
        /* eslint-disable no-console */
        return (_console = console).log.apply(_console, arguments);
        /* eslint-enable no-console */
      }
    },
    /**
     * Prints the arguments to the console with a warning icon.
     * You can pass as many arguments as you want and they will be joined together with a space.
     *
     * ```javascript
     * Logger.warn('Something happened!');
     * // "Something happened!" will be printed to the console with a warning icon.
     * ```
     *
     * @method warn
     * @for Logger
     * @param {*} arguments
     * @public
     */
    warn: function warn() {
      if (Logger.isActive('warn')) {
        var _console2;
        /* eslint-disable no-console */
        return (_console2 = console).warn.apply(_console2, arguments);
        /* eslint-enable no-console */
      }
    },
    /**
     * Prints the arguments to the console with an error icon, red text and a stack trace.
     * You can pass as many arguments as you want and they will be joined together with a space.
     *
     * ```javascript
     * Logger.error('Danger! Danger!');
     * // "Danger! Danger!" will be printed to the console in red text.
     * ```
     *
     * @method error
     * @for Logger
     * @param {*} arguments
     * @public
     */
    error: function error() {
      if (Logger.isActive('error')) {
        var _console3;
        /* eslint-disable no-console */
        return (_console3 = console).error.apply(_console3, arguments);
        /* eslint-enable no-console */
      }
    },
    /**
     * Logs the arguments to the console.
     * You can pass as many arguments as you want and they will be joined together with a space.
     *
     * ```javascript
     * let foo = 1;
     * Logger.info('log value of foo:', foo);
     * // "log value of foo: 1" will be printed to the console
     * ```
     *
     * @method info
     * @for Logger
     * @param {*} arguments
     * @public
     */
    info: function info() {
      if (Logger.isActive('info')) {
        var _console4;
        /* eslint-disable no-console */
        return (_console4 = console).info.apply(_console4, arguments);
        /* eslint-enable no-console */
      }
    },
    /**
     * Logs the arguments to the console in blue text **ONLY IN DEV MODE**
     * You can pass as many arguments as you want and they will be joined together with a space.
     *
     * ```javascript
     * let foo = 1;
     * Logger.debug('log value of foo:', foo);
     * // "log value of foo: 1" will be printed to the console
     * ```
     *
     * @method debug
     * @for Logger
     * @param {*} arguments
     * @public
     */
    debug: function debug() {
      if (Logger.isActive('debug')) {
        var _console6;
        /* eslint-disable no-console */
        if (console.debug) {
          var _console5;
          return (_console5 = console).debug.apply(_console5, arguments);
        }
        return (_console6 = console).info.apply(_console6, arguments);
        /* eslint-enable no-console */
      }
    },
    /**
     * If the value passed into `Logger.assert` is not truthy it will throw an error with a stack trace.
     *
     *   ```javascript
     *  Logger.assert(true); // undefined
     *  Logger.assert(true === false); // Throws an Assertion failed error.
     *  Logger.assert(true === false, 'Something invalid'); // Throws an Assertion failed error with message.
     *  ```
     *
     * @method assert
     * @for Logger
     * @param {Boolean} bool Value to test
     * @param {String} message Assertion message on failed
     * @public
     **/
    assert: function assert() {
      if (Logger.isActive('assert')) {
        var _console7;
        /* eslint-disable no-console */
        return (_console7 = console).assert.apply(_console7, arguments);
        /* eslint-enable no-console */
      }
    }
  };
  var _default = _exports.default = Logger;
});