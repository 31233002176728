define("boondmanager/helpers/is-selected", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isSelected = isSelected;
  // TODO: Make it private or scoped to the component
  function isSelected(params) {
    var option = params[0];
    var selected = params[1];
    if (selected === undefined || selected === null) {
      return false;
    }
    option = option && option.hasOwnProperty('id') ? option.id : option;
    if (Ember.isArray(selected)) {
      return selected.indexOf(option) > -1;
    }
    //Checklistes
    return Ember.isEqual(option, selected);
  }
  var _default = _exports.default = Ember.Helper.helper(isSelected);
});