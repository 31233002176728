define("boondmanager/pods/components/octane/bm-field/subdivisions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "hOZgDzxQ",
    "block": "{\"symbols\":[\"__arg0\",\"__arg1\",\"item\",\"&attrs\",\"@name\",\"@disabled\",\"@onChange\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-field-subdivisions\"],[16,\"data-name\",[32,0,[\"dataName\"]]],[17,4],[4,[38,1],[[32,0,[\"onInsert\"]]],null],[12],[2,\"\\n\\t\"],[8,\"octane/bm-field/select\",[],[[\"@name\",\"@options\",\"@selected\",\"@disabled\",\"@onChange\",\"@required\",\"@namedBlocksInfo\"],[[32,5],[32,0,[\"options\"]],[32,0,[\"selectedSubDivision\"]],[32,6],[32,7],true,[30,[36,2],null,[[\"option\"],[1]]]]],[[\"default\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[32,1],\"option\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[32,3,[\"value\"]]],[2,\"\\n\\t\\t\"]],\"parameters\":[3]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"let\",\"did-insert\",\"hash\",\"-is-named-block-invocation\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/subdivisions/template.hbs"
  });
});