define("boondmanager/initializers/textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'add data-attributes to textarea component',
    initialize: function initialize() {
      Ember.TextArea.reopen({
        didRender: function didRender() {
          this._super.apply(this, arguments);
          if (this.element.name) {
            this.element.dataset.name = "input-".concat(this.element.name);
          }
        }
      });
    }
  };
});