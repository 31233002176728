define("boondmanager/utils/time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convertMinutesToHours = convertMinutesToHours;
  _exports.convertTimeToDecimal = convertTimeToDecimal;
  _exports.decimalHoursToTime = decimalHoursToTime;
  _exports.getSplittedTime = getSplittedTime;
  _exports.getSplittedTimeString = getSplittedTimeString;
  /**
   * Get splitted time value
   * @param time
   * @param separator
   * @returns {{hours: Number, minutes: Number}}
   * @private
   */
  function getSplittedTime(time, separator) {
    var baseValue = getSplittedTimeString(time, separator);
    var value;
    if (baseValue) {
      value = {
        hours: parseInt(baseValue.hours),
        minutes: parseInt(baseValue.minutes)
      };
    }
    return value;
  }

  /**
   * Get splitted time string value
   * @param time
   * @param separator
   * @returns {{hours: String, minutes: String}}
   * @private
   */
  function getSplittedTimeString(time, separator) {
    separator = separator || ':';
    var value;
    var splitted = time ? time.split(separator) : [];
    if (splitted.length === 2) {
      value = {
        hours: splitted[0],
        minutes: splitted[1]
      };
    }
    return value;
  }

  /**
   * Conversion de minutes au format HH:mm
   * @param minutes
   * @param separator
   * @param returnZero
   * @return {string}
   */
  function convertMinutesToHours(minutes, separator, returnZero) {
    separator = separator || ':';
    var hours = minutes / 60 ^ 0;
    var hourLength = hours.toString().length;
    return minutes ? "0".concat(hours).slice(-hourLength) + separator + ('0' + minutes % 60).slice(-2) : returnZero ? 0 : '';
  }

  /**
   * Conversion d'un temps au format HH:mm en nombre à décimales
   * @param time
   * @param separator
   * @return {number}
   */
  function convertTimeToDecimal(time, separator) {
    var split = getSplittedTime(time, separator);
    var value = 0;
    if (time) {
      value = parseFloat(split.hours + '.' + parseInt(split.minutes / 6 * 10, 10));
    }
    return value;
  }

  /**
   * Converti un nombre d'heures décimales en format HH:mm
   * @param value
   * @param separator
   * @param avoidZeroMinutes
   * @return {string}
   */
  function decimalHoursToTime(value, separator, avoidZeroMinutes) {
    separator = separator || ':';
    var decimalTime = parseFloat(value);
    decimalTime = decimalTime * 60 * 60;
    var hours = Math.floor(decimalTime / (60 * 60));
    decimalTime = decimalTime - hours * 60 * 60;
    var minutes = Math.floor(decimalTime / 60);
    var hourLength = hours.toString().length;
    var result = "0".concat(hours).slice(-hourLength);
    if (!avoidZeroMinutes || separator !== ':') result += separator;
    if (!avoidZeroMinutes || minutes > 0) result += "0".concat(minutes).slice(-2);
    return result;
  }
});