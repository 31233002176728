define("boondmanager/initializers/config-meta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function initialize(application) {
    var env = application.resolveRegistration('config:environment');
    var mapType = function mapType(val) {
      if (val === '') {
        return null;
      } else if (val === 'true') {
        return true;
      } else if (val === 'false') {
        return false;
      } else {
        return val;
      }
    };
    var envOverride = function envOverride(env, key, value) {
      var path = key.split('.');
      var segment = path.shift();
      var subObject = Ember.get(env, segment);
      if (_typeof(subObject) === 'object') {
        envOverride(subObject, path.join('.'), value);
      } else {
        env[segment] = value;
      }
    };

    // 1. Grab all the meta tags from the DOM.
    var metaTags = document.getElementsByTagName('meta');

    // 2. Process each of the discovered meta tags.
    for (var i = 0; i < metaTags.length; i++) {
      // 3. Get the meta tag name and value.
      var envKey = metaTags[i].getAttribute('name');
      var envValue = metaTags[i].getAttribute('content');

      // 4. Does the meta tag start with 'bm-' prefix ?
      if (/^bm-/.test(envKey)) {
        // 5. Produce a camelized version of the env variable name, ignoring the initial 'bm-' (length 3).
        var propertyName = envKey.substring(3);

        // 6. Map the string values to actual types.
        envOverride(env, propertyName, mapType(envValue));
      }
    }
  }
  var _default = _exports.default = {
    name: 'config-meta',
    initialize: initialize
  };
});