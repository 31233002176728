define("boondmanager/pods/components/octane/bm-search-filters/fields/reporting-compare-indicators/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "7qaj6zJh",
    "block": "{\"symbols\":[\"@filter\"],\"statements\":[[6,[37,0],[[32,1,[\"displayFilter\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n\\t\\t\\t\"],[8,\"octane/bm-field\",[],[[\"@name\"],[[32,1,[\"label\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[8,\"octane/bm-field/compare-indicators\",[],[[\"@name\",\"@selected\",\"@onChange\",\"@multiple\",\"@indicators\",\"@renderInPlace\"],[\"compare-indicators\",[32,1,[\"modalValue\"]],[32,0,[\"update\"]],true,[32,0,[\"options\"]],true]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-search-filters/fields/reporting-compare-indicators/template.hbs"
  });
});