define("boondmanager/pods/components/octane/bm-closable-pill/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "IiROEBot",
    "block": "{\"symbols\":[\"@isClosable\",\"@onClose\",\"@onClick\",\"@icon\"],\"statements\":[[6,[37,1],[[30,[36,3],[[32,0,[\"label\",\"isLoading\"]],[30,[36,2],[[32,0,[\"label\",\"isLoaded\"]],[32,0,[\"label\",\"value\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"li\"],[14,0,\"bmc-search-basic-fields-filter_list-item\"],[12],[2,\"\\n\\t\\t\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-rectangle bmb-compact\"],[24,\"data-placement\",\"top\"],[16,\"aria-label\",[30,[36,1],[[32,0,[\"label\",\"isLoading\"]],[30,[36,0],[\"bmi-spinner fa-pulse\"],null],[32,0,[\"label\",\"value\"]]],null]]],[[\"@disabled\",\"@closeButton\",\"@onClose\",\"@onClick\"],[[32,0,[\"disabled\"]],[32,1],[32,2],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[30,[36,0],[[32,4]],null]],[2,\" \"],[1,[30,[36,1],[[32,0,[\"label\",\"isLoading\"]],[30,[36,0],[\"bmi-spinner fa-pulse\"],null],[32,0,[\"label\",\"value\"]]],null]],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"icon\",\"if\",\"and\",\"or\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-closable-pill/template.hbs"
  });
});