define("boondmanager/utils/import-data/get-error-generation-reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getErrorGenerationReports = getErrorGenerationReports;
  /**
   * This return array errors from Ajax response error 422
   * errorsAttributeMapper: map attributes with personnal dictonnary
   * @FixMe : Put this in a service to have DI ?
   */
  function getErrorGenerationReports(errData, i18n, messagesService, errorsAttributeMapper) {
    if (Ember.isArray(errData)) {
      return errData.filter(function (item) {
        return item.code !== 1027;
      }).map(function (item) {
        if (typeof item.row !== 'undefined') {
          if (typeof item.attributes !== 'undefined') {
            var attributes = item.attributes.split(',').map(function (attribute) {
              if (typeof errorsAttributeMapper === 'function') return i18n.exists(errorsAttributeMapper(attribute)) ? i18n.t(errorsAttributeMapper(attribute)) : attribute;else return attributes;
            });
            return i18n.t('adminimportdata:view.notimported.rowWithAttributes', {
              row: item.row,
              attributes: attributes.join(', '),
              message: messagesService.error("".concat(item.code, ".details"))
            });
          } else {
            return i18n.t('adminimportdata:view.notimported.rowWithoutAttributes', {
              row: item.row,
              message: messagesService.error("".concat(item.code, ".details"))
            });
          }
        } else {
          return i18n.t('adminimportdata:view.notimported.global', {
            message: messagesService.error("".concat(item.code, ".details"))
          });
        }
      });
    } else return [];
  }
});