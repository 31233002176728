define("boondmanager/helpers/bm-action-type", ["exports", "boondmanager/helpers/label-for"], function (_exports, _labelFor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @extends TFindHelper
   * @public
   * @method bm-action-type
   * @for Ember.Templates.helpers
   */
  var _default = _exports.default = _labelFor.default.extend({
    i18n: Ember.inject.service(),
    /**
     * @private
     * 	 Find and outputs translated text.
     *
     * @param {Array} params - positional parameters passed to the helper.
     *   The 1st element must be the translation key to locate the array in i18n dict
     *   The 2nd element is the searched value - default = ''
     *   The 3nd element is the key (or index) where we search the value defined in 2nd element - default = 'id'
     *   The 4th element is the key (or index) where we keep returned value - default = 'value'
     *
     * @param {Object} hash - an object containing the hash parameters passed to the
     *   helper. Used for translation substitutions.
     *
     * @return {String} text localized for the current locale.
     */
    compute: function compute(params, hash) {
      var action = params[0];
      var category = action.get('category');
      var type = action.get('typeOf');
      switch (category) {
        case '1':
          return this._super(['setting.action.opportunity', type], hash);
        case '2':
          return this._super(['setting.action.project', type], hash);
        case '3':
          return this._super(['setting.action.order', type], hash);
        case '7':
          return this._super(['setting.action.resource', type], hash);
        case '8':
          return this._super(['setting.action.candidate', type], hash);
        case '10':
          return this._super(['setting.action.crm', type], hash);
        case '11':
          return this._super(['setting.action.bill', type], hash);
        default:
          return '';
      }
    }
  });
});