define("boondmanager/pods/components/octane/bm-field/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "4cMBC8ib",
    "block": "{\"symbols\":[\"@wrongLogin\",\"&attrs\",\"@login\",\"@password\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"bml-login-credentials \",[32,0,[\"classNames\"]]]]],[17,2],[4,[38,0],[[32,0,[\"onInsert\"]]],null],[4,[38,1],[[32,0,[\"onUpdate\"]],[32,3]],null],[4,[38,1],[[32,0,[\"onUpdate\"]],[32,4]],null],[12],[2,\"\\n\\t\"],[3,\"ie8, ie9 does not support html5 placeholder, so we just show field title for that\"],[2,\"\\n\\t\"],[8,\"octane/bm-field\",[],[[\"@name\"],[[30,[36,2],[\"login:view.username\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[8,\"octane/bm-field/input\",[[24,1,\"login-field\"],[24,\"maxlength\",\"100\"],[24,3,\"login\"],[24,\"data-name\",\"login\"],[24,\"autocomplete\",\"login\"]],[[\"@value\",\"@enter\"],[[32,3],[32,0,[\"onEnter\"]]]],null],[2,\"\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\n\\t\"],[8,\"octane/bm-field\",[],[[\"@name\"],[[30,[36,2],[\"login:view.password\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[8,\"octane/bm-field/input\",[[24,\"maxlength\",\"32\"],[24,3,\"password\"],[24,\"data-name\",\"password\"]],[[\"@value\",\"@type\",\"@enter\"],[[32,4],\"password\",[32,0,[\"onEnter\"]]]],null],[2,\"\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,3],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[8,\"octane/bm-alerts\",[],[[\"@type\",\"@messages\"],[\"danger\",[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update\",\"t\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/login/template.hbs"
  });
});