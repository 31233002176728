define("boondmanager/pods/components/octane/bm-search-filters/fields/composite/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "fv/CgWYO",
    "block": "{\"symbols\":[\"subFilter\",\"FilterComponent\",\"@filter\"],\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,3,[\"subFilters\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"component\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[8,[32,2],[],[[\"@filter\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"let\",\"-track-array\",\"each\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-search-filters/fields/composite/template.hbs"
  });
});