define("boondmanager/initializers/cleanupTooltip", ["exports", "jquery", "boondmanager/utils/logger"], function (_exports, _jquery, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    initialize: function initialize() {
      Ember.Route.reopen({
        init: function init() {
          this._super.apply(this, arguments);
          this.on('deactivate', function () {
            _logger.default.log('Initializer:cleanupTooltip - ondeactivate event route');
            (0, _jquery.default)('.tooltip').tooltip('hide');
            (0, _jquery.default)('.bm-tooltip').tooltip('hide');
          });
        }
      });
    }
  };
});