define("boondmanager/pods/private/workinprogress/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "8scAIHUV",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"error-page\"],[12],[2,\"\\n\\n\\t\"],[10,\"div\"],[14,0,\"error-img\"],[12],[2,\"\\n\\t\\t\"],[1,[30,[36,0],[\"img/Logo192Trans.png\",\"BoondManager\"],null]],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\\t\"],[10,\"h1\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"custom-alerts bmc-alerts bmc-alerts-warning\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,1],[\"bmi-warning\"],null]],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,2],[\"workinprogress:title\"],null]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\\t\"],[10,\"p\"],[14,0,\"error-description\"],[12],[2,\"\\n\\t\\t\"],[1,[30,[36,2],[\"workinprogress:view.message\"],null]],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\\t\"],[10,\"p\"],[14,0,\"error-next-step\"],[12],[2,\"\\n\\t\\t\"],[1,[30,[36,2],[\"messages:error.nextStep\"],null]],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\\t\"],[10,\"ul\"],[14,0,\"actions\"],[12],[2,\"\\n\\t\\t\"],[10,\"li\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"a\"],[14,6,\"javascript:history.back()\"],[14,0,\"bm-btn bm-btn-default\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[1,[30,[36,1],[\"bmi-arrow-left\"],null]],[2,\"\\n\\t\\t\\t\\t\"],[1,[30,[36,2],[\"components:bmBtnBack.previousPage\"],null]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"li\"],[12],[2,\"\\n\\t\\t\\t\"],[8,\"link-to\",[[24,0,\"bm-btn bm-btn-default\"]],[[\"@route\"],[\"private.dashboard\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\"],[1,[30,[36,1],[\"bmi-dashboard\"],null]],[2,\"\\n\\t\\t\\t\\t\"],[1,[30,[36,2],[\"dashboard:title\"],null]],[2,\"\\n\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"img-relative\",\"icon\",\"t\"]}",
    "moduleName": "boondmanager/pods/private/workinprogress/template.hbs"
  });
});