define("boondmanager/pods/components/octane/bm-modal/forms/confirm-unvalidation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Baazc7UB",
    "block": "{\"symbols\":[\"__arg0\",\"@showConfirmUnvalidationForm\",\"&attrs\"],\"statements\":[[8,\"octane/bm-modal\",[[24,0,\"bmc-modal-native_content-center\"],[17,3]],[[\"@modalNativeDisplayed\",\"@onClosed\",\"@namedBlocksInfo\"],[[32,2],[32,0,[\"onClose\"]],[30,[36,4],null,[[\"header\",\"content\",\"footer\"],[0,0,0]]]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],\"header\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[1,[30,[36,0],[\"common:actions.unvalidate\"],null]],[2,\"\\n\\t\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],\"content\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n\\n\\t\\t\"],[10,\"p\"],[14,0,\"bmc-modal-text-with-icon\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,3],[\"bmi-form bmc-modal-icon-large\"],null]],[10,\"br\"],[12],[13],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,0],[\"components:bmModalNativeFormsConfirmUnvalidation.message\"],null]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],\"footer\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-rectangle\"],[24,\"data-name\",\"button-cancel\"]],[[\"@onClick\"],[[32,0,[\"onClose\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[30,[36,0],[\"common:actions.cancel\"],null]],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-rectangle bmb-confirm\"],[24,\"data-name\",\"button-confirm\"]],[[\"@onClick\"],[[32,0,[\"onConfirm\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[30,[36,0],[\"common:actions.confirm\"],null]],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-is-named-block-invocation\",\"if\",\"icon\",\"hash\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-modal/forms/confirm-unvalidation/template.hbs"
  });
});