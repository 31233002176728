define("boondmanager/pods/components/octane/bm-field/simple-color-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Fbhs2Ia0",
    "block": "{\"symbols\":[\"__arg0\",\"__arg1\",\"color\",\"selected\",\"@fieldname\",\"&attrs\",\"@options\"],\"statements\":[[11,\"div\"],[16,\"data-name\",[31,[[32,0,[\"dataName\"]]]]],[16,0,[31,[\"bmc-field-simple-colorpicker \",[32,0,[\"classNames\"]]]]],[17,6],[4,[38,3],[[32,0,[\"onInsert\"]]],null],[12],[2,\"\\n\\t\"],[8,\"octane/bm-field/select\",[[24,\"data-name\",\"simple-colorpicker\"]],[[\"@useObjects\",\"@dropdownClass\",\"@triggerClass\",\"@forceHideSearch\",\"@multiple\",\"@options\",\"@selected\",\"@onChange\",\"@disabled\",\"@allowClear\",\"@namedBlocksInfo\"],[true,\"bmc-field-simple-colorpicker-options\",\"bmc-field-simple-colorpicker-trigger\",true,false,[32,7],[32,0,[\"selected\"]],[32,0,[\"onChange\"]],[32,0,[\"disabled\"]],false,[30,[36,4],null,[[\"selected-item\",\"option\"],[1,1]]]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],\"selected-item\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[8,\"octane/bm-field/select/selected/colors\",[],[[\"@selected\",\"@fieldname\"],[[32,4],[32,5]]],null],[2,\"\\n\\t\\t\"]],\"parameters\":[4]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],\"option\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[8,\"octane/bm-field/select/results/colors\",[],[[\"@color\"],[[32,3]]],null],[2,\"\\n\\t\\t\"]],\"parameters\":[3]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"let\",\"-is-named-block-invocation\",\"if\",\"did-insert\",\"hash\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/simple-color-picker/template.hbs"
  });
});