define("boondmanager/pods/components/octane/bm-kanban/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ASOs0TRd",
    "block": "{\"symbols\":[\"@scrollName\",\"&default\",\"&attrs\",\"@namedBlocksInfo\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-kanbans\"],[17,3],[4,[38,1],[[32,0,[\"initSyncScroll\"]]],null],[4,[38,2],[[32,0,[\"removeSyncScroll\"]]],null],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"bmc-kanbans_content\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[32,4],\"header\",false],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[11,\"div\"],[24,0,\"bmc-kanbans_headers\"],[4,[38,1],[[32,0,[\"initFadeInOutComponent\"]]],null],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"div\"],[14,0,\"bmc-kanbans_headers-scroller syncscroll\"],[15,\"data-scroll-groupname\",[32,1]],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-circle bmb-circle-left\"],[24,\"data-name\",\"button-scroll-left\"],[24,\"data-placement\",\"top\"]],[[\"@iconOnly\",\"@onClick\"],[\"bmi-chevron-left\",[32,0,[\"scrollLeft\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\\t\\t\\t\"],[18,2,[[30,[36,0],[\"header\"],null]]],[2,\"\\n\\t\\t\\t\\t\\t\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-circle bmb-circle-right\"],[24,\"data-name\",\"button-scroll-right\"],[24,\"data-placement\",\"top\"]],[[\"@iconOnly\",\"@onClick\"],[\"bmi-chevron-right\",[32,0,[\"scrollRight\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[30,[36,3],[[32,4],\"content\",false],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"div\"],[14,0,\"bmc-kanbans_columns syncscroll\"],[15,\"data-scroll-groupname\",[32,1]],[12],[2,\"\\n\\t\\t\\t\\t\"],[18,2,[[30,[36,0],[\"content\"],null]]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-named-block-invocation\",\"did-insert\",\"will-destroy\",\"-has-block\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-kanban/template.hbs"
  });
});