define("boondmanager/pods/components/octane/bm-alerts/invoices/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Dih8PSZO",
    "block": "{\"symbols\":[\"message\",\"@model\",\"@warnings\"],\"statements\":[[6,[37,3],[[32,3,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-alerts\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"li\"],[14,0,\"bmc-alert_list-item\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,1,[\"code\"]],\"invoiceDateModified\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[1,[30,[36,0],null,[[\"code\",\"oldDate\",\"date\"],[[32,1,[\"code\"]],[30,[36,1],[[32,1,[\"date\"]]],[[\"format\"],[\"DD/MM/YYYY\"]]],[30,[36,1],[[32,2,[\"date\"]]],[[\"format\"],[\"DD/MM/YYYY\"]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\\t\"],[1,[30,[36,0],null,[[\"code\"],[[32,1,[\"code\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"warning\",\"time\",\"eq\",\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-alerts/invoices/template.hbs"
  });
});