define("boondmanager/pods/components/octane/bm-field/phone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "DSJ+YevX",
    "block": "{\"symbols\":[\"&attrs\",\"@title\",\"@value\",\"@name\",\"@required\",\"@disabled\",\"@key-up\",\"@onChange\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"bmc-field-phone bm-input-group \",[32,0,[\"classNames\"]]]]],[17,1],[4,[38,0],[[32,0,[\"onInsert\"]]],null],[12],[2,\"\\n\\t\"],[10,\"span\"],[14,0,\"bm-input-group-icon-left bm-tooltips\"],[14,\"data-container\",\"body\"],[15,\"data-original-title\",[32,2]],[12],[2,\"\\n\\t\\t\"],[11,\"a\"],[16,0,[31,[\"bm-link-secondary \",[30,[36,1],[[32,0,[\"tel\"]],\"bm-disabled\"],null]]]],[24,\"rel\",\"noopener noreferrer nofollow\"],[4,[38,2],[\"click\",[32,0,[\"callto\"]]],null],[12],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,5],[[30,[36,4],[[32,0,[\"icon\"]],\" \",[30,[36,3],[[32,3],\"highlight\"],null]],null]],null]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[8,\"octane/bm-field/input\",[[16,1,[32,0,[\"inputId\"]]],[24,\"pattern\",\"^((\\\\+\\\\d{1,3}(-| )?\\\\(?\\\\d\\\\)?(-| )?\\\\d{1,5})|(\\\\(?\\\\d{2,6}\\\\)?))(-| )?(\\\\d{3,4})(-| )?(\\\\d{4})(( x| ext)\\\\d{1,5}){0,1}$\"],[24,\"maxlength\",\"20\"],[16,3,[32,4]],[16,\"data-name\",[32,4]]],[[\"@required\",\"@value\",\"@type\",\"@enter\",\"@disabled\",\"@keyUp\",\"@onChange\"],[[32,5],[32,3],\"tel\",[32,0,[\"onEnter\"]],[32,6],[32,7],[32,8]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"unless\",\"on\",\"if\",\"concat\",\"icon\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/phone/template.hbs"
  });
});