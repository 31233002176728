define("boondmanager/pods/components/octane/bm-header-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "h4WTxzUI",
    "block": "{\"symbols\":[\"&default\",\"@nameComplement\",\"@name\",\"@headerIcon\",\"@headerThumbnail\",\"@imgProfile\",\"&attrs\",\"@namedBlocksInfo\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"klassNames\"]]],[17,7],[12],[2,\"\\n\"],[6,[37,0],[[30,[36,4],[[32,4],[32,5]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[15,0,[31,[\"bmc-header-info_icon \",[30,[36,0],[[30,[36,3],[[32,6],[32,5]],null],\"bmc-header-info_img-profile\"],null]]]],[12],[2,\"\\n\"],[6,[37,0],[[32,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[1,[30,[36,2],[[32,5],\"\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\\t\"],[1,[30,[36,1],[[32,4]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"bmc-header-info_text\"],[12],[2,\"\\n\"],[6,[37,0],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"span\"],[14,0,\"bmc-header-info_text-name\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[2,[32,3]],[2,\" \"],[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[10,\"span\"],[14,0,\"bmc-header-info_text-name-complement\"],[12],[2,\"- \"],[1,[32,2]],[13]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[30,[36,5],[[32,8],\"default\",[27,[32,1]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"icon\",\"img-absolute\",\"and\",\"or\",\"-has-block\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-header-info/template.hbs"
  });
});