define("boondmanager/pods/components/octane/bm-alerts/notcopied/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "8C5ofMzx",
    "block": "{\"symbols\":[\"@type\",\"@onClose\",\"&default\"],\"statements\":[[8,\"octane/bm-alerts\",[],[[\"@type\",\"@onClose\"],[[30,[36,0],[[32,1],[32,1],\"warning\"],null],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[10,\"li\"],[14,0,\"bmc-alert_list-item\"],[12],[2,\"\\n\\t\\t\"],[18,3,null],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-alerts/notcopied/template.hbs"
  });
});