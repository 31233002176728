define("boondmanager/helpers/action-type-title", ["exports", "boondmanager/models/action"], function (_exports, _action) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * An HTMLBars helper function that exposes the i18next `t()` function. Automatically
   * refreshes the translated text if the application's selected locale changes.
   */
  var _default = _exports.default = Ember.Helper.extend({
    i18n: Ember.inject.service(),
    settings: Ember.inject.service(),
    /**
     * Search for action type value in all action type settings available in dictionary
     * @param params
     * @param hash
     * @returns {*}
     */
    compute: function compute(params, hash) {
      var _this = this;
      var typeId = params[0] || hash.type;
      var showCategory = hash.showCategory;
      var currentModule = hash.module;
      var items = [];
      _action.categoriesOrder.forEach(function (category) {
        items = items.concat(_this.get('settings').customer.action[category].map(function (item) {
          return {
            id: item.id,
            value: item.value,
            category: category
          };
        }));
      });
      var trad = items.find(function (item) {
        return item.id === parseInt(typeId);
      });
      var value = typeId;
      if (trad) {
        value = trad.value;
        if (showCategory && currentModule !== trad.category) value += " (".concat(this.get('i18n').t('actions:search.category', {
          context: trad.category
        }), ")");
      }
      return value;
    },
    refreshText: Ember.observer('i18n._locale', function () {
      this.recompute();
    })
  });
});