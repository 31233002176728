define("boondmanager/initializers/fragment-transform", ["exports", "boondmanager/transforms/fragment", "boondmanager/transforms/fragment-array", "boondmanager/transforms/array"], function (_exports, _fragment, _fragmentArray, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    application.register('transform:-bf-fragment', _fragment.default);
    application.register('transform:-bf-fragmentarray', _fragmentArray.default);
    application.register('transform:-bf-array', _array.default);
  }
  var _default = _exports.default = {
    name: 'fragment-transform',
    after: 'ember-data',
    initialize: initialize
  };
});