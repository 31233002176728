define("boondmanager/helpers/moment-from-now-dynamic", ["exports", "ember-moment/helpers/-base", "ember-moment/utils/helper-compute"], function (_exports, _base, _helperCompute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; } // app/helpers/moment-from-now-dynamic.js
  // Get a live updating 'moment-from-now' with an interval that changes based on how old the given time is
  var _default = _exports.default = _base.default.extend({
    compute: (0, _helperCompute.default)(function (params, _ref) {
      var hideSuffix = _ref.hideSuffix,
        locale = _ref.locale,
        timeZone = _ref.timeZone;
      var interval = 1000;
      var moment = Ember.get(this, 'moment');
      // Morph moment is from the base helper
      var morphedMoment = this.morphMoment(moment.moment.apply(moment, _toConsumableArray(params)), {
        locale: locale,
        timeZone: timeZone
      });
      var nowMorphedMoment = this.morphMoment(moment.moment(), {
        locale: locale,
        timeZone: timeZone
      });

      // Set the interval based on the difference in times
      var timeDiff = nowMorphedMoment.diff(morphedMoment, 'seconds');
      if (timeDiff < 60) {
        // Diff is less than 1 minute, set 1 second intervals
        interval = 1000; // Milliseconds
      } else if (timeDiff < 3600) {
        // Diff is less than 1 hour, set 30 second intervals
        interval = 30000;
      } else if (timeDiff < 86400) {
        // Diff is less than 1 day, set 30 minute intervals
        interval = 1800000;
      } else {
        // Diff is greater than 1 day, set 1 hour intervals
        interval = 3600000;
      }

      // Uses the interval & sets timeout, from the base helper
      this._super.apply(this, _toConsumableArray(params).concat([{
        interval: interval,
        hideSuffix: hideSuffix,
        locale: locale,
        timeZone: timeZone
      }]));
      return morphedMoment.fromNow(hideSuffix);
    })
  });
});