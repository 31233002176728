define("boondmanager/pods/components/octane/bm-markdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "lRBDhJi8",
    "block": "{\"symbols\":[\"&attrs\",\"@hbs\",\"@content\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"bmc-markdown \",[30,[36,3],[[32,0,[\"isCompiling\"]],\"bmc-markdown-compiling\"],null]]]],[24,\"data-name\",\"component-markdown\"],[17,1],[4,[38,4],[[32,0,[\"onInsert\"]]],null],[4,[38,5],[[32,0,[\"updateContext\"]],[32,2]],null],[4,[38,5],[[32,0,[\"transformContent\"]],[32,3]],null],[4,[38,6],[\"click\",[32,0,[\"onClick\"]]],null],[12],[2,\"\\n\"],[6,[37,3],[[32,0,[\"isCompiling\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\"],[1,[30,[36,1],[\"bmi-spinner fa-pulse\"],null]],[2,\"\\n\\t\"],[10,\"span\"],[14,0,\"sr-only\"],[12],[1,[30,[36,2],[\"common:loading.title\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[1,[30,[36,0],[[32,0,[\"compiledContent\"]]],[[\"class\",\"simpleLineBreaks\"],[\"bmc-markdown-text\",\"true\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"markdown-to-html\",\"icon\",\"t\",\"if\",\"did-insert\",\"did-update\",\"on\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-markdown/template.hbs"
  });
});