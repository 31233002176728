define("boondmanager/pods/components/octane/bm-todolist/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "r5EfuCrj",
    "block": "{\"symbols\":[\"__arg0\",\"&default\",\"@namedBlocksInfo\",\"&attrs\",\"@cardClass\"],\"statements\":[[11,\"div\"],[17,4],[24,0,\"bmc-todolist\"],[12],[2,\"\\n\\t\"],[8,\"octane/bm-card\",[[24,\"data-name\",\"card-todolist\"],[16,0,[32,5]]],[[\"@namedBlocksInfo\"],[[30,[36,3],null,[[\"content\"],[0]]]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,4],[[32,1],\"content\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,3],\"header\",false],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[18,2,[[30,[36,0],[\"header\"],null]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[30,[36,1],[[32,3],\"content\",false],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[18,2,[[30,[36,0],[\"content\"],null]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-named-block-invocation\",\"-has-block\",\"if\",\"hash\",\"-is-named-block-invocation\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-todolist/template.hbs"
  });
});