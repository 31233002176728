define("boondmanager/utils/index", ["exports", "boondmanager/utils/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.changeRelationship = changeRelationship;
  _exports.default = void 0;
  _exports.getInstanceClone = getInstanceClone;
  _exports.getReasons = getReasons;
  _exports.isDefined = isDefined;
  _exports.isFunction = isFunction;
  _exports.isTransition = isTransition;
  /**
    @module boondmanager/utils
  */

  /**
   * isDefined check if nested prop exists
   *
   * @method isDefined
   * @for boondmanager/utils
   * @static
   * @param  {Object } obj   root Object in which check nested props
   * @param  {string}  path  complet path of tested props like 'level1.level2.propToTest'
   * @return {Boolean} true if all nested props exists (root included)
   * @public
   */
  function isDefined(obj, path) {
    var typeOfObj = Ember.typeOf(obj);
    if (typeOfObj === 'null' || typeOfObj === 'undefined') {
      return false;
    }
    if (typeOfObj !== 'object') {
      _logger.default.warn("Function isDefined() - 'obj' arg is not an object...");
      return false;
    }
    if (typeof path !== 'string' || path === '') {
      _logger.default.warn("Function isDefined() - 'path' arg is not a string or is empty...");
      return false;
    }
    var current = obj;
    return path.split('.').every(function (prop) {
      if (typeof current[prop] === 'undefined') {
        return false;
      } else {
        current = current[prop];
        return true;
      }
    });
  }

  /**
   * isTransition check if an object is a Transition object
   *
   * @method isTransition
   * @for boondmanager/utils
   * @static
   * @param  {Object} obj  root Object in which need te be checked
   * @return {Boolean} true if it is a {Transition}
   * @public
   */
  function isTransition(obj) {
    return Ember.typeOf(obj) === 'object' && !!obj.isTransition;
  }

  /**
   * changeRelationship mise à jour du relation dans un modèle
   * Petit hack pour passer le modèle principal à dirty
   *
   * @method changeRelationship
   * @for boondmanager/utils
   * @static
   * @param  {Object } model         Modèle à mettre à jour
   * @param  {String}  relationship  Nom de la relation (attribut)
   * @param  {Object}  value         Valeur de la relation
   * @public
   */
  function changeRelationship(model, relationship, value) {
    model[relationship] = value;

    // TODO change this on ember data upgrade
    // TODO Test without this line or test this.model.currentState.notify(relationship)
    model._internalModel.setDirtyAttribute(relationship, value);

    // BM-14578 : dans le cas d'un power-select mettant un jour un tableau dans un model, le dernier élément ne pouvais pas être déselectionné.
    model.notifyPropertyChange(relationship);

    // Hack to update selected on octane TODO remove with octane bm-field select ????
    model[relationship];
  }

  /**
   * getReasons Permet de récupérer la liste des motifs de fins de process actifs associés à un état spécifique
   *
   * @method getReasons
   * @for boondmanager/utils
   * @static
   * @param  {Object } dictionary         Dictionnaire dans lequel chercher l'état et les motifs
   * @param  {Number}  state              ID de l'état courant
   * @param  {Number}  currentStateReason Filtre sur le stateReason courant
   * @public
   */
  function getReasons(dictionary, state, currentStateReason) {
    var currentState = dictionary.find(function (item) {
      return item.id === state;
    });
    return currentState ? currentState.reason.filter(function (reason) {
      return reason.isEnabled || currentStateReason === reason.id;
    }) : [];
  }
  function isFunction(val) {
    return val && typeof val === 'function';
  }

  /**
   * Get instance clone from builder definition
   * @param {Class} instance
   * @returns {any}
   */
  function getInstanceClone(instance) {
    return Object.assign(Object.create(Object.getPrototypeOf(instance)), instance);
  }
  var _default = _exports.default = {
    isTransition: isTransition,
    isDefined: isDefined,
    changeRelationship: changeRelationship,
    isFunction: isFunction,
    getInstanceClone: getInstanceClone
  };
});