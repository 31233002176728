define("boondmanager/utils/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VIEW_MODE_LIST = _exports.VIEW_MODE_KANBAN = _exports.MAX_ROWS_BY_PAGE = _exports.DYNAMIC_PERIOD_OPTIONS = _exports.DISABLED_SEARCH_KEY = _exports.DEFAULT_SEARCH_KEY = _exports.DEFAULT_ROWS_BY_PAGE = void 0;
  _exports.calculateNbPages = calculateNbPages;
  _exports.solrize = solrize;
  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  var DEFAULT_ROWS_BY_PAGE = _exports.DEFAULT_ROWS_BY_PAGE = 30;
  var MAX_ROWS_BY_PAGE = _exports.MAX_ROWS_BY_PAGE = 100; // If 1000, the backend will use default limit (30)
  var DISABLED_SEARCH_KEY = _exports.DISABLED_SEARCH_KEY = '-1';
  var DEFAULT_SEARCH_KEY = _exports.DEFAULT_SEARCH_KEY = '0';
  var VIEW_MODE_LIST = _exports.VIEW_MODE_LIST = 'list';
  var VIEW_MODE_KANBAN = _exports.VIEW_MODE_KANBAN = 'kanban';
  var DYNAMIC_PERIOD_OPTIONS = _exports.DYNAMIC_PERIOD_OPTIONS = ['yesterday', 'lastWeek', 'lastMonth', 'lastTrimester', 'lastSemester', 'lastYear', 'lastFiscalYear', 'lastCustomPeriod', 'today', 'thisWeek', 'thisMonth', 'thisTrimester', 'thisSemester', 'thisYear', 'thisFiscalYear', 'untilToday', 'tomorrow', 'nextWeek', 'nextMonth', 'nextTrimester', 'nextSemester', 'nextYear', 'nextFiscalYear', 'nextCustomPeriod'];

  /**
   * Calcul du nombre de pages d'une recherche
   * @param nbRows
   * @param rowsByPage
   * @return {number}
   */
  function calculateNbPages(nbRows, rowsByPage) {
    var max = typeof rowsByPage === 'number' ? rowsByPage : DEFAULT_ROWS_BY_PAGE;
    return Math.ceil(nbRows / max);
  }

  /**
   * Convert Keywords into a complex search for solr.
   * Will build a string like field[0]:keywords OR fields[1]:keywords OR ....
   * @param {string} input keywords
   * @param {array} fields List of fields to search into
   * @return {string}
   */
  function solrize(input, fields) {
    if (!Ember.isArray(fields) && !fields.length) {
      return input;
    }
    var lastItem = fields.length - 1;
    var keywords = '';
    var i = 0;
    var _iterator = _createForOfIteratorHelper(fields),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var field = _step.value;
        keywords += "".concat(field, ":").concat(input);
        if (i !== lastItem) {
          keywords += ' OR ';
        }
        i++;
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    return keywords;
  }
});