define("boondmanager/helpers/lookup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.lookup = lookup;
  function lookup(params) {
    var map = params[0];
    var entry = params[1];
    var subKey = params[2];
    //const keyRef = params[3];

    if (map && map[entry]) {
      if (params.length >= 3) {
        if (map[entry][subKey]) {
          return map[entry][subKey];
        }
      } else {
        return map[entry];
      }
    }
    return null;
  }
  var _default = _exports.default = Ember.Helper.helper(lookup);
});