define("boondmanager/pods/components/octane/bm-list-profile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "fx6rM0p6",
    "block": "{\"symbols\":[\"&default\",\"@underlineItem\",\"@overview\",\"&attrs\",\"@namedBlocksInfo\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"bmc-list-profile \",[30,[36,0],[[32,3],\"bmc-list-profile_overview\"],null],\" \",[30,[36,0],[[32,2],\"bmc-list-profile_list-item-underline\"],null]]]],[17,4],[12],[2,\"\\n\\t\"],[10,\"ul\"],[14,0,\"bmc-list-profile_list\"],[12],[2,\"\\n\"],[6,[37,0],[[30,[36,1],[[32,5],\"default\",[27,[32,1]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"-has-block\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-list-profile/template.hbs"
  });
});