define("boondmanager/helpers/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * A basic helper designed to display a warning message
   */
  var _default = _exports.default = Ember.Helper.extend({
    messages: Ember.inject.service(),
    compute: function compute(args, kwargs) {
      if (args.length || !kwargs.code) {
        throw new Error('No code specified for warning of non-named args specified');
      }
      return this.get('messages').error(kwargs.code, kwargs);
    },
    refreshText: Ember.observer('i18n._locale', function () {
      this.recompute();
    })
  });
});