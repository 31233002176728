define("boondmanager/helpers/moment-months", ["exports", "moment", "ember-moment/utils/helper-compute", "ember-moment/helpers/-base"], function (_exports, _moment, _helperCompute, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    compute: (0, _helperCompute.default)(function (params, _ref) {
      var short = _ref.short;
      this._super.apply(this, arguments);
      var fn = short ? 'monthsShort' : 'months';
      var args = [];
      if (params.length === 1 && typeof params[0] === 'number') {
        args.push(params[0] - 1);
      }
      return _moment.default[fn].apply(_moment.default, args);
    })
  });
});