define("boondmanager/pods/components/octane/bm-search-filters/fields/reporting-availability/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "B2RFhxn7",
    "block": "{\"symbols\":[\"__arg0\",\"option\",\"@filter\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n\\t\\t\"],[8,\"octane/bm-field\",[],[[\"@name\",\"@namedBlocksInfo\"],[[32,3,[\"label\"]],[30,[36,4],null,[[\"content\"],[0]]]]],[[\"default\"],[{\"statements\":[[6,[37,6],[[30,[36,5],[[32,1],\"content\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,3,[\"options\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[8,\"octane/bm-field/radio\",[],[[\"@name\",\"@checked\",\"@labelText\",\"@onChange\"],[[32,3,[\"queryParamKey\"]],[30,[36,0],[[32,2,[\"id\"]],[32,3,[\"modalValue\"]]],null],[32,2,[\"value\"]],[30,[36,1],[[32,0,[\"check\"]],[32,2]],null]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\t\\t\\t\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"is-selected\",\"fn\",\"-track-array\",\"each\",\"hash\",\"-is-named-block-invocation\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-search-filters/fields/reporting-availability/template.hbs"
  });
});