define("boondmanager/pods/components/octane/bm-field/subscription-regulate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ndFUms81",
    "block": "{\"symbols\":[\"@index\",\"&attrs\",\"@regulate\",\"@currencySymbol\",\"@change\",\"@agencyChange\",\"@forceZero\",\"@disabled\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"bmc-field-subscription-regulate \",[32,0,[\"errorClass\"]]]]],[17,2],[4,[38,2],[[32,0,[\"onInsert\"]]],null],[12],[2,\"\\n\\t\"],[8,\"octane/bm-field\",[],[[\"@noTitle\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[8,\"octane/bm-field/money\",[],[[\"@name\",\"@value\",\"@currencySymbol\",\"@change\",\"@agencyChange\",\"@forceZero\",\"@disabled\",\"@onChange\"],[[30,[36,4],[\"regulateAmount[\",[35,3],\"]\"],null],[32,3,[\"amountExcludingTax\"]],[32,4],[32,5],[32,6],[32,7],[32,8],[30,[36,1],[[30,[36,5],[[32,3,[\"amountExcludingTax\"]]],null]],null]]],null],[2,\"\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\n\\t\"],[8,\"octane/bm-field\",[],[[\"@noTitle\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[8,\"octane/bm-field/input\",[[24,\"maxlength\",\"100\"],[16,3,[30,[36,4],[\"regulateDescription[\",[32,1],\"]\"],null]],[16,\"data-name\",[30,[36,4],[\"regulateDescription[\",[32,1],\"]\"],null]]],[[\"@disabled\",\"@value\"],[[32,8],[32,3,[\"description\"]]]],null],[2,\"\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\n\\n\"],[6,[37,6],[[32,8]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-icon\"],[24,\"data-name\",\"button-delete-subscription-regulate\"],[16,\"data-original-title\",[30,[36,0],[\"common.delete\"],null]],[16,\"aria-label\",[30,[36,0],[\"common.delete\"],null]]],[[\"@iconOnly\",\"@onClick\"],[\"bmi-delete\",[30,[36,1],[[32,0,[\"deleteRegulate\"]],[32,1]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"fn\",\"did-insert\",\"index\",\"concat\",\"mut\",\"unless\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/subscription-regulate/template.hbs"
  });
});