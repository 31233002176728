define("boondmanager/adapters/downloadcenterfolder", ["exports", "boondmanager/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    deleteRecordUrlTemplate: '{+host}/api{/middlepoint}/{pathForType}{/perimeterManagers}{/folder}{?query}',
    queryRecordUrlTemplate: '{+host}/api{/middlepoint}/{pathForType}{/perimeterManagers}{/folder}{?query*}',
    findRecordUrlTemplate: '{+host}/api{/middlepoint}/{pathForType}{/perimeterManagers}{/folder}{?query*}',
    queryUrlTemplate: '{+host}/api{/middlepoint}/{pathForType}{?query*}',
    urlSegments: Object.freeze({
      query: function query(type, id, snapshot, _query) {
        var filteredQuery;
        if (_query) {
          filteredQuery = Ember.assign({}, _query);
          Object.keys(filteredQuery).forEach(function (key) {
            /* on ne les supprime pas ici
            if (typeof this.urlSegments[key] !== 'undefined') { // remove queryparams already in url like 'id'
            	delete filteredQuery[key];
            } else
            */
            if (typeof filteredQuery[key] === 'undefined') {
              // remove undefined queryparams
              delete filteredQuery[key];
            }
          });
          if (this.sortQueryParams) {
            filteredQuery = this.sortQueryParams(filteredQuery);
          }
        }
        return filteredQuery;
      },
      perimeterManagers: function perimeterManagers(type, id, snapshot, query) {
        if (query && query.perimeterManagers) {
          var ep = query.perimeterManagers;
          return ep;
        }
        return snapshot.adapterOptions.perimeterManagers;
      },
      folder: function folder(type, id, snapshot, query) {
        if (query && query.folder) {
          var ep = query.folder;
          return ep;
        }
        if (snapshot.adapterOptions.folder) {
          return snapshot.adapterOptions.folder;
        }
        return snapshot.record.get('name');
      }
    }),
    pathForType: function pathForType() {
      return 'download-center';
    },
    visitorAccess: function visitorAccess(folder, perimeterManagers, verb) {
      var url = this._buildURL(folder.get('modelName')) + '/' + perimeterManagers + '/' + folder.get('name') + '/visitor-access';
      return this.ajax(url, verb);
    }
  });
});