define("boondmanager/adapters/appentity", ["exports", "boondmanager/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    urlTemplate: '{+host}/api/apps/{id}/entities{/appentity_id}{/endpoint*}{.format}{?query*}',
    queryUrlTemplate: '{+host}/api/apps/{id}/entities{/viewer}{/appentity_id}{/endpoint*}{.format}{?query*}',
    updateRecordUrlTemplate: '{+host}/api/apps/{id}/entities{/appentity_id}{/endpoint*}{?query*}',
    createRecordUrlTemplate: '{+host}/api/apps/{id}/entities{?query*}',
    deleteRecordUrlTemplate: '{+host}/api/apps/{id}/entities{/appentity_id}',
    urlSegments: Object.freeze({
      id: function id(type, _id, snapshot, query) {
        if (query && Ember.get(query, 'id')) {
          return query.id;
        } else if (snapshot && Ember.get(snapshot, 'adapterOptions.id')) {
          return snapshot.adapterOptions.id;
        }
      },
      viewer: function viewer(type, id, snapshot, query) {
        if (query && Ember.get(query, 'viewer')) {
          return 'viewer';
        }
      },
      appentity_id: function appentity_id(type, id, snapshot, query) {
        var normalizedID = id;
        if (query && query.appentity_id) {
          normalizedID = query.appentity_id;
        } else if (snapshot && Ember.get(snapshot, 'adapterOptions.appentity_id')) {
          normalizedID = snapshot.adapterOptions.appentity_id;
        }
        return normalizedID === 'default' ? '0' : normalizedID;
      }
    }),
    generate: function generate(model, params) {
      var _this = this;
      var url = this.buildURL(model, null, null, 'query', params);
      return this.ajax(url, 'GET', {
        data: params
      }).then(function () {
        //Le back ne renvoie pas les fichiers générés.
        var routeName = _this.get('router.currentRouteName');
        Ember.getOwner(_this).lookup("route:".concat(routeName)).refresh(true);
      });
    }
  });
});