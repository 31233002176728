define("boondmanager/helpers/absolutePath", ["exports", "boondmanager/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.absolutePath = absolutePath;
  _exports.default = void 0;
  function absolutePath(params) {
    var src = (params[0] || '').replace(/^\/+/, '');
    return new Ember.String.htmlSafe("".concat(_environment.default.apiURL, "/").concat(src));
  }
  var _default = _exports.default = Ember.Helper.helper(absolutePath);
});