define("boondmanager/helpers/explicit-query-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.explicitQueryParams = explicitQueryParams;
  function explicitQueryParams(params, hash) {
    return typeof params[0] !== 'undefined' ? params[0] : hash;
  }
  var _default = _exports.default = Ember.Helper.helper(explicitQueryParams);
});