define("boondmanager/helpers/column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.column = column;
  _exports.default = void 0;
  function column(params) {
    var array = params[0];
    var c = params[1];
    var index = params[2];
    if (params.length === 3) {
      var data = {};
      for (var i = 0; i < array.length; i++) {
        data[array[i][index]] = array[i][c];
      }
      return data;
    } else {
      var _data = [];
      for (var _i = 0; _i < array.length; _i++) {
        _data.push(array[_i][c]);
      }
      return _data;
    }
  }
  var _default = _exports.default = Ember.Helper.helper(column);
});