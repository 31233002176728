define("boondmanager/pods/components/octane/bm-alerts/contract/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "OMuZ2kHW",
    "block": "{\"symbols\":[\"message\",\"@model\",\"@warnings\"],\"statements\":[[6,[37,5],[[32,3,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-alerts\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"li\"],[14,0,\"bmc-alert_list-item\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[1,[30,[36,2],null,[[\"code\",\"databaseContractAverageDailyCost\"],[[32,1,[\"code\"]],[30,[36,1],[[32,2,[\"contractAverageDailyCost\"]]],[[\"sign\",\"change\",\"agencyChange\",\"noHtml\"],[[30,[36,0],[\"setting.currency\",[32,0,[\"originalCurrency\"]],\"id\",\"symbol\"],null],[32,0,[\"originalExchangeRate\"]],[32,0,[\"originalExchangeRateAgency\"]],true]]]]]]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"label-for\",\"money\",\"warning\",\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-alerts/contract/template.hbs"
  });
});