define("boondmanager/pods/components/octane/bm-ajax-indicator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "yfmacZ/M",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"bmc-ajax-indicator\",[30,[36,0],[[32,0,[\"showLoader\"]],\" bm-show\"],null]]]],[17,1],[4,[38,1],[[32,0,[\"onInsertElement\"]]],null],[12],[2,\"\\n\\t\"],[8,\"octane/bm-loader\",[],[[],[]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"did-insert\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-ajax-indicator/template.hbs"
  });
});