define("boondmanager/pods/components/octane/bm-btn/resize-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FJcCLrA/",
    "block": "{\"symbols\":[\"dd\",\"menuoption\",\"@name\",\"@options\",\"@resizable\"],\"statements\":[[8,\"basic-dropdown\",[[24,\"data-name\",\"button-resize-menu\"],[24,0,\"bmb-resize-menu\"],[16,\"aria-label\",[32,3]],[24,\"horizontalPosition\",\"auto-right\"]],[[\"@renderInPlace\",\"@bubbles\"],[true,false]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[8,[32,1,[\"Trigger\"]],[[24,0,\"bmb-dropdown_label bmb-simple bmb-icon\"]],[[\"@stopPropagation\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[1,[30,[36,1],[\"bmi-ellipsis-v\"],null]],[2,\"\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\"],[8,[32,1,[\"Content\"]],[[24,0,\"bmb-dropdown_content\"],[16,\"data-name\",[31,[\"sort-menu-options-\",[32,3]]]]],[[\"@onMouseLeave\"],[[32,0,[\"close\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[8,\"octane/bm-btn\",[[24,0,\"dropdown-item\"],[24,\"data-name\",\"button-change-option\"],[16,\"aria-label\",[32,2,[\"value\"]]]],[[\"@onClick\",\"@bubbles\"],[[30,[36,0],[[32,0,[\"changeOption\"]],[32,2,[\"id\"]],[32,1]],null],false]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\"],[1,[30,[36,1],[[32,2,[\"icon\"]]],null]],[2,\" \"],[1,[32,2,[\"value\"]]],[2,\"\\n\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[6,[37,4],[[32,5]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-btn\",[[16,3,[31,[\"bmb-table-border-\",[32,3]]]],[24,0,\"bmb-table-border\"]],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"icon\",\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-btn/resize-menu/template.hbs"
  });
});