define("boondmanager/adapters/downloadcenterfile", ["exports", "boondmanager/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    deleteRecordUrlTemplate: '{+host}/api{/middlepoint}/{pathForType}{/perimeterManagers}{/folder}{/file}',
    urlSegments: Object.freeze({
      perimeterManagers: function perimeterManagers(type, id, snapshot /*,query*/) {
        return snapshot.adapterOptions.perimeterManagers;
      },
      folder: function folder(type, id, snapshot /*,query*/) {
        return snapshot.adapterOptions.folder;
      },
      file: function file(type, id, snapshot /*,query*/) {
        return snapshot.record.get('name');
      }
    }),
    pathForType: function pathForType() {
      return 'download-center';
    }
  });
});