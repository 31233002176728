define("boondmanager/helpers/badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.badge = badge;
  _exports.default = void 0;
  function badge(params) {
    var color = params[0];
    var value = params[1];
    var moreClass = params[2];
    return new Ember.String.htmlSafe('<span class="badge badge-' + color + (moreClass !== undefined ? ' ' + moreClass : '') + '">' + Ember.Handlebars.Utils.escapeExpression(value) + '</span>');
  }
  var _default = _exports.default = Ember.Helper.helper(badge);
});