define("boondmanager/pods/components/octane/bm-admin-globaldata/action/backup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/4TG79/p",
    "block": "{\"symbols\":[\"@saveInProgress\",\"@lastBackupDate\",\"@showBackup\",\"@showRestoreBackup\"],\"statements\":[[6,[37,4],null,[[\"to\"],[\"bmp-globaldata-action-backups\"]],[[\"default\"],[{\"statements\":[[2,\"\\t\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-circle\"],[24,\"data-placement\",\"top\"],[16,\"data-original-title\",[30,[36,0],[\"adminglobaldata:view.setting.restoreBackup.title\"],null]],[16,\"aria-label\",[30,[36,0],[\"globaldata.setting.restoreBackup.title\"],[[\"date\"],[[30,[36,3],[[32,2]],null]]]]]],[[\"@iconOnly\",\"@onClick\",\"@disabled\"],[\"bmi-restore-backup\",[32,0,[\"confirmRestoreBackup\"]],[30,[36,2],[[30,[36,1],[[32,2],\"none\"],null],[32,1]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[1,[30,[36,0],[\"globaldata.setting.restoreBackup.title\"],[[\"date\"],[[30,[36,3],[[32,2]],null]]]]],[2,\"\\n\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"octane/bm-modal/backup\",[],[[\"@showModal\",\"@onSave\",\"@onClose\"],[[32,3],[32,0,[\"backup\"]],[32,0,[\"cancelBackup\"]]]],null],[2,\"\\n\\n\"],[8,\"octane/bm-modal/restore-backup\",[],[[\"@show\",\"@onConfirm\",\"@lastBackupDate\",\"@onClose\"],[[32,4],[32,0,[\"restoreBackup\"]],[32,2],[32,0,[\"closeRestoreBackup\"]]]],null]],\"hasEval\":false,\"upvars\":[\"t\",\"eq\",\"or\",\"moment-format\",\"ember-wormhole\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-admin-globaldata/action/backup/template.hbs"
  });
});