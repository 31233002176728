define("boondmanager/adapters/billing", ["exports", "boondmanager/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    pathForType: function pathForType(modelName) {
      return modelName;
    },
    query: function query(store, type, _query) {
      var billingType = _query.billingType;
      var modelName = type.modelName;
      switch (billingType) {
        case 'schedule':
          modelName = 'billing-schedules-balance';
          break;
        case 'monthly':
          modelName = 'billing-monthly-balance';
          break;
        case 'project':
          modelName = 'billing-projects-balance';
          break;
        case 'delivery':
          modelName = 'billing-deliveries-purchases-balance';
          break;
      }
      var url = this.buildURL(modelName, null, null, 'query', _query);
      return this.ajax(url, 'GET');
    }
  });
});