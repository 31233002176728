define("boondmanager/pods/components/octane/bm-gadgets/widget/my-periodicals-targets/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "rQIDx589",
    "block": "{\"symbols\":[\"chart\",\"__arg0\",\"@columns\"],\"statements\":[[6,[37,6],[[30,[36,5],[[30,[36,5],[[32,0,[\"myPeriodicalsTargets\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[10,\"div\"],[15,0,[30,[36,0],[[30,[36,3],[[32,3],2],null],\"col-12 col-xl-6\",\"col-12 col-xl-4\"],null]],[12],[2,\"\\n\\t\\t\"],[8,\"octane/bm-gadget\",[],[[\"@collection\",\"@namedBlocksInfo\"],[true,[30,[36,4],null,[[\"header\",\"content\"],[0,0]]]]],[[\"default\"],[{\"statements\":[[6,[37,0],[[30,[36,1],[[32,2],\"header\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,1,[\"isFulfilled\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[1,[32,1,[\"gadgetDependsOn\",\"chartTitle\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[1,[30,[36,2],[\"common:loading.title\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[30,[36,1],[[32,2],\"content\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,1,[\"isFulfilled\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[8,\"octane/bm-high-charts\",[],[[\"@chartOptions\",\"@content\",\"@height\"],[[32,1,[\"gadgetDependsOn\",\"chartOptions\"]],[32,1,[\"gadgetDependsOn\",\"chartData\"]],\"271px\"]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[8,\"octane/bm-loader\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[2]}]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"-is-named-block-invocation\",\"t\",\"eq\",\"hash\",\"-track-array\",\"each\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-gadgets/widget/my-periodicals-targets/template.hbs"
  });
});